import React, { useState, useEffect } from "react";
import Logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import whiteLogo from "../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import AnimatedShinyText from "../Common/animations/animatedButton";
import arrowBlueRight from "../../Assets/Images/LandingPage/arrow-blue-right.svg";
import arrowWhiteRight from "../../Assets/Images/LandingPage/arrow-small-right.svg";
import { Link } from "react-router-dom";
import "./loader";
import "../../CSS/Header/header.css";
const Header = (props) => {
  const [headerBackground, setHeaderBackground] = useState("transparent");
  const color = props.color;
  const anticolor =
    color === "white" || color === "#193946" ? "black" : "white";
  const [linkbg, setLinkbg] = useState(color);
  const [logo, setLogo] = useState(
    (color === "white" || color === "#193946") &&
      props.type !== "investor" &&
      props.type !== "founder"
      ? whiteLogo
      : Logo
  );
  const handleScroll = () => {
    const elements = document.getElementsByClassName("sign-in-span");
    if (window.scrollY > 100) {
      elements[0].style.color = "#00A6FB";
      elements[1].style.color = "#00A6FB";

      color === "#193946"
        ? setHeaderBackground("#193946")
        : setHeaderBackground("#fff");
      color === "white"
        ? setLinkbg(anticolor)
        : color === "#193946"
        ? setLinkbg("#FFF")
        : setLinkbg(color);
      color === "white"
        ? setLogo(Logo)
        : setLogo(
            props.type !== "investor" && props.type !== "founder"
              ? whiteLogo
              : Logo
          );
    } else {
      elements[0].style.color = props.type === "landing" && "#FFF";
      elements[1].style.color = props.type === "landing" && "#FFF";
      color === "white"
        ? setHeaderBackground("transparent")
        : color === "#193946"
        ? setHeaderBackground("#193946")
        : setHeaderBackground("#fff");
      color === "white"
        ? setLogo(
            props.type !== "investor" && props.type !== "founder"
              ? whiteLogo
              : Logo
          )
        : color === "#193946"
        ? setLogo(whiteLogo)
        : setLogo(Logo);
      color === "#193946" ? setLinkbg("#FFF") : setLinkbg(color);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (color === "#193946") {
      setHeaderBackground("#193946");
      setLinkbg("white");
    }
  }, [color]);
  return (
    <section className="Header">
      <div
        className="landing-header"
        style={{ backgroundColor: headerBackground }}
      >
        <div>
          <Link to="/">
            {" "}
            <img className="company-logo" src={logo} />
          </Link>
        </div>
        <div
          className="cm-header-wrap"
          style={{ backgroundColor: headerBackground }}
        >
          <div className="cm-menu-wrap">
            <div
              className="cm-menu-btn fa fa-align-right"
              style={{ color: linkbg }}
            ></div>
            <div
              className="cm-menu-inner"
              style={{ backgroundColor: headerBackground }}
            >
              <ul className="menu-ul clear-all">
                <li className="has-child">
                  <Link
                    to="/investor"
                    className="text-wrapper-2"
                    style={{
                      color:
                        props.type === "founder"
                          ? "rgba(0, 0, 0, 0.6)"
                          : linkbg,
                      fontWeight: props.type === "investor" ? 500 : 400,
                    }}
                  >
                    For Investors
                  </Link>
                  <Link to="/investor" className="arrow-link">
                    <img src={arrowBlueRight} className="bluearrowright"></img>
                  </Link>
                </li>
                <li className="has-child">
                  <Link
                    to="/founder"
                    className="text-wrapper-2"
                    style={{
                      color:
                        props.type === "investor"
                          ? "rgba(0, 0, 0, 0.6)"
                          : linkbg,
                      fontWeight: props.type === "founder" ? 500 : 400,
                    }}
                  >
                    For Founders
                  </Link>
                  <Link to="/founder" className="arrow-link">
                    <img src={arrowBlueRight} className="bluearrowright"></img>
                  </Link>
                </li>

                <button className="get-started mobile-stats">
                  <div className="content">
                    <div className="text-wrapper">
                      <Link
                        to="/signup"
                        state={{ active: "investor" }}
                        className="get-started-link"
                      >
                        Get Started
                      </Link>
                      <img
                        src={arrowWhiteRight}
                        className="whitearrowright"
                      ></img>
                    </div>
                  </div>
                </button>

                {/* <AnimatedShinyText bgcolor={"#FFFFFF"} color={"#000"} /> */}
                <AnimatedShinyText
                  bgcolor={"[#00A6FB]"}
                  color={"#fff"}
                  type="sign-in"
                />
                <AnimatedShinyText bgcolor={"[#00A6FB]"} color={"#fff"} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
