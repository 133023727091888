import React from "react";
import CompanyOverview from "../../../Common/components/companyOverview";

import { Helmet } from "react-helmet";

const Overview = ({ deal, stage }) => {
  const companyName = deal ? deal.CompanyName : "";

  return (
    <div>
      <Helmet>
        <title>{`Investor | ${companyName} - overview`}</title>
      </Helmet>
      <CompanyOverview deal={deal} reqToEdit={false} stage={stage} />
    </div>
  );
};

export default Overview;
