import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../CSS/Signup/style.css";
import LeftSection from "./LeftSection.jsx";
import RightSection from "./RightSection.jsx";
import { useNavigate } from "react-router-dom";
import { handleNavigation } from "../Common/functions.jsx";
const SignUp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    handleNavigation(navigate);
  }, [navigate]);
  return (
    <>
      <Helmet>
        <title>Fundrev | Sign Up</title>
      </Helmet>
      <div className="signUp-div">
        <LeftSection
          heading="Accelerated Deal Closures"
          content="Fundrev closes deals 75% faster, saving time and resources."
        />
        <div className="investorRegisterWithSignUpText">
          <RightSection />
        </div>
      </div>
    </>
  );
};

export default SignUp;
