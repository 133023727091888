import React, { useState } from "react";
import PasswordValidator from "../../Common/passwordValidator";
import { changePassword } from "../../../endpoints/common";
import Toast from "../Toast";

import { FormControl, FormLabel, Input } from "@mui/joy";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ChangePasswordModal = ({
  passwordModalOpen,
  setPasswordModalOpen,
  token,
  containerId,
}) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    value1: false,
    value2: false,
    value3: false,
  });
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });

  const updateSignInPassword = (newPassword) => {
    setNewPassword(newPassword);
  };
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };
  const handleSubmit = async () => {
    try {
      if (oldPassword === "") {
        Toast("Old password field is empty", "error", containerId);
        return;
      } else if (newPassword !== confirmPassword) {
        Toast("Passwords do not match", "error", containerId);
        return;
      } else if (oldPassword === newPassword) {
        Toast("Old and new passwords cannot be same", "error", containerId);
        return;
      } else if (
        allStates.lowerValidated === false ||
        allStates.upperValidated === false ||
        allStates.numberValidated === false ||
        allStates.specialValidated === false ||
        allStates.lengthValidated === false
      ) {
        Toast(
          "Password does not meet the requirements",
          "warning",
          containerId
        );
        return;
      } else {
        const body = {
          password: oldPassword,
          newPassword: newPassword,
        };
        const response = await changePassword(body, token);
        if (response.error) {
          const statusCode = response.error.response.status;
          if (statusCode === 400) {
            Toast("Password not provided", "error", containerId);
          } else if (statusCode === 401) {
            Toast("Incorrect password", "error", containerId);
          } else if (statusCode === 404) {
            Toast("Member not found", "success", containerId);
          }
        } else if (response.response.status === 200) {
          Toast("Password updated successfully", "success", containerId);
          setPasswordModalOpen(false);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        }
      }
    } catch (e) {
      Toast("Internal server error", "error", containerId);
    }
  };
  return (
    <Dialog
      open={passwordModalOpen}
      PaperProps={{
        component: "form",
        sx: {
          width: "700px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle className="dialog-title">Change Your Password</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setPasswordModalOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="dialogcontent dialogcontent-forget-password">
          <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
            <FormControl
              style={{ marginTop: "20px", marginBottom: "20px" }}
              className="form-control"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="password-label label">
                Old Password
              </FormLabel>
              <Input
                endDecorator={
                  !showPassword.value1 ? (
                    <VisibilityOffIcon
                      onClick={() =>
                        setShowPassword((prevState) => ({
                          ...prevState,
                          value1: true,
                        }))
                      }
                    />
                  ) : (
                    <Visibility
                      onClick={() =>
                        setShowPassword((prevState) => ({
                          ...prevState,
                          value1: false,
                        }))
                      }
                    />
                  )
                }
                className="email-address-input"
                placeholder="Re-enter password"
                color="neutral"
                type={showPassword.value1 ? "text" : "password"}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </FormControl>
            <PasswordValidator
              updatePassword={updateSignInPassword}
              sendStatesToParent={receiveStatesFromChild}
              page="signin"
            />
            <FormControl
              style={{ marginTop: "20px", marginBottom: "20px" }}
              className="form-control"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="password-label label">
                Confirm Password
              </FormLabel>
              <Input
                endDecorator={
                  !showPassword.value2 ? (
                    <VisibilityOffIcon
                      onClick={() =>
                        setShowPassword((prevState) => ({
                          ...prevState,
                          value2: true,
                        }))
                      }
                    />
                  ) : (
                    <Visibility
                      onClick={() =>
                        setShowPassword((prevState) => ({
                          ...prevState,
                          value2: false,
                        }))
                      }
                    />
                  )
                }
                className="email-address-input"
                placeholder="Re-enter password"
                color="neutral"
                type={showPassword.value2 ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Button
              className="button proceed-btn"
              fullWidth
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          </DialogContentText>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
