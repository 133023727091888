import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import greenLine from "../../../../Assets/Images/InvestorPage/Dashboard/greenLine.svg";
import star from "../../../../Assets/Images/InvestorPage/Dashboard/star.svg";
import filledStar from "../../../../Assets/Images/InvestorPage/Dashboard/filledStar.svg";
import "react-circular-progressbar/dist/styles.css";
import "../../../../CSS/InvestorPage/Dashboard/card.css";

const NoNameCard = (props) => {
  const Navigate = useNavigate();
  const [isStarFilled, setIsStarFilled] = useState(false);

  const handleStarClick = async () => {
    setIsStarFilled(!isStarFilled);
  };

  return (
    <>
      <div
        className="Card-div"
        style={{ cursor: "pointer" }}
        onClick={() => Navigate(`/admin/noNameDetails`, { state: { deal: props.deal, token: props.token, fileToPreview: props.fileToPreview } })}
      >
        <div
          className="Card-div-2"
          style={{ display: props.isNew ? "flex" : "none" }}
        >
          New
        </div>
        <img
          src={isStarFilled ? filledStar : star}
          className="card-star"
          alt="star"
          onClick={handleStarClick}
        />
        <div
          className="Card-div-3"
          style={{
            margin: props.isNew ? "10px 0px 0px 0px" : "0px 25px 0px 0px",
          }}
        >
          {props.cardTitle}
        </div>
        <div className="Card-div-4">{props.cardDescription}</div>
        <div className="Card-div-5">
          {props.cardTags.slice(0, 3).map((tag, index) => (
            <div key={index} className="Card-div-6">
              {tag}
            </div>
          ))}
          {props.cardTags.length > 3 && (
            <div className="Card-div-7 blue-text">
              +{props.cardTags.length - 3}
            </div>
          )}
        </div>
        <div className="Card-div-9">
          <div className="Card-div-10">
            <div className="Card-div-11"> ₹{props.revenue}Cr</div>
            <div className="Card-div-12">Revenue</div>
          </div>
          <div className="Card-div-10">
            <div className="Card-div-11">₹{props.grossProfit}</div>
            <div className="Card-div-12">Gross Profit</div>
          </div>
          <div className="Card-div-10">
            <div className="Card-div-11">₹{props.EBITDA}</div>
            <div className="Card-div-12">EBITDA</div>
          </div>
          <div className="Card-div-10">
            <div className="Card-div-11">{props.EBITDA_Percentage}%</div>
            <div className="Card-div-12">EBITDA%</div>
          </div>
        </div>
        <div className="Card-div-22">
          <div className="Card-div-23">
            <div className="Card-div-24">Current Scenario</div>
            <div className="Card-div-25">
              <div className="Card-div-26">
                <div className="Card-div-27">₹{props.IOI_Bids}Cr</div>
                <div className="Card-div-28">IOI bids</div>
              </div>
              <img loading="lazy" src={greenLine} className="Card-img" />
              <div className="Card-div-29">
                <div className="Card-div-30">{props.numInvestors}</div>
                <div className="Card-div-31">Investors</div>
              </div>
            </div>
          </div>
          <CircularProgressbarWithChildren
            value={props.percetageFundingRaised}
            circleRatio={0.75}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
              trailColor: "#eee",
              pathColor: "#00a6fb",
            })}
          >
            <div className="progress-bar-div">
              <div className="progress-bar-div-2">
                ₹{props.totalFundingRaised}Cr
              </div>
              <div className="progress-bar-div-3">
                {props.percetageFundingRaised}% Raised
              </div>
            </div>
            <p className="progress-bar-div-4">₹{props.fundingRequired}Cr</p>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </>
  );
};

export default NoNameCard;
