import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../LandingPage/header";
import "../../../CSS/InvestorPage/style.css";
import InvestorPageFront from "./InvestorPageFront";
import InvestorPageMid1 from "./InvestorPageMid1";
import InvestorPageMid2 from "./InvestorPageMid2";
import InvestorPageMid3 from "./InvestorPageMid3";
import InvestorPageMid4 from "./InvestorPageMid4";
import InvestorPageMid5 from "./InvestorPageMid5";
import InvestorPageMid6 from "./InvestorPageMid6";
import OrbitingCirclesDemo from "../../Common/animations/orbitingCircles";

import Footer from "../../LandingPage/Footer";
const InvestorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="InvestorPage">
      <Helmet>
        <title>Fundrev | Investor</title>
      </Helmet>
      <Header color="black" type="investor" />
      <InvestorPageFront />
      <InvestorPageMid1 />
      <div className="div-26">
        <InvestorPageMid2 />
        <InvestorPageMid3 />
        <InvestorPageMid4 />
      </div>
      <InvestorPageMid5 />
      <InvestorPageMid6 />
      <OrbitingCirclesDemo />
      <div className="landing-page ">
        <Footer />
      </div>
    </section>
  );
};

export default InvestorPage;
