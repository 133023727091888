import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LeftSection from "../../SignUp/LeftSection";
import PasswordValidator from "../../Common/passwordValidator.jsx";
import { Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "../../../CSS/Signup/investorRegister.css";
import Header from "../../LandingPage/header";
import Toast from "../../Common/Toast";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
} from "@mui/joy";
import { useNavigate, Link } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { checkStartupRegistered } from "../../../endpoints/startup";
import { useLoader } from "../../Common/LoaderProvider.jsx";

const CompanyRegister = () => {
  const navigate = useNavigate();
  const loader = useLoader();
  const [companyFormData, setCompanyFormData] = useState({
    name: "",
    companyName: "",
    cin: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    const companyData = JSON.parse(localStorage.getItem("companyDetails"));
    if (companyData) {
      setCompanyFormData({
        name: companyData.name,
        companyName: companyData.companyName,
        cin: companyData.cin,
        phoneNumber: companyData.phoneNumber,
        email: companyData.email,
        password: companyData.password,
        confirmPassword: companyData.confirmPassword,
      });
    }
  }, []);

  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const updatePassword = (newPassword) => {
    setCompanyFormData((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  };
  const handlePhoneChange = (value) => {
    setCompanyFormData((prevState) => ({
      ...prevState,
      phoneNumber: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Object.values(companyFormData).every((value) => value.trim())) {
      Toast("Please fill in all fields.", "warning", "comapnyRegister");
      return;
    }

    if (!matchIsValidTel(companyFormData.phoneNumber)) {
      Toast("Please enter a valid phone number.", "warning", "comapnyRegister");
      return;
    }
    if (
      allStates.lowerValidated === false ||
      allStates.upperValidated === false ||
      allStates.numberValidated === false ||
      allStates.specialValidated === false ||
      allStates.lengthValidated === false
    ) {
      Toast(
        "Password does not meet the requirements",
        "warning",
        "comapnyRegister"
      );
      return;
    }
    if (companyFormData.password !== companyFormData.confirmPassword) {
      Toast("Passwords do not match.", "warning", "comapnyRegister");
      return;
    } else {
      delete companyFormData.confirmPassword;
    }
    localStorage.setItem("companyDetails", JSON.stringify(companyFormData));
    let response;
    try {
      loader.start();
      response = await checkStartupRegistered(companyFormData.email);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data) {
      if (response.data.status === 200) {
        navigate("/companyOnBoarding", {
          state: {
            data: companyFormData,
          },
        }); // Assuming this is the next route
      }
    } else if (response.error.response.status === 409) {
      Toast(
        "Alerady Registered with this email. Please sign in.",
        "info",
        "comapnyRegister"
      );
      setTimeout(() => {
        navigate("/signIn", {
          state: { sentemail: companyFormData.email, type: "Startup" },
        });
      }, 2000);
      return;
    }

    // try {
    //   // Example API call for demonstration
    //   const response = await axios.post("https://api.yourplatform.com/company/signup", companyFormData);
    //
    //   if (
    //     response.data.message === "Please verify your email with the code sent."
    //   ) {
    //     // alert("Form submitted successfully");
    //     // localStorage.setItem("jwt", response.data.data.token);
    //     navigate("/emailVerification", {
    //       state: {
    //         investorEmail: finalFormData.email,
    //         investorName: finalFormData.name,
    //         token: response.data.verificationToken,
    //         investorPhoneNumber:finalFormData.investorPhoneNumber,
    //         type:finalFormData.investorType
    //       },
    //     });
    //   }
    // } catch (error) {
    //   console.error("Error submitting form data:", error);
    //   alert("An error occurred during registration.");
    // }
  };

  return (
    <>
      <Helmet>
        <title>Fundrev | Company Register</title>
      </Helmet>
      <div className="signUp-div">
        <ToastContainer position="top-center" containerId="comapnyRegister" />
        <LeftSection
          heading="Enhanced Founder Capacity"
          content="AI-powered processes free up founder time and mental space for core business activities."
        />
        <div className="investorRegisterWithSignUpText">
          <div className="signInWithHeader investorDetailsPage">
            <Header color="black" type="investor" />
            <div className="investorRegister">
              <div className="section">
                <div className="goBack" onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon />
                  <Typography className="back-text">Back</Typography>
                </div>
                <div className="title">
                  <Typography variant="h6" className="heading">
                    Register Company Account!
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="grey"
                    className="content"
                  >
                    Pitch your startup, connect with investors, and accelerate
                    deal closures with Fundrev's streamlined platform.
                  </Typography>
                </div>
                <div className="form">
                  <div className="form-section Register-form-section">
                    <FormControl
                      className="form-control"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <FormLabel className="label">Company Name</FormLabel>
                      <Input
                        className="input"
                        placeholder="Enter Company Name"
                        color="neutral"
                        name="companyName"
                        value={companyFormData.companyName}
                        onChange={handleChange}
                        required
                      />
                    </FormControl>
                    <FormControl
                      className="form-control"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <FormLabel className="label">CIN/ LLPIN</FormLabel>
                      <Input
                        className="input"
                        placeholder="Enter CIN/ LLPIN Number"
                        color="neutral"
                        type="text"
                        value={companyFormData.cin}
                        name="cin"
                        // value={companyFormData.find((options)=>options.value === cin)}
                        onChange={handleChange}
                        required
                      />
                    </FormControl>
                  </div>

                  <div className="form-section Register-form-section">
                    <FormControl
                      className="form-control"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <FormLabel className="label">Full Name</FormLabel>
                      <Input
                        className="input"
                        placeholder="Enter Name"
                        color="neutral"
                        name="name"
                        value={companyFormData.name}
                        onChange={handleChange}
                        required
                      />
                    </FormControl>
                    <FormControl
                      className="form-control"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <FormLabel className="label">Phone Number</FormLabel>
                      <MuiTelInput
                        defaultCountry="IN"
                        className="input"
                        value={companyFormData.phoneNumber}
                        onChange={handlePhoneChange}
                        required
                      />
                    </FormControl>
                  </div>

                  <div className="form-section Register-form-section">
                    <FormControl
                      className="form-control"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <FormLabel className="label">
                        Official Email Address
                      </FormLabel>
                      <Input
                        className="input"
                        placeholder="Enter work email address"
                        color="neutral"
                        type="email"
                        name="email"
                        value={companyFormData.email}
                        onChange={handleChange}
                        required
                      />
                    </FormControl>
                    <PasswordValidator
                      updatePassword={updatePassword}
                      sendStatesToParent={receiveStatesFromChild}
                    />
                  </div>
                  <div className="form-section Register-form-section">
                    <FormControl
                      className="form-control"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <FormLabel className="label">Confirm Password</FormLabel>
                      <Input
                        endDecorator={
                          !showConfirmPassword ? (
                            <VisibilityOffIcon
                              onClick={() => setshowConfirmPassword(true)}
                            />
                          ) : (
                            <Visibility
                              onClick={() => setshowConfirmPassword(false)}
                            />
                          )
                        }
                        className="input"
                        placeholder="Re-enter password"
                        color="neutral"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={companyFormData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                    </FormControl>
                  </div>
                  <div className="form-section Register-form-section">
                    <Button className="button" fullWidth onClick={handleSubmit}>
                      Save & Continue
                    </Button>
                  </div>
                </div>
                <Typography className="text">
                  <div className="textContent">
                    Already have an account?{" "}
                    <Link
                      to="/signin"
                      style={{ color: "rgba(0, 166, 251, 1)" }}
                    >
                      Sign In
                    </Link>
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyRegister;
