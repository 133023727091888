// sentry-init.js
import * as Sentry from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: "https://ec7d7eaf6b58f905e1559f12da408315@o4507814718013440.ingest.us.sentry.io/4507815058669568", // Use environment variable or hardcode
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, // Adjust based on your needs
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1, // Adjust based on your needs
  replaysOnErrorSampleRate: 1.0,
});

export default Sentry;
