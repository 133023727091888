import React from "react";

import { Link } from "react-router-dom";
import logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import instagram from "../../Assets/Images/LandingPage/instagram.svg";
import facebook from "../../Assets/Images/LandingPage/facebook.svg";
import twitter from "../../Assets/Images/LandingPage/twitter.svg";
import linkedin from "../../Assets/Images/LandingPage/linkedin.svg";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const Navigate = useNavigate();
  const RedirectLink = (phase, page) => {
    const element = document.getElementById(phase);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      Navigate(`/${page}`);
      setTimeout(() => {
        const updatedElement = document.getElementById(phase);
        if (updatedElement) {
          updatedElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  };
  return (
    <section className="Footer">
      <div className="frame-92">
        <div className="frame-93">
          <div className="frame-94 social-links-div">
            <div className="logo-social-links">
              <img
                className="element-FUNDREV-LOGO-3"
                alt="Element FUNDREV LOGO"
                src={logo}
              />
              <div className="social-links mobile-social-links">
                <a href="https://www.instagram.com/fundrev.ai/" target="_blank">
                  <img className="img-6" alt="Vector" src={instagram} />
                </a>
                <a
                  href="https://in.linkedin.com/company/fundrev-ai"
                  target="_blank"
                >
                  <img className="img-6" alt="Vector" src={linkedin} />
                </a>
                <a href="https://x.com/fundrevai" target="_blank">
                  <img className="img-6" alt="Vector" src={twitter} />
                </a>
              </div>
            </div>
            <p className="text-wrapper-14">
              Fueling the synergy between visionary founders and institutional
              investors, our platform is the nexus of innovation and capital.
            </p>
            <div className="social-links laptop-social-links">
              <a href="https://www.instagram.com/fundrev.ai/" target="_blank">
                <img className="img-6" alt="Vector" src={instagram} />
              </a>
              <a
                href="https://in.linkedin.com/company/fundrev-ai"
                target="_blank"
              >
                <img className="img-6" alt="Vector" src={linkedin} />
              </a>
              <a href="https://x.com/fundrevai" target="_blank">
                <img className="img-6" alt="Vector" src={twitter} />
              </a>
            </div>
          </div>
          <div className="frame-95">
            <div className="frame-96">
              <div className="text-wrapper-15">For Investors</div>
              <div className="frame-97">
                <div
                  className="text-wrapper-16"
                  onClick={() => RedirectLink("phase1", "investor")}
                >
                  Investment
                </div>
                <div
                  className="text-wrapper-17"
                  onClick={() => RedirectLink("phase2", "investor")}
                >
                  Portfolio Management
                </div>
                <div
                  className="text-wrapper-17"
                  onClick={() => RedirectLink("phase3", "investor")}
                >
                  Secondaries
                </div>
                <div
                  className="text-wrapper-17"
                  onClick={() => RedirectLink("phase4", "investor")}
                >
                  Fundraising
                </div>
              </div>
            </div>
            <div className="frame-96">
              <div className="text-wrapper-15">For Companies</div>
              <div className="frame-97">
                <div
                  className="text-wrapper-16"
                  onClick={() => RedirectLink("fundraise", "founder")}
                >
                  Fundraise
                </div>
                <div
                  className="text-wrapper-17"
                  onClick={() => RedirectLink("investorreporting", "founder")}
                >
                  Investor Reporting
                </div>
              </div>
            </div>
            {/* <div className="frame-96">
              <div className="text-wrapper-15">Support</div>
              <div className="frame-97">
                <div className="text-wrapper-16">About Us</div>
                <div className="text-wrapper-17">Help</div>
                <div className="text-wrapper-17">FAQ</div>
                <div className="text-wrapper-17">Resources</div>
              </div>
            </div> */}
            <div className="frame-96">
              <div className="text-wrapper-15">Contact</div>
              <div className="frame-97">
                <a href="tel:+918100199003" style={{ textDecoration: "none" }}>
                  <div className="text-wrapper-16">+91 81001 99003</div>
                </a>
                <a
                  href="mailto:hello@fundrev.ai"
                  style={{ textDecoration: "none" }}
                >
                  <div className="text-wrapper-16">hello@fundrev.ai</div>
                </a>
                <div className="text-wrapper-16">
                  <p>
                    Wework Prestige Cube,
                    <br /> Bangalore-560030
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-disclosure">
          <p>
            We strive to comply with all applicable laws in connection with the
            services offered through this platform. Please note that Fundrev is
            not a stock exchange recognized by the Securities and Exchange Board
            of India (SEBI) under the Securities Contract (Regulation) Act,
            1956, and does not serve as a fundraising platform. Fundrev, through
            its platform, only facilitates interactions between investors and
            target companies in a private and restricted setting. No securities
            are offered by any company on this platform.
          </p>
          <br />
          <p>
            We encourage you to read the terms of use carefully before utilizing
            this platform or any of the services provided.
          </p>
        </div>
        <div className="terms-conditions">
          <div className="frame-98">
            <div>
              <p className="text-wrapper-19 copyright">
                © 2024 Fundrev Technologies Private Limited. All Right Reserved.
              </p>
            </div>
            <div className="frame-99">
              <Link to="/terms-of-use">
                <div className="text-wrapper-16">Terms &amp; Conditions</div>
              </Link>
              <Link to="/privacy-policy">
                <div className="text-wrapper-16">Privacy Policy</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
