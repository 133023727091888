import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./topbar";
import { useZoomEffect } from "../../../Common/functions";
import { getInvestorsByDealID } from "../../../../endpoints/deal";

import { useLocation, useNavigate } from "react-router-dom";
const InvestorStages = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const { dealId, fundrevAnalystID, token, companyName } = location.state || {};
  const [investors, setInvestors] = useState([]);
  const [dealInteractions, setDealInteractions] = useState([]);
  const fetchInvestorsByDealId = async () => {
    try {
      const response = await getInvestorsByDealID(dealId, token);
      setDealInteractions(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchInvestorsByDealId();
  }, [dealId]);
  useZoomEffect();
  return (
    <div className="InvestorSide">
      <Sidebar active="opportunities" />
      <Topbar title="Company Preview" />
      <div className="InvestorSide-box">
        {dealInteractions.length > 0 ? (
          <table className="investor-stage-table">
            <thead>
              <tr>
                <th>Investor Name</th>
                <th>Stage</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dealInteractions.map((dealInteraction) => {
                return (
                  <tr>
                    <td>{dealInteraction.investorID}</td>
                    <td>{dealInteraction.Stage}</td>
                    <td>
                      {dealInteraction.Stage === "requestAccepted" ? (
                        <button
                          className="toggleStatusbtn"
                          onClick={() => {
                            Navigate("/analyst/signNDA", {
                              state: {
                                dealId: dealId,
                                companyName: companyName,
                                investorID: dealInteraction.investorID,
                                stage: "requestAccepted",
                                token: token,
                                fundrevAnalystID: fundrevAnalystID,
                              },
                            });
                          }}
                        >
                          Upload NDA
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          "No Investors with associated with deal are found"
        )}
      </div>
    </div>
  );
};

export default InvestorStages;
