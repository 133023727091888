import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { investorState, saveState } from "../investorState";
import ProfileBox from "./profileBox";
import Notifications from "./notifications";
import { fetchInvestorMemberDetails } from "../../../../endpoints/investor";
import CornerProfileBox from "./cornerProfileBox";

import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import notificationicon from "../../../../Assets/Images/InvestorPage/Dashboard/notificationicon.svg";
import searchicon from "../../../../Assets/Images/InvestorPage/Dashboard/searchicon.svg";
import settingsicon from "../../../../Assets/Images/InvestorPage/Dashboard/settingsicon.svg";
import { ClickAwayListener } from "@mui/material";
import { Button } from "@mui/joy";

const Topbar = ({ title }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [investorProfile, setInvestorProfile] = useState("");
  const { ProfileBoxOpen } = useRecoilValue(investorState);
  const [state, setState] = useRecoilState(investorState);
  const [modal, setModal] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchInvestorMemberDetails(token);
      }
      if (token) {
        if (response && response.data !== null) {
          setName(response.data.data.name);
          setInvestorProfile(response.data.data.memberImg);
          const newState = {
            ...state,
            investorId: response.data.data.investorID,
            name: response.data.data.name,
            profileCompleted: response.data.data.profileCompleted,
          };
          setState(newState);
        } else {
        }
      }
    };

    fetchDetails();
  }, [token, investorState]);
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };
  const handleProfileBoxOpen = () => {
    setModal(!modal);
  };

  const notifications = [
    // {
    //   title: "Your deal request has been accepted",
    //   company: "Amazon",
    //   time: "0 min",
    //   img: "https://cdn.builder.io/api/v1/image/assets/TEMP/7dd6d1e2baaacbc86a6d1fea7af195373f00bd5383e407647c14fd93384554e0?apiKey=32c42a50fb8f44509096999a9c1a8276&width=100",
    // },
    // {
    //   title: "Your IOI Bid request has been accepted",
    //   company: "Nykaa",
    //   time: "6 min",
    //   img: "https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&",
    // },
  ];

  const handleClickAway = () => {
    setModal(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="topbardiv">
        <ProfileBox modal={modal} />
        <Notifications notifications={notifications} />
        <div className="topbardiv-2">{title}</div>
        <div className="topbardiv-3">
          {/* <div className="topbardiv-4">
            <img
              loading="lazy"
              src={searchicon}
              className="img"
            />
            <input 
              className='topbardiv-5' 
              placeholder='Search here'
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div> */}
          <div className="topbar-utilities">
            <div className="settings">
              {/* <img
                loading="lazy"
                src={settingsicon}
                className="topbarimg-4"
                
              /> */}
              {state.profileCompleted ? (
                <Button
                  color="success"
                  onClick={() => {
                    navigate("/investor/account", {
                      state: {
                        activeButton: 2,
                      },
                    });
                  }}
                >
                  <p>Profile completed</p>
                </Button>
              ) : (
                <Button
                  sx={{
                    border: "1px solid #F4BD50",
                    backgroundColor: "transparent",
                    color: "#F4BD50",
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => {
                    navigate("/investor/account", {
                      state: {
                        activeButton: 2,
                      },
                    });
                  }}
                >
                  <p>Complete Profile</p>
                </Button>
              )}
            </div>
            {/* <div className='notifications'>
              <img
                loading="lazy"
                onClick={handleOpen}
                src={notificationicon}
                className="topbarimg-5"
              />
            </div> */}
            <CornerProfileBox modal={modal} setModal={setModal} />
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Topbar;
