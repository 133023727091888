import React,{useState,useEffect} from 'react'
import Arrow from "../../Assets/Images/LandingPage/arrow-small-right.svg"
import frame11 from "../../Assets/Images/LandingPage/frame11.svg"
import frame11small from "../../Assets/Images/LandingPage/frame11small.svg"
import { useNavigate } from 'react-router-dom'
const LandingPageMid2 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const frame11Url = windowWidth > 991 ? frame11 : frame11small

  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className='LandingPageMid LandingPageMid2'>
       
        <img src={frame11Url} alt="frame11" className='frame-49'></img>
       
        
        <div className="frame-77">
            <div className="frame-74">
                <div className="frame-75">
                    <div className="frame-48">
                        <div className="text-wrapper-7">FOR INVESTORS</div>
                    </div>
                    <div className="frame-76">
                        <h2 className='Lets curated-deals laptop-deals'>Curated Deals <br/>from Active<br/><span className="Fundrev"> Fundraisers</span></h2>
                        <h2 className='Lets curated-deals mobile-deals'>Curated Deals from <br/>Active<span className="Fundrev"> Fundraisers</span></h2>
                    </div>
                    <h3 className="text-wrapper-11">
                    Say goodbye to mismatched investment opportunities. Fundrev offers a tailored 
                    selection that meets your criteria, connecting you with active, innovative 
                    fundraisers. Simply express interest, expedite due diligence, and close deals 
                    efficiently. Embrace a smarter, streamlined investment experience with Fundrev. 
                    </h3>
                </div>
                <button className="button-3" onClick={()=>navigate("/investor")}>
                    <div className="content">
                        <div className="text-wrapper-6">Learn More</div>
                    </div>
                    <img className="img-5" alt="Heroicons outline" src={Arrow} />
                </button>
            </div>
        </div> 
    </section>
  )
}

export default LandingPageMid2
