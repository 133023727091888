import React,{useState,useEffect} from 'react'

import deal1 from  "../../../Assets/Images/InvestorPage/deal-1.svg"
import deal1small from  "../../../Assets/Images/InvestorPage/deal1small.svg"
import deal2 from  "../../../Assets/Images/InvestorPage/deal-2.svg"
import deal2small from  "../../../Assets/Images/InvestorPage/deal2small.svg"
import deal3 from  "../../../Assets/Images/InvestorPage/deal-3.svg"
import deal3small from  "../../../Assets/Images/InvestorPage/deal3small.svg"
import blueup from  "../../../Assets/Images/InvestorPage/up.svg"
import dropdown from  "../../../Assets/Images/InvestorPage/dropdown.svg"
const InvestorPageMid4 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const deal1Url = windowWidth > 991 ? deal1 : deal1small
  const deal2Url = windowWidth > 991 ? deal2 : deal2small
  const deal3Url = windowWidth > 991 ? deal3 : deal3small
  const [activeDiv2, setActiveDiv2] = useState("div-7");
  const [activeParentDiv2, setActiveParentDiv2] = useState("div-192");
  const [activeImg2,setActiveImg2] = useState("img-17")
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    const handleClick = (div,ParentDiv,img) =>{
        // Deactivate all divs
        setActiveImg2(img);
        const divs = document.querySelectorAll('.active-div-2');
        const Parentdivs = document.querySelectorAll('.active-parent-div-2');
        divs.forEach((el) => {
          el.classList.remove('active-div-2');
          el.classList.add('inactive-div-2');
        });
        Parentdivs.forEach((el) => {
          el.classList.remove('active-parent-div-2');
          el.classList.add('inactive-parent-div-2');
        });
    
        // Activate the clicked div
        setActiveDiv2(div);
        setActiveParentDiv2(ParentDiv)
        const element = document.getElementById(div);
        const Parent = document.getElementById(ParentDiv);
        element.classList.remove("inactive-div-2");
        Parent.classList.remove("inactive-parent-div-2");
        element.classList.add("active-div-2");
        Parent.classList.add("active-parent-div-2");
      }
      let imgSrc;
      if (activeDiv2 === "div-7") {
        imgSrc = deal1Url;
      } else if (activeDiv2 === "div-8") {
        imgSrc = deal2Url;
      } else if (activeDiv2 === "div-9") {
        imgSrc = deal3Url;
      }
  return (
    <section className='InvestorPageMid4'>
      <div className="div-186">
        <div className="div-187">
            <div className="column-14 laptop-img">
              <img className='deal-img' src={imgSrc}></img>
            </div>
            <div className="column-15">
            <div className="div-189">
                <div className="div-190">Deal Close</div>
                <div className="div-191">
                Close deals faster with hassle free negotiation and deal
                documentation
                </div>
                <div className="div-192 active-parent-div-2" id="div-192" onClick={()=>handleClick("div-7","div-192","img-17")}>
                <div className='div-193'>01.</div>
                <div className="div-194">
                Facilitate end-to-end deal closure from IOI to SPA
                </div>
                <img
                    loading="lazy"
                    className="img-17"
                    src={activeImg2==="img-17"?blueup:dropdown}
                />
                </div>
                <div className="div-195 active-div-2" id="div-7">
                <p>Seamlessly progress from sending IOI bids to approval,diligence, LOI bids, and SPA preparation—all within the platform for comprehensive and efficient investment management.</p>
                <div className="column-14 mobile-img">
                  <img className='deal-img' src={imgSrc}></img>
                </div>
                </div>
                <div className="div-196 inactive-parent-div-2"  id="div-196" onClick={()=>handleClick("div-8","div-196","img-18")}>
                <div className='div-197'>02.</div>
                <div className="div-198 ">
                Access controls to safeguard against data breaches
                </div>
                <img
                    loading="lazy"
                    className="img-18"
                    src={activeImg2==="img-18"?blueup:dropdown}
                />
                </div>
                <div className="div-195 inactive-div-2" id="div-8">
                <p>Improve security by eliminating document exchanges and controlled access. Streamline negotiations with redlining, contextual comments, and version control features for seamless collaboration.</p>
                <div className="column-14 mobile-img">
                  <img className='deal-img' src={imgSrc}></img>
                </div>
                </div>
                <div className="div-199 inactive-parent-div-2" id="div-199" onClick={()=>handleClick("div-9","div-199","img-19")}>
                <div className='div-200'>03.</div>
                <div className="div-201">
                Finalize investments using digital signatures
                </div>
                <img
                    loading="lazy"
                    src={activeImg2==="img-19"?blueup:dropdown}
                    className="img-19"
                />
                </div>
                <div className="div-195 inactive-div-2" id="div-9">
                <p>Streamline the closing process. Sign legally binding documents digitally through Fundrev for secure and efficient investment closure.</p>
                <div className="column-14 mobile-img">
                  <img className='deal-img' src={imgSrc}></img>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
  )
}

export default InvestorPageMid4
