import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";

const CompanyMapperTable = ({
  data,
  editData,
  onNameChange,
  onVisibilityChange,
}) => {
  return (
    <>
      <Paper
        sx={{
          width: "50%",
        }}
      >
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Visibility</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Update Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      padding: "0",
                    }}
                  >
                    <Switch
                      checked={
                        index < 5 // First 5 items are always visible and disabled
                          ? true
                          : editData[index]?.isVisible !== undefined
                          ? editData[index].isVisible
                          : row.isVisible
                      }
                      disabled={index < 5} // Disable the switch for the first 5 rows
                      onChange={(e) =>
                        onVisibilityChange(index, e.target.checked)
                      } // Handle the change for isVisible
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0",
                    }}
                  >
                    {row.name} {/* Display the name from original tableData */}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={editData[index]?.name || row.name} // Use editData for editing
                      onChange={(e) => onNameChange(index, e.target.value)} // Update editData when changes are made
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default CompanyMapperTable;
