import React, { useState } from "react";
import "../../../../CSS/FounderPage/Dashboard/deal.css";
import { Switch } from "@mui/material";

const DealHeader = ({ deal, len }) => {
  // const [isChecked, setIsChecked] = useState(true);
  return (
    <>
      <div className="header-div">
        <div className="header-div-2">
          <div className="dealId-div">
            <div className="dealId-text">Deal ID: {deal.DealID}</div>
            <div className="investor-text">{len} Investors</div>
          </div>
          {/* <div className="header-div-3">
            <div className="pause-deal-div">
              <div className="pause-deal-div-2">
                <div className="pause-deal-text">Pause Deal</div>
                <Switch checked = {isChecked} onChange={()=>setIsChecked(!isChecked)}/>
                <div className="live-deal-text">Live</div>
              </div>
            </div>
            <div className="close-text">Close</div>
            <div className="delete-text">Delete</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DealHeader;
