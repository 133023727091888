import React from "react";
import { Checkbox } from "@mui/joy";

const CheckboxGroup = ({
  title,
  options,
  selectedOptions,
  setSelectedOptions,
  className,
}) => {
  const handleCheckboxChange = (label, checked) => {
    setSelectedOptions((prev) =>
      checked ? [...prev, label] : prev.filter((item) => item !== label)
    );
  };

  return (
    <div
      className={`deals-filter-option ${className}`}
      style={{
        borderBottom:
          title === "Industry Vertical" ? "1px solid #CACACA" : "0px",
      }}
    >
      <p>{title}</p>
      <div className={`deals-checkboxes`}>
        {options.map((option, index) => (
          <Checkbox
            key={index}
            label={option}
            className="deal-checkbox"
            checked={selectedOptions.includes(option)}
            onChange={(e) => handleCheckboxChange(option, e.target.checked)}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
