import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Front from "./front";
import Header from "./header";
import LandingPageMid1 from "./LandingPageMid1";
import LandingPageMid2 from "./LandingPageMid2";
import LandingPageMid4 from "./LandingPageMid4";
import ElevateExperience from "./ElevateExperience";
import OrbitingCirclesDemo from "../Common/animations/orbitingCircles";
import data from "./data";
import Footer from "./Footer";
import Slider from "./carousel";
import "../../CSS/LandingPage/styles.css";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Landing-Page">
      <Helmet>
        <title>Fundrev | Home</title>
      </Helmet>
      <Header color="white" type="landing" />
      <Front />
      <div className="LandingPageMid-div-1 frame-36 landing-page middle-section">
        <LandingPageMid1 />
        <LandingPageMid2 />
      </div>
      <div>
        <Slider data={data} activeSlide={2} />
      </div>
      <div className="LandingPageMid-div frame-36 landing-page middle-section">
        <LandingPageMid4 />
      </div>
      <OrbitingCirclesDemo />
      <div className="landing-page footer-landing-page">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
