import React from 'react'

import { useNavigate } from 'react-router-dom';
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";

import { Helmet } from 'react-helmet';
const InvestorRevoked = () => {
   const navigate = useNavigate();
  return (
    <div className='investorRevoked-screen'>
      <Helmet>
        <title>Investor | Invalid Invite</title>
      </Helmet>
      <img 
        className='investorRevoked-fundrev-logo'
        onClick={() => navigate('/')}
        src={Logo}
      />
      <div className="invalid-invite-div">
        <div className="invalid-invite-div-2">
            <div className="invalid-invite-div-3">Invite Invalid</div>
            <div className="invalid-invite-div-4">
                This invite may be revoked, or you might not have the permission to
                join.
            </div>
            <button 
                className="invalid-invite-div-5"
                onClick={() => navigate('/')}
            >Continue to Fundrev
            </button>
        </div>
      </div>
    </div>
  )
}

export default InvestorRevoked
