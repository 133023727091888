import {
  Box,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const TopCustomerTable = ({ data }) => {
  const [option, setOption] = useState(`ltm`);
  const handleSelect = (event) => {
    setOption(event.target.value);
  };
  const headers = data?.data?.header;
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: "569px",
          height: "453px",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
          fontFamily: "Karla, sans-serif",
          padding: 2,
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <Box
          display={`flex`}
          alignItems={`center`}
          justifyContent={`space-between`}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Karla,sans-serif",
              color: "#3C5168",
            }}
          >
            {data.name}
          </p>
          <Select
            value={option}
            onChange={handleSelect}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              width: "173px",
              height: "32px",
              fontFamily: "Karla,sans-serif",
              fontSize: "14px",
              fontWeight: "400",
              color: "#636363",
              borderRadius: 1,
            }}
          >
            <MenuItem
              sx={{
                fontFamily: "Karla,sans-serif",
                fontSize: "14px",
                fontWeight: "400",
              }}
              value={`ltm`}
            >
              LTM
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: "Karla,sans-serif",
                fontSize: "14px",
                fontWeight: "400",
              }}
              value={`overall`}
            >
              Overall
            </MenuItem>
          </Select>
        </Box>
        <Paper
          sx={{
            marginTop: "10px",
            width: "509px",
            overflow: "hidden",
            boxShadow: "0",
          }}
        >
          <TableContainer>
            <Table aria-label="Top 10 Customer Table" size="small">
              <TableHead>
                <TableRow>
                  {headers?.map((header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        width: "105px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: 700,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        border: "1px solid #B8B8B8",
                        paddingY: "2.5px",
                      }}
                    >
                      {header !== "Revenue" && header}
                      {header === "Revenue" && (
                        <Box
                          sx={{
                            display: `flex`,
                            gap: "2px",
                            alignItems: "baseline",
                          }}
                        >
                          Revenue
                          <Typography
                            sx={{
                              fontFamily: `Karla, sans-serif`,
                              fontSize: "10px",
                              fontWeight: "700px",
                              color: "#6B7494",
                            }}
                          >
                            (in lakhs)
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.[option].map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        width: "169px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: index === 10 || index === 12 ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: (index === 10 || index === 12) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        paddingY: "2.5px",
                        borderTop:
                          (index === 10 || index === 12) && "2px solid #00A6FB",
                      }}
                    >
                      {row.customer}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: index === 10 || index === 12 ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: (index === 10 || index === 12) && "#00A6FB",
                        paddingY: "2.5px",
                        border: "1px solid #B8B8B8",
                        borderTop:
                          (index === 10 || index === 12) && "2px solid #00A6FB",
                      }}
                    >
                      {/* {Math.round(row.revenue)} */}
                      {formatToLakhs(row.revenue)}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: index === 10 || index === 12 ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color: (index === 10 || index === 12) && "#00A6FB",
                        border: "1px solid #B8B8B8",
                        fontStyle: "italic",
                        paddingY: "2.5px",
                        borderTop:
                          (index === 10 || index === 12) && "2px solid #00A6FB",
                      }}
                    >
                      {row.percentage}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default TopCustomerTable;
