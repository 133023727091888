import React,{useState} from 'react'
import plus from "../../../Assets/Images/InvestorPage/plus.svg"
import minus from "../../../Assets/Images/InvestorPage/minus.svg"
const InvestorPageMid6 = () => {
  const [activeAnswerDiv, setActiveAnswerDiv] = useState("div-15");
  const [activeAnswerParentDiv, setActiveAnswerParentDiv] = useState("div-10");
  const [activeImg,setActiveImg] = useState("img-2")
  const handleClick = (div, ParentDiv, img) => {
    const divs = document.querySelectorAll('.active-answer-div');
    const parentDivs = document.querySelectorAll('.active-answer-parent-div');
    
    // Remove active classes from all elements
    divs.forEach(el => el.classList.replace('active-answer-div', 'inactive-answer-div'));
    parentDivs.forEach(el => el.classList.replace('active-answer-parent-div', 'inactive-answer-parent-div'));

    // Toggle active classes for clicked elements
    const element = document.getElementById(div);
    const parent = document.getElementById(ParentDiv);
    const isActive = activeImg === img;

    setActiveImg(isActive ? "" : img);
    element.classList.replace(isActive ? 'active-answer-div' : 'inactive-answer-div', isActive ? 'inactive-answer-div' : 'active-answer-div');
    parent.classList.replace(isActive ? 'active-answer-parent-div' : 'inactive-answer-parent-div', isActive ? 'inactive-answer-parent-div' : 'active-answer-parent-div');
};
  return (
    <section className='InvestorPageMid6'>
      <div className="div-219">
          <div className="div-220">frequently ask question</div>
          <div className="div-221">Have questions? Find answers</div>
          
          
          <div className="div-224">
            <div className="div-225 active-answer-parent-div" id="div-11" onClick={()=>handleClick("div-16","div-11","img-2")}>
              <div className="div-226">
                Can I trust the information I received from Fundrev?
              </div>
              <img
                loading="lazy"
                src={activeImg==="img-2"?minus:plus}
                className="img-23 "
                id="img-2"
              />
            </div>
            <div className="div-227 active-answer-div" id="div-16">
            Fundrev performs quality checks to validate the accuracy of information provided by companies on the platform, although it does not ensure its complete veracity.
            </div>
          </div>
          <div className="div-224">
            <div className="div-225 inactive-answer-parent-div" id="div-12" onClick={()=>handleClick("div-17","div-12","img-3")}>
              <div className="div-226">
              What is the pricing charged to the investors for investment?
              </div>
              <img
                loading="lazy"
                src={activeImg==="img-3"?minus:plus}
                className="img-23"
                id="img-3"
              />
            </div>
            <div className="div-227 inactive-answer-div" id="div-17">
            Fundrev enables investors to explore, connect, and invest without incurring any subscription or finder's fees.
            </div>
          </div>
          <div className="div-224">
            <div className="div-225 inactive-answer-parent-div" id="div-13" onClick={()=>handleClick("div-18","div-13","img-4")}>
              <div className="div-226">
              Can I register as an Angel Investor on Fundrev?
              </div>
              <img
                loading="lazy"
                src={activeImg==="img-4"?minus:plus}
                className="img-23"
                id="img-4"
              />
            </div>
            <div className="div-227 inactive-answer-div" id="div-18">
            At present, angel investors can only access specific deals by invitation when the lead investor opts to onboard them for a particular opportunity, without the ability to filter new investment opportunities independently.
            </div>
          </div>
          <div className="div-224 last-question">
            <div className="div-225 inactive-answer-parent-div" id="div-14" onClick={()=>handleClick("div-19","div-14","img-5")}>
              <div className="div-226">
              Is it possible for me to purchase 100% ownership of companies listed on Fundrev?
              </div>
              <img
                loading="lazy"
                src={activeImg==="img-5"?minus:plus}
                className="img-23"
                id = "img-5"
              />
            </div>
            <div className="div-227 inactive-answer-div" id="div-19">
            Fundrev enables direct discussions between investors and companies listed on the platform. Additionally, if a company expresses interest in selling its ownership stake, the platform accommodates such listings.
            </div>
          </div>
        </div>
    </section>
  )
}

export default InvestorPageMid6
