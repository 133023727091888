import React,{useState,useEffect} from 'react'
import step1 from "../../../Assets/Images/FounderPage/step1.svg"
import step1small from "../../../Assets/Images/FounderPage/step1small.svg"
const FoundersPageMid2 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const step1Url = windowWidth > 991 ? step1 : step1small
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className='InvestorPageMid2'>
    <div className="div-27">
    <div className="div-28">
        <div className='sourcing-div'>
            <img className='sourcing-img' src={step1Url}></img>
        </div>
        <div className="column-9">
        <div className="div-103">
            <div className="div-104">STEP 01</div>
            <div className="div-105">Create a Compelling Profile</div>
            <p className='div-103-content'>Create a thorough profile for your startup on Fundrev. Include key details like your mission, market opportunity, traction, and funding requirements. Upload your pitch deck, financial projections, and relevant documents to showcase your potential to investors. </p>
            </div>
        </div>
    </div>
    </div>
    </section>
  )
}

export default FoundersPageMid2
