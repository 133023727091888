import "./sentry-init";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { HashRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { Navigate, Outlet } from "react-router-dom";
import "./index.css";
import Loader from "./Components/Common/Loader";
import mixpanel from "mixpanel-browser";

//common pages
import Home from "./Components/LandingPage/home";
import PrivacyPolicy from "./Components/LandingPage/privacyPolicy";
import TermsOfUse from "./Components/LandingPage/termsOfUse";
import InvestorPage from "./Components/Investor/LandingPage/InvestorPage";
import FoundersPage from "./Components/Founders/LandingPage/FoundersPage";
import SignUp from "./Components/SignUp/SignUp";
import SignIn from "./Components/SignIn/SignIn";
import VerificationPage from "./Components/Common/pages/VerificationPage";
import NoRoutesMatched from "./Components/Common/pages/noRoutesMatched";
import WithMobileRedirect from "./Components/Common/pages/mobileRedirect";
import MobileRedirectPage from "./Components/Common/pages/mobileRedirectPage";

//Investor Onboarding pages
import InvestorRegister from "./Components/Investor/RegisterPage/InvestorRegister";
import Investordetails from "./Components/Investor/OnboardingPage/investordetails";
import InvestorMemberSignUp from "./Components/Investor/RegisterPage/investorMemberSignUp";
import InvestorMembersOnBoarding from "./Components/Members/onBoardingPage/investorMemberOnBoarding";
import InvestorRevoked from "./Components/Investor/RegisterPage/investorRevoked";
import NoNameDeal from "./Components/Investor/LandingPage/noNameDeal";
import NoNameDeals from "./Components/Investor/LandingPage/noNameDeals";
import InvestorDealRegister from "./Components/Investor/RegisterPage/investorDealRegister";
import InvestorDealSignIn from "./Components/Investor/RegisterPage/investorDealSignIn";

//Investor dashboard pages
import Opportunities from "./Components/Investor/Dashboard/Opportunities/OpportunitiesPage";
import OngoingDeals from "./Components/Investor/Dashboard/ongoingDeals";
import Monitoring from "./Components/Investor/Dashboard/monitoring";
import InvestorSideCompanyDetails from "./Components/Investor/Dashboard/investorSideCompanyDetails";
import CompanyDetailsPage from "./Components/Investor/Dashboard/companyDetails/CompanyDetailsPage";
import Wishlist from "./Components/Investor/Dashboard/Opportunities/Wishlist";
import InvestorAgreement from "./Components/Investor/Dashboard/agreement";
import AccountPage from "./Components/Investor/Dashboard/account/accountPage";
import InvestorNoRoutesMatched from "./Components/Investor/noRoutesMacthed/InvestorNoRoutesMatched";
import StartupNoRoutesMatched from "./Components/Founders/noRoutesMatched/StartupNoRoutesMatched";

//company onboarding pages
import CompanyRegister from "./Components/Founders/RegisterPage/CompanyRegister";
import CompanyOnBoarding from "./Components/Founders/RegisterPage/CompanyOnboarding";
import CompanyDetails from "./Components/Founders/OnboardingPage/companyDetails";
import CompanyWaitlistPage from "./Components/Founders/RegisterPage/companyWaitlistPage";
import CompanyMemberSignUp from "./Components/Founders/RegisterPage/CompanyMemberSignUp";
import StartupMembersOnBoarding from "./Components/Members/onBoardingPage/startupMembersOnBoarding";
import CompanyProfilePreview from "./Components/Founders/OnboardingPage/profilePreview";
import RedirectToProfilePreview from "./Components/SignIn/redirectToProfilePreview";

//Company Dashboard pages
import Fundraising from "./Components/Founders/Dashboard/Fundraising/fundraising";
import InvestorDetails from "./Components/Founders/Dashboard/investorDetails/investorDetails";
import Dataroom from "./Components/Founders/Dashboard/MyProfile/dataroom";
import CompanyAgreemnt from "./Components/Founders/Dashboard/agreement";
import MyProfile from "./Components/Founders/Dashboard/MyProfile/myProfile";
import CompanyAccountPage from "./Components/Founders/Dashboard/account/accountPage";

//Admin panel pages
import AdminPanel from "./Components/Admin/adminPanel";
import AdminSignIn from "./Components/Admin/adminSignIn";
import OpportunitiesPreview from "./Components/Admin/Investor/Dashboard/OpportunitiesPreview";
import InvestorPannel from "./Components/Admin/Investor/Onboarding/InvestorPannel";
import InvestorPreview from "./Components/Admin/Investor/Onboarding/InvestorPreview";
import DataroomUpload from "./Components/Admin/Investor/Dashboard/DataroomUpload";
import InvestorStages from "./Components/Admin/Investor/Dashboard/investorStages";
import AnalystSignNDA from "./Components/Admin/signNDA";
import AnswerQnAs from "./Components/Admin/Investor/Dashboard/answerQnAs";
import DealDetailsEdit from "./Components/Admin/Investor/Dashboard/dealDetailsEdit";
import AnalyticsMapper from "./Components/Admin/Company/AnalyticsMapper";
import CompanyAnalytics from "./Components/Founders/Dashboard/MyProfile/CompanyAnalytics";
import { LoaderProvider } from "./Components/Common/LoaderProvider";
import AdminAnalytics from "./Components/Admin/Investor/Dashboard/AdminAnalytics";
import DownloadPdf from "./Components/Admin/Investor/Dashboard/downloadPdf";
import UnifiedInvestorPreview from "./Components/Admin/UnifiedInvestor/unifiedInvestorPreview";

const RedirectRoute = ({ element: Component, redirectTo, ...rest }) => {
  const WrappedComponent = WithMobileRedirect(Component, redirectTo);
  return <WrappedComponent {...rest} />;
};

const PrivateRoutes = ({ role }) => {
  let auth = { token: localStorage.getItem("token") };
  let userRole = localStorage.getItem("role");
  return auth.token && userRole === role ? (
    <Outlet />
  ) : role === "admin" ? (
    <Navigate to="adminsignin" />
  ) : (
    <Navigate to="/signin" />
  );
};

// Initialize Google Analytics when the app loads
ReactGA.initialize("G-ZZ3KTT98CP");

// Near entry of your product, init Mixpanel
mixpanel.init("7828b0f3d2d16da7f81d226f6ae8695c", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

export default function App() {
  useEffect(() => {
    // Track the initial page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <RecoilRoot>
      <HashRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/emailVerification" element={<VerificationPage />} />
          <Route path="/loading" element={<Loader />} />
          {/* Investor Routes */}
          <Route path="/investor" element={<InvestorPage />} />
          <Route path="/investorRegister" element={<InvestorRegister />} />
          <Route
            path="/investor/deal/Register"
            element={<InvestorDealRegister />}
          />
          <Route
            path="/investor/deal/signIn"
            element={<InvestorDealSignIn />}
          />
          <Route path="/investor/revoked" element={<InvestorRevoked />} />
          <Route
            path="/investor/*"
            element={
              <RedirectRoute
                element={InvestorNoRoutesMatched}
                redirectTo="/mobileRedirect"
              />
            }
          />
          <Route
            path="/investorMemberSignup/:id"
            element={<InvestorMemberSignUp />}
          />
          <Route
            path="/investorMembersOnBoarding"
            element={<InvestorMembersOnBoarding />}
          />
          <Route path="/investor/deals" element={<NoNameDeals />} />
          <Route element={<PrivateRoutes role="investor" />}>
            <Route path="/investor/deal" element={<NoNameDeal />} />
            <Route path="/investorDetails" element={<Investordetails />} />
            <Route
              path="/investor/Opportunities"
              element={
                <RedirectRoute
                  element={Opportunities}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/ongoingDeals"
              element={
                <RedirectRoute
                  element={OngoingDeals}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/monitoring"
              element={
                <RedirectRoute
                  element={Monitoring}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/wishlist"
              element={
                <RedirectRoute
                  element={Wishlist}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/companyDetails"
              element={
                <RedirectRoute
                  element={InvestorSideCompanyDetails}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/signNDA"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/IOIBid"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/LOIAgreement"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/spaNegotiation"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/companyDetails/ndaSigned"
              element={
                <RedirectRoute
                  element={CompanyDetailsPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/account"
              element={
                <RedirectRoute
                  element={AccountPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
          </Route>

          {/* Founder Routes */}
          <Route path="/founder" element={<FoundersPage />} />
          <Route path="/companyRegister" element={<CompanyRegister />} />
          <Route
            path="/company/register"
            element={<RedirectToProfilePreview />}
          />
          <Route path="/companyOnBoarding" element={<CompanyOnBoarding />} />
          <Route
            path="/company/*"
            element={
              <RedirectRoute
                element={StartupNoRoutesMatched}
                redirectTo="/mobileRedirect"
              />
            }
          />
          <Route
            path="/companyMemberSignup/:id"
            element={<CompanyMemberSignUp />}
          />
          <Route
            path="/companyMembersOnBoarding"
            element={<StartupMembersOnBoarding />}
          />
          <Route
            path="/companyWaitlistPage"
            element={<CompanyWaitlistPage />}
          />
          <Route element={<PrivateRoutes role="company" />}>
            <Route path="/companyDetails" element={<CompanyDetails />} />
            <Route
              path="/company/fundraising"
              element={
                <RedirectRoute
                  element={Fundraising}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/dataroom"
              element={
                <RedirectRoute
                  element={Dataroom}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/profilePreview"
              element={
                <RedirectRoute
                  element={CompanyProfilePreview}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/myProfile"
              element={
                <RedirectRoute
                  element={MyProfile}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/account"
              element={
                <RedirectRoute
                  element={CompanyAccountPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/investorDetails"
              element={
                <RedirectRoute
                  element={InvestorDetails}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/signNDA"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/ioiBid"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/loiAgreement"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/spaNegotiation"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/analytics"
              element={
                <RedirectRoute
                  element={CompanyAnalytics}
                  redirectTo="/mobileRedirect"
                />
              }
            />
          </Route>

          {/* Admin/Analyst Routes */}
          <Route path="/adminSignIn" element={<AdminSignIn />} />
          <Route element={<PrivateRoutes role="admin" />}>
            <Route path="/adminPanel" element={<AdminPanel />} />
            <Route
              path="/admin/previewOpportunities"
              element={<OpportunitiesPreview />}
            />
            <Route path="/admin/investorPannel" element={<InvestorPannel />} />
            <Route path="/admin/dataroom" element={<DataroomUpload />} />
            <Route
              path="/admin/investor/preview"
              element={<InvestorPreview />}
            />
            <Route
              path="/admin/unifiedInvestorPreview"
              element={<UnifiedInvestorPreview />}
            />

            <Route path="/admin/investorStages" element={<InvestorStages />} />
            <Route path="/analyst/signNDA" element={<AnalystSignNDA />} />
            <Route
              path="/admin/profilePreview"
              element={<CompanyProfilePreview />}
            />
            <Route path="/admin/answerQnAs" element={<AnswerQnAs />} />
            <Route
              path="/admin/dealDetailsEdit"
              element={<DealDetailsEdit />}
            />
            <Route
              path="/admin/analyticsMapper"
              element={<AnalyticsMapper />}
            />
            <Route path="/admin/analytics" element={<AdminAnalytics />} />
            <Route path="/admin/downloadPDF" element={<DownloadPdf />} />
          </Route>

          {/* General Route */}
          <Route path="*" element={<NoRoutesMatched />} />
          <Route path="/mobileRedirect" element={<MobileRedirectPage />} />
        </Routes>
      </HashRouter>
    </RecoilRoot>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoaderProvider>
    <App />
  </LoaderProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
