import React, {useEffect, useState} from 'react'
import { useRecoilState } from 'recoil';
import { companyState ,saveState} from '../companyState';
const Header = (props) => {
  const [activeBtn, setActiveBtn] = useState(props.activeBtn);
  const [state, setState] = useRecoilState(companyState);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  return (
    <div className='cdetails-first-div header-with-border-bottom'>
        <div className="typeOfDeals" style={{paddingLeft:"0px"}}>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 1 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(1);
            handleActiveBtn(1);
            }}
            style={{
              width:"140px"
            }}
        >
            Overview
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 2 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(2);
            handleActiveBtn(2);
            }}
            style={{
              width:"200px"
            }}
        >
            Deal Documents 
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 3 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(3);
            handleActiveBtn(3);
            }}
            style={{
              width:"180px"
            }}
        >
            Q & A Section
        </button>
        </div>
        <div className='typeOfDeals-border' style={{top:"49.5px"}}></div>
    </div>
  )
}

export default Header
