import React, { useEffect, useState } from "react";
import Topbar from "../Admin/Investor/Dashboard/topbar";
import Sidebar from "./Investor/Dashboard/Sidebar";
import Message from "../Common/components/message";
import Thread from "../Common/components/thread";
import { useZoomEffect, fetchBlobName } from "../Common/functions";
import Toast from "../Common/Toast";
import {
  addDealInteraction,
  getDealInteraction,
  updateDealInteraction,
  dealInterestByStage,
  postStageData,
} from "../../endpoints/deal";
import { fetchInvestorMemberDetailsbyId } from "../../endpoints/investor";
import { getFundrevAnalystDetailsById } from "../../endpoints/fundrevAnalyst";
import UploadDocumentModal from "../Common/modals/uploadDocumentModal";

import { Textarea } from "@mui/joy";
import { ToastContainer } from "react-toastify";
import moment from "moment-timezone";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import FileImg from "../../Assets/Images/signup/file.svg";
import download from "../../Assets/Images/InvestorPage/Dashboard/download.svg";
import uploadFileBlue from "../../Assets/Images/InvestorPage/Dashboard/uploadFile.svg";
import file from "../../Assets/Images/InvestorPage/Dashboard/file.svg";
import sendMessage from "../../Assets/Images/InvestorPage/Dashboard/addMessage.svg";
import cross from "../../Assets/Images/signup/cross.svg";
import filter from "../../Assets/Images/InvestorPage/Dashboard/filter.svg";

import "../../CSS/InvestorPage/Dashboard/signNDA.css";
const MessagesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { stage, dealId, companyName, token, fundrevAnalystID, investorID } =
    location.state || {
      stage: "requestAccepted",
    };
  const [latestStage, setLatestStage] = useState("requestAccepted");
  const [fileUploads, setFileUploads] = useState([]);
  const [fileInfo, setFileInfo] = useState({});
  const [message, setMessage] = useState("");
  const [threadID, setThreadID] = useState();
  const [Messages, setMessages] = useState([]);
  const [replyingTo, setReplyingTo] = useState();
  const [editMessageOption, setEditMessageOption] = useState();
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("all");
  const [ndaFile, setndaFile] = useState();
  const [NDADocuments, setNDADocuments] = useState([]);
  const [version, setVersion] = useState("1");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fileUploadTimeStamps, setFileUploadTimeStamps] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ndaModalOpen, setndaModalOpen] = useState(false);
  const [NDASignedInvestor, setNDASignedInvestor] = useState(false);
  const [investorSignedNDA, setInvestorSignedNDA] = useState([]);
  useEffect(() => {
    if (!token) {
      navigate("/signIn");
    }
  }, [token]);
  const ndaFileChange = (file) => {
    setndaFile(file);
  };
  const handleCancelNDA = () => {
    setndaFile();
    setndaModalOpen(false);
  };
  const handleFileUpload = (uploadType, e) => {
    const file = e.target.files[0];
    if (!file) return;
    fileUploads[uploadType] = 1;
    var filename = file.name;
    const fileURL = URL.createObjectURL(file);
    setFileInfo((prevState) => ({
      ...prevState,
      [uploadType]: { file, filename, fileURL },
    }));
  };
  const handleReplyData = (name, threadID) => {
    setReplyingTo(name);
    setThreadID(threadID);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleEnterClick = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addMessage();
    }
  };
  const fetchAnalystDetails = async () => {
    if (!token) return;
    try {
      // If the user is fundrev analyst then fetch the details of the fundrev analyst
      if (fundrevAnalystID) {
        const response = await getFundrevAnalystDetailsById({
          fundrevAnalystID: fundrevAnalystID,
        });
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
          setEmail(data.email);
          setName(data.name);
        }
        return response;
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, [fundrevAnalystID]);

  const handleDeleteFile = (e, uploadType) => {
    fileUploads[uploadType] = 0;
    setFileInfo((prevState) => {
      const newState = { ...prevState };
      delete newState[uploadType];
      return newState;
    });
  };
  const addMessage = async () => {
    if (editMessageOption) {
      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("messageID", editMessageOption.messageID);
      formData.append("message", editMessageOption.message);
      if (fileUploads["attachFile"]) {
        formData.append("file", fileInfo["attachFile"].file);
      }
      const result = await updateDealInteraction(formData, token);
      if (result.data.status === 200) {
        fetchMessages();
        setMessage("");
        setFileInfo({});
        setReplyingTo("");
        setThreadID();
        setFileUploads([]);
        setEditMessageOption();
        return;
      } else {
        Toast("Failed to update the message", "error");
      }
    }
    if (message || fileInfo["attachFile"]) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("message", message);
      formData.append("DealID", dealId);
      formData.append("fundrevAnalystID", fundrevAnalystID);
      formData.append("RoundType", stage);
      formData.append("investorID", investorID);

      if (threadID) {
        formData.append("threadID", threadID);
      }

      if (fileUploads["attachFile"]) {
        formData.append("file", fileInfo["attachFile"].file);
      }
      const result = await addDealInteraction(formData, token);
      if (result.data.status === 201) {
        fetchMessages();
        setMessage("");
        setFileInfo({});
        setReplyingTo("");
        setThreadID();
        setFileUploads([]);
      } else {
        Toast("Failed to add the message", "error");
      }
    }
  };
  const fetchMemberDetails = async (ID, role) => {
    try {
      if (role === "Investor") {
        const data = await fetchInvestorMemberDetailsbyId({
          investorMemberID: ID,
          token: token,
        });
        return data;
      } else {
        const data = await fetchAnalystDetails();
        return data;
      }
    } catch (error) {}
  };
  const fetchMessages = async () => {
    try {
      const data = {
        DealID: dealId,
        investorID: investorID,
        RoundType: stage,
      };
      const response = await getDealInteraction(data, token);
      const fetchedMessages = response.data.data;

      let processedMessagesByThread = {}; // Object to store messages by threadID

      // messgaes can be written by both investor and startup
      for (const message of fetchedMessages) {
        let memberDetailsdata;
        let memberDetails;
        let resolvedByPersonData;
        let resolvedByPersonName;
        let isInvestor;
        if (message.investorMemberID) {
          memberDetailsdata = await fetchMemberDetails(
            message.investorMemberID,
            "Investor"
          );
          isInvestor = true;
          memberDetails = memberDetailsdata.data.data.data;
        } else {
          //Analyst
          memberDetailsdata = await fetchMemberDetails(
            message.resolvedBy,
            "Analyst"
          );
          isInvestor = false;
          memberDetails = memberDetailsdata.data.data;
        }
        // We are not saving whether the message is saved ny an investor or statrto hence can be identified by the investorMemberID or startupMemberID (message.resolvedBy)
        if (message.resolvedBy !== undefined) {
          if (message.resolvedBy.slice(0, 3) === "INM") {
            resolvedByPersonData = await fetchMemberDetails(
              message.resolvedBy,
              "Investor"
            );
            resolvedByPersonName = resolvedByPersonData.data.data.data.name;
          } else {
            resolvedByPersonData = await fetchMemberDetails(
              message.resolvedBy,
              "Analyst"
            );
            resolvedByPersonName = resolvedByPersonData.data.data.name;
          }
        }
        const newMessage = {
          name: memberDetails.name,
          firmName: memberDetails.companyName
            ? memberDetails.companyName
            : null,
          profilePhoto: isInvestor
            ? memberDetails.memberImg
            : memberDetails.profile,
          message: message.message,
          messageID: message.messageID,
          threadID: message.threadID,
          isThreadResolved: message.isThreadResolved,
          resolvedBy:
            message.resolvedBy !== undefined ? resolvedByPersonName : null,
          investorMemberID: message.investorMemberID
            ? message.investorMemberID
            : null,
          fundrevAnalystID: message.fundrevAnalystID
            ? message.fundrevAnalystID
            : null,
          file: message.file ? message.file : null,
          time: moment(message.createdAt)
            .tz("Asia/Kolkata")
            .format("MM/DD/YYYY h:mm A"), // Convert to IST and format
          updatedAt: moment(message.updatedAt)
            .tz("Asia/Kolkata")
            .format("MM/DD/YYYY h:mm A"),
          role: isInvestor ? "Investor" : "Fundrev Analyst",
          stage: stage,
          latestStage: latestStage,
          userRole: "Investor",
        };

        if (!processedMessagesByThread[newMessage.threadID]) {
          processedMessagesByThread[newMessage.threadID] = []; // Initialize array if not exists
        }

        processedMessagesByThread[newMessage.threadID].push(newMessage); // Push message to corresponding threadID array
      }

      // Sort messages in each thread based on the 'time' field (timestamp)
      for (const threadID in processedMessagesByThread) {
        processedMessagesByThread[threadID].sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );
      }

      // Now, sort the threads based on the latest message in each thread
      const sortedThreads = Object.entries(processedMessagesByThread).sort(
        ([, messagesA], [, messagesB]) => {
          const latestTimeA = messagesA[messagesA.length - 1].time;
          const latestTimeB = messagesB[messagesB.length - 1].time;
          return new Date(latestTimeA) - new Date(latestTimeB);
        }
      );

      // Convert sortedThreads back into an object
      const sortedMessagesByThread = Object.fromEntries(sortedThreads);

      setMessages(sortedMessagesByThread);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const removeReplyingTo = () => {
    setReplyingTo("");
    setThreadID();
  };
  const handleEditMessgae = (message, messageID, file) => {
    setEditMessageOption({ message, messageID, file });
  };
  const toggleFilterBox = () => {
    setFilterBoxOpen(!filterBoxOpen);
  };
  useEffect(() => {
    //Only call fetchMessages if investorID is available or the messages will get mixed up.
    if (investorID) {
      fetchMessages();
    }
  }, [investorID]);
  const handleNDASubmit = async () => {
    if (!ndaFile) {
      Toast("Please upload a file", "error");
      return;
    }
    const formData = new FormData();
    formData.append("DealID", dealId);
    formData.append("Stage", "requestAccepted");
    formData.append("IsInterested", true);
    formData.append("investorID", investorID);
    formData.append("NDADocument", ndaFile[0]);
    const response = await postStageData(formData, token);
    if (response.data !== null) {
      setndaModalOpen(false);
      setFileInfo({});
      setndaFile();
      getDealInterestByStage(dealId, investorID, stage);
    }
  };
  const getDealInterestByStage = async (dealId, investorID, stage) => {
    try {
      const response = await dealInterestByStage(
        dealId,
        investorID,
        stage,
        token
      );
      if (response) {
        if (stage === "requestAccepted") {
          const filteredData = response.data.filter((item) => item.NDADocument);
          const newDocuments = filteredData.map((item) => item.NDADocument);
          const newFileUploadTimeStamps = filteredData.map(
            (item) => item.createdAt
          );
          const investorSignednda = filteredData.map(
            (item) => item.NDA_Signed_Investor
          );
          const ndaSignedI = response.data.some(
            (item) => item.NDA_Signed_Investor
          );
          setInvestorSignedNDA(investorSignednda); // Keep an array whether NDA at that index is signed by investor or not
          setNDASignedInvestor(ndaSignedI);
          setNDADocuments(newDocuments);
          setFileUploadTimeStamps(newFileUploadTimeStamps);
          setVersion(newDocuments.length);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getDealInterestByStage(dealId, investorID, stage);
  }, [investorID, Messages, stage]);
  //For getting the latest stage to ensure that the edit can be done only for the latest stage
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  useZoomEffect();

  return (
    <div className="InvestorSide">
      <ToastContainer position="top-center" />
      <Sidebar active="opportunities" />
      <Topbar title="Analyst Upload NDA" />
      <UploadDocumentModal
        DocumentUploadModalOpen={ndaModalOpen}
        setDocumentUploadModalOpen={setndaModalOpen}
        DocumentUploadFile={ndaFile}
        DocumentUploadFileChange={ndaFileChange}
        handleCancelDocumentUpload={handleCancelNDA}
        handleDocumentUploadSubmit={handleNDASubmit}
      />
      <div className="InvestorSide-box">
        <div
          className="InvestorSide-content"
          style={{
            marginBottom: replyingTo
              ? "180px"
              : fileUploads["attachFile"]
              ? "250px"
              : "130px",
          }}
        >
          <div className="cdetails-div" style={{ paddingLeft: "10px" }}>
            <div className="signNDA-first-div">
              {stage === "requestAccepted" ? (
                <>
                  <p className="MessagePage-stage">{`${companyName} - Sign NDA`}</p>
                  <div className="messagePage-btns">
                    {NDASignedInvestor ? (
                      <div className="stage-display-btn req-pending">
                        NDA Signed by Investor
                      </div>
                    ) : NDADocuments.length > 0 ? (
                      <div className="stage-display-btn req-pending">
                        Signed NDA yet to be uploaded
                      </div>
                    ) : null}
                    {NDADocuments.length > 0 && (
                      <button
                        className="showInterestButtons-div-3"
                        style={{
                          border: "0px",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                        onClick={() => setndaModalOpen(true)}
                      >
                        Revise NDA
                      </button>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {stage === "requestAccepted" ? (
            <section className="sign-nda-section">
              <div className="MessagePage-first-div">
                <p className="activity-text">Document</p>
                {NDADocuments.length > 0 && (
                  <div className="messagePage-btns">
                    <p className="version-text">Version :</p>
                    <FormControl sx={{ m: 1, minWidth: 154 }}>
                      <Select
                        sx={{ height: "36px", width: "154px" }}
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {NDADocuments.map((bid, index) => (
                          <MenuItem value={NDADocuments.length - index}>
                            v{NDADocuments.length - index}.0
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              {NDADocuments.length > 0 ? (
                <div className="NDA-document SPA-document">
                  <div className="NDA-document-left">
                    <img src={FileImg} />
                    <div className="NDA-document-name">
                      <p>
                        {fetchBlobName(
                          NDADocuments.length > 0 &&
                            NDADocuments[NDADocuments.length - version]
                        )}
                      </p>
                    </div>
                    <p className="file-time-stamp">
                      {moment(
                        fileUploadTimeStamps[
                          fileUploadTimeStamps.length - version
                        ]
                      )
                        .tz("Asia/Kolkata")
                        .format("MM/DD/YYYY h:mm A")}
                    </p>
                    <p>
                      {investorSignedNDA[NDADocuments.length - version] ? (
                        <p className="blue-text">Signed by Investor</p>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className="NDA-document-right">
                    <Link to={NDADocuments[NDADocuments.length - version]}>
                      <img src={download} />
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <FileUploader
                    multiple={true}
                    handleChange={setndaFile}
                    name="file"
                  >
                    <div className="upload-file-div">
                      {ndaFile ? (
                        <div
                          className="upload-spa-div"
                          style={{ marginTop: "0px" }}
                        >
                          <p>{`File name: ${ndaFile[0].name}`}</p>
                        </div>
                      ) : (
                        <div className="upload-spa-div-6">
                          <div className="upload-file-div-2">
                            <img
                              loading="lazy"
                              src={uploadFileBlue}
                              className="upload-file-img"
                            />
                          </div>
                          <div className="upload-file-div-3">
                            <div className="upload-file-div-4">
                              Click to Upload
                            </div>
                            <div className="upload-file-div-5">
                              or drag and drop
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </FileUploader>
                  {ndaFile ? (
                    <div className="upload-spa-div">
                      <button
                        className="InvestorSide-content-button-1"
                        onClick={handleNDASubmit}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </section>
          ) : null}
          <div className="filter-box-div" onClick={toggleFilterBox}>
            <p className="activity-text">Activity</p>
            <button className="InvestorSide-content-button-1">
              <img loading="lazy" src={filter} className="img" />
              Filter
            </button>
            <div
              className="NDA-filter-box"
              style={{ display: filterBoxOpen ? "flex" : "none" }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="all"
                  className="filter-box-radio"
                  control={<Radio />}
                  label="Show all threads"
                />
                <FormControlLabel
                  value="unresolved"
                  className="filter-box-radio"
                  control={<Radio />}
                  label="Show unresolved threads"
                />
                <FormControlLabel
                  value="resolved"
                  style={{ width: "100%" }}
                  control={<Radio />}
                  label="Show resolved threads"
                />
              </RadioGroup>
            </div>
          </div>

          {Object.values(Messages).map((threadMessages, threadIndex) =>
            // Check if thread contains more than one member
            threadMessages.length > 1 ? (
              <Thread
                key={threadIndex}
                messages={threadMessages}
                handleReplyData={handleReplyData}
                personalID={fundrevAnalystID}
                fetchMessages={fetchMessages}
                handleEditMessage={handleEditMessgae}
                option={selectedOption}
              />
            ) : (
              // If no, render individual messages
              threadMessages.map((message, index) => (
                <Message
                  key={index}
                  personalID={fundrevAnalystID}
                  userID={
                    message.role === "Investor"
                      ? message.investorMemberID
                      : fundrevAnalystID
                  }
                  name={message.name ? message.name : ""}
                  firmName={message.firmName ? message.firmName : ""}
                  role={message.role}
                  time={message.time}
                  message={message.message}
                  messageID={message.messageID}
                  threadID={message.threadID}
                  isThreadResolved={message.isThreadResolved}
                  resolvedBy={message.resolvedBy}
                  updatedAt={message.updatedAt}
                  profilePhoto={message.profilePhoto}
                  handleReplyData={handleReplyData}
                  file={message.file ? message.file : null}
                  handleEdit={handleEditMessgae}
                  fetchMessages={fetchMessages}
                  option={selectedOption}
                  stage={stage}
                  latestStage={message.latestStage}
                  userRole={message.userRole}
                />
              ))
            )
          )}
          {stage === latestStage ? (
            <div className="add-comment-div">
              {fileUploads["attachFile"] ? (
                <div
                  className="NDA-document"
                  style={{ marginTop: "0px", marginBottom: "20px" }}
                >
                  <div className="NDA-document-left">
                    <img src={FileImg} />
                    <div className="NDA-document-name">
                      <p>{fileInfo["attachFile"].filename}</p>
                    </div>
                  </div>
                  <div className="NDA-document-right">
                    <img
                      className="attch-file-delete"
                      src={cross}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleDeleteFile(e, "attachFile")}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {replyingTo ? (
                <div
                  className="replying-to-div"
                  style={{ marginBottom: "10px", marginLeft: "12px" }}
                >
                  <div className="replying-to">Replying to {replyingTo}</div>
                  <div>
                    <img
                      src={cross}
                      className="replying-to-cross"
                      onClick={removeReplyingTo}
                    />
                  </div>
                </div>
              ) : null}
              {editMessageOption ? (
                <div>
                  <img
                    src={cross}
                    className="edit-cross"
                    onClick={() => setEditMessageOption()}
                  ></img>
                  {editMessageOption.file && !fileUploads["attachFile"] ? (
                    <div
                      className="NDA-document"
                      style={{ marginTop: "0px", marginBottom: "20px" }}
                    >
                      <div className="NDA-document-left">
                        <img src={FileImg} />
                        <div className="NDA-document-name">
                          <p>{fetchBlobName(editMessageOption.file)}</p>
                        </div>
                      </div>
                      <div className="NDA-document-right">
                        <img
                          className="attch-file-delete"
                          src={cross}
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setEditMessageOption((prevState) => ({
                              ...prevState,
                              file: null,
                            }))
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  <Textarea
                    className="add-comment-div-2"
                    value={
                      editMessageOption ? editMessageOption.message : message
                    }
                    onKeyDown={handleEnterClick}
                    onChange={(e) =>
                      setEditMessageOption((prevState) => ({
                        ...prevState,
                        message: e.target.value,
                      }))
                    }
                  />
                </div>
              ) : (
                <Textarea
                  className="add-comment-div-2"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleEnterClick}
                  placeholder="Add any suggestions, changes to the document …"
                />
              )}
              <div className="add-comment-div-3">
                <div class="upload-btn-wrapper attach-file-wrapper">
                  <button
                    className="upload-img-btn"
                    style={{ cursor: "pointer" }}
                  >
                    <img loading="lazy" src={file} alt="Attach File" />
                  </button>
                  <input
                    id="attachFile"
                    type="file"
                    onChange={(e) => handleFileUpload("attachFile", e)}
                  />
                </div>
                <img
                  loading="lazy"
                  src={sendMessage}
                  onClick={addMessage}
                  className="add-comment-img-2"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
