import {
  Box,
  MenuItem,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import React, { useEffect } from "react";

const TimePeriodButton = ({
  type,
  setType,
  title,
  ltm,
  select,
  year = true,
  option,
  setOption,
}) => {
  const handleSelect = (event) => {
    setOption(event.target.value);
  };
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== null) {
      setType(newAlignment);
    }
  };
  const getText = () => {
    switch (type) {
      case "m":
        return `Month`;
      case "q":
        return `Quarter`;
      case "y":
        return `Year`;
      case "ltm":
        return "ltm";
      default:
        return ``;
    }
  };
  useEffect(() => {
    setOption && setOption(`previous${type}`);
  }, [type]);
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      borderRadius: "8px",
      height: "32px",
      border: 0,
      width: "48px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      '&[aria-pressed="true"]': {
        color: "#00A6FB",
        background: "#E5F5FE",
        borderColor: "#00A6FB",
        border: "1px solid #00A6FB",
        "&:hover": {
          backgroundColor: "#E5F5FE",
        },
      },
    },
  }));
  return (
    <>
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={{
          paddingRight: "5px",
        }}
      >
        <p
          style={{
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Karla,sans-serif",
            color: "#3C5168",
          }}
        >
          {title}
        </p>
        <div>
          {select && type !== "ltm" && (
            <Select
              value={option}
              onChange={handleSelect}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "173px",
                height: "32px",
                fontFamily: "Karla,sans-serif",
                fontSize: "14px",
                fontWeight: "400",
                color: "#636363",
                borderRadius: 1,
              }}
            >
              <MenuItem
                sx={{
                  fontFamily: "Karla,sans-serif",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                value={`previous${type}`}
              >
                Previous {getText()}
              </MenuItem>
              <MenuItem
                sx={{
                  fontFamily: "Karla,sans-serif",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                value={`last${type}`}
              >
                Previous Year
              </MenuItem>
            </Select>
          )}
          <StyledToggleButtonGroup
            exclusive
            aria-label="Period"
            value={type}
            onChange={handleChange}
          >
            <ToggleButton value="m">M</ToggleButton>
            <ToggleButton value="q">Q</ToggleButton>
            {year && <ToggleButton value="y">Y</ToggleButton>}
            {ltm && <ToggleButton value="ltm">LTM</ToggleButton>}
          </StyledToggleButtonGroup>
        </div>
      </Box>
    </>
  );
};

export default TimePeriodButton;
