import React, { useState } from "react";
import "../../../../CSS/FounderPage/Dashboard/dataroom.css";
import fileIcon from "../../../../Assets/Images/FounderPage/Dashboard/fileicon.svg"
import folderIcon from "../../../../Assets/Images/FounderPage/Dashboard/foldericon.svg"
const DataroomAddBtn = ({ setAddFolderModal, setIsaddDocument }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="btn-div">
        <div className="addBtn-div" onClick={() => setIsOpen(!isOpen)}>
          <div>+ Add</div>
        </div>
        {isOpen && (
          <div className="dropdown-div">
            <div
              className="div-5"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsaddDocument(true);
                setAddFolderModal(true);
              }}
            >
              <img
                loading="lazy"
                src={fileIcon}
                className="img-2"
              />
              <div className="div-6">Document</div>
            </div>
            <div
              className="div-7"
              style={{ cursor: "pointer" }}
              onClick={() => setAddFolderModal(true)}
            >
              <img
                loading="lazy"
                src={folderIcon}
                className="img-3"
              />
              <div className="div-8">Folder</div>
            </div>
            {/* <div className="div-9">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/289effd2de402f199ff20a352e04e5a1b8b277b2984fb8b10a70d040284d42b9?"
                className="img-4"
              />
              <div className="div-10">Bulk Update</div>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default DataroomAddBtn;
