import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-policy">
      <Header color="#193946" type="privacyPolicy" />
      <Helmet>
        <title>Fundrev | Privacy Policy </title>
      </Helmet>
      <div className="bg-gray-100 min-h-screen p-6">
        <div className="mx-auto bg-white p-8 shadow-lg mt-[70px] mb-[20px] max-w-[1300px] rounded-[10px]">
          <h1 className="text-3xl font-bold mb-6 text-center">
            Privacy Policy
          </h1>

          <ol className="list-decimal pl-6 space-y-6">
            <li>
              <h2 className="text-xl font-semibold mb-3">Introduction</h2>
              <p>
                Fundrev Technologies Private Limited ("Fundrev," "we," "us," or
                "our") values your privacy and is committed to protecting your
                personal data. This Privacy Policy outlines how we collect, use,
                disclose, and safeguard your information when you visit our
                website, use our services, or engage with us in any manner. By
                accessing our platform or services, you agree to the terms of
                this Privacy Policy.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Information We Collect
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Personal Information:</strong> Includes identification
                  data (name, date of birth, gender, nationality, and
                  government-issued identification numbers), contact information
                  (email address, phone number, postal address), and
                  professional information (job title, company name,
                  professional background, and experience).
                </li>
                <li>
                  <strong>Financial Information:</strong> Includes investment
                  data (details of investment transactions, type of investment,
                  and transaction history) and tax information (tax residency,
                  Taxpayer Identification Number, and other relevant tax
                  details).
                </li>
                <li>
                  <strong>Technical Information:</strong> Includes log data (IP
                  address, browser type, operating system, and access times),
                  device information (device type, unique device identifiers,
                  and mobile network information), and usage data (page views,
                  clicks, and interaction with content).
                </li>
                <li>
                  <strong>Behavioural Information:</strong> Includes preference
                  data (preferences, interests, and activities on the platform)
                  and survey responses (data collected from responses to
                  surveys, questionnaires, and feedback forms).
                </li>
                <li>
                  <strong>Third-Party Information:</strong> Includes data from
                  partners (business partners, investment platforms, financial
                  institutions, and analytics providers) and social media data
                  (information from social media platforms if accounts are
                  linked or interactions with content occur).
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                How We Use Your Information
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Service Delivery:</strong> For account management,
                  transaction processing, and customer support.
                </li>
                <li>
                  <strong>Communication:</strong> For updates and notifications,
                  legal and regulatory notices.
                </li>
                <li>
                  <strong>Legal Compliance:</strong> For fraud prevention and
                  regulatory compliance including KYC and AML requirements.
                </li>
                <li>
                  <strong>Analytics and Improvement:</strong> For platform
                  enhancement, analyzing usage patterns, and personalization.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Sharing of Information
              </h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Service Providers:</strong> Includes third-party
                  vendors (for operating the platform and providing customer
                  support) and IT service providers (cloud storage, data
                  hosting, and cybersecurity services).
                </li>
                <li>
                  <strong>Business Partners:</strong> Includes investors,
                  fundraisers, financial institutions (banks, payment
                  processors, and credit bureaus).
                </li>
                <li>
                  <strong>Legal Authorities:</strong> Includes government
                  agencies (regulatory bodies, law enforcement) and legal
                  advisors (lawyers, auditors, and consultants).
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Data Security</h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Technical Safeguards:</strong> Includes encryption
                  technologies and access controls.
                </li>
                <li>
                  <strong>Organizational Measures:</strong> Includes employee
                  training and incident response protocols.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Data Retention</h2>
              <p>
                We retain your personal data only for as long as necessary to
                fulfill the purposes outlined in this Privacy Policy: account
                data for the duration of your account's existence, transaction
                data for the period required by applicable obligations, and
                communication data for the duration of relevant communication
                plus a reasonable time.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Your Rights</h2>
              <ol className="list-decimal pl-6 space-y-2">
                <li>
                  <strong>Access:</strong> Right to know about the personal data
                  we hold and how we process it.
                </li>
                <li>
                  <strong>Correction:</strong> Right to rectify inaccuracies in
                  your personal data.
                </li>
                <li>
                  <strong>Deletion:</strong> Right to erase your personal data,
                  subject to legal obligations.
                </li>
                <li>
                  <strong>Objection:</strong> Right to object to the processing
                  of your personal data.
                </li>
                <li>
                  <strong>Portability:</strong> Right to transfer your data to
                  another service provider.
                </li>
              </ol>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                International Data Transfers
              </h2>
              <p>
                Your information may be transferred and processed in countries
                outside your country of residence, including countries that may
                not provide the same level of data protection as your home
                country. We ensure appropriate safeguards are in place, such as
                standard contractual clauses.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Children’s Privacy</h2>
              <p>
                Our platform is not intended for individuals under the age of
                18. We do not knowingly collect personal data from minors. If we
                become aware of such data, we will take steps to delete it.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">
                Changes to this Privacy Policy
              </h2>
              <p>
                We may update this Privacy Policy to reflect changes in our
                practices or for legal reasons. Significant changes will be
                posted on our website, and the date of the last update will be
                indicated.
              </p>
            </li>

            <li>
              <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
              <p>
                If you have any questions or requests regarding this Privacy
                Policy, please contact us at:
              </p>
              <ul className="list-disc pl-6">
                <li>Email: hello@fundrev.ai</li>
                <li>
                  Address: WEWORK PRESTIGE CUBE NO 26 LASKAR HOSUR ROAD Adugodi
                  Bangalore South Bangalore- 560030 Karnataka
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
      <div className="landing-page ">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
