import axios from "axios";
const token = localStorage.getItem("token");

export const getAllDeals = async (token) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/getAllDeals`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const addDealInteraction = async (messageData, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/deals/addDealInteraction",
      messageData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getCoInvestorData = async (data) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/getCoinvestorData/${data.dealId}/${data.investorId}`,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getTotalAmountRaisedByStartup = async (data) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/getTotalAmountRaised/${data.dealId}`,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getIOIDetails = async (data) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/getIOIDetails/${data.dealId}`,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealInteraction = async (data, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/deals/getDealInteractions",
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getAllEditRequests = async (token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/allEditRequests`,
      {},
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const ToggleResolveThread = async (data, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/deals/toggleResolveThread",
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getNoNameDeals = async (investorId, token) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/noNameDeals/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      // `http://localhost:3000/deals/noNameDeals/${investorId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getAllNoNameDeals = async (token) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/allNoNameDeals`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getPassedDeals = async (investorId, token) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/passedDeals/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      // `http://localhost:3000/deals/passedDeals/${investorId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const updateDealInteraction = async (data, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/deals/updateDealInteraction",
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealByStage = async (dealId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByStage/${dealId}/${investorId}`
      `https://api.fundrev.ai/deals/dealsByStage/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getAllDealInteractions = async (dealId, investorId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/allDealsByInvestorId/${investorId}/${dealId}`
      `https://api.fundrev.ai/deals/allDealsByInvestorId/${investorId}/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const postStageData = async (formData, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/deals/stageData/",
      // "https://api.fundrev.ai/deals/stageData/",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealIdbyStartupId = async (startupId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealIdByStartupId/${startupId}`
      `https://api.fundrev.ai/deals/getDealId/${startupId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getDealsByInvestorID = async (investorId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByInvestorId/${investorId}`
      `https://api.fundrev.ai/deals/dealsByInvestorId/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getInvestorsByDealID = async (dealId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByDealId/${dealId}`
      `https://api.fundrev.ai/deals/dealsByDealId/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealsByStartupID = async (startupId) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByStartupId/${startupId}`
      `https://api.fundrev.ai/deals/dealsByStartupId/${startupId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const dealInterestByStage = async (dealId, investorID, stage) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByInvestorId/${investorId}`
      `https://api.fundrev.ai/deals/dealInterestByStage/${dealId}/${investorID}/${stage}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getDealDetailsByDealId = async (dealId, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/dealDetails/${dealId}`,
      {},
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const publishStartup = async (data, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/publishStartup`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const sendForReview = async (data, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/sendForReview`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const updateDeal = async (formDataInput, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/dealUpdate`,
      formDataInput,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const showInterestInDeal = async (dealId, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/showInterestInDeal`,
      { dealId },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const toggleOpenListing = async (DealID, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/deals/toggleOpenListing`,
      { DealID },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const createOpenListing = async (token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/deals/createOpenListing",
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const getActiveDealsCount = async (token) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/deals/activeDealsCount`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
