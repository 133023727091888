import React from "react";
import { Box, Grid } from "@mui/material";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";

const CustomerTapeAnalytics = ({ data }) => {
  return (
    <>
      <div>
        <Grid container spacing={2} marginBottom={10}>
          {data?.plotData &&
            Object.keys(data?.plotData)
              .filter((key) => key !== "xLabels")
              .map((key) => (
                <Grid item xs="auto" md="auto" lg="auto" key={key}>
                  <Box>
                    <PlotTemplate
                      data={data?.plotData[key]}
                      xLabelData={data?.plotData.xLabels}
                    />
                  </Box>
                </Grid>
              ))}
        </Grid>
      </div>
    </>
  );
};

export default CustomerTapeAnalytics;
