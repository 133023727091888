import React from "react";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";
import CashFlowStatementTable from "./AnalyticsPlots/CashFlowStatementTable";

const HistoricalFinancials = ({ data }) => {
  return (
    <>
      <AnalyticsPageTitle title={`Historical Financials`} />
      {/* <CashFlowStatementTable data={data.tables.cashFlowStatement} /> */}
    </>
  );
};

export default HistoricalFinancials;
