import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { companyState, saveState } from "../companyState";
import ProfileBox from "./profileBox";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";

import { useNavigate } from "react-router-dom";

import notificationicon from "../../../../Assets/Images/InvestorPage/Dashboard/notificationicon.svg";
import searchicon from "../../../../Assets/Images/InvestorPage/Dashboard/searchicon.svg";
import settingsicon from "../../../../Assets/Images/InvestorPage/Dashboard/settingsicon.svg";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/material";
import CornerProfileBox from "./cornerProfileBox";

const Topbar = ({ title, isBack }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { ProfileBoxOpen } = useRecoilValue(companyState);
  const [name, setName] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");
  const [modal, setModal] = useState(false);
  const [state, setState] = useRecoilState(companyState);
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchStartupMemberDetails(token);
      }
      if (token) {
        if (response && response.data !== null) {
          setName(response.data.data.name);
          setCompanyProfile(response.data.data.memberImg);
          const newState = {
            ...state,
            startupId: response.data.data.startupId,
            startupMemberId: response.data.data.startupMemberID,
          };
          setState(newState);
          saveState(newState);
        } else {
        }
      }
    };

    fetchDetails();
  }, [token]);
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };
  const handleClickAway = () => {
    setModal(false);
  };

  const notifications = [
    // {
    //   title: "Your deal request has been accepted",
    //   company: "Amazon",
    //   time: "0 min",
    //   img: "https://cdn.builder.io/api/v1/image/assets/TEMP/7dd6d1e2baaacbc86a6d1fea7af195373f00bd5383e407647c14fd93384554e0?apiKey=32c42a50fb8f44509096999a9c1a8276&width=100",
    // },
    // {
    //   title: "Your IOI Bid request has been accepted",
    //   company: "Nykaa",
    //   time: "6 min",
    //   img: "https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/83280f7979692a8a1c5898ed3317e7ce048449b418f95ec3b50a9de21e2fd1ac?apiKey=32c42a50fb8f44509096999a9c1a8276&",
    // },
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="topbardiv">
        <ProfileBox modal={modal} />
        <div
          className="topbardiv-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          {isBack && (
            <ArrowBackIos
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          )}

          {title}
        </div>
        <div className="topbardiv-3">
          {/* <div className="topbardiv-4">
            <img
              loading="lazy"
              src={searchicon}
              className="img"
            />
            <input 
              className='topbardiv-5' 
              placeholder='Search here'
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div> */}
          <div className="topbar-utilities">
            <div className="settings">
              <img
                loading="lazy"
                src={settingsicon}
                className="topbarimg-4"
                onClick={() => {
                  navigate("/company/account", {
                    state: {
                      activeButton: 1,
                    },
                  });
                }}
              />
            </div>
            {/* <div className='notifications'>
              <img
                loading="lazy"
                onClick={handleOpen}
                src={notificationicon}
                className="topbarimg-5"
              />
            </div> */}
            <CornerProfileBox modal={modal} setModal={setModal} />
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Topbar;
