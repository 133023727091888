import React, {useEffect, useState} from 'react'
import { useRecoilState } from 'recoil';
import { companyOnBoardingState ,saveState} from './companyOnBoardingState';
const DetailedCardHeader = (props) => {
  const [activeBtn, setActiveBtn] = useState(props.activeBtn);
  const [state, setState] = useRecoilState(companyOnBoardingState);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  return (
    <div className='cdetails-first-div'>
        <div className="typeOfDeals" style={{paddingLeft:"0px"}}>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 1 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(1);
            handleActiveBtn(1);
            }}
        >
            Overview
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 2 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(2);
            handleActiveBtn(2);
            }}
        >
            Analytics
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 3 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(3);
            handleActiveBtn(3);
            }}
        >
            Dataroom
        </button>
        </div>
    </div>
  )
}

export default DetailedCardHeader
