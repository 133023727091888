import { Typography } from "@mui/material";
import React, { useState } from "react";
import "../../CSS/Signup/rightSection.css";
import Header from "../LandingPage/header";
import investor from "../../Assets/Images/signup/rightSection/investor.png";
import investor_hovered from "../../Assets/Images/signup/rightSection/investor_hovered.png";
import company from "../../Assets/Images/signup/rightSection/company.png";
import company_hovered from "../../Assets/Images/signup/rightSection/company_hovered.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, Link } from "react-router-dom";

const RightSection = () => {
  const [isHoveredInv, setIsHoveredInv] = useState(false);
  const [isHoveredCmp, setIsHoveredCmp] = useState(false);
  const navigate = useNavigate();
  const handleMouseEnterInv = () => {
    document.querySelector('.inv_arrow').style.visibility = 'visible';
    setIsHoveredInv(true);
  };

  const handleMouseLeaveInv = () => {
    document.querySelector('.inv_arrow').style.visibility = 'hidden';
    setIsHoveredInv(false);
  };

  const handleMouseEnterCmp = () => {
    document.querySelector('.cmp_arrow').style.visibility = 'visible';
    setIsHoveredCmp(true);
  };

  const handleMouseLeaveCmp = () => {
    document.querySelector('.cmp_arrow').style.visibility = 'hidden';
    setIsHoveredCmp(false);
  };

  return (
    <div className="rightSectionWithHeader">
      <Header color="black" type="investor"/>
      <div className="rightSection">
      <div className="section">
        <div className="title">
          <Typography variant="h6" className="heading">
            Join Us!
          </Typography>
          <Typography variant="subtitle1" color="grey" className="content">
            To begin this journey, tell us what type of account you'd be
            opening.
          </Typography>
        </div>

        {/* Investor Section */}
        <div
          className="investor"
          onMouseEnter={handleMouseEnterInv}
          onMouseLeave={handleMouseLeaveInv}
          onClick={()=>navigate("/investorRegister")}
        >
          {isHoveredInv ? (
            <img src={investor_hovered} alt="Investor" className="icon" />
          ) : (
            <img src={investor} alt="Investor" className="icon" />
          )}
          <div className="investor_intro">
            <Typography className="inv-heading">Investor</Typography>
            <Typography className="inv-data">
              Make data-driven investments efficiently on Fundrev.
            </Typography>
          </div>
          <ArrowForwardIcon className="inv_arrow" />
        </div>

        {/* Company Section */}
        <div
          className="company"
          onMouseEnter={handleMouseEnterCmp}
          onMouseLeave={handleMouseLeaveCmp}
          onClick={()=>navigate("/companyRegister")}
        >
          {isHoveredCmp ? (
            <img src={company_hovered} alt="Company" className="icon" />
          ) : (
            <img src={company} alt="Company" className="icon" />
          )}
          <div className="investor_intro">
            <Typography className="inv-heading">Company</Typography>
            <Typography className="inv-data">
            Pitch, Connect, Close: Accelerate Deals with Fundrev. 
            </Typography>
          </div>
          <ArrowForwardIcon className="cmp_arrow" />
        </div>
        <Typography className="text">
          <div className="textContent">
            Already have an account?{" "}
            <Link to="/signin" style={{ color: "rgba(0, 166, 251, 1)" }}>
              Sign In
            </Link>
          </div>
        </Typography>
      </div>
    </div>
    </div>
    
  );
};

export default RightSection;
