import React, { useState, useEffect } from "react";
import "../../../../CSS/InvestorPage/Dashboard/profileBox.css";
import { fetchInvestorMemberDetails } from "../../../../endpoints/investor";
import { useRecoilState, useRecoilValue } from "recoil";
import { investorState } from "../investorState";

import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

import edit from "../../../../Assets/Images/InvestorPage/Dashboard/edit.svg";
import blueLine from "../../../../Assets/Images/InvestorPage/Dashboard/blue-line.svg";
import userProfile from "../../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";
const ProfileBox = ({ modal }) => {
  const Navigate = useNavigate();
  const [joiningDate, setJoiningDate] = useState("");
  const [state, setState] = useRecoilState(investorState);
  const [name, setName] = useState("");
  const [investorProfile, setInvestorProfile] = useState("");
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchInvestorMemberDetails(token);
      }
      if (token) {
        if (response && response.data !== null) {
          setJoiningDate(
            moment(response.data.data.createdAt)
              .tz("Asia/Kolkata")
              .format("DD MMMM, YYYY")
          );
          setName(response.data.data.name);
          setInvestorProfile(response.data.data.memberImg);
          const newState = {
            ...state,
            investorId: response.data.data.investorID,
          };
          setState(newState);
        } else {
        }
      }
    };

    fetchDetails();
  }, [token, investorState, modal]);
  return (
    <div className="profile-box" style={{ display: modal ? "block" : "none" }}>
      <div className="discord-card-left">
        <div className="overlap">
          <div className="card-base">
            <div className="overlap-group">
              <div className="rectangle" />
              <div className="ellipse" />
              <img
                className="img"
                alt="Ellipse"
                srcSet={
                  investorProfile === undefined || investorProfile === ""
                    ? userProfile
                    : investorProfile
                }
              />
            </div>
            <div className="div" />
          </div>
          <div
            className="group"
            onClick={() => {
              Navigate("/investor/account", {
                state: {
                  activeButton: 2,
                },
              });
            }}
          >
            <div className="overlap-2" style={{ cursor: "pointer" }}>
              <img className="icon" alt="Icon" src={edit} />
              <div className="ellipse-2" />
            </div>
          </div>
          <div className="group-2">
            <div className="text-wrapper">Investor</div>
            <div className="text-wrapper-2">{name}</div>
          </div>
          <div className="group-3">
            <div className="text-wrapper">{joiningDate}</div>
            <div className="text-wrapper-3">Member Since</div>
          </div>
          <img className="line" alt="Line" src={blueLine} />
        </div>
      </div>
    </div>
  );
};

export default ProfileBox;
