import React, { useEffect, useState } from "react";
// import { investorState } from '../investorState';
import { isValidEmail } from "../../../Common/functions";
import Toast from "../../../Common/Toast";
// import { fetchInvestorMemberDetails, fetchMembers , memberResendInvite, investorAddMember,deleteMember } from '../../../../endpoints/investor';

import { useRecoilValue } from "recoil";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TagsInput from "react-tagsinput";
import { Helmet } from "react-helmet";

import members from "../../../../Assets/Images/signup/members.svg";
import invite from "../../../../Assets/Images/signup/invite.svg";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Crown from "../../../../Assets/Images/FounderPage/Dashboard/Crown.svg";
import "react-tagsinput/react-tagsinput.css";
import { companyState } from "../companyState";
import {
  deleteMember,
  fetchMembers,
  fetchStartupMemberDetails,
  memberResendInvite,
  startupAddMember,
} from "../../../../endpoints/startup";
import DeleteAccountModal from "../../../Common/modals/deleteAccountModal";
import { useLoader } from "../../../Common/LoaderProvider";

const Members = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [email, setEmail] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [startupID, setStartupID] = useState("");
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [DeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [accountDeleteEmail, setAccountDeleteEmail] = useState("");
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(startupID, token);

      if (response && response.data) {
        const membersData = response.data.data;

        setInvitedMembers(membersData);
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to fetch members:", error);
    }
  };
  const fetchMemberDetails = async () => {
    if (!token) return;
    try {
      loader.start("Fetching member details");
      const response = await fetchStartupMemberDetails(token);
      loader.stop();

      if (response.data !== null) {
        const responseData = response.data;
        const { data } = responseData;

        setStartupID(data.startupId);
        setEmail(data.email);
        if (data.isAdmin) {
          setAdminEmail(data.email);
        }
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchMemberDetails();
  }, []);
  useEffect(() => {
    fetchMembersData();
  }, [startupID]);
  const resendInvite = async (memberEmail) => {
    try {
      const data = {
        startupID: startupID,
        memberEmail: memberEmail,
        email: email,
      };

      loader.start("Resending invite");
      const response = await memberResendInvite(data, token);
      loader.stop();
      if (response && response.data) {
        Toast("Invite Resent", "success", "accountPage");
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to resend invite:", error);
    }
  };
  const handleChange = (emails) => {
    if (emails.length === 0) {
      setEmails(emails);
      return;
    }
    if (isValidEmail(emails[emails.length - 1])) {
      setEmails(emails);
    } else {
      Toast("Please enter a valid email address.", "info", "accountPage");
    }
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of the space bar
      const trimmedValue = inputValue.trim(); // Trim the current input value
      if (trimmedValue) {
        if (isValidEmail(trimmedValue) === false) {
          Toast("Please enter a valid email address.", "error", "accountPage");
          return;
        }
        handleChange([...emails, trimmedValue]);
        setInputValue(""); // Clear the input field after adding the tag
      }
    }
  };
  const sendInvite = async (emails) => {
    try {
      if (emails.length === 0) {
        if (inputValue.trim() !== "") {
          if (isValidEmail(inputValue) === false) {
            Toast(
              "Please enter a valid email address.",
              "error",
              "accountPage"
            );
            setEmails([]);
            setInputValue("");
            return;
          }
          emails.push(inputValue);
        } else {
          Toast(
            "Please enter atleast one email address.",
            "error",
            "accountPage"
          );
          setEmails([]);
          setInputValue("");
          return;
        }
      }
      loader.start("Sending invites");
      const response = await startupAddMember(emails, token);
      loader.stop();
      if (response.data !== null) {
        setOpenSaveModal(true);
        setEmails([]);
      } else {
        Toast("Error in inviting member", "error", "accountPage");
      }
    } catch (error) {
      loader.stop();
      Toast("Error in inviting member", "error", "accountPage");
    }
    fetchMembersData();
  };
  const deleteFounderMember = async (email) => {
    const member = invitedMembers.find((member) => member.email === email);

    try {
      loader.start("Updating member");
      const response = await deleteMember(member._id, startupID, token);
      loader.stop();
      if (response) {
        Toast("Member deleted successfully", "info", "accountPage");
        setAccountDeleteEmail("");
        setDeleteAccountModalOpen(false);
        fetchMembersData();
      } else {
      }
    } catch (error) {
      loader.stop();
      console.error("Error deleting member:", error);
    }
  };

  const handleDeleteAccountModal = (email) => {
    setAccountDeleteEmail(email);
    setDeleteAccountModalOpen(true);
  };

  return (
    <div style={{ width: "97%" }}>
      <Helmet>
        <title>Company | Members</title>
      </Helmet>
      <div className="members-first-div">
        <button className="invite-members-div" onClick={() => setOpen(true)}>
          <img src={members} />
          &nbsp;&nbsp; Invite Members
        </button>
      </div>
      <DeleteAccountModal
        deleteAccountModalOpen={DeleteAccountModalOpen}
        setDeleteAccountModalOpen={setDeleteAccountModalOpen}
        deleteFounderMember={deleteFounderMember}
        email={accountDeleteEmail}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            setOpen(false);
          },
          sx: {
            width: "700px", // Adjust width as needed
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle>Invite Team members</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ backgroundColor: "#f7f7f7" }}>
          <div className="dialogcontent">
            <DialogContentText className="dialogcontenttext">
              Invite by email
            </DialogContentText>
            <div className="select-peers-input">
              <TagsInput
                value={emails}
                onChange={handleChange}
                inputValue={inputValue}
                onChangeInput={handleInputChange}
                onlyUnique
                inputProps={{
                  placeholder: "Select peers",
                  onKeyDown: handleKeyDown,
                }}
              />
              <button
                className="send-invite"
                style={{ cursor: "pointer" }}
                onClick={() => sendInvite(emails)}
              >
                <p>Send Invite</p>
                <img src={invite} />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <table className="members-table">
        <thead>
          <tr className="members-header">
            <th className="top-left-th">Name</th>
            <th>Official Email Address</th>
            <th>Status</th>
            <th></th>
            <th className="top-right-th"></th>
          </tr>
        </thead>
        <tbody>
          {invitedMembers.map((member, index) => (
            <tr key={index}>
              <td className="name-cell">
                {member.name}
                {member.isAdmin && (
                  <img src={Crown} style={{ marginLeft: "10px" }} />
                )}
                <div>
                  {member.email === email && (
                    <p className="you-btn" style={{ marginLeft: "10px" }}>
                      You
                    </p>
                  )}
                </div>
              </td>
              <td className="email-cell">{member.email}</td>
              {}
              <td className="email-cell">
                {member.isActive ? (
                  <div>
                    <div className="green-dot" />
                    Active
                  </div>
                ) : (
                  <div>
                    <div className="yellow-dot" />
                    Pending
                  </div>
                )}
              </td>
              <td className="resend-invite">
                {!member.isActive ? (
                  <p onClick={() => resendInvite(member.email, token)}>
                    Resend Invite
                  </p>
                ) : (
                  ""
                )}
              </td>
              <td className="delete-account">
                {email === adminEmail && !member.isActive && (
                  <p onClick={() => handleDeleteAccountModal(member.email)}>
                    {`Revoke`}
                  </p>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Members;
