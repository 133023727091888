export function formatToLakhs(
  num,
  format = true,
  currency = false,
  symbol = false,
  percentage = false
) {
  const lakhs = num / 100000; // Convert number to Lakhs
  if (percentage) {
    return Number(num).toFixed(1) + `%`;
  }
  if (Math.abs(num) >= 1000) {
    return num
      ? new Intl.NumberFormat("en-IN", {
          maximumFractionDigits: 2, // Customize decimal places if needed
          style: currency ? "currency" : "decimal", // Choose currency or decimal formatting
          currency: "INR",
        }).format(format ? lakhs : num) + `${symbol ? `L` : ""}`
      : `-`; // Append 'L' for Lakhs
  } else {
    return num || `-`;
  }
}
