import Step1 from "../../Assets/Images/LandingPage/Steps/step1.svg";
import Step2 from "../../Assets/Images/LandingPage/Steps/step2.svg";
import Step3 from "../../Assets/Images/LandingPage/Steps/step3.svg";
import Step4 from "../../Assets/Images/LandingPage/Steps/step4.svg";
import Step5 from "../../Assets/Images/LandingPage/Steps/step5.svg";
import Step6 from "../../Assets/Images/LandingPage/Steps/step6.svg";
export default [
  {
    dayNumber:"1-5",
    type:"FOUNDERS",
    heading:"Create a Compelling profile",
    info:"Prepare for investment in just 24-48 hours with our advanced pitch and data room creation, complemented by expert advice from your dedicated deal analyst.",
    img:Step1
  },
  {
    dayNumber:"5",
    type:"FOUNDERS",
    heading:"Publish Your Pitch",
    info:"Go Live and instantly capture investor interest for your fundraising goals. Bypass the wait for referral-based meetings and connect directly with hundreds of investors. ",
    img:Step2
  }, 
  {
    dayNumber:"6-10",
    type:"FOUNDERS",
    heading:"Get Interests From Qualified Investors",
    info:"Stay informed about investor interest and manage which investors have permission to view your profile.",
    img:Step3
  },
  {
    dayNumber:"10-20",
    type:"FOUNDERS",
    heading:"Receive Investor Bids",
    info:"Manage multiple investor bids and selectively grant data room access for due diligence, keeping full control of your data 's privacy.",
    img:Step4
  },
  {
    dayNumber:"20-40",
    type:"INVESTORS",
    heading:"Investor Due Diligence",
    info:"Find all the essential tools for deal closure in one convenient location, catering to all your due diligence requirements.",
    img:Step5
  },
  {
    dayNumber:"40-60",
    type:"INVESTORS",
    heading:"Negotiation and Final Close",
    info:"Negotiate terms, determine valuations, and finalize deals seamlessly, all from a single, unified platform. ",
    img:Step6
  },
];
