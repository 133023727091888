import React, { useEffect, useState } from "react";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";

import circularPhoneIconGery from "../../../Assets/Images/InvestorPage/Dashboard/circularPhoneIconGrey.svg";
import circularMailIconGery from "../../../Assets/Images/InvestorPage/Dashboard/circularMailIconGrey.svg";
const FundrevAnalystDetails = ({ analystID }) => {
  const [analyst, setAnalyst] = useState({});
  const fetchAnalystDetails = async () => {
    try {
      const response = await getFundrevAnalystDetailsById({
        fundrevAnalystID: analystID,
      });
      setAnalyst(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, []);
  return (
    <div
      className="investor-account-fundrev-analyst"
      style={{ border: "0px", paddingTop: "0px", paddingLeft: "0px" }}
    >
      <div className="cdetails-div-60" style={{ marginBottom: "20px" }}>
        Your Fundrev Analyst
      </div>
      <div className="fundrev-analyst-div">
        <img
          loading="lazy"
          src={analyst.profile}
          alt="analyst profile"
          className="fundrev-img"
        />
        <div className="analyst-name-div">
          <div className="analyst-name">{analyst.name}</div>
          <div className="analyst-joined-date">
            Joined your team on {analyst.joinedAt}
          </div>
        </div>
      </div>
      <div className="analyst-connect-div">
        <div className="analyst-contact-div">
          <img
            loading="lazy"
            src={circularPhoneIconGery}
            className="phone-icon-img-2"
            alt="phone"
          />
          <a className="analyst-phone" href={`tel:${analyst.phoneNumber}`}>
            <div>{analyst.phoneNumber}</div>
          </a>
        </div>
        <div className="analyst-email-div">
          <img
            loading="lazy"
            src={circularMailIconGery}
            className="email-icon-img-3"
            alt="mail"
          />
          <a className="analyst-email" href={`mailto:${analyst.email}`}>
            <div>{analyst.email}</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FundrevAnalystDetails;
