import React, { useState, useEffect } from "react";
import dashboard from "../../../Assets/Images/FounderPage/dashbaord.svg"
import dashboardsmall from "../../../Assets/Images/FounderPage/dashboardsmall.svg"
import Arrow from "../../../Assets/Images/LandingPage/arrow-small-right.svg"
import { Link } from 'react-router-dom'
const FoundersPageFront = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth > 991 ? dashboard : dashboardsmall;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="investor-div">
    <section className="investor-section">
        <header className="investor-header">
          <button className="investor-header-btn">FOR FOUNDERS</button>
        </header>
        <div className="investor-content">
          <div className="content-main">
            <div className="content-column">
              <h1 className="main-heading">Save time, Accelerate<br/> Fundraising</h1>
            </div>
            <div className="content-column action-column">
              <button className="get-started-btn founder-get-started">
                <Link to="/companyRegister" 
                  state={{ active:"investor" }} 
                  className='get-started-link'
                >
                  Schedule a call
                </Link>
                <img className="arrow-investor" src={Arrow} alt="arrow"/>
              </button>
              
            </div>
          </div>
        </div>
        <img
          src={imageUrl}
          alt=""
          className="banner-image"
        />
    </section>
    </div>
  )
}

export default FoundersPageFront
