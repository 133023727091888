import React, { useEffect, useState } from "react";
import LeftSection from "../../SignUp/LeftSection";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import "../../../CSS/Signup/investorRegister.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Header from "./companyDetailsHeader";
import { FormControl, FormLabel, Textarea } from "@mui/joy";
import { Button, Input } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Toast from "../../Common/Toast";
import { ToastContainer } from "react-toastify";
import { startupSignUp } from "../../../endpoints/startup";
import { useLoader } from "../../Common/LoaderProvider";

const CompanyDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const { data } = location.state || {};
  const [formData, setFormData] = useState({
    revenue: "",
    industry: "",
    fundingType: "",
    timeline: "",
    website: "",
    techstack: "",
    description: "",
  });
  const [companyWebsiteError, setCompanyWebsiteError] = useState("");
  const FundingTypeOptions = [
    { label: "VC Backed", value: "VC Backed" },
    { label: "Angel Backed", value: "Angel Backed" },
    { label: "Bootstrapped", value: "Bootstrapped" },
  ];

  const TimelineOptions = [
    {
      label: "In less than 6 months",
      value: "In less than 6 months",
    },
    { label: "6-12m", value: "6-12m" },
    {
      label: "More than 12 months",
      value: "More than 12 months",
    },
  ];

  useEffect(() => {
    if (data) {
      setFormData(data);
    }

    const companyData = JSON.parse(localStorage.getItem("companyDetails"));
    if (companyData) {
      setFormData(companyData);
    }
  }, []);

  function isValidUrl(url) {
    const regex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return regex.test(url);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "website") {
      if (!isValidUrl(value)) {
        setCompanyWebsiteError("Please enter a valid URL.");
      } else {
        setCompanyWebsiteError(""); // Clear the error message if the URL is valid
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add validation logic here if necessary
    if (!Object.values(formData).every((value) => value.trim())) {
      Toast("Please fill in all fields.", "info", "comapnyRegisterSecond");
      return;
    }
    let responseData;
    try {
      loader.start("Signing up...");
      responseData = await startupSignUp(formData);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (responseData?.data !== null) {
      // Example API call for demonstration
      const response = responseData.data;

      if (
        response.data.message === "Please verify your email with the code sent."
      ) {
        // alert("Form submitted successfully");
        localStorage.setItem("companyDetails", JSON.stringify(formData));
        navigate("/emailVerification", {
          state: {
            formData: formData,
            companyEmail: data.email,
            companyName: data.companyName,
            companyPhoneNumber: data.phoneNumber,
            companyPassword: data.password,
            startupToken: response.data.verificationToken,
            role: "startup",
          },
        });
      }
    } else {
      if (responseData.error.response.status === 409) {
        Toast(
          "Alerady Registered with this email. Please sign in.",
          "info",
          "comapnyRegisterSecond"
        );
        setTimeout(() => {
          navigate("/signIn", {
            state: { sentemail: data.email, type: "Startup" },
          });
        }, 2000);
      } else {
        console.error("Error submitting form data:", responseData.error);
        Toast(
          "Error submitting form data. Please try again.",
          "error",
          "comapnyRegisterSecond"
        );
      }
    }
    // navigate("/emailVerification"); // Proceed to the next step
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "64px",
      minWidth: "100%",
      fontSize: "14px",
      background: "none",
      border: "1px solid rgba(134, 146, 166, 1)",
      borderRadius: "6px",
      padding: "15px",
      fontFamily: '"Karla", sans-serif',
      minHeight: "64px",
      alignItems: "base-line",
    }),
  };

  return (
    <>
      <Helmet>
        <title>Fundrev | Company Register</title>
      </Helmet>
      <div className="signUp-div">
        <ToastContainer
          position="top-center"
          containerId="comapnyRegisterSecond"
        />
        <LeftSection
          heading="Improved Efficiency"
          content="Fundrev streamlines due diligence, enabling startups to quickly secure funding and focus on growth."
        />
        <div className="investorRegisterWithHeader">
          <Header color="white" type="startup" />
          <div className="investorRegister">
            <div className="section">
              <div className="goBack" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                <Typography className="back-text">Back</Typography>
              </div>
              <div className="title">
                <Typography variant="h6" className="heading">
                  Fill In Your Company Details
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="grey"
                  className="content"
                >
                  Pitch your startup, connect with investors, and accelerate
                  deal closures with Fundrev's streamlined platform.
                </Typography>
              </div>
              <div className="form">
                <div className="form-section Register-form-section">
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">
                      Revenue (last 12 months in Cr)
                    </FormLabel>
                    <Input
                      className="input"
                      startDecorator={"₹"}
                      placeholder="Enter revenue"
                      color="neutral"
                      name="revenue"
                      value={formData.revenue}
                      type="number"
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Industry</FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter industry"
                      color="neutral"
                      name="industry"
                      value={formData.industry}
                      type="text"
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div className="form-section Register-form-section">
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Funding Type</FormLabel>
                    <Select
                      styles={customStyles}
                      options={FundingTypeOptions}
                      value={FundingTypeOptions.find(
                        (option) => option.value === formData.fundingType
                      )}
                      onChange={(option) =>
                        handleSelectChange("fundingType", option.value)
                      }
                    />
                  </FormControl>

                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Timeline to Raise?</FormLabel>
                    <Select
                      styles={customStyles}
                      options={TimelineOptions}
                      value={TimelineOptions.find(
                        (option) => option.value === formData.timeline
                      )}
                      onChange={(option) =>
                        handleSelectChange("timeline", option.value)
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-section Register-form-section">
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Company Website</FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter company website"
                      color="neutral"
                      type="url"
                      name="website"
                      value={formData.website}
                      required
                      onChange={handleChange}
                    />
                    {companyWebsiteError && (
                      <div style={{ color: "red" }}>{companyWebsiteError}</div>
                    )}
                  </FormControl>
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Tech Stack Used</FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter tech stack used"
                      color="neutral"
                      type="text"
                      name="techstack"
                      value={formData.techstack}
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>

                <div className="form-section Register-form-section">
                  <FormControl
                    sx={{ width: "100%" }}
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">
                      Business Description
                    </FormLabel>
                    <Textarea
                      minRows={4}
                      className="desc-input"
                      color="neutral"
                      placeholder="Describe your business"
                      type="text"
                      name="description"
                      value={formData.description}
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
                <form
                  lassName="form-section Register-form-section"
                  onSubmit={handleSubmit}
                >
                  {/* Your input fields */}
                  <Button type="submit" className="button" fullWidth>
                    Save & Continue
                  </Button>
                </form>
                {/* 
              <div className="form-section Register-form-section">
                <Button className="button" fullWidth onSubmit={handleSubmit}>
                  Save & Continue
                </Button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailPage;
