import {
  Box,
  Table,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const FinancialStatementTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [open, setOpen] = useState(true);

  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];

  useEffect(() => {}, [type, data]);

  function CollapsibleRow(props) {
    const { row } = props;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            sx={{
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 1,
              width: 123,
              paddingY: "0px",
              whiteSpace: "nowrap",
              fontFamily: "Karla, sans-serif",
              padding: 0,
              borderBottom: "1px solid #B8B8B8",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #B8B8B8",
                height: "100%",
                width: "100%",
                padding: "3px 10px",
              }}
            >
              {row}

              <IconButton
                aria-label="expand row"
                size="small"
                sx={{
                  paddingY: 0,
                }}
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <KeyboardArrowUpIcon fontSize="3px" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="3px" />
                )}
              </IconButton>
            </div>
          </TableCell>
          {Object.values(data[type]["SG&A"]["Total SG&A Expenses"])?.map(
            (value, index) => (
              <TableCell
                key={index}
                sx={{
                  width: 123,
                  paddingY: "0px",
                  whiteSpace: "nowrap",
                  fontFamily: "Karla, sans-serif",
                  borderRight: "1px dotted #DADADA",
                  borderBottom: "1px solid #B8B8B8",
                }}
              >
                {formatToLakhs(value)}
              </TableCell>
            )
          )}
        </TableRow>
        {Object.keys(data[type][row]).map((inside_row, index) => {
          return (
            inside_row !== "Total SG&A Expenses" &&
            Object.values(data[type][row][inside_row])[0] && (
              <TableRow
                key={index}
                sx={{
                  display: open ? "table-row" : "none",
                  animation: open
                    ? "fadeIn 0.5s ease-in-out"
                    : "fadeOut 0.5s ease-in-out",
                  "@keyframes fadeIn": {
                    from: {
                      opacity: 0,
                      transform: "translateY(-10px)",
                    },
                    to: {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                  "@keyframes fadeOut": {
                    from: {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                    to: {
                      opacity: 0,
                      transform: "translateY(-10px)",
                    },
                  },
                }}
              >
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1,
                    width: 123,
                    paddingY: "0px",
                    whiteSpace: "nowrap",
                    fontFamily: "Karla, sans-serif",
                    padding: 0,
                    borderBottom: "1px solid #B8B8B8",
                  }}
                >
                  <div
                    style={{
                      borderRight: "1px solid #B8B8B8",
                      height: "100%",
                      width: "100%",
                      padding: "3px 10px",
                      paddingLeft: "30px",
                    }}
                  >
                    {inside_row}
                  </div>
                </TableCell>
                {Object.values(data[type][row][inside_row]).map(
                  (val, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        width: 123,
                        paddingY: "0px",
                        fontFamily: "Karla, sans-serif",
                        borderRight: "1px dotted #DADADA",
                        borderBottom: "1px solid #B8B8B8",
                      }}
                    >
                      {formatToLakhs(val && val) || 0}
                    </TableCell>
                  )
                )}
              </TableRow>
            )
          );
        })}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "1150px",
          height: open ? "600px" : "540px",
          padding: "15px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <TimePeriodButton
          type={type}
          setType={setType}
          ltm={true}
          title={data.name}
        />
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: 0,
            paddingX: "5px",
          }}
        >
          <TableContainer
            sx={{
              border: "1px solid #B8B8B8",
            }}
          >
            <Table aria-label="Financial statement table" size="small">
              <TableHead>
                <TableRow>
                  {[
                    "Particulars",
                    ...Object.keys(data[type][Object.keys(data[type])[0]]),
                  ].map((column, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      sx={{
                        position: "sticky",
                        left: index === 0 ? 0 : "auto",
                        background: index === 0 ? "white" : "none",
                        zIndex: index === 0 ? 1 : 0,
                        width: 123,
                        whiteSpace: "nowrap",
                        fontWeight: 700,
                        fontFamily: "Karla, sans-serif",
                        borderRight: index !== 0 && "1px solid #B8B8B8",
                        borderBottom: "1px solid #B8B8B8",
                        padding: index === 0 ? 0 : "3px 10px",
                        paddingY: "0px",
                      }}
                    >
                      <div
                        style={{
                          borderRight: index === 0 && "1px solid #B8B8B8",
                          height: "100%",
                          width: "100%",
                          padding: index === 0 && "3px 10px",
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        {column}
                        {index === 0 && (
                          <Typography
                            sx={{
                              fontFamily: `Karla, sans-serif`,
                              fontSize: "10px",
                              fontWeight: "700px",
                              color: "#6B7494",
                            }}
                          >
                            (in lakhs)
                          </Typography>
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data[type]).map((row, index) =>
                  row === "SG&A" ? (
                    <CollapsibleRow row={row} key={index} />
                  ) : (
                    <TableRow key={index}>
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: 1,
                            width: 123,
                            paddingY: "0px",
                            whiteSpace: "nowrap",
                            fontFamily: "Karla, sans-serif",
                            padding: 0,
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #000",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                          }}
                        >
                          <div
                            style={{
                              borderRight: "1px solid #B8B8B8",
                              height: "100%",
                              width: "100%",
                              padding: "3px 10px",
                            }}
                          >
                            {row}
                          </div>
                        </TableCell>
                      )}
                      {row !== "SG&A" &&
                        Object.keys(data[type][Object.keys(data[type])[0]]).map(
                          (val, index) => (
                            <TableCell
                              key={index}
                              sx={{
                                width: 123,
                                padding: "3px 10px",
                                fontFamily: "Karla, sans-serif",
                                borderRight: "1px dotted #DADADA",
                                fontWeight: IMPRows.includes(row)
                                  ? "700"
                                  : "400",
                                borderTop:
                                  IMPRows.includes(row) && "1.5px solid #000",
                                fontStyle: percentageRows.includes(row)
                                  ? "italic"
                                  : "normal",
                              }}
                            >
                              {formatToLakhs(data[type][row][val]) || `-`}
                            </TableCell>
                          )
                        )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default FinancialStatementTable;
