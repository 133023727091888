import React, { useState } from "react";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { postStageData } from "../../../endpoints/deal";
import Toast from "../Toast";
import { useLoader } from "../LoaderProvider";

const PassDealModal = ({
  openReasonModal,
  setOpenReasonModal,
  stage,
  formData,
  isCompany,
  containerId,
  type,
}) => {
  const containerName = containerId ? containerId : "companyDetails";
  const request = type === "withdraw" ? "withdrawing" : "passing";
  const token = localStorage.getItem("token");
  const [notInterestedReasonsStage1, setNotInterestedReasonsStage1] =
    useState("");
  const Navigate = useNavigate();
  const loader = useLoader();
  const handleNotInterested = async () => {
    if (isCompany) {
      if (notInterestedReasonsStage1 !== "") {
        console.log(stage);
        const updatedFormData = {
          Pass_Deal_Reason: notInterestedReasonsStage1,
          ...formData,
          IsRejected: true,
          Stage: stage,
        };
        try {
          loader.start("Declining deal...");
          const response = await postStageData(updatedFormData, token);
          loader.stop();
        } catch (error) {
          loader.stop();
        }
        Navigate("/company/fundraising");
        setOpenReasonModal(false);
      } else {
        Toast("Please enter the reason", "error", containerName);
      }
    } else {
      if (notInterestedReasonsStage1 !== "") {
        const updatedFormData = {
          ...formData,
          Not_Interested_Reason: notInterestedReasonsStage1,
          IsInterested: false,
          Stage: stage,
        };

        loader.start("Declining deal...");
        try {
          const response = await postStageData(updatedFormData, token);
          loader.stop();
        } catch (error) {
          loader.stop();
        }

        setOpenReasonModal(false);
        Navigate(
          stage === "initialStage"
            ? "/investor/opportunities"
            : "/investor/ongoingdeals"
        );
      } else {
        Toast("Please enter the reason", "error", containerName);
      }
    }
  };

  return (
    <>
      {/* <Modal
        open={openReasonModal}
        onClose={() => setOpenReasonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "220px" }}
        >
          
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            onClick={() => setOpenReasonModal(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading">Are you sure?</p>
            </div>
            <div className="subheading">
              <p>
                Are you sure you want to pass this deal. This action cannot be
                reversed.
              </p>
            </div>
          </div>

          <div className="btn-box">
            <button
              className="reason-submit cancel"
              style={{ height: "40px" }}
              onClick={() => setOpenReasonModal(false)}
            >
              Cancel
            </button>
            <button
              className="reason-submit pass-deal"
              onClick={() => handleNotInterested()}
            >
              Pass Deal
            </button>
          </div>
        </Box>
      </Modal> */}
      <Modal
        open={openReasonModal}
        onClose={() => setOpenReasonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "220px" }}
        >
          <div className="reason-box-first-div">
            <p>Reason for {request} the deal:</p>
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{ cursor: "pointer" }}
              onClick={() => setOpenReasonModal(false)}
            ></img>
          </div>
          <div className="reasons-box">
            <TextField
              id="standard-basic"
              placeholder="Reason"
              variant="standard"
              fullWidth
              value={notInterestedReasonsStage1}
              onChange={(e) => setNotInterestedReasonsStage1(e.target.value)}
            />
          </div>
          <button
            className="reason-submit"
            onClick={() => handleNotInterested()}
          >
            Continue
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default PassDealModal;
