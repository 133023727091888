import React, { useState, useEffect } from "react";
import Step from "./step";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default (props) => {
  const [activeSlide, setactiveSlide] = useState(props.activeSlide);
  const [slideNumber, setSlideNumber] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //Here we need to set percentage of not entire width but of (50vw +700px) because we have set the padding of (50vw - 700px) to right.
  const numSteps =
    windowWidth < 576
      ? 100
      : windowWidth < 991
      ? 60
      : windowWidth < 1500
      ? 60
      : windowWidth < 1300
      ? 80
      : windowWidth > 3000
      ? (500 * 130) / (windowWidth / 2 + (700 / windowWidth) * 100)
      : windowWidth > 1500
      ? (500 * 110) / (windowWidth / 2 + (700 / windowWidth) * 100)
      : 60;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const next = () => {
    // activeSlide < props.data.length - 1 && setactiveSlide(activeSlide + 1);
    if (slideNumber < 6) {
      setSlideNumber(slideNumber + 1);
      const sliderAnimated = document.querySelector(".slider.animated");
      sliderAnimated.style.transform = `translate3d(-${
        slideNumber * numSteps
      }%,0px,0px)`;
    }
  };
  const prev = () => {
    if (slideNumber > 1) {
      setSlideNumber(slideNumber - 1);
      const sliderAnimated = document.querySelector(".slider.animated");
      sliderAnimated.style.transform = `translate3d(-${
        (slideNumber - 2) * numSteps
      }%,0px,0px)`;
    }
  };
  const getStyles = (index) => {
    if (activeSlide === index)
      return {
        display: "block",
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    else if (activeSlide - 1 === index) {
      if (window.innerWidth < 576) {
        return {
          display: "none",
          transform: "translateX(-240px) translateZ(-400px)",
          zIndex: 9,
        };
      } else {
        return {
          display: "block",
          transform: "translateX(-240px) translateZ(-400px)",
          zIndex: 9,
        };
      }
    } else if (activeSlide + 1 === index) {
      if (window.innerWidth < 576) {
        return {
          display: "none",
          transform: "translateX(240px) translateZ(-400px)",
          zIndex: 9,
        };
      } else {
        return {
          display: "block",
          transform: "translateX(240px) translateZ(-400px)",
          zIndex: 9,
        };
      }
    } else if (activeSlide - 2 === index) {
      if (window.innerWidth < 991) {
        return {
          display: "none",
          transform: "translateX(-480px) translateZ(-500px)",
          zIndex: 8,
        };
      } else {
        return {
          display: "block",
          transform: "translateX(-240px) translateZ(-400px)",
          zIndex: 8,
        };
      }
    } else if (activeSlide + 2 === index) {
      if (window.innerWidth < 991) {
        return {
          display: "none",
          transform: "translateX(480px) translateZ(-500px)",
          zIndex: 8,
        };
      } else {
        return {
          display: "block",
          transform: "translateX(480px) translateZ(-500px)",
          zIndex: 8,
        };
      }
    } else if (index < activeSlide - 2) {
      if (window.innerWidth < 991) {
        return {
          display: "none",
          transform: "translateX(-480px) translateZ(-500px)",
          zIndex: 7,
        };
      } else {
        return {
          display: "block",
          transform: "translateX(-480px) translateZ(-500px)",
          zIndex: 7,
        };
      }
    } else if (index > activeSlide + 2) {
      if (window.innerWidth < 991) {
        return {
          display: "none",
          transform: "translateX(480px) translateZ(-500px)",
          zIndex: 7,
        };
      } else {
        return {
          display: "block",
          transform: "translateX(480px) translateZ(-500px)",
          zIndex: 7,
        };
      }
    }
  };

  return (
    <>
      <div className="frame-101">
        <div className="typography-32">
          Close Deals <span className="gradient-color">75% Faster</span>
        </div>
        <div className="frame-102">
          <div className="arrow-btn">
            <img
              className="img-6 arrow-left"
              alt="Button"
              onClick={prev}
              src={require("../../Assets/Images/LandingPage/arrow-right-black.png")}
            />
          </div>
          <div className="frame-84">
            <div className="typography-33">{slideNumber}/6</div>
          </div>
          <div className="arrow-btn">
            <img
              className="img-6"
              alt="Button"
              onClick={next}
              src={require("../../Assets/Images/LandingPage/arrow-right-black.png")}
            />
          </div>
        </div>
      </div>
      <div className="center">
        <div className="slideC">
          {props.data.map((item, i) => (
            <React.Fragment key={item.id}>
              <div
                className="slide"
                style={{
                  ...getStyles(i),
                }}
              >
                <Step
                  dayNumber={item.dayNumber}
                  heading={item.heading}
                  info={item.info}
                  img={item.img}
                  type={item.type}
                />
                <SliderContent {...item} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <Carousel
        className="crsl"
        autoPlay={false}
        centerSlidePercentage={numSteps}
        centerMode
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        interval={3000}
      >
        {props.data.map((item, i) => (
          <React.Fragment key={item.id}>
            <Step
              dayNumber={item.dayNumber}
              heading={item.heading}
              info={item.info}
              img={item.img}
              type={item.type}
              index={i}
            />
            <SliderContent {...item} />
          </React.Fragment>
        ))}
      </Carousel>
    </>
  );
};

const SliderContent = (props) => {
  return <div className="sliderContent"></div>;
};
