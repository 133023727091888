import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fundrevLogo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import whitelogout from "../../../../Assets/Images/InvestorPage/Dashboard/whitelogout.svg";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const InvestorSidebar = ({ active }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/adminPanel");
  };
  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  return (
    <div className="opdiv">
      <img
        loading="lazy"
        src={fundrevLogo}
        className="opimg"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <div className="sidebar-content">
        <div className="opdiv-2">
          <div className="normal-link">
            <div className="opdiv-3" id="investorPreview">
              <AdminPanelSettingsIcon className="opimg-2" />
              <div
                className="opdiv-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Investor Preview
              </div>
            </div>
          </div>
        </div>
        <div className="opdiv-15" onClick={handleLogout}>
          <img loading="lazy" src={whitelogout} className="opimg-8" />
          <div className="opdiv-4">Admin Panel</div>
        </div>
      </div>
    </div>
  );
};

export default InvestorSidebar;
