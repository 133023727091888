import React from "react";
import InvestorSignInBlock from "./investorSignInBlock";
import { ToastContainer } from "react-toastify";
const InvestorDealSignIn = () => {
  return (
    <div className="investorDealRegister investorDealSignIn">
      <ToastContainer position="top-center" containerId="signIn" />
      <InvestorSignInBlock startPoint="noNameDeals" />
    </div>
  );
};

export default InvestorDealSignIn;
