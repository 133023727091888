import React from "react";
import v1 from "../../../Assets/Images/InvestorPage/v1.svg";
import logowhite from "../../../Assets/Images/InvestorPage/logowhite.svg";
const InvestorPageMid5 = () => {
  return (
    <section className="InvestorPageMid5">
      <div className="div-202">
        <div className="div-203">
          <div className="div-204" id="phase2">
            Phase 2
          </div>
          <div className="div-205">Portfolio Management</div>
          <div className="div-206">
            Stay informed and empowered with: Real-time Portfolio Performance
            Tracking, Valuations, Timely Updates, News, and more—ensuring you
            never miss a beat in your portfolio.
          </div>
          <div className="div-207 laptop-stats" id="phase4">
            Phase 4
          </div>
          <div className="div-208 laptop-stats">Fundraising</div>
          <div className="div-209 laptop-stats">
            Attract interest from qualified HNIs, Corporates, and Family Offices
            for your upcoming fund with a full suite of fundraising tools.
          </div>
          <div className="div-211 mobile-stats">Phase 3</div>
          <div className="div-212 mobile-stats">Secondaries</div>
          <div className="div-213 mobile-stats">
            Exceed Fund Expectations: Identify strategic and financial buyers,
            expedite fund closures, surpass DPI goals, and elevate Fund IRRs for
            outstanding results.
          </div>
        </div>

        <div className="div-210" id="phase3">
          <div className="div-211 laptop-stats">Phase 3</div>
          <div className="div-212 laptop-stats">Secondaries</div>
          <div className="div-213 laptop-stats">
            Exceed Fund Expectations: Identify strategic and financial buyers,
            expedite fund closures, surpass DPI goals, and elevate Fund IRRs for
            outstanding results.
          </div>
          <div className="div-207 mobile-stats" id="phase4">
            Phase 4
          </div>
          <div className="div-208 mobile-stats">Fundraising</div>
          <div className="div-209 mobile-stats">
            Attract interest from qualified HNIs, Corporates, and Family Offices
            for your upcoming fund with a full suite of fundraising tools.
          </div>
        </div>
      </div>
      {/* <div className="div-214">
          <div className="div-215">
            <div className="div-216">
              <img
                loading="lazy"
                src={logowhite}
                className="img-21"
              />
              <div className="div-217">
                Complete this survey to access your
                priority features and unlock complimentary credits.
              </div>
              <div className="div-218">Complete Survey</div>
            </div>
          </div>
        </div> */}
    </section>
  );
};

export default InvestorPageMid5;
