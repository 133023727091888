import React, {useState, useEffect} from 'react'

import { useNavigate } from 'react-router-dom';

import error404 from "../../../Assets/Images/noRoutesMatched/error404.svg"
const StartupNoRoutesMatched = () => {
    const Navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      
    useEffect(() => {
      if (windowWidth < 1300 && windowWidth > 991) {
        document.getElementsByClassName("noRoutes-div")[0].style.zoom = "55%";
      } else {
        document.getElementsByClassName("noRoutes-div")[0].style.zoom = "100%";
      }
    }, [windowWidth]);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <div className="noRoutes-div">
        <div className="noRoutes-div-2">
          <img
            loading="lazy"
            src={error404}
            className="noRoutes-img"
          />
          <div className="noRoutes-div-3">Looks like this page doesn't exist!</div>
          <div className="noRoutes-div-4">
            Go back to fundraising  and continue exploring.
          </div>
          <div className="noRoutes-div-5" 
            style={{cursor:"pointer"}}
            onClick={()=>Navigate("/founder")}
            >Back to Fundraising</div>
        </div>
    </div>
  )
}

export default StartupNoRoutesMatched
