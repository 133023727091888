import React from "react";

import { Modal } from "@mui/material";
import { Box } from "@mui/joy";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import Bin from "../../../Assets/Images/signup/bin.svg";
const SpaAcceptanceModal = ({
  spaAcceptanceModal,
  setSpaAcceptanceModal,
  spaAcceptedInvestor,
  spaAcceptedCompany,
  closeDeal,
  acceptSPA,
}) => {
  return (
    <Modal
      open={spaAcceptanceModal}
      onClose={() => setSpaAcceptanceModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="modal-box reason-box"
        style={{ width: "600px", height: "220px" }}
      >
        <img
          className="reason-box-closeicon"
          src={closeIcon}
          style={{
            cursor: "pointer",
            position: "fixed",
            right: "20px",
            top: "20px",
          }}
          onClick={() => setSpaAcceptanceModal(false)}
        ></img>
        <div className="text-box">
          <div className="heading">
            <p className="heading">Are you sure?</p>
          </div>
          <div className="subheading">
            <p>
              Before accepting the SPA please ensure that the you have correctly
              read the SPA as this action is cannot be reversed.
            </p>
          </div>
        </div>

        <div className="btn-box">
          <button
            className="reason-submit cancel"
            style={{ height: "40px" }}
            onClick={() => setSpaAcceptanceModal(false)}
          >
            Cancel
          </button>
          <button
            className="reason-submit"
            onClick={
              (spaAcceptedInvestor && !spaAcceptedCompany) ||
              (spaAcceptedCompany && !spaAcceptedInvestor)
                ? closeDeal
                : acceptSPA
            }
          >
            Accept SPA
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default SpaAcceptanceModal;
