import React from "react";
import CardTags from "./molecules/cardTags";
import Finanicals from "../../Investor/Dashboard/Components/finanicals";
import { addDealToWishlist, removeDealFromWishlist } from "../functions";
import FundrevAnalystDetails from "../../Common/components/fundrevAnalystDetails";

import { investorState } from "../../Investor/Dashboard/investorState";

import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import greenTick from "../../../Assets/Images/InvestorPage/Dashboard/greenTick.svg";
import redMark from "../../../Assets/Images/InvestorPage/Dashboard/redMark.svg";
import star from "../../../Assets/Images/InvestorPage/Dashboard/star.svg";
import filledStar from "../../../Assets/Images/InvestorPage/Dashboard/filledStar.svg";
const NoNamePreview = ({
  deal,
  stage,
  isStar,
  setisStar,
  dealID,
  cardTags,
}) => {
  const location = useLocation();
  const Navigate = useNavigate();
  const { passedStage, isPassed } = location.state || {};
  const { investorId, name } = useRecoilValue(investorState);

  const handleAddToWishlist = async (startupId, investorId) => {
    setisStar(true);
    await addDealToWishlist(startupId, investorId);
  };

  const handleRemoveFromWishlist = async (startupId, investorId) => {
    setisStar(false);
    await removeDealFromWishlist(startupId, investorId);
  };
  return (
    <section className="NoNamePreview">
      <div
        className="cdetails-div"
        style={{ margin: stage === "companyOnBoarding" && "0px" }}
      >
        <div className="cdetails-first-div">
          <div className="cdetails-div-2">{deal ? deal.Title : ""}</div>
          {isPassed !== true && (
            <>
              {stage === "companyOnBoarding" ? (
                ""
              ) : stage === "initialStage" && isStar === false ? (
                <button
                  className="add-to-wishlist"
                  onClick={() =>
                    handleAddToWishlist(
                      deal ? deal.OrganizationID : "",
                      investorId
                    )
                  }
                >
                  <img src={star} alt="star" />
                  Add to wishlist
                </button>
              ) : (
                stage === "initialStage" &&
                isStar === true && (
                  <button
                    className="add-to-wishlist"
                    onClick={() =>
                      handleRemoveFromWishlist(
                        deal ? deal.OrganizationID : "",
                        investorId
                      )
                    }
                  >
                    <img src={filledStar} alt="filled star" />
                    Added to wishlist
                  </button>
                )
              )}
              {stage === "requestSent" && (
                <button className="stage-display-btn req-pending">
                  Approval Pending
                </button>
              )}
              {stage === "requestAccepted" && (
                <button className="stage-display-btn req-accepted">
                  Request Accepted
                </button>
              )}
            </>
          )}
        </div>
        <div className="laptop-stats">
          <p
            className="cdetails-div-3"
            style={{ display: "inline-block", whiteSpace: "normal" }}
          >
            {deal ? deal.SubHeading : ""}
          </p>
        </div>
        <div className="mobile-stats">
          <p className="cdetails-subheading">{deal ? deal.SubHeading : ""}</p>
        </div>
        <div className="cdetails-div-15" style={{ marginTop: "30px" }}>
          {deal ? deal.Description : ""}
        </div>
        <CardTags deal={deal} page={"noNameDetailsPage"} cardTags={cardTags} />
        <div className="cdetails-div-11" />
        <div className="cdetails-div-12">Key Highlights</div>
        <div className="cdetails-div-13">
          <div className="cdetails-div-14">
            <div className="column">
              <div
                className="cdetails-div-15 key-highlights-col"
                style={{ marginLeft: "30px" }}
              >
                {deal && deal.KeyHighlights
                  ? (() => {
                      try {
                        const highlights = JSON.parse(deal.KeyHighlights);
                        return highlights.map((highlight, index) => (
                          <ul key={index} style={{ listStyleType: "disc" }}>
                            <li>{highlight}</li>
                          </ul>
                        ));
                      } catch (error) {
                        console.error("Error parsing KeyHighlights:", error);
                        return null; // or some fallback UI
                      }
                    })()
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="cdetails-div-11" />
        <div className="cdetails-div-12">Purpose Of Fundraising</div>
        <div className="cdetails-div-13">
          <div className="cdetails-div-14">
            <div className="column">
              <div
                className="cdetails-div-15 key-highlights-col"
                style={{ marginLeft: "30px" }}
              >
                {deal && deal.PurposeOfFundraising
                  ? (() => {
                      try {
                        const purposes = JSON.parse(deal.PurposeOfFundraising);
                        return purposes.map((purpose, index) => (
                          <ul key={index} style={{ listStyleType: "disc" }}>
                            <li>{purpose}</li>
                          </ul>
                        ));
                      } catch (error) {
                        console.error("Error parsing KeyHighlights:", error);
                        return null; // or some fallback UI
                      }
                    })()
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="cdetails-div-11" />
        <Finanicals deal={deal} page="noNamePreview" />
        <div className="cdetails-div-11" />
        <div className="cdetails-div-60">Data Availability with Fundrev</div>
        <div className="cdetails-div-61">
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal
                    ? deal.DataAvailabilityWithFundrev.CustomerData === "true"
                      ? greenTick
                      : redMark
                    : ""
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Customer data</div>
                {/* <div className="cdetails-div-65">1000+ Users</div> */}
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal
                    ? deal.DataAvailabilityWithFundrev.Financial === "true"
                      ? greenTick
                      : redMark
                    : ""
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Financial</div>
                {/* <div className="cdetails-div-65">-----</div> */}
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal
                    ? deal.DataAvailabilityWithFundrev.Dataroom === "true"
                      ? greenTick
                      : redMark
                    : ""
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Dataroom</div>
                {/* <div className="cdetails-div-65">Intralinks</div> */}
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal
                    ? deal.DataAvailabilityWithFundrev.ForecastModel === "true"
                      ? greenTick
                      : redMark
                    : ""
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Forecast Model</div>
                {/* <div className="cdetails-div-65">-----</div> */}
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal
                    ? deal.DataAvailabilityWithFundrev
                        .ManagementPresentation === "true"
                      ? greenTick
                      : redMark
                    : ""
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Management Presentation</div>
              </div>
            </div>
          </div>
        </div>
        <div className="cdetails-div-11" />
        <div style={{ marginBottom: stage !== "companyOnBoarding" && 80 }}>
          <FundrevAnalystDetails analystID={"FN123456"} />
        </div>
      </div>
    </section>
  );
};

export default NoNamePreview;
