import React, { useEffect, useState } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Header from "./header";
import Overview from "./overview";
import DataRoom from "./dataroom";
import Activity from "./activity";
import Analytics from "./analytics/analytics";
import { useZoomEffect } from "../../../Common/functions";
import PassDealModal from "../../../Common/modals/passDealModal";
import IOIBidModal from "../../../Common/modals/IOIBidModal";
import LOIBidModal from "../../../Common/modals/LOIBidModal";
import { investorState } from "../investorState";
import Toast from "../../../Common/Toast";
import { getAllDealInteractions } from "../../../../endpoints/deal";
import AskQuestionModal from "../../../Common/modals/askQuestionModal";

import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { ToastContainer } from "react-toastify";

import { postStageData } from "../../../../endpoints/deal";
import Sidebar2 from "../Components/sidebar2";
import { useLoader } from "../../../Common/LoaderProvider";

const CompanyDetailsPage = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const { deal, passedStage } = location.state || {};
  const token = localStorage.getItem("token");
  const [stage, setStage] = useState(passedStage);
  const { activeBtn } = useRecoilValue(investorState);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [askQuestionModal, setAskQuestionModal] = useState(false);
  const [IOIBidModalOpen, setIOIBidModalOpen] = useState(false);
  const [LOIAgreementModalOpen, setLOIAgreementModalOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [investmentType, setInvestmentType] = useState("");
  const [selectedDate, handleDateChange] = useState(dayjs());
  const [fileUploads, setFileUploads] = useState({});
  const [analytics, setAnalytics] = useState("summary");
  const [fileInfo, setFileInfo] = useState({
    IOILetter: { filename: "", fileURL: "" },
    LOILetter: { filename: "", fileURL: "" },
  });
  const { name, investorId } = useRecoilValue(investorState);
  const [ioiBidDetails, setIoiBidDetails] = useState({
    postMoneyValuation: "",
    transactionCompletionDate: dayjs(),
    investmentType: "",
    dataRequests: "",
    investmentAmount: "",
    diligenceItems: "",
    IOILetter: "",
  });

  const [loiAgreement, setLOIAgreement] = useState({
    investmentAmount: "",
    valuationAmount: "",
    selectedDate: dayjs(),
    closingConditions: "",
    valuationConsiderations: "",
    paymentOptions: "",
    LOILetter: "",
    investmentType: "",
  });

  const [formData, setFormData] = useState({
    DealID: deal ? deal.DealID : "",
    investorID: investorId,
  });

  const handleLOIInputChange = (field, value) => {
    setLOIAgreement((prev) => ({ ...prev, [field]: value }));
  };

  const handleFileUpload = (uploadType, e) => {
    const file = e.target.files[0];
    if (!file) return;
    fileUploads[uploadType] = 1;
    var filename = file.name;
    const fileURL = URL.createObjectURL(file);
    setFileInfo((prevState) => ({
      ...prevState,
      [uploadType]: { filename, fileURL },
    }));
    if (uploadType === "IOILetter") {
      setIoiBidDetails((prevState) => ({
        ...prevState,
        IOILetter: file,
      }));
    }
    if (uploadType === "LOILetter") {
      setLOIAgreement((prevState) => ({
        ...prevState,
        LOILetter: file,
      }));
    }
  };
  const handleDeleteFile = (e, uploadType, step) => {
    fileUploads[uploadType] = 0;
    document.getElementById(uploadType).value = "";
    setFileInfo((prevState) => {
      const newState = { ...prevState };
      delete newState[uploadType];
      return newState;
    });
    if (uploadType === "IOILetter") {
      setIoiBidDetails((prevState) => ({
        ...prevState,
        IOILetter: "",
      }));
    }
    if (uploadType === "LOILetter") {
      setLOIAgreement((prevState) => ({
        ...prevState,
        LOILetter: "",
      }));
    }
  };
  useZoomEffect();
  const handleInvestmentTypeChange = (selectedOption) => {
    setInvestmentType(selectedOption.value);
  };
  const calculatePercentage = (value, total) => {
    setPercentage(((value / total) * 100).toFixed(2));
  };
  const checkAllFields = (obj) => {
    for (const key of Object.keys(obj)) {
      if (obj[key] === "" || obj[key] === null) {
        Toast("Please fill all the fields", "error", "companyDetails");
        return false;
      }
    }
    return true;
  };
  const handleIOISubmit = async () => {
    if (!checkAllFields(ioiBidDetails)) {
      Toast("Please fill all the fields", "error", "companyDetails");
      return;
    } else if (parseFloat(percentage) > 100) {
      Toast(
        "Investment Amount cannot be greater than current round",
        "error",
        "companyDetails"
      );
      return;
    } else if (
      parseFloat(ioiBidDetails.investmentAmount) >
      parseFloat(ioiBidDetails.postMoneyValuation)
    ) {
      Toast(
        "Stake Acquired cannot be greater than 100%",
        "error",
        "companyDetails"
      );
      return;
    } else {
      const formData = new FormData();
      formData.append("DealID", deal.DealID);
      formData.append("investorID", investorId);
      formData.append("IsInterested", true);
      formData.append("Stage", "ioiSubmitted");
      formData.append("sentBy", name);

      // Append IOI_Bid_Details as individual fields
      Object.keys(ioiBidDetails).forEach((key) => {
        if (key === "IOILetter") {
          formData.append(`IOI_Bid_Details[${key}]`, "");
        } else {
          formData.append(`IOI_Bid_Details[${key}]`, ioiBidDetails[key]);
        }
      });

      // Append IOI_Letter file
      if (ioiBidDetails.IOILetter) {
        formData.append("IOILetter", ioiBidDetails.IOILetter);
      }
      try {
        loader.start("Submitting IOI Bid...");
        const response = await postStageData(formData, token);
        loader.stop();
        if (response.data !== null) {
          setStage("ioiSubmitted");
        }
        setIOIBidModalOpen(false);
        Navigate("/investor/IOIBid", {
          state: {
            stage: "ioiSubmitted",
            dealId: deal.DealID,
            companyName: deal.CompanyName,
            fundingRequired: deal.FundingRequired,
          },
        });
      } catch (error) {
        loader.stop();
      }
    }
  };
  const handleLOISubmit = async () => {
    if (!checkAllFields(loiAgreement)) {
      Toast("Please fill all the fields", "error", "companyDetails");
      return;
    } else if (parseFloat(percentage) > 100) {
      Toast(
        "Investment Amount cannot be greater than current round",
        "error",
        "companyDetails"
      );
      return;
    } else if (
      parseFloat(loiAgreement.investmentAmount) >
      parseFloat(loiAgreement.valuationAmount)
    ) {
      Toast(
        "Stake Acquired cannot be greater than 100%",
        "error",
        "companyDetails"
      );
      return;
    }
    const formData = new FormData();
    formData.append("DealID", deal.DealID);
    formData.append("investorID", investorId);
    formData.append("IsInterested", true);
    formData.append("Stage", "loiSubmitted");
    formData.append("sentBy", name);

    // Append IOI_Bid_Details as individual fields
    Object.keys(loiAgreement).forEach((key) => {
      if (key === "LOILetter") {
        formData.append(`LOI_Agreement_Details[${key}]`, "");
      } else {
        formData.append(`LOI_Agreement_Details[${key}]`, loiAgreement[key]);
      }
    });

    // Append IOI_Letter file
    if (loiAgreement.LOILetter) {
      formData.append("LOILetter", loiAgreement.LOILetter);
    }
    try {
      loader.start("Submitting LOI Agreement...");
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setStage("loiSubmitted");
      }
      setLOIAgreementModalOpen(false);
      Navigate("/investor/LOIAgreement", {
        state: {
          stage: "loiSubmitted",
          dealId: deal.DealID,
          companyName: deal.CompanyName,
          fundingRequired: deal.FundingRequired,
        },
      });
    } catch (error) {
      loader.stop();
    }
  };

  const redirectToIOIBid = () => {
    Navigate("/investor/IOIBid", {
      state: {
        stage: "ioiSubmitted",
        dealId: deal.DealID,
        companyName: deal.CompanyName,
        fundingRequired: deal.FundingRequired,
      },
    });
  };
  const redirectToLOIBid = () => {
    Navigate("/investor/LOIAgreement", {
      state: {
        stage: "loiSubmitted",
        dealId: deal.DealID,
        companyName: deal.CompanyName,
        fundingRequired: deal.FundingRequired,
      },
    });
  };
  const redirectToSPANegotiation = () => {
    Navigate("/investor/spaNegotiation", {
      state: {
        stage: "spaNegotiation",
        dealId: deal.DealID,
        companyName: deal.CompanyName,
      },
    });
  };
  const fetchAllInteractions = async () => {
    try {
      loader.start("Fetching interactions...");
      const response = await getAllDealInteractions(
        deal.DealID,
        investorId,
        token
      );
      loader.stop();
      setStage(response.data[response.data.length - 1].Stage);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (!deal || !passedStage) {
      Navigate("/investor/ongoingdeals");
    }
  }, [deal, passedStage]);
  useEffect(() => {
    if (deal && investorId) fetchAllInteractions();
  }, [deal, investorId]);
  return (
    <div className="InvestorSide" style={{ overflowX: "hidden" }}>
      <ToastContainer position="top-center" containerId="companyDetails" />
      {activeBtn === 2 ? (
        <Sidebar2
          active="ongoingDeals"
          setAnalytics={setAnalytics}
          analytics={analytics}
        />
      ) : (
        <Sidebar active="ongoingDeals" />
      )}
      {/* <Sidebar active="ongoingDeals" /> */}
      <Topbar title="Ongoing Deals" />
      <PassDealModal
        setOpenReasonModal={setOpenReasonModal}
        openReasonModal={openReasonModal}
        stage={stage}
        formData={formData}
      />
      <AskQuestionModal
        askQuestionModal={askQuestionModal}
        setAskQuestionModal={setAskQuestionModal}
        token={token}
        dealID={deal ? deal.DealID : ""}
        investorID={investorId}
        roundType={stage}
        setNewQuestionAsked={setNewQuestionAsked}
      />
      <IOIBidModal
        IOIBidModalOpen={IOIBidModalOpen}
        setIOIBidModalOpen={setIOIBidModalOpen}
        ioiBidDetails={ioiBidDetails}
        setIoiBidDetails={setIoiBidDetails}
        handleDateChange={handleDateChange}
        handleIOISubmit={handleIOISubmit}
        handleFileUpload={handleFileUpload}
        fileUploads={fileUploads}
        fileInfo={fileInfo}
        handleDeleteFile={handleDeleteFile}
        investmentType={investmentType}
        handleInvestmentTypeChange={handleInvestmentTypeChange}
        fundingRequired={deal ? deal.FundingRequired : ""}
        calculatePercentage={calculatePercentage}
        percentage={percentage}
      />
      <LOIBidModal
        loiAgreement={loiAgreement}
        LOIAgreementModalOpen={LOIAgreementModalOpen}
        setLOIAgreementModalOpen={setLOIAgreementModalOpen}
        handleLOIInputChange={handleLOIInputChange}
        handleDateChange={handleDateChange}
        selectedDate={selectedDate}
        handleLOISubmit={handleLOISubmit}
        handleFileUpload={handleFileUpload}
        handleDeleteFile={handleDeleteFile}
        fileUploads={fileUploads}
        fileInfo={fileInfo}
        setLOIAgreement={setLOIAgreement}
        investmentType={investmentType}
        fundingRequired={deal ? deal.FundingRequired : ""}
        calculatePercentage={calculatePercentage}
        percentage={percentage}
      />
      <div className="InvestorSide-box" style={{ overflowX: "hidden" }}>
        <div className="InvestorSide-content">
          <div className="cdetails-div">
            <Header activeBtn={1} stage={stage} />
            {activeBtn === 1 && <Overview deal={deal} />}
            {activeBtn === 2 && (
              <Analytics
                deal={deal}
                analytics={analytics}
                setAnalytics={setAnalytics}
              />
            )}
            {activeBtn === 3 && (
              <DataRoom stage={stage} deal={deal} role="investor" />
            )}
            {activeBtn === 4 && (
              <Activity
                deal={deal}
                newQuestionAsked={newQuestionAsked}
                setNewQuestionAsked={setNewQuestionAsked}
              />
            )}
          </div>
          <div className="showInterestButtons-div">
            {stage !== "dealClosed" && (
              <div
                className="showInterestButtons-div-2"
                onClick={() => setOpenReasonModal(true)}
              >
                Pass Deal
              </div>
            )}
            <div className="showInterestButtons-right">
              {(stage === "ndaSigned" ||
                stage === "ioiSubmitted" ||
                stage === "ioiAccepted" ||
                stage === "loiSubmitted" ||
                stage === "spaNegotiation" ||
                stage === "loiAccepted") && (
                <div
                  className="stage-display-btn ask-question"
                  onClick={() => setAskQuestionModal(true)}
                >
                  Ask a question
                </div>
              )}
              {stage === "ndaSigned" && (
                <div
                  className="showInterestButtons-div-3"
                  onClick={() => setIOIBidModalOpen(true)}
                >
                  Submit IOI Bid
                </div>
              )}
              {stage === "ioiSubmitted" && (
                <div
                  className="stage-display-btn ask-question"
                  onClick={redirectToIOIBid}
                >
                  View IOI Bid
                </div>
              )}
              {stage === "ioiAccepted" && (
                <div
                  className="showInterestButtons-div-3"
                  onClick={() => setLOIAgreementModalOpen(true)}
                >
                  Submit LOI Agreement
                </div>
              )}
              {stage === "loiSubmitted" && (
                <div
                  className="stage-display-btn ask-question"
                  onClick={redirectToLOIBid}
                >
                  View LOI Agreement
                </div>
              )}
              {stage === "spaNegotiation" && (
                <div
                  className="showInterestButtons-div-3"
                  onClick={redirectToSPANegotiation}
                >
                  View SPA Negotiation
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsPage;
