import React, { useEffect, useState } from "react";
import LeftSection from "../../SignUp/LeftSection";
import PasswordValidator from "../../Common/passwordValidator.jsx";
import Header from "../../LandingPage/header";
import Toast from "../../Common/Toast";
import {
  fetchMembers,
  fetchInvestorDetails,
  memberSignUp,
} from "../../../endpoints/investor";

import { Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Helmet } from "react-helmet";

import "../../../CSS/Signup/investorRegister.css";
import { useLoader } from "../../Common/LoaderProvider.jsx";

const InvestorMemberSignUp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [token, setToken] = useState();
  const location = useLocation();
  const [name, setName] = useState("");
  const [memberData, setMemberData] = useState({
    name: "",
    title: "",
    companyName: "",
    organizationId: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const loader = useLoader();
  const [investorData, setInvestorData] = useState();

  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const updatePassword = (newPassword) => {
    setMemberData((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  };
  const handlePhoneChange = (value) => {
    setMemberData((prevState) => ({
      ...prevState,
      phoneNumber: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Object.values(memberData).every((value) => value.trim())) {
      Toast("Please fill in all fields.", "warning", "investorMemberSignUp");
      return;
    }

    if (!matchIsValidTel(memberData.phoneNumber)) {
      Toast(
        "Please enter a valid phone number.",
        "warning",
        "investorMemberSignUp"
      );
      return;
    }

    if (memberData.password !== memberData.confirmPassword) {
      Toast("Passwords do not match.", "warning", "investorMemberSignUp");
      return;
    } else {
      delete memberData.confirmPassword;
    }
    // localStorage.setItem("companyDetails", JSON.stringify(memberData));
    // navigate("/companyOnBoarding", {
    //   state: {
    //     data: memberData,
    //   },
    // });
    try {
      loader.start("Signing up...");
      const response = await memberSignUp(memberData);
      if (response.error) {
        if (response.error.status === 401) {
          Toast(
            "Toy have already accepted the Terms and Conditions. Redirecting to respective page",
            "info",
            "investorMemberSignUp"
          );
        }
        setTimeout(() => {
          navigate("/signIn", {
            state: { sentemail: memberData.email, type: "Investor" },
          });
        }, 1000);
      }
      localStorage.setItem("token", response.data.data.data.token);
      loader.stop();
      if (response.data !== null) {
        Toast(
          "Congratulations you signed up successfully",
          "success",
          "investorMemberSignUp"
        );
        navigate("/investorMembersOnBoarding", {
          state: {
            name: memberData.name,
            title: memberData.title,
            investorID: memberData.organizationId,
            phoneNumber: memberData.phoneNumber,
            email: memberData.email,
          },
        });
      } else {
        Toast("Error in signUp", "error", "investorMemberSignUp");
      }
    } catch (error) {
      loader.stop();
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    setToken(token);
    const senderName = queryParams.get("name");
    setName(senderName);
    setMemberData((prev) => ({
      ...prev,
    }));
    if (token) {
      // You can now use this token to authenticate API requests or manage sessions
    }
    const fetchDetails = async () => {
      try {
        loader.start("Loading Investor Details...");
        const response = await fetchInvestorDetails(token);
        loader.stop();
        if (response) {
          const investorDetails = response.data.data;
          setInvestorData(investorDetails);

          if (investorDetails) {
            setMemberData((prev) => ({
              ...prev,
              companyName: investorDetails.companyName,
              organizationId: investorDetails.investorID,
            }));
          }
        }
      } catch (error) {
        loader.stop();
      }
    };

    fetchDetails();
  }, [location, token]);

  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        loader.start("Loading Members...");
        const response = await fetchMembers(memberData.organizationId, token);
        loader.stop();
        if (response) {
          const members = response.data.data;

          if (members && members.length > 0) {
            const member = members.find((member) => member._id === id);
            if (member === undefined) {
              navigate("/investor/revoked");
            } else if (member) {
              setMemberData((prev) => ({
                ...prev,
                email: member.email,
              }));
            }
          }
        }
      } catch (error) {
        loader.stop();
      }
    };

    fetchAllMembers();
  }, [memberData.organizationId, token]);

  return (
    <>
      <Helmet>
        <title>Investor | Sign Up</title>
      </Helmet>
      <div className="signUp-div">
        <ToastContainer
          position="top-center"
          containerId="investorMemberSignUp"
        />
        <LeftSection
          heading="Secure and Supportive Environment"
          content="Encrypted transactions, RBAC controls, and responsive customer support ensure a secure and streamlined investment experience."
        />
        <div className="investorRegisterWithHeader investorDetailsPage">
          <Header color="black" type="investor" />
          <div className="investorRegister">
            <div className="section">
              <div className="goBack" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                <Typography className="back-text">Back</Typography>
              </div>
              <div className="title">
                <Typography variant="h6" className="heading">
                  {name} has invited you to join Fundrev!
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="grey"
                  className="content"
                >
                  Kindly complete the following details to join the team.
                </Typography>
              </div>
              <div className="form">
                <div className="form-section">
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Full Name</FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter Full Name"
                      color="neutral"
                      name="name"
                      value={memberData.name}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>

                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Title</FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter title"
                      color="neutral"
                      type="text"
                      value={memberData.title}
                      name="title"
                      // value={memberData.find((options)=>options.value === cin)}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                </div>

                <div className="form-section">
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">
                      Investment Firm Name
                    </FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter investment Firm Name"
                      color="neutral"
                      name="companyName"
                      value={memberData.companyName}
                      onChange={handleChange}
                      required
                      disabled
                    />
                  </FormControl>

                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Investor ID</FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter Investor ID"
                      color="neutral"
                      type="text"
                      value={memberData.organizationId}
                      name="organizationId"
                      // value={memberData.find((options)=>options.value === cin)}
                      onChange={handleChange}
                      required
                      disabled
                    />
                  </FormControl>
                </div>

                <div className="form-section">
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Phone Number</FormLabel>
                    <MuiTelInput
                      defaultCountry="IN"
                      className="input"
                      value={memberData.phoneNumber}
                      onChange={handlePhoneChange}
                      required
                    />
                  </FormControl>

                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">
                      Official Email Address
                    </FormLabel>
                    <Input
                      className="input"
                      placeholder="Enter work email address"
                      color="neutral"
                      type="email"
                      name="email"
                      value={memberData.email}
                      onChange={handleChange}
                      required
                      disabled
                    />
                  </FormControl>
                </div>

                <div className="form-section">
                  <PasswordValidator
                    updatePassword={updatePassword}
                    sendStatesToParent={receiveStatesFromChild}
                  />
                  <FormControl
                    className="form-control"
                    id="Id"
                    required
                    size="lg"
                    color="primary"
                  >
                    <FormLabel className="label">Confirm Password</FormLabel>
                    <Input
                      endDecorator={
                        !showConfirmPassword ? (
                          <VisibilityOffIcon
                            onClick={() => setshowConfirmPassword(true)}
                          />
                        ) : (
                          <Visibility
                            onClick={() => setshowConfirmPassword(false)}
                          />
                        )
                      }
                      className="input"
                      placeholder="Re-enter password"
                      color="neutral"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={memberData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                </div>
                <div className="form-section">
                  <Button className="button" fullWidth onClick={handleSubmit}>
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorMemberSignUp;
