import React, {useEffect, useState} from 'react'
import { useRecoilState } from 'recoil';
import { investorState ,saveState} from '../investorState';
const Header = (props) => {
  const [activeBtn, setActiveBtn] = useState(props.activeBtn);
  const [state, setState] = useRecoilState(investorState);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  return (
    <div className="investorDetailsPage-header">
        <div className='cdetails-first-div'>
        <div className="typeOfDeals" style={{paddingLeft:"0px",marginTop:"0px"}}>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 1 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(1);
            handleActiveBtn(1);
            }}
            style={{
                width:"150px"
            }}
        >
            Overview
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 2 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(2);
            handleActiveBtn(2);
            }}
            style={{
                width:"150px"
            }}
        >
            Analytics
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 3 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(3);
            handleActiveBtn(3);
            }}
            style={{
                width:"150px"
            }}
        >
            Dataroom
        </button>
        <button
            className={`typeOfDeals-btn ${
            activeBtn === 4 ? "typeOfDeals-active-btn" : ""
            }`}
            onClick={() => {
            setActiveBtn(4);
            handleActiveBtn(4);
            }}
            style={{
                display:props.from==="companyOnBoarding"?"none":"",
                width:"150px"
            }}
        >
            Activity
        </button>
        </div>
       {props.stage ==="ndaSigned" &&
        <button className='stage-display-btn req-accepted'>
            NDA Signed
        </button>
        }
        {props.stage ==="ioiSubmitted" &&
        <button className='stage-display-btn req-pending'>
            Approval Pending
        </button>
        }
        {props.stage ==="ioiAccepted" &&
        <button className='stage-display-btn req-accepted'>
            IOI Accepted
        </button>
        }
        {props.stage ==="loiSubmitted" &&
        <button className='stage-display-btn req-pending'>
            Approval Pending
        </button>
        }
        {props.stage ==="loiAccepted" &&
        <button className='stage-display-btn req-accepted'>
            LOI Accepted
        </button>
        }
        {props.stage ==="spaNegotiation" &&
        <button className='stage-display-btn req-pending'>
            SPA Negotiation
        </button>
        }
        {props.stage ==="dealClosed" &&
        <button className='stage-display-btn req-accepted'>
            Deal Closed
        </button>
        }
        
        </div>
        <div className='typeOfDeals-border' style={{width:"80%"}}></div>
    </div>
    
  )
}

export default Header
