import React, { Fragment } from "react";
import styled from "styled-components";

const Input = styled.input`
  height: 34px;
  border: 0px;

  width: 100%;
  padding: 0 0.5em;
  border-radius: 0;

  height: 35px;
`;

export const InputCell = ({
  placeholder,
  disabled,
  type,
  maxLength = "50",
  cell: { value },
  row: { index, original },
  column: { id },
  updateData,
}) => {
  const handleChange = (e) => {
    e.preventDefault();

    updateData(index, id, e.target.value);
    updateData(index, "errors", {
      ...original.errors,
      [id]: `Field ${id} is required`,
    });
  };
  //
  return (
    <Fragment>
      <Input
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleChange}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        type={type}
      />
    </Fragment>
  );
};

export default InputCell;
