import React from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "../Dashboard/Sidebar";
import InvestorSidebar from "../Dashboard/investorSidebar";

import { useLocation } from "react-router-dom";
import Editable from "../../../Common/components/editable";
import InputCell from "../../../Common/components/inputCell";
import Topbar from "../Dashboard/topbar";
const InvestorPreview = () => {
  const location = useLocation();
  const { dealID, investor, startupID, state } = location.state;
  const columns = React.useMemo(
    () => [
      {
        Header: "Fund",
        accessor: "fund",
        placeholder: "fund",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Vintage",
        accessor: "vintage",
        placeholder: "vintage",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Investments",
        accessor: "investments",
        placeholder: "investments",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Aum",
        accessor: "aum",
        placeholder: "aum",
        Cell: InputCell,
        width: 180,
      },
      {
        Header: "Dry Powder",
        accessor: "dryPowder",
        placeholder: "dryPowder",
        Cell: InputCell,
        width: 180,
      },
    ],
    []
  );
  return (
    <>
      <div className="InvestorSide" style={{ overflowY: "hidden" }}>
        <ToastContainer position="top-center" />
        {state === "companies" ? (
          <Sidebar
            dealID={dealID}
            active={"investorPanel"}
            startupID={startupID}
          />
        ) : (
          <InvestorSidebar active="investorPreview" />
        )}

        <Topbar title="Investor Preview" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div">
              <div className="companyReview">
                <p className="investorDetails-subheading">Investor Overview</p>
                <div className="companyReview-box">
                  <div className="companyReview-img">
                    <img
                      src={
                        investor.investorLogo
                          ? investor.investorLogo
                          : "default_logo.png"
                      }
                      className="InvestorLogo"
                      alt="Upload Image"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div>
                    <div className="companyReview-info">
                      <div>
                        <p className="companyReview-companyName">
                          {investor.companyName ? investor.companyName : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="companyReview-stats laptop-stats"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="admin-panel-Investment-thesis">
                        <div
                          className="companyReview-group"
                          style={{ width: "100%" }}
                        >
                          <div className="group-heading">
                            {investor.investmentThesis
                              ? investor.investmentThesis
                              : "N/A"}
                          </div>
                          <div className="group-subheading">
                            Investment Thesis
                          </div>
                        </div>
                      </div>
                      <div
                        className="admin-panel-investor-stats"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "20px",
                        }}
                      >
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {investor.headquarters
                              ? investor.headquarters
                              : "N/A"}
                          </div>
                          <div className="group-subheading">Headquarters</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {investor.foundedYear
                              ? investor.foundedYear
                              : "N/A"}
                          </div>
                          <div className="group-subheading">Founded Year</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {investor.numEmployees
                              ? investor.numEmployees
                              : "N/A"}
                          </div>
                          <div className="group-subheading">
                            No. of Employees
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {investor.investorType
                              ? investor.investorType
                              : "N/A"}
                          </div>
                          <div className="group-subheading">
                            Investment Type
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {investor.investorWebsite ? (
                              <a
                                href={`https://${investor.investorWebsite}`}
                                target="blank"
                                style={{ color: "black" }}
                              >
                                {investor.investorWebsite}
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </div>
                          <div className="group-subheading">Website</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dealTeam" style={{ marginBottom: "0px" }}>
                <p className="investorDetails-subheading">Investment Team</p>
                <div className="team-profiles-box">
                  {investor.foundingMember &&
                    investor.foundingMember[0] &&
                    JSON.parse(investor.foundingMember[0]).map(
                      (member, index) => (
                        <div className="team-profile-div" key={index}>
                          <img
                            loading="lazy"
                            src={
                              member.foundingTeamProfile
                                ? member.foundingTeamProfile
                                : "default_person.png"
                            }
                            className="team-profile-img"
                            alt={member.name ? member.name : "Profile Image"}
                          />
                          <div className="team-deal-div-2">
                            <div className="team-deal-div-3">
                              {member.name ? member.name : "-"}
                            </div>
                            <div className="team-deal-div-4">
                              {member.title ? member.title : "-"}
                            </div>
                            <div className="team-deal-div-5">
                              <span className="deal-team-span">
                                Email Id: &nbsp;
                              </span>
                              {member.email ? member.email : "-"}
                            </div>
                            <div className="team-deal-div-5">
                              <span className="deal-team-span">
                                Contact Info: &nbsp;
                              </span>
                              {member.phoneNumber ? member.phoneNumber : "-"}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="FundingDetails">
                <p className="investorDetails-subheading">Investment Focus</p>
                <div className="companyReview-stats laptop-stats">
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.ticketSizevalue && investor.ticketSizevalue[0]
                        ? JSON.parse(investor.ticketSizevalue[0]).join(" - ")
                        : "N/A"}{" "}
                      ₹Cr
                    </div>
                    <div className="group-subheading">Ticket Size Value</div>
                  </div>
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.stakeAcquired && investor.stakeAcquired[0]
                        ? JSON.parse(investor.stakeAcquired[0]).join(" - ") +
                          "%"
                        : "N/A"}
                    </div>
                    <div className="group-subheading">Stake Acquired</div>
                  </div>
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.investmentStage && investor.investmentStage[0]
                        ? JSON.parse(investor.investmentStage[0]).join(", ")
                        : "N/A"}
                    </div>
                    <div className="group-subheading">Investment Stage</div>
                  </div>
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.assetClass && investor.assetClass[0]
                        ? JSON.parse(investor.assetClass[0]).join(", ")
                        : "N/A"}
                    </div>
                    <div className="group-subheading">Asset Class</div>
                  </div>
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.financialCriteria &&
                      investor.financialCriteria[0]
                        ? JSON.parse(investor.financialCriteria[0]).join(", ")
                        : "N/A"}
                    </div>
                    <div className="group-subheading">Financial Criteria</div>
                  </div>
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.otherCriteria ? investor.otherCriteria : "N/A"}
                    </div>
                    <div className="group-subheading">Other Criteria</div>
                  </div>
                  <div className="companyReview-group companyReview-group-2">
                    <div className="group-heading">
                      {investor.coInvestment ? "YES" : "NO"}
                    </div>
                    <div className="group-subheading">Co-Investment</div>
                  </div>
                </div>
                <div className="table-details">
                  <Editable
                    columns={columns}
                    data={
                      investor.investmentDetails &&
                      investor.investmentDetails[0]
                        ? JSON.parse(investor.investmentDetails[0])
                        : []
                    }
                    updateData={() => {}}
                    isCompany={false}
                  />
                </div>
              </div>
              <div className="FundingDetails" style={{ border: "none" }}>
                <p className="investorDetails-subheading">Industry Focus</p>
                <div
                  className="companyReview-stats laptop-stats"
                  style={{ justifyContent: "space-around", gap: "none" }}
                >
                  <div
                    className="companyReview-group"
                    style={{ maxHeight: "200px" }}
                  >
                    <div className="group-subheading">Ideal Fit</div>
                    <div className="group-heading industry_focus">
                      {investor.idealFocusOptions &&
                      investor.idealFocusOptions[0]
                        ? JSON.parse(investor.idealFocusOptions[0]).join(", ")
                        : "N/A"}
                    </div>
                  </div>
                  <br />
                  <div
                    className="companyReview-group companyReview-group-2"
                    style={{ maxHeight: "200px" }}
                  >
                    <div className="group-subheading">Moderate Fit</div>
                    <div className="group-heading industry_focus">
                      {investor.moderatelySuitableOptions &&
                      investor.moderatelySuitableOptions[0]
                        ? JSON.parse(
                            investor.moderatelySuitableOptions[0]
                          ).join(", ")
                        : "N/A"}
                    </div>
                  </div>
                  <br />
                  <div
                    className="companyReview-group companyReview-group-2"
                    style={{ maxHeight: "200px" }}
                  >
                    <div className="group-subheading">Non Fit</div>
                    <div className="group-heading industry_focus">
                      {investor.negativeListOptions &&
                      investor.negativeListOptions[0]
                        ? JSON.parse(investor.negativeListOptions[0])
                            .map((option) => option.value)
                            .join(", ")
                        : "N/A"}
                    </div>
                  </div>
                  <br />
                  <div
                    className="companyReview-group companyReview-group-2"
                    style={{ maxHeight: "200px" }}
                  >
                    <div className="group-subheading">End Market Focus</div>
                    <div className="group-heading industry_focus">
                      {investor.endMarketFocus && investor.endMarketFocus[0]
                        ? JSON.parse(investor.endMarketFocus[0]).join(", ")
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorPreview;
