import React,{useState,useEffect} from 'react'
import step3 from "../../../Assets/Images/FounderPage/step3.svg"
import step3small from "../../../Assets/Images/FounderPage/step3small.svg"
const FoundersPageMid4 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const step3Url = windowWidth > 991 ? step3 : step3small
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className='InvestorPageMid2'>
    <div className="div-27">
    <div className="div-28">
        <div className='sourcing-div'>
            <img className='sourcing-img' src={step3Url}></img>
        </div>
        <div className="column-9">
        <div className="div-103">
            <div className="div-104">STEP 03</div>
            <div className="div-105">Get customized funding guidance for your startup</div>
            <p className='div-103-content'>Receive personalized support from Fundrev's expert team throughout your fundraising journey. Schedule consultations for insights on investor outreach, pitch preparation, due diligence, negotiation, and closing funding rounds. Tap into our industry connections and expertise to navigate fundraising confidently.</p>
            </div>
        </div>
    </div>
    </div>
    </section>
  )
}

export default FoundersPageMid4
