import React, { useEffect, useState } from "react";
import DealHeader from "./dealHeader";
import { Helmet } from "react-helmet";
import {
  getDealDetailsByDealId,
  getInvestorsByDealID,
} from "../../../../endpoints/deal";
import { useRecoilValue } from "recoil";
import { companyState } from "../companyState";
import { getDetailsById } from "../../../../endpoints/investor";
import { useLoader } from "../../../Common/LoaderProvider";

const Deals = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const { dealId } = useRecoilValue(companyState);
  const [deal, setDeal] = useState({});
  const [allInvestors, setAllInvestors] = useState([]);
  const [IOIBidsValue, setIOIBidsValue] = useState([]);
  const [LOIBidsValue, setLOIBidsValue] = useState([]);
  const [totalIOIBidVlaue, setTotalIOIBidValue] = useState(0);
  const [totalLOIBidVlaue, setTotalLOIBidValue] = useState(0);
  useEffect(() => {
    const fetchDealDetails = async () => {
      try {
        let response;
        if (token && dealId) {
          loader.start("Fetching deal details");
          response = await getDealDetailsByDealId(dealId, token);

          setDeal(response.data);
        } else {
          loader.stop();
        }
      } catch (error) {
        loader.stop();
      }
    };

    const getAllInvestors = async () => {
      try {
        const response = await getInvestorsByDealID(dealId, token);

        const investors = response.data;

        // Fetch all investor details concurrently
        const investorDetailsPromises = investors.map(async (investor) => {
          const investorId = investor.investorID;
          const resp = await getDetailsById(investorId, token);
          const name = resp.data.data.companyName;
          //
          return {
            ...investor,
            name,
          };
        });

        // Resolve all promises and update state
        const updatedInvestors = await Promise.all(investorDetailsPromises);
        loader.stop();
        setAllInvestors(updatedInvestors);
      } catch (error) {
        loader.stop();
      }
    };

    fetchDealDetails();
    getAllInvestors(token);
  }, [dealId, token]);
  const setIOIBids = async () => {
    const investmentAmounts = allInvestors.map(
      (investor) => investor.allIOI_Bids[0]?.investmentAmount || 0
    );
    const totalInvestmentAmount = investmentAmounts.reduce(
      (total, amount) => total + parseFloat(amount),
      0
    );
    setTotalIOIBidValue(totalInvestmentAmount);
    setIOIBidsValue((prevState) => ({
      ...prevState,
      investmentAmounts,
    }));
  };
  const setLOIBids = async () => {
    const investmentAmounts = allInvestors.map(
      (investor) => investor.allLOI_Agreements[0]?.investmentAmount || 0
    );
    const totalInvestmentAmount = investmentAmounts.reduce(
      (total, amount) => total + parseFloat(amount),
      0
    );
    setTotalLOIBidValue(totalInvestmentAmount);
    setLOIBidsValue((prevState) => ({
      ...prevState,
      investmentAmounts,
    }));
  };
  useEffect(() => {
    setIOIBids();
    setLOIBids();
  }, [allInvestors]);
  return (
    <>
      <div
        className="investorDetails investor-account-box"
        style={{ width: "97%" }}
      >
        <Helmet>
          <title>Company | Your Deals</title>
        </Helmet>
        <div className="deal-table-div">
          <DealHeader deal={deal} len={allInvestors.length} />
          <table>
            <thead>
              <tr>
                <th
                  className="deal-cell-heading"
                  rowSpan={2}
                  style={{ width: "15%" }}
                >
                  Investor
                </th>
                <th
                  className="deal-cell-heading text-align-center"
                  rowSpan={2}
                  style={{ width: "7%" }}
                >
                  Status
                </th>
                <th
                  className="deal-cell-heading cell-noBorder text-align-center"
                  style={{ width: "7%" }}
                >
                  IOI Bid (₹Cr)
                </th>
                <th
                  className="deal-cell-heading cell-noBorder text-align-center"
                  style={{ width: "7%" }}
                >
                  LOI Bid (₹Cr)
                </th>
                <th
                  className="deal-cell-heading cell-noBorder text-align-center"
                  colSpan={3}
                  style={{ width: "20%" }}
                >
                  Activity
                </th>
              </tr>
              <tr>
                <th
                  className=" text-align-center"
                  style={{ width: "7%", color: "#00A6FB" }}
                >
                  {`Total:${totalIOIBidVlaue}`}
                </th>
                <th
                  className=" text-align-center"
                  style={{ width: "7%", color: "#00A6FB" }}
                >
                  {`Total:${totalLOIBidVlaue}`}
                </th>
                <th className="deal-cell-subheading">
                  <th
                    className="cell-noBorder text-align-center"
                    style={{ width: "7%" }}
                  >
                    Data Requests
                  </th>
                  <th
                    className="cell-noBorder text-align-center"
                    style={{ width: "7%" }}
                  >
                    Q&As
                  </th>
                  <th
                    className="cell-noBorder text-align-center"
                    style={{ width: "7%" }}
                  >
                    Acceptance/ Denial
                  </th>
                </th>
              </tr>
            </thead>
            <tbody>
              {allInvestors.map((investor) => (
                // investor.IsInterested === true &&
                <tr>
                  <td
                    className="deal-cell-subHeading"
                    style={{ fontSize: "14px" }}
                  >
                    {investor.name}
                    <td
                      className="cell-noBorder deal-cell-heading"
                      style={{ padding: "8px 0px" }}
                    >
                      {investor.investorID}
                    </td>
                  </td>
                  <td className="text-align-center">
                    <div className="stage-button">
                      {investor.IsInterested === true ? (
                        investor.Stage.replace(
                          "requestSent",
                          "Request Received"
                        )
                          .replace("requestAccepted", "Request Accepted")
                          .replace("ioiSubmitted", "IOI Received")
                          .replace("ioiAccepted", "IOI Accepted")
                          .replace("loiSubmitted", "LOI Received")
                          .replace("loiAccepted", "LOI Accepted")
                          .replace("spaNegotiation", "SPA Negotiation")
                          .replace("dealClosed", "Deal Closed")
                      ) : (
                        <>
                          {investor.IsInterested === false
                            ? "Deal Passed By Investor"
                            : investor.IsRejected &&
                              "Declined Offer By Company"}
                        </>
                      )}
                    </div>
                  </td>
                  <td className="text-align-center deal-cell-heading">
                    {investor.allIOI_Bids.length > 0
                      ? investor.allIOI_Bids[0].investmentAmount
                      : "-"}
                  </td>
                  <td className="text-align-center deal-cell-heading">
                    {investor.allLOI_Agreements.length > 0
                      ? investor.allLOI_Agreements[0].investmentAmount
                      : "-"}
                  </td>
                  <td>
                    <td
                      className="cell-noBorder text-align-center"
                      style={{ width: "7%" }}
                    >
                      <div className="stage-button stage-button-done">Done</div>
                    </td>
                    <td
                      className="cell-noBorder text-align-center "
                      style={{ width: "7%" }}
                    >
                      <div className="stage-button stage-button-pending">
                        Pending
                      </div>
                    </td>
                    <td className="cell-noBorder" style={{ width: "7%" }}>
                      <div className="stage-button stage-button-done">Done</div>
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Deals;
