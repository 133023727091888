import React, { useState, useEffect } from "react";
import { getAllNoNameDeals } from "../../../endpoints/deal";
import Card from "../../Common/components/Card";
import Header from "../../LandingPage/header";
import Footer from "../../LandingPage/Footer";
import NoDeal from "../../Investor/Dashboard/Opportunities/NoDeal";
import Toast from "../../Common/Toast";
import DealsFilterBox from "../../Common/components/DealsFilterBox";

import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

import "../../../CSS/InvestorPage/noNameDeals.css";
import { CiFilter } from "react-icons/ci";

const NoNameDeals = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");

  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filterBoxOpen, setFilterBoxOpen] = useState(true);

  async function getNoNameDeals() {
    try {
      const data = await getAllNoNameDeals(token);
      setDeals(data.data);
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "noNameDeals");
    }
  }
  useEffect(() => {
    getNoNameDeals();
  }, [token]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 991) setFilterBoxOpen(false);
  }, [windowWidth]);
  return (
    <section>
      <Helmet>
        <title>Investor | Deals</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="noNameDeals" />
      <Header color="#193946" type="noNameDeals" />
      <div className="investor-noName-deals">
        <div style={{ padding: "0px 20px" }}>
          <div className="noNameDeal-first-section">
            <p className="noNameDeal-heading">
              Find highly qualified investments on Fundrev
            </p>
            <button
              className="InvestorSide-content-button-1 laptop-stats"
              onClick={() => setFilterBoxOpen(!filterBoxOpen)}
            >
              <CiFilter />
              Filter
            </button>
            <div className="mobile-stats">
              <button
                className="InvestorSide-content-button-1 mobile-stats"
                onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                style={{ padding: "10px 20px", borderRadius: "10px" }}
              >
                <CiFilter />
              </button>
            </div>
          </div>

          <DealsFilterBox
            deals={deals}
            setFilteredDeals={setFilteredDeals}
            filterBoxOpen={filterBoxOpen}
          />
        </div>

        <div className="opportunites-display">
          {filteredDeals && filteredDeals.length > 0 ? (
            filteredDeals.map((deal) => (
              <Card
                startPoint="noNameDeals"
                key={deal.DealID}
                isNew={false}
                cardTitle={deal.Title}
                cardDescription={deal.SubHeading}
                cardTags={[
                  ...(() => {
                    try {
                      const roundType = JSON.parse(deal.RoundType);
                      return Array.isArray(roundType) ? roundType : [];
                    } catch (error) {
                      console.error("Error parsing Round Type:", error);
                      return []; // Fallback value
                    }
                  })(),
                  ...(() => {
                    try {
                      const industryVerticals = JSON.parse(
                        deal.IndustryVerticals
                      );
                      return Array.isArray(industryVerticals)
                        ? industryVerticals
                        : [];
                    } catch (error) {
                      console.error("Error parsing IndustryVerticals:", error);
                      return []; // Fallback value
                    }
                  })(),
                  ...(() => {
                    try {
                      const endMarketFocus = JSON.parse(deal.EndMarketFocus);
                      return Array.isArray(endMarketFocus)
                        ? endMarketFocus
                        : ["_"];
                    } catch (error) {
                      console.error("Error parsing EndMarketFocus:", error);
                      return ["_"]; // Fallback value
                    }
                  })(),
                ]}
                revenue={deal.Revenue ? deal.Revenue : 0}
                grossProfit={deal.GrossProfit}
                EBITDA={deal.EBITDA}
                EBITDA_Percentage={deal.EBITDA_Percentage}
                IOI_Bids={deal.IOIBids}
                numInvestors={deal.InvestorsNum}
                fundingRequired={
                  deal.FundingRequired ? deal.FundingRequired : 0
                }
                totalFundingRaised={deal.TotalFundingRaised}
                dealId={deal.OrganizationID}
                deal={deal}
              />
            ))
          ) : (
            <NoDeal />
          )}
        </div>
      </div>
      <div className="landing-page ">
        <Footer />
      </div>
    </section>
  );
};

export default NoNameDeals;
