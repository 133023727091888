import React, { useEffect } from "react";
import LeftSection from "../SignUp/LeftSection";
import InvestorSignInBlock from "../Investor/RegisterPage/investorSignInBlock";
import { handleNavigation } from "../Common/functions.jsx";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "../../CSS/Signup/investorRegister.css";
import "../../CSS/SignIn/style.css";

const SignIn = () => {
  const navigate = useNavigate();
  useEffect(() => {
    handleNavigation(navigate);
  }, [navigate]);
  return (
    <>
      <Helmet>
        <title>Fundrev | Sign In</title>
      </Helmet>
      <div className="signUp-div signIn-div">
        <ToastContainer position="top-center" containerId="signIn" />
        <LeftSection
          heading="Accelerated Deal Closures"
          content="Fundrev closes deals 75% faster, saving time and resources."
        />
        <InvestorSignInBlock />
      </div>
    </>
  );
};

export default SignIn;
