import React, { useState } from "react";
import LeftSection from "../SignUp/LeftSection";
import Header from "../LandingPage/header";

import { adminLogin } from "../../endpoints/admin";

import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import { Helmet } from "react-helmet";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import axios from "axios";
import Toast from "../Common/Toast";
import { ToastContainer } from "react-toastify";
const AdminSignIn = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    value1: false,
    value2: false,
    value3: false,
  });
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignInSubmit = async () => {
    try {
      const response = await adminLogin(signInData, token);
      if (response.data !== null) {
        localStorage.setItem("token", response.data.data.verificationToken);
        localStorage.setItem("role", "admin");
        if (response.data.message === "Admin signed in successfully") {
          navigate("/adminPanel", {
            state: {
              verificationToken: response.data.data.verificationToken,
            },
          });
        } else if (response.status === 401) {
          alert("Invalid credentials");
        }
      } else {
        Toast("Internal server error", "error", "adminSignin");
      }
    } catch (error) {
      Toast("Internal server error", "error", "adminSignin");
      console.error("Error signing in:", error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Fundrev | Sign In</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="adminSignin" />
      <div className="signUp-div signIn-div">
        <LeftSection
          heading="Accelerated Deal Closures"
          content="Fundrev closes deals 75% faster, saving time and resources."
        />
        <div className="investorRegisterWithHeader">
          <Header color="black" type="investor" />
          <div className="investorRegister">
            <div className="section">
              <div className="goBack" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                <Typography className="back-text">Back</Typography>
              </div>
              <div className="title">
                <Typography
                  variant="subtitle1"
                  color="grey"
                  className="heading"
                >
                  Enter Admin Credentials
                </Typography>
              </div>
              <div className="form">
                <FormControl
                  className="form-control full-width-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Email Address</FormLabel>
                  <Input
                    className="input"
                    placeholder="Enter email address"
                    color="neutral"
                    type="email"
                    name="email"
                    value={signInData.email}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  className="form-control full-width-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Password</FormLabel>
                  <Input
                    endDecorator={
                      !showPassword.value1 ? (
                        <VisibilityOffIcon
                          onClick={() =>
                            setShowPassword((prevState) => ({
                              ...prevState,
                              value1: true,
                            }))
                          }
                        />
                      ) : (
                        <Visibility
                          onClick={() =>
                            setShowPassword((prevState) => ({
                              ...prevState,
                              value1: false,
                            }))
                          }
                        />
                      )
                    }
                    className="input"
                    placeholder="Enter password"
                    color="neutral"
                    type={showPassword.value1 ? "text" : "password"}
                    value={signInData.password}
                    name="password"
                    onChange={handleChange}
                  />
                </FormControl>
                <Button
                  className="button proceed-btn"
                  fullWidth
                  onClick={handleSignInSubmit}
                  style={{ textAlign: "center" }}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSignIn;
