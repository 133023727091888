import React, { useState } from "react";
import Person from "../../../../Assets/Images/signup/Person.svg";
import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";
import {
  calculateInvestments,
  calculateTotalAum,
  truncateText,
} from "../../../Common/functions";
const Overview = ({ investor, teamDetails }) => {
  //
  const companyName = investor ? investor.CompanyName : "";
  //   const columns = React.useMemo(() => cols, []);
  const Navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  // Parse the badges from investor's idealFocusOptions
  const badges = investor
    ? JSON.parse(investor.industryVerticalOptions[0])
    : [];
  const initialBadgeCount = 10;

  // Toggle function to show more or less
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  // )

  return (
    <>
      <div className="companyReview">
        <div
          className="cdetails-first-div"
          style={{ marginRight: "30px", alignItems: "baseline" }}
        >
          <p className="investorDetails-subheading">Investor Overview</p>
          <button className="investor-id">
            Investor ID: {investor ? investor.investorID : ""}
          </button>
        </div>
        <div className="companyReview-box">
          <div className="companyReview-img ">
            <img
              src={investor ? investor.investorLogo : ""}
              className="InvestorLogo"
              alt="Upload Image"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div>
            <div className="companyReview-info">
              <div>
                <p className="companyReview-companyName">
                  {investor ? investor.companyName : ""}
                </p>
                <p className="companyReview-comapnyDesc">
                  {investor ? investor.investmentThesis : ""} {/* //doubt */}
                </p>
              </div>
            </div>
            <div className="companyReview-stats laptop-stats">
              {/* <div className="companyReview-group">
                <div className="group-heading">{investor?investor.LegalEntityName:""}</div>
                <div className="group-subheading">Legal Entity Name</div>
              </div> */}
              <div className="companyReview-group">
                <div className="group-heading">
                  {investor ? investor.headquarters : ""}
                </div>
                <div className="group-subheading">Headquarters</div>
              </div>
              <div className="companyReview-group">
                <div className="group-heading">
                  {investor ? investor.foundedYear : ""}
                </div>
                <div className="group-subheading">Founded Year</div>
              </div>
              <div className="companyReview-group">
                <div className="group-heading">
                  {investor ? investor.investorWebsite : ""}
                </div>
                <div className="group-subheading">Website</div>
              </div>
            </div>
            <div className="companyReview-stats laptop-stats">
              <div className="companyReview-group">
                <div className="group-heading">
                  {calculateTotalAum(
                    investor ? JSON.parse(investor.investmentDetails[0]) : []
                  )}
                </div>
                <div className="group-subheading">AUM(₹Cr)</div>
              </div>
              <div className="companyReview-group">
                <div className="group-heading">
                  {calculateInvestments(
                    investor ? JSON.parse(investor.investmentDetails[0]) : []
                  )}
                </div>
                <div className="group-subheading">No. of Investments</div>
              </div>
              <div className="companyReview-group">
                <div className="group-heading">
                  {/* {investor?JSON.parse(investor.IndustryVerticals).join(", "):""} */}
                  {investor ? investor.investorType : ""}
                </div>
                <div className="group-subheading">Investment Type</div>
              </div>
            </div>

            <div className="companyReview-stats laptop-stats">
              <div className="companyReview-group" style={{ width: "400px" }}>
                <div className="group-heading">
                  {investor &&
                  investor.ticketSizevalue &&
                  investor.ticketSizevalue.length > 0 ? (
                    <>
                      ₹{JSON.parse(investor.ticketSizevalue[0])[0]}Cr{" "}
                      <span style={{ color: "#8692A6", fontSize: "14px" }}>
                        (Min) - - - -{" "}
                      </span>
                      ₹{JSON.parse(investor.ticketSizevalue[0])[1]}Cr
                      <span style={{ color: "#8692A6", fontSize: "14px" }}>
                        {" "}
                        (Max)
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="group-subheading">Ticket Size</div>
              </div>

              <div className="companyReview-group">
                <div className="group-heading">
                  {/* {investor?JSON.parse(investor.IndustryVerticals).join(", "):""} */}
                  {investor ? investor.keyInvestments : ""}
                </div>
                <div className="group-subheading">Key Investments</div>
              </div>
            </div>

            <div className="companyReview-stats laptop-stats">
              {/* <div className="companyReview-group" style={{width:'400px'}}>
                <div className="group-heading">{investor?`₹${JSON.parse(investor.ticketSizevalue[0])[0]}Cr(Min) ---- ₹${JSON.parse(investor.ticketSizevalue[0])[1]}Cr(Max)`:""}</div>
                <div className="group-subheading">Ticket Size</div>
              </div>
             
              <div className="companyReview-group">
                <div className="group-heading">
                </div>
                <div className="group-subheading">Key Investors</div>
              </div> */}
              <div className="badges" style={{ width: "100%" }}>
                <div
                  className="badge-soft-secondary"
                  style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                >
                  {badges
                    .slice(0, showAll ? badges.length : initialBadgeCount)
                    .map((item, index) => (
                      <div className="badge-base-wrapper" key={index}>
                        <div
                          className="badge-base"
                          style={{
                            backgroundColor: "#E9F3F8",
                            padding: "10px",
                            borderRadius: "6px",
                          }}
                        >
                          <div className="elements">
                            <div
                              className="text-wrapper-2"
                              style={{
                                textWrap: "wrap",
                                color: "#3C5168",
                                fontWeight: "700",
                              }}
                            >
                              {item}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {badges.length > initialBadgeCount && (
                  <p
                    onClick={toggleShowAll}
                    style={{
                      marginTop: "10px",
                      padding: "10px",
                      color: "#007bff",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {showAll ? "Show Less" : "Show More"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="FoundingTeam"
        style={{ borderBottom: "none", marginBottom: "100px" }}
      >
        <p className="investorDetails-subheading">Investment Team</p>
        <div className="profile-boxes">
          {teamDetails && teamDetails.length > 0
            ? teamDetails.map((member) => (
                <div className="team-profiles-box">
                  <div className="team-profile-div">
                    <img
                      loading="lazy"
                      src={member.memberImg ? member.memberImg : Person}
                      className="team-profile-img"
                    />
                    <div className="team-profile-div-2">
                      {member.linkedIn ? (
                        <Link to={member.linkedIn}>
                          <div className="team-profile-div-3">
                            {member.name}
                          </div>
                        </Link>
                      ) : (
                        <div
                          className="team-profile-div-3"
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {member.name}
                        </div>
                      )}
                      <div className="team-profile-div-4">{member.title}</div>
                      <div className="team-profile-div-5">
                        {member.biography.length > 100
                          ? truncateText(member.biography, 100)
                          : member.biography}
                      </div>{" "}
                      {/** //doubt */}
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};

export default Overview;
