import React, { useState ,useEffect} from 'react'
import dropdown from  "../../../Assets/Images/InvestorPage/dropdown.svg"
import blueup from  "../../../Assets/Images/InvestorPage/up.svg"
import sourcing1 from  "../../../Assets/Images/InvestorPage/sourcing-1.svg"
import sourcing1small from  "../../../Assets/Images/InvestorPage/sourcing1small.svg"
import sourcing2 from  "../../../Assets/Images/InvestorPage/sourcing-2.svg"
import sourcing2small from  "../../../Assets/Images/InvestorPage/sourcing2small.svg"
import sourcing3 from  "../../../Assets/Images/InvestorPage/sourcing-3.svg"
import sourcing3small from  "../../../Assets/Images/InvestorPage/sourcing3small.svg"
const InvestorPageMid2 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sourcing1Url = windowWidth > 991 ? sourcing1 : sourcing1small
  const sourcing2Url = windowWidth > 991 ? sourcing2 : sourcing2small
  const sourcing3Url = windowWidth > 991 ? sourcing3 : sourcing3small
  const [activeDiv, setActiveDiv] = useState("div-1");
  const [activeParentDiv, setActiveParentDiv] = useState("div-106");
  const [activeImg,setActiveImg] = useState("img-7")

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (div,ParentDiv,img) =>{
    // Deactivate all divs
    setActiveImg(img);
    const divs = document.querySelectorAll('.active-div');
    const Parentdivs = document.querySelectorAll('.active-parent-div');
    divs.forEach((el) => {
      el.classList.remove('active-div');
      el.classList.add('inactive-div');
    });
    Parentdivs.forEach((el) => {
      el.classList.remove('active-parent-div');
      el.classList.add('inactive-parent-div');
    });

    // Activate the clicked div
    setActiveDiv(div);
    setActiveParentDiv(ParentDiv)
    const element = document.getElementById(div);
    const Parent = document.getElementById(ParentDiv);
    element.classList.remove("inactive-div");
    Parent.classList.remove("inactive-parent-div");
    element.classList.add("active-div");
    Parent.classList.add("active-parent-div");
  }
  let imgSrc;
  if (activeDiv === "div-1") {
    imgSrc = sourcing1Url;
  } else if (activeDiv === "div-2") {
    imgSrc = sourcing2Url;
  } else if (activeDiv === "div-3") {
    imgSrc = sourcing3Url;
  }
  return (
  <section className='InvestorPageMid2'>
    <div className="div-27">
    <div className="div-28">
        {/* <div className="Listings" style={{height: 720, position: 'relative', background: 'linear-gradient(0deg, #F8F9FA 0%, #F8F9FA 100%)', borderRadius: 32, overflow: 'hidden'}}>
            <div style={{width: 231, height: 231, left: 120, top: 161, position: 'absolute', opacity: 0.60, background: 'linear-gradient(0deg, #00A6FB 0%, #00A6FB 100%)', boxShadow: '400px 400px 400px ', borderRadius: 9999, filter: 'blur(400px)'}} />
            <div style={{width: 231, height: 231, left: 269, top: 375, position: 'absolute', opacity: 0.80, background: 'linear-gradient(0deg, #C2F970 0%, #C2F970 100%), linear-gradient(0deg,  0%,  100%)', boxShadow: '500px 500px 500px ', borderRadius: 9999, filter: 'blur(500px)'}} />
            <div className="rect-1" style={{width: 400, height: 563, top: 80, position: 'absolute', background: 'white', boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.04)', borderRadius: 24, overflow: 'hidden', backdropFilter: 'blur(8.64px)'}}>
                <div style={{height: 48, padding: 16, left: 24, top: 103, position: 'absolute', background: '#FAFAFA', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex'}}>
                    <div style={{justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
                    <div style={{color: '#161616', fontSize: 14, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Asking price</div>
                    <div style={{width: 6, height: 6, background: '#00A6FB', borderRadius: 9999}} />
                    </div>
                    <div style={{justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
                    <div style={{color: '#161616', fontSize: 14, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>TTM Revenue</div>
                    <div style={{width: 6, height: 6, background: '#00A6FB', borderRadius: 9999}} />
                    </div>
                    <div style={{justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
                    <div style={{color: '#161616', fontSize: 14, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>TTM EBITDA</div>
                    <div style={{width: 6, height: 6, background: '#00A6FB', borderRadius: 9999}} />
                    </div>
                </div>
                </div>
                <div style={{left: 24, top: 24, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex'}}>
                <div style={{width: 332, color: '#161616', fontSize: 24, fontFamily: 'Karla', fontWeight: '500', wordWrap: 'break-word'}}>Recommended Listings</div>
                <div style={{color: '#2E2A2A', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>+15 listings</div>
                </div>
                <div style={{left: 24, top: 175, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                <div style={{color: '#2E2A2A', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Based on criteria</div>
                <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{padding: 12, background: '#00A6FB', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{height: 35, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>
                        <div style={{color: 'rgba(255, 255, 255, 0.60)', fontSize: 12, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                        <div style={{color: 'white', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>SaaS</div>
                    </div>
                    <div style={{height: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', height: 8, background: 'rgba(255, 255, 255, 0.40)', borderRadius: 100}} />
                        <div style={{width: 92, height: 8, background: 'rgba(255, 255, 255, 0.40)', borderRadius: 100}} />
                    </div>
                    <div style={{width: 136, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', height: 8, background: 'rgba(255, 255, 255, 0.40)', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: 'rgba(255, 255, 255, 0.40)', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: 'rgba(255, 255, 255, 0.40)', borderRadius: 100}} />
                    </div>
                    </div>
                    <div style={{padding: 12, background: '#FAFAFA', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{height: 35, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>
                        <div style={{color: 'rgba(22, 22, 22, 0.60)', fontSize: 12, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                        <div style={{color: '#161616', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>e Commerce</div>
                    </div>
                    <div style={{height: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{width: 92, height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    <div style={{width: 136, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    </div>
                    <div style={{padding: 12, background: '#FAFAFA', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{height: 35, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>
                        <div style={{color: 'rgba(22, 22, 22, 0.60)', fontSize: 12, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                        <div style={{color: '#161616', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>SaaS</div>
                    </div>
                    <div style={{height: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{width: 92, height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    <div style={{width: 136, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    </div>
                </div>
                </div>
                <div style={{left: 24, top: 341, position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                <div style={{color: '#2E2A2A', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Fundrev top pics</div>
                <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{padding: 12, background: '#FAFAFA', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{height: 35, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>
                        <div style={{color: 'rgba(22, 22, 22, 0.60)', fontSize: 12, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                        <div style={{color: '#161616', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Mobile</div>
                    </div>
                    <div style={{height: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{width: 92, height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    <div style={{width: 136, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    </div>
                    <div style={{padding: 12, background: '#FAFAFA', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{height: 35, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>
                        <div style={{color: 'rgba(22, 22, 22, 0.60)', fontSize: 12, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                        <div style={{color: '#161616', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Health Tech</div>
                    </div>
                    <div style={{height: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{width: 92, height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    <div style={{width: 136, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    </div>
                    <div style={{padding: 12, background: '#FAFAFA', borderRadius: 8, overflow: 'hidden', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
                    <div style={{height: 35, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 2, display: 'flex'}}>
                        <div style={{color: 'rgba(22, 22, 22, 0.60)', fontSize: 12, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                        <div style={{color: '#161616', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>SaaS</div>
                    </div>
                    <div style={{height: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex'}}>
                        <div style={{alignSelf: 'stretch', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{width: 92, height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    <div style={{width: 136, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex'}}>
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                        <div style={{flex: '1 1 0', height: 8, background: '#E5E5E5', borderRadius: 100}} />
                    </div>
                    </div>
                </div>
                </div>
                <div style={{height: 92, paddingLeft: 24, paddingRight: 24, paddingTop: 32, paddingBottom: 32, left: 0, top: 467, position: 'absolute', background: '#3C5168', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                <div style={{alignSelf: 'stretch', height: 28, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex'}}>
                    <div style={{width: 352, height: 10, background: 'rgba(255, 255, 255, 0.20)', borderRadius: 100}} />
                    <div style={{width: 248, height: 10, background: 'rgba(255, 255, 255, 0.20)', borderRadius: 100}} />
                </div>
                </div>
            </div>
            <div className="rect-2" style={{padding: 18, top: 338, position: 'absolute', background: 'rgba(255, 255, 255, 0.80)', boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.04)', borderRadius: 12, overflow: 'hidden', backdropFilter: 'blur(8.64px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 18, display: 'inline-flex'}}>
                <div style={{height: 52, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 3, display: 'flex'}}>
                <div style={{color: 'rgba(22, 22, 22, 0.60)', fontSize: 18, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>Startup Type</div>
                <div style={{color: '#161616', fontSize: 24, fontFamily: 'Karla', fontWeight: '400', wordWrap: 'break-word'}}>D2C</div>
                </div>
                <div style={{height: 30, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 6, display: 'flex'}}>
                <div style={{alignSelf: 'stretch', height: 12, background: '#E5E5E5', borderRadius: 150}} />
                <div style={{width: 138, height: 12, background: '#E5E5E5', borderRadius: 150}} />
                </div>
                <div style={{width: 204, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 6, display: 'inline-flex'}}>
                <div style={{flex: '1 1 0', height: 12, background: '#E5E5E5', borderRadius: 150}} />
                <div style={{flex: '1 1 0', height: 12, background: '#E5E5E5', borderRadius: 150}} />
                <div style={{flex: '1 1 0', height: 12, background: '#E5E5E5', borderRadius: 150}} />
                </div>
            </div>
        </div> */}
        <div className='sourcing-div laptop-img'>
            <img className='sourcing-img' src={imgSrc}></img>
        </div>
        <div className="column-9">
        <div className="div-103">
            <div className="div-104">SOURCING</div>
            <div className="div-105">Source deals that align with your investment thesis</div>
            <div className="div-106 active-parent-div" id="div-106" onClick={()=>handleClick("div-1","div-106","img-7")}>
            <div className='div-107'>01.</div>
            <div className="div-108" >
            Discover relevant deals within minutes
            </div>
            <img
                loading="lazy"
                src={activeImg==="img-7"?blueup:dropdown}
                className="img-7"
                onClick={()=>handleClick("div-1","div-106","img-7")}
            />
            </div>
            <div className="div-109 active-div" id="div-1" >
            <p>Refine your investment options with filters like industry, target market, business model, investment size, revenue, and more. Find opportunities that match your criteria precisely, avoiding deals that don't fit your requirements.</p>
            <div className='sourcing-div mobile-img'>
                <img className='sourcing-img' src={imgSrc}></img>
            </div>
            </div>
            <div className="div-110 inactive-parent-div" id="div-110" onClick={()=>handleClick("div-2","div-110","img-8")}>
            <div className='div-111'>02.</div>
            <div className="div-112">
            Receive Instant Alerts on New Opportunities
            </div>
            <img
                loading="lazy"
                src={activeImg==="img-8"?blueup:dropdown}
                className="img-8"
                onClick={()=>handleClick("div-2","div-110","img-8")}
            />
            </div>
            <div className="div-109 inactive-div" id="div-2" >
            <p>Receive real-time email notifications when a listing aligns with your investment criteria. Stay informed about new opportunities, ensuring you never miss potential investments.</p>
            <div className='sourcing-div mobile-img'>
                <img className='sourcing-img' src={imgSrc}></img>
            </div>
            </div>
            <div className="div-113 inactive-parent-div" id="div-113" onClick={()=>handleClick("div-3","div-113","img-9")}>
            <div className='div-114'>03.</div>
            <div className="div-115" >
            Efficiently secure your spot in the data room
            </div>
            <img
                loading="lazy"
                src={activeImg==="img-9"?blueup:dropdown}
                className="img-9"
                onClick={()=>handleClick("div-3","div-113","img-9")}
            />
            </div>
            <div className="div-109 inactive-div" id="div-3">
            <p>Stay ahead of investors by securing exclusivity for the diligence process, ensuring focused attention on deals that are likely to close.</p>
            <div className='sourcing-div mobile-img'>
                <img className='sourcing-img' src={imgSrc}></img>
            </div>
            </div>
            </div>
        </div>
    </div>
    </div>
    </section>
  )
}

export default InvestorPageMid2
