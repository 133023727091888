import React, { useState, useEffect } from "react";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import NoNamePreview from "../../../Common/components/NoNamePreview";
import { useLocation } from "react-router-dom";
import Logo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
const DownloadPdf = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [deal, setDeal] = useState();
  const { dealID } = location.state || {};
  const getDealDetails = async () => {
    try {
      const response = await getDealDetailsByDealId(dealID, token);
      setDeal(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getDealDetails();
  }, [dealID, token]);
  return (
    <>
      <img src={Logo} alt={"Fundrev"} style={{ padding: "30px" }} />
      <NoNamePreview deal={deal} />
    </>
  );
};

export default DownloadPdf;
