import React from "react";
import Header from "../../Common/components/header";
import { Helmet } from "react-helmet";
import companyWaitList from "../../../Assets/Images/signup/companyWaitlist.svg";
const companyWaitlistPage = () => {
  return (
    <section className="investorDetailsPage">
      <Helmet>
        <title>Fundrev | Company Waitlist</title>
      </Helmet>
      <Header color="black" token="Startuptoken" type="Startup" />
      <div className="companyWaitlistDiv">
        <img className="companyWaitlistImg" src={companyWaitList} />
        <p className="bold-text">
          You are on the <span className="blue-text">waitlist</span>
        </p>
        <p className="normal-text">
          Thanks for joining Fundrev waitlist. You can expect to hear from a
          member of our team shortly <span className="blue-text">:)</span>{" "}
        </p>
        <p className="normal-text">
          Think it's taking too long? Email{" "}
          <a
            href="mailto:deals@fundrev.ai"
            style={{
              textDecoration: "none",
              color: "#00A6FB",
            }}
          >
            deals@fundrev.ai{" "}
          </a>
          with your company details, and we'll fast-track your onboarding.
        </p>
      </div>
    </section>
  );
};

export default companyWaitlistPage;
