import React, { useEffect, useState } from "react";
import "../../../../CSS/FounderPage/Dashboard/timeline.css";
import tick from "../../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Tick.svg";
import circle from "../../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Circle.svg";
import { stages, useZoomEffect } from "../../../Common/functions";

import { useNavigate } from "react-router-dom";
import { getAllDealInteractions } from "../../../../endpoints/deal";
const Timeline = ({ passedStage, investor, companyName, dealId, sentBy }) => {
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const [allInterest, setAllInterest] = useState([]);
  useZoomEffect();
  const handleStageClick = (stage) => {
    if (stage === "ndaSigned") {
      Navigate("/company/signNDA", {
        state: {
          stage: "requestAccepted",
          dealId: dealId,
          investorID: investor.investorID,
          companyName: companyName,
          sentBy: sentBy,
        },
      });
    } else if (stage === "ioiAccepted") {
      Navigate("/company/ioiBid", {
        state: {
          stage: "ioiSubmitted",
          dealId: dealId,
          investorID: investor.investorID,
          companyName: companyName,
          sentBy: sentBy,
        },
      });
    } else if (stage === "loiAccepted") {
      Navigate("/company/loiAgreement", {
        state: {
          stage: "loiSubmitted",
          dealId: dealId,
          investorID: investor.investorID,
          companyName: companyName,
          sentBy: sentBy,
        },
      });
    } else if (stage === "spaNegotiation") {
      Navigate("/company/spaNegotiation", {
        state: {
          stage: "spaNegotiation",
          dealId: dealId,
          investorID: investor.investorID,
          companyName: companyName,
          sentBy: sentBy,
        },
      });
    }
  };

  const passedStageIndex = stages.indexOf(passedStage);

  useEffect(() => {
    const getAllInterest = async () => {
      try {
        const response = await getAllDealInteractions(
          dealId,
          investor.investorID,
          token
        );
        setAllInterest(response.data);
      } catch (error) {}
    };
    getAllInterest();
  }, []);

  return (
    <>
      <div
        className={`timeline ${
          passedStage === "requestSent" ||
          (passedStage === "loiAccepted" && "timeline-padding")
        } `}
      >
        <div className="div-2">Timeline</div>
        {stages.map((stage, index) => (
          <div className="div-3" key={index}>
            {stage === "dealClosed" ? <></> : <div className="line"></div>}
            <div
              className={`details-div ${
                index <= passedStageIndex ? "completed" : ""
              } ${index === passedStageIndex + 1 ? "clickable" : ""}`}
              // style={{
              //   cursor: index === passedStageIndex + 1 ? "pointer" : "default",
              // }}
            >
              <img
                loading="lazy"
                src={index <= passedStageIndex ? tick : circle}
                className="img"
                alt={stage}
              />
              <div className="div-4">
                <div className="div-5">
                  {allInterest.some(
                    (item) =>
                      item.Stage === stage &&
                      new Date(item.createdAt).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                  ) && (
                    <span>
                      {new Date(
                        allInterest.find(
                          (item) => item.Stage === stage
                        ).createdAt
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  )}
                </div>
                <div
                  className={`div-6 ${
                    index <= passedStageIndex ? "completed-text" : ""
                  }`}
                >
                  {stage
                    .replace("requestSent", "Request Received")
                    .replace("ioiSubmitted", "IOI Received")
                    .replace("loiSubmitted", "LOI Received")
                    .replace("ndaSigned", "NDA Signed")
                    .replace("requestAccepted", "Request Accepted")
                    .replace("ioiAccepted", "IOI Accepted")
                    .replace("loiAccepted", "LOI Accepted")
                    .replace("spaNegotiation", "SPA Negotiation")
                    .replace("dealClosed", "Deal Closed")
                    .trim()}

                  {(stage === "ndaSigned" ||
                    stage === "ioiAccepted" ||
                    stage === "loiAccepted" ||
                    stage === "spaNegotiation") &&
                    index <= passedStageIndex + 1 && (
                      <p
                        className="view-details"
                        onClick={() => handleStageClick(stage)}
                      >
                        View Details
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Timeline;
