import React from "react";
import { Box } from "@mui/material";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import FinancialStatementTable from "./AnalyticsPlots/FinancialStatementTable";
import FinancialStatementSmallComparisonTable from "./AnalyticsPlots/FinancialStatementSmallComparisonTable";
import FinancialStatementBigComparisonTable from "./AnalyticsPlots/FinancialStatementBigComparisonTable";
const FinancialStatementAnalytics = ({ data }) => {
  return (
    <>
      {data && (
        <div>
          <FinancialStatementTable data={data?.tableData} />
          <div
            style={{
              height: "10px",
            }}
          />
          <FinancialStatementBigComparisonTable data={data?.tableData} />
          <div
            style={{
              height: "10px",
            }}
          />
          {/* <FinancialStatementSmallComparisonTable data={data?.tableData} /> */}
          {/* <Box
            sx={{
              marginTop: 10,
              marginBottom: 10,
              background: "white",
              width: "1141px",
              height: "450px",
              borderRadius: "8px",
              paddingX: "10px",
            }}
          >
            <FinancialPlot data={data?.plotData} />
          </Box> */}
        </div>
      )}
    </>
  );
};

export default FinancialStatementAnalytics;
