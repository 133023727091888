import React, { useEffect, useState } from "react";
import Toast from "../Common/Toast";
import { getAllDeals, toggleOpenListing } from "../../endpoints/deal";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OpenListing = ({ createNewDeal, setCreateNewDeal }) => {
  const navigate = useNavigate();
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");

  async function getAllListings() {
    try {
      const data = await getAllDeals(token);
      const openListing = data.data.filter((deal) => !deal.OrganizationID);
      setDeals(openListing);
      setCreateNewDeal(false);
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "noNameDeals");
    }
  }
  async function toggleStatus(DealID) {
    try {
      await toggleOpenListing(DealID, token);

      getAllListings();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "noNameDeals");
    }
  }
  useEffect(() => {
    getAllListings();
  }, [token]);
  useEffect(() => {
    if (createNewDeal) {
      getAllListings();
    }
  }, [createNewDeal]);
  return (
    <div className="table-container">
      <ToastContainer position="top-center" containerId="noNameDeals" />
      <table>
        <thead>
          <tr>
            <th>Deal ID</th>
            <th>Company Name</th>
            <th>Open Listing</th>
            <th>Deal details</th>
            {/* <th>Investors</th> */}
          </tr>
        </thead>
        <tbody>
          {deals?.map((deal) => {
            return (
              <tr key={deal?.dealID}>
                <td>{deal?.DealID}</td>
                <td>{deal?.CompanyName}</td>
                <td>
                  <button
                    className={`toggleStatusbtn ${
                      deal?.openListing ? "Approved-btn" : "Pending-btn"
                    }`}
                    onClick={() => toggleStatus(deal.DealID)}
                  >
                    {deal?.openListing ? "Visible" : "Not Visible"}
                  </button>
                </td>
                <td>
                  <div
                    className="toggleStatusbtn"
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      navigate("/admin/dealDetailsEdit", {
                        state: {
                          dealID: deal.DealID,
                          startupID: deal.OrganizationID,
                        },
                      });
                    }}
                  >
                    Edit
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OpenListing;
