import { atom } from 'recoil';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('companyOnBoardingState');
    if (serializedState === null) {
      return { activeBtn:1, notificationBarOpen: false, numMessages: 0};
    }
    const state = JSON.parse(serializedState);
    return {
      ...state,
      notificationBarOpen: state.notificationBarOpen !== undefined ? state.notificationBarOpen : false,
    };
  } catch (err) {
    return {  activeBtn:'', notificationBarOpen: false, numMessages: 0};
  }
};

export const companyOnBoardingState = atom({
  key: 'companyOnBoardingState',
  default: loadState(),
});

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('companyOnBoardingState', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const interactedDealsStateCompanyOnBoarding = atom({
  key: 'interactedDealsStateCompanyOnBoarding', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});