import React from 'react'
import '../../CSS/LandingPage/step.css'
const Step = (props) => {
  return (
    <div className="step react-carousel-step" >
      <div className="frame">
        <div className="step-div">
          <div className="div-wrapper">
            <div className="text-wrapper">DAY {props.dayNumber}</div>
          </div>
          <div className="frame-2">
            <div className="text-wrapper-2">{props.type}</div>
          </div>
        </div>
        <div className="frame-3">
          <div className="typography">{props.heading}</div>
          <p className="prepare-for">
            {" "}
            {props.info}
          </p>
        </div>
      </div>
      
        <img src={props.img} alt="step1" className={` step-image step-image-${props.index}`} />
      
    </div>
  )
}

export default Step
