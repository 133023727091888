import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import deals from "../../../../Assets/Images/InvestorPage/Dashboard/deals.svg";
import fundrevLogo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import whitelogout from "../../../../Assets/Images/InvestorPage/Dashboard/whitelogout.svg";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { FaDatabase, FaFileUpload } from "react-icons/fa";
import {
  AiFillEdit,
  AiOutlineLineChart as AiFillBoxPlot,
} from "react-icons/ai";
import { Typography } from "@mui/material";

const Sidebar2 = ({ active, setAnalytics, analytics, dealID, startupID }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleLogout = () => {
    navigate("/adminPanel");
  };
  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  return (
    <div className="opdiv2">
      <img
        loading="lazy"
        src={fundrevLogo}
        className="opimg2"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <div className="complete-sidebar">
        <div className="sidebar-content2">
          <div className="opdiv2-2">
            <div
              className="normal-link"
              onClick={() =>
                navigate("/admin/profilePreview", {
                  state: {
                    DealID: dealID,
                    startupID: startupID,
                    token: token,
                    analystID: "FN123456",
                  },
                })
              }
            >
              <div className="opdiv2-3" id="profilePreview">
                <img loading="lazy" src={deals} className="opimg2-2" />
              </div>
            </div>
            <div
              className="normal-link"
              onClick={() =>
                navigate("/admin/investorPannel", {
                  state: {
                    dealID,
                    startupID,
                  },
                })
              }
            >
              <div className="opdiv2-3" id="investorPanel">
                <AdminPanelSettingsIcon className="opimg2-2" />
              </div>
            </div>
            <div
              className="normal-link"
              onClick={() =>
                navigate("/admin/dataroom", {
                  state: {
                    dealID,
                    startupID,
                  },
                })
              }
            >
              <div className="opdiv2-3" id="dataRoom">
                <FaDatabase className="opimg2-2" />
              </div>
            </div>
            <div
              className="normal-link"
              onClick={() =>
                navigate("/admin/dealDetailsEdit", {
                  state: {
                    dealID,
                    startupID,
                  },
                })
              }
            >
              <div className="opdiv2-3" id="dealDetails">
                <AiFillEdit className="opimg2-2" />
              </div>
            </div>
            <div
              className="normal-link"
              onClick={() =>
                navigate("/admin/analyticsMapper", {
                  state: {
                    dealID,
                    startupID,
                  },
                })
              }
            >
              <div className="opdiv2-3" id="analyticsMapper">
                <FaFileUpload className="opimg2-2" />
              </div>
            </div>
            <div
              className="normal-link"
              onClick={() =>
                navigate("/admin/analytics", {
                  state: {
                    dealID,
                    startupID,
                  },
                })
              }
            >
              <div className="opdiv2-3" id="analytics">
                <AiFillBoxPlot className="opimg2-2" />
              </div>
            </div>
          </div>
          <div className="opdiv2-15" onClick={handleLogout}>
            <img loading="lazy" src={whitelogout} className="opimg2-8" />
          </div>
        </div>
        <div
          className="opdiv2-16"
          style={{
            backgroundColor: "#002132",
            alignItems: "start",
            gap: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor: analytics === "summary" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("summary")}
          >
            Summary
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "businessAnalytics" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("businessAnalytics")}
          >
            Business Analytics
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "salesAnalytics" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("salesAnalytics")}
          >
            Sales Analytics
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "historicalFinancials" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("historicalFinancials")}
          >
            Historical Financials
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "financialForecast" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("financialForecast")}
          >
            Financial Forecast
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "fundingDetails" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("fundingDetails")}
          >
            Funding Details
          </Typography>
          {/* <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "customerTape" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("customerTape")}
          >
            Customer Tape
          </Typography> */}
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "financialStatement" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("financialStatement")}
          >
            Financial Statement
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Sidebar2;
