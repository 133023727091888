import React, { useState, useEffect } from "react";
import { fetchBlobName } from "../../../Common/functions";
import { getAllDealInteractions } from "../../../../endpoints/deal";
import { Link } from "react-router-dom";

import { dealInterestByStage } from "../../../../endpoints/deal";
import FileImg from "../../../../Assets/Images/signup/file.svg";
import { useLoader } from "../../../Common/LoaderProvider";
const DealDocuments = ({ dealId, investorID }) => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [NDADocument, setNDADocument] = useState([]);
  const [IoiBid, setIoiBid] = useState([]);
  const [LoiAgreement, setLoiAgreement] = useState([]);
  const [SPA, setSPA] = useState([]);
  const [latestStage, setLatestStage] = useState();
  const stages = [
    { dateKey: "initialStage", index: 0 },
    { dateKey: "requestSent", index: 1 },
    { dateKey: "requestAccepted", index: 2 },
    { dateKey: "ndaSigned", index: 3 },
    { dateKey: "ioiSubmitted", index: 4 },
    { dateKey: "ioiAccepted", index: 5 },
    { dateKey: "loiSubmitted", index: 6 },
    { dateKey: "loiAccepted", index: 7 },
    { dateKey: "spaNegotiation", index: 8 },
    { dateKey: "dealClosed", index: 9 },
  ];
  useEffect(() => {
    const fetchAllInteractions = async () => {
      try {
        const response = await getAllDealInteractions(
          dealId,
          investorID,
          token
        );
        setLatestStage(response.data[response.data.length - 1].Stage);
      } catch (error) {
        loader.stop();
      }
    };
    fetchAllInteractions();
  }, [dealId, investorID]);
  const getDealInterestByStage = async (dealId, investorID, stage) => {
    try {
      const response = await dealInterestByStage(
        dealId,
        investorID,
        stage,
        token
      );
      loader.stop();
      if (response) {
        if (stage === "requestAccepted") {
          const filteredData = response.data.filter((item) => item.NDADocument);
          const NDADocuments = filteredData.map((item) => item.NDADocument);
          setNDADocument(NDADocuments[0]);
        } else if (stage === "ioiSubmitted") {
          const IOIBids = response.data.map((item) => item.IOI_Bid_Details);
          setIoiBid(IOIBids[0].IOILetter);
        } else if (stage === "loiSubmitted") {
          const LOIAgreements = response.data.map(
            (item) => item.LOI_Agreement_Details
          );
          setLoiAgreement(LOIAgreements[0].LOILetter);
        } else if (stage === "spaNegotiation") {
          const filteredData = response.data.filter((item) => item.SPA);
          const SPAs = filteredData.map((item) => item.SPA);
          setSPA(SPAs[0]);
        }
      }
    } catch (error) {
      loader.stop();
    }
  };

  const files = [
    { file: NDADocument, name: "NDADocument" },
    { file: IoiBid, name: "IoiBid" },
    { file: LoiAgreement, name: "LoiAgreement" },
    { file: SPA, name: "SPA" },
  ];
  const FileUpload = ({ file, fileName }) => (
    <div className="fileUploads" style={{ width: "fit-content" }}>
      <img src={FileImg} alt="file" />
      <div className="fileUploads-div-2">
        <Link to={file} className="fileLink">
          <div className="fileUploads-div-3" style={{ width: "fit-content" }}>
            {fetchBlobName(file)}
          </div>
        </Link>
      </div>
    </div>
  );
  const getDealDocuments = async () => {
    if (dealId && investorID) {
      const index = stages.findIndex((stage) => stage.dateKey === latestStage);
      if (index >= 2) {
        getDealInterestByStage(dealId, investorID, "requestAccepted");
      }
      if (index >= 4) {
        getDealInterestByStage(dealId, investorID, "ioiSubmitted");
      }
      if (index >= 6) {
        getDealInterestByStage(dealId, investorID, "loiSubmitted");
      }
      if (index >= 8) {
        getDealInterestByStage(dealId, investorID, "spaNegotiation");
      }
    }
  };
  useEffect(() => {
    loader.start("Getting deal documents...");
    getDealDocuments();
  }, [dealId, investorID, latestStage]);
  return (
    <div
      className="Deal-documents-div"
      style={{ border: "0px", marginTop: "0px" }}
    >
      <div className="all-file-uploads">
        {files.map(
          ({ file, name }) =>
            file.length > 0 && (
              <FileUpload key={name} file={file} fileName={name} />
            )
        )}
      </div>
    </div>
  );
};

export default DealDocuments;
