import React,{useState, useEffect} from 'react'
import Sidebar from '../Components/sidebar'
import Topbar from '../Components/topbar'
import Account from './account'
import Profile from './profile'
import Members from './members'
import { useZoomEffect } from '../../../Common/functions'

import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from "react-toastify";


import "../../../../CSS/InvestorPage/Dashboard/account.css"
const AccountPage = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const token= localStorage.getItem("token");
  // useEffect(() => {
  //   if (!token) {
  //     Navigate("/signIn");
  //   }
  // }, [token]);
  const { activeButton } = location.state || { activeButton: 1 };
  const [activeBtn, setActiveBtn] = useState(activeButton);
  const handleActiveBtn = (activeBtn) => {
    setActiveBtn(activeBtn);
  }
  useZoomEffect();
  return (
    <div className='InvestorSide'>
        <ToastContainer position="top-center" containerId="investorAccount"/>
      <Sidebar/>
      <Topbar title= "Settings"/>
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className='cdetails-div'>
            <div className='cdetails-first-div header-with-border-bottom'>
              <div className="typeOfDeals" style={{paddingLeft:"0px",marginTop:"0px"}}>
                  <button
                      className={`typeOfDeals-btn ${
                      activeBtn === 1 ? "typeOfDeals-active-btn" : ""
                      }`}
                      onClick={() => {
                      setActiveBtn(1);
                      handleActiveBtn(1);
                      }}
                      style={{
                        width:"140px"
                      }}
                  >
                      Account
                  </button>
                  <button
                      className={`typeOfDeals-btn ${
                      activeBtn === 2 ? "typeOfDeals-active-btn" : ""
                      }`}
                      onClick={() => {
                      setActiveBtn(2);
                      handleActiveBtn(2);
                      }}
                      style={{
                        width:"140px"
                      }}
                  >
                      Profile
                  </button>
                  <button
                      className={`typeOfDeals-btn ${
                      activeBtn === 3 ? "typeOfDeals-active-btn" : ""
                      }`}
                      onClick={() => {
                      setActiveBtn(3);
                      handleActiveBtn(3);
                      }}
                      style={{
                        width:"140px"
                      }}
                  >
                      Members
                  </button>
              </div>
              <div className='typeOfDeals-border'></div>
            </div>
            {activeBtn === 1 && <Account/>}
            {activeBtn === 2 && <Profile/>}
            {activeBtn === 3 && <Members/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
