import { atom , useResetRecoilState } from 'recoil';
import { useCallback } from 'react';


const loadState = () => {
  try {
    const serializedState = localStorage.getItem('companyState');
    if (serializedState === null) {
      return { name: '', activeBtn:1, notificationBarOpen: false , ProfileBoxOpen: false , wishListOpen: false, dealId:'',startupId:'', numMessages: 0, startupMemberId:""};
    }
    const state = JSON.parse(serializedState);
    return {
      ...state,
      notificationBarOpen: state.notificationBarOpen !== undefined ? state.notificationBarOpen : false,
      ProfileBoxOpen: state.ProfileBoxOpen !== undefined ? state.ProfileBoxOpen : false,
      wishListOpen: state.wishListOpen !== undefined ? state.wishListOpen : false,
    };
  } catch (err) {
    return { name: '', activeBtn: 1, notificationBarOpen: false, ProfileBoxOpen: false, wishListOpen: false, dealId:'', startupId:'', numMessages: 0, startupMemberId:"" };
  }
};

export const companyState = atom({
  key: 'companyState',
  default: loadState(),
});

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('companyState', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const interactedDealsStateCompany = atom({
  key: 'interactedDealsStateCompany', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const useCompanyLogout = () => {
  const resetCompanyState = useResetRecoilState(companyState);
  const resetInteractedDealsState = useResetRecoilState(interactedDealsStateCompany);

  return useCallback(() => {
    localStorage.removeItem('companyState');

    // Reset Recoil state
    resetCompanyState();
    resetInteractedDealsState();
  }, [resetCompanyState, resetInteractedDealsState]);
};