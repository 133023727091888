import axios from "axios";
const token = localStorage.getItem("token");
export const getValues = async () => {
  try {
    const response = await fetch("https://api.fundrev.ai/startup/getValues", {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null }; // Returning data and null for error
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message }; // Returning null for data and the error message
  }
};

export const startupSignUp = async (formData) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/signup",
      // "http://localhost:3000/startup/signup",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const checkStartupRegistered = async (email) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/checkAlreadyRegistered",
      { email },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const fetchStartupDetails = async (token) => {
  //
  try {
    const response = await fetch("https://api.fundrev.ai/startup/details", {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const fetchStartupDetailsById = async (startupId, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/detailsById",
      // "http://localhost:3000/startup/detailsById",
      { startupId },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response) {
      throw new Error("Network response was not ok");
    }
    const responseData = await response.data.data;
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const PostDetails = async (formDataInput, token, setUploading) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/onboarding",
      // "https://api.fundrev.ai/startup/onboarding",
      formDataInput,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
    }
    setUploading(false);
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    setUploading(false);
    return error;
  }
};

export const startupAddMember = async (emails, token) => {
  const formData = {
    members: emails.map((email) => ({ email })),
  };
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/addmember",
      // "http://localhost:3000/startup/addMember",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
    }
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Failed to send request", error);
    return { data: null, error: error.message };
  }
};
export const fetchMembers = async (startupId, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/getMembers",
      // "http://localhost:3000/startup/getMembers",
      { startupId },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};
export const memberSignUp = async (memberData) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/memberSignup",
      // "https://api.fundrev.ai/startup/memberSignup",
      memberData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const memberUpdate = async (memberData, token) => {
  try {
    const response = await axios.post(
      // "http://localhost:3000/startup/memberUpdate",
      "https://api.fundrev.ai/startup/memberUpdate",
      memberData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const deleteMember = async (id, startupId, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/startup/memberDelete/${id}`,
      // `http://localhost:3000/startup/memberDelete/${id}`,
      { startupId },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const startupMemberLogin = async (signInData) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/memberLogin",
      signInData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const fetchStartupMemberDetails = async (token) => {
  try {
    const response = await fetch(
      "https://api.fundrev.ai/startup/memberDetails",
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const fetchStartupMemberDetailsbyId = async (data) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/startup/memberDetailsId",
      data,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getAllStartups = async () => {
  try {
    const response = await fetch(
      "https://api.fundrev.ai/startup/getAllStartups",
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null }; // Returning data and null for error
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message }; // Returning null for data and the error message
  }
};

export const memberResendInvite = async (data) => {
  try {
    const response = await axios.post(
      // "http://localhost:3000/startup/memberResendInvite",
      "https://api.fundrev.ai/startup/memberResendInvite",
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealTeamDetails = async (startupID, token) => {
  try {
    const formData = {
      startupID: startupID,
    };
    const response = await axios.post(
      `https://api.fundrev.ai/startup/dealTeamDetails`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
