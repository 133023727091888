import React from "react";
import "../../CSS/Signup/leftSection.css";
import whiteLogo from "../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg"
import { Link } from "react-router-dom";
import Frame16 from "./Frame";

const LeftSection = (props) => {
  return (
    <>
      <div className="container">
        <div>
          <Link to="/">
            {" "}
            <img className="comapny-logo" src={whiteLogo} />
          </Link>
        </div>
        <Frame16 props={props}/>
      </div>

    </>
  );
};

export default LeftSection;
