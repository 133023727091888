import React, {useState,useEffect} from 'react'

import dropdown from  "../../../Assets/Images/InvestorPage/dropdown.svg"
import blueup from  "../../../Assets/Images/InvestorPage/up.svg"

import diligence1 from  "../../../Assets/Images/InvestorPage/diligence-1.svg"
import diligence1small from  "../../../Assets/Images/InvestorPage/diligence1small.svg"
import diligence2 from  "../../../Assets/Images/InvestorPage/diligence-2.svg"
import diligence2small from  "../../../Assets/Images/InvestorPage/diligence2small.svg"
import diligence3 from  "../../../Assets/Images/InvestorPage/diligence-3.svg"
import diligence3small from  "../../../Assets/Images/InvestorPage/diligence3small.svg"

const InvestorPageMid3 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const diligence1Url = windowWidth > 991 ? diligence1 : diligence1small
  const diligence2Url = windowWidth > 991 ? diligence2 : diligence2small
  const diligence3Url = windowWidth > 991 ? diligence3 : diligence3small
  const [activeDiv1, setActiveDiv1] = useState("div-4");
  const [activeParentDiv1, setActiveParentDiv1] = useState("div-121");
  const [activeImg1,setActiveImg1] = useState("img-10")

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (div,ParentDiv,img) =>{
    // Deactivate all divs
    setActiveImg1(img);
    const divs = document.querySelectorAll('.active-div-1');
    const Parentdivs = document.querySelectorAll('.active-parent-div-1');
    divs.forEach((el) => {
      el.classList.remove('active-div-1');
      el.classList.add('inactive-div-1');
    });
    Parentdivs.forEach((el) => {
      el.classList.remove('active-parent-div-1');
      el.classList.add('inactive-parent-div-1');
    });

    // Activate the clicked div
    setActiveDiv1(div);
    setActiveParentDiv1(ParentDiv)
    const element = document.getElementById(div);
    const Parent = document.getElementById(ParentDiv);
    element.classList.remove("inactive-div-1");
    Parent.classList.remove("inactive-parent-div-1");
    element.classList.add("active-div-1");
    Parent.classList.add("active-parent-div-1");
  }
  let imgSrc;
  if (activeDiv1 === "div-4") {
    imgSrc = diligence1Url;
  } else if (activeDiv1 === "div-5") {
    imgSrc = diligence2Url;
  } else if (activeDiv1 === "div-6") {
    imgSrc = diligence3Url;
  }
  return (
    <section className='InvestorPageMid3'>
    <div className="div-116">
      <div className="div-117">
        <div className="column-10">
        <div className="div-118">
            <div className="div-119">Due Diligence</div>
            <div className="div-120">
            All-in-One Diligence Platform
            </div>
            <div className="div-121 active-parent-div-1" id="div-121" onClick={()=>handleClick("div-4","div-121","img-10")}>
            <div className='div-122'>01.</div>
            <div className="div-123">
            Get the Data That Counts
            </div>
            <img
                loading="lazy"
                src={activeImg1==="img-10"?blueup:dropdown}
                className="img-10"
            />
            </div>
            <div className="div-124 active-div-1" id="div-4" >
              <p>Access a real-time dashboard showcasing standard industry metrics and prevent selective data presentation. Make strategic decisions with comprehensive insights.</p>
            
            <div className='sourcing-div mobile-img'>
              <img className="diligence-img" src={imgSrc}></img>
            </div>
            </div>
            <div className="div-125 inactive-parent-div-1" id="div-125" onClick={()=>handleClick("div-5","div-125","img-11")}>
            <div className='div-126'>02.</div>
            <div className="div-127">
            AI Co-Pilot: Sift through vast amounts of data in mere minutes
            </div>
            <img
                loading="lazy"
                src={activeImg1==="img-11"?blueup:dropdown}
                className="img-11"
            />
            </div>
            <div className="div-124 inactive-div-1" id="div-5">
            <p>Navigate complex data effortlessly with AI assistance. Ask questions, get answers, and access relevant files without sifting through mountains of information.</p>
            <div className='sourcing-div mobile-img'>
              <img className="diligence-img" src={imgSrc}></img>
            </div>
            </div>
            <div className="div-128 inactive-parent-div-1" id="div-128" onClick={()=>handleClick("div-6","div-128","img-12")}>
            <div className='div-129'>03.</div>
            <div className="div-130">
            Resolve open diligence items by engaging directly with the company
            </div>
            <img
                loading="lazy"
                src={activeImg1==="img-12"?blueup:dropdown}
                className="img-12"
            />
            </div>
            <div className="div-124 inactive-div-1" id="div-6">
            <p>Directly engage with startups, request specific data, and receive prompt responses—all in one convenient location!</p>
           
            <div className='sourcing-div mobile-img'>
              <img className="diligence-img" src={imgSrc}></img>
            </div>
            </div>
        </div>
        </div>
        <div className="column-11 laptop-img">
              <img className="diligence-img" src={imgSrc}></img>
        </div>
    </div>
    </div>
    </section>
  )
}

export default InvestorPageMid3