import React, { useEffect, useState } from "react";
import {
  formatDistanceToNow,
  parseISO,
  isWithinInterval,
  subDays,
} from "date-fns";
import {
  useZoomEffect,
  stages,
  calculateTotalAum,
} from "../../../Common/functions";
import onGoingDealsBg2 from "../../../../Assets/Images/InvestorPage/Dashboard/onGoingDealsBg2.svg";
import onGoingDealsBg3 from "../../../../Assets/Images/InvestorPage/Dashboard/onGoingDealsBg3.svg";
import { getInvestorsByDealID } from "../../../../endpoints/deal";
import { getDetailsById } from "../../../../endpoints/investor";
import OngoingInvestorCard from "../Components/ongoingInvestorCard";
import { useLoader } from "../../../Common/LoaderProvider";

const OngoingInvestors = ({ dealId }) => {
  const token = localStorage.getItem("token");
  const [investors, setInvestors] = useState([]);
  const [investorDetails, setinvestorDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState("currentDeals");
  const loader = useLoader();
  //
  const fetchInvestorsByDealId = async () => {
    try {
      const response = await getInvestorsByDealID(dealId, token);
      //
      setInvestors(response.data);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (dealId && dealId.length > 0) {
      fetchInvestorsByDealId();
    }
  }, [dealId]);

  const groupedDeals = stages.reduce((acc, stage) => {
    acc[stage] = investors
      ? investors.filter((investor) => investor.Stage === stage)
      : [];
    return acc;
  }, {});

  const getFormattedDate = (date) => {
    const createdAt = new Date(date);
    const tenDaysAgo = subDays(new Date(), 10);
    const isWithinLastTenDays = isWithinInterval(createdAt, {
      start: tenDaysAgo,
      end: new Date(),
    });

    if (isWithinLastTenDays) {
      return createdAt.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    } else {
      return formatDistanceToNow(parseISO(date), { addSuffix: true }).replace(
        " ago",
        ""
      );
    }
  };

  useEffect(() => {
    const fetchInvestorDetails = async () => {
      const details = {};
      for (const investor of investors) {
        try {
          const response = await getDetailsById(investor.investorID, token);
          details[investor.investorID] = response.data.data;
        } catch (error) {
          loader.stop();
        }
      }
      setinvestorDetails(details);
      filteredDeals();
      loader.stop();
    };

    if (investors ? investors.length > 0 : false) {
      fetchInvestorDetails();
    } else if (investors?.length === 0 && dealId) {
      loader.stop();
    }
  }, [investors]);

  //

  const renderDealCard = (investor, stage, index) => {
    const details = investorDetails[investor.investorID];
    if (!details) return null;
    const latestActivity = getFormattedDate(investor.createdAt);

    return (
      <OngoingInvestorCard
        index={index}
        key={investor.investorID}
        stage={stage}
        companyName={details.companyName}
        companyWebsite={details.investorWebsite}
        companyLogoLink={details.investorLogo}
        owner={investor.sentBy} //doubt
        location={details.headquarters}
        requestedDate={stage === "requestSent" ? latestActivity : undefined}
        acceptedDate={
          stage === "requestAccepted" ||
          stage === "ioiAccepted" ||
          stage === "loiAccepted"
            ? latestActivity
            : undefined
        }
        signingDate={stage === "ndaSigned" ? latestActivity : undefined}
        submittedDate={
          stage === "ioiSubmitted" || stage === "lioSubmitted"
            ? latestActivity
            : undefined
        }
        aum={calculateTotalAum(JSON.parse(details.investmentDetails[0]))}
        ticketSize={JSON.parse(details.ticketSizevalue[0])}
        investorType={details.investorType}
        closingDate={stage === "dealClosed" ? latestActivity : undefined}
        badges={JSON.parse(details.idealFocusOptions[0])}
        investor={details}
        isInterested={investor.IsInterested}
        isRejected={investor.IsRejected}
        dealId={dealId}
        fetchInvestorsByDealId={fetchInvestorsByDealId}
      />
    );
  };
  useZoomEffect();
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const filteredDeals = () => {
    switch (selectedTab) {
      case "allDeals":
        return investors;
      case "currentDeals":
        //

        return investors
          ? investors.filter(
              (investor) =>
                investor.IsInterested !== false && investor.IsRejected !== true
            )
          : "";
      case "deadDeals":
        return investors
          ? investors.filter(
              (investor) =>
                investor.IsInterested === false || investor.IsRejected === true
            )
          : "";
      default:
        return investors;
    }
  };

  return (
    <>
      <div className="onGoingDeals-tabs">
        <button
          className={selectedTab === "allDeals" ? "tabBtn active" : "tabBtn"}
          onClick={() => handleTabClick("allDeals")}
        >
          All
        </button>
        <button
          className={
            selectedTab === "currentDeals" ? "tabBtn active" : "tabBtn"
          }
          onClick={() => handleTabClick("currentDeals")}
        >
          Current Deals
        </button>
        <button
          className={selectedTab === "deadDeals" ? "tabBtn active" : "tabBtn"}
          onClick={() => handleTabClick("deadDeals")}
        >
          Dead Deals
        </button>
      </div>
      <div className="onGoingDeals-section">
        {stages.map((stage, index) => (
          <div key={index} className="onGoingDeals-col" id={stage}>
            <div className="onGoingDeals-types-div">
              <div className="onGoingDeals-types-div-2">
                <img
                  loading="lazy"
                  src={
                    stage === "dealClosed" ? onGoingDealsBg3 : onGoingDealsBg2
                  }
                  className="onGoingDeals-types-img"
                />
                <div className="onGoingDeals-types-div-3">
                  <div className="onGoingDeals-types-div-4">
                    {stage
                      .replace("requestSent", "requestReceived")
                      .replace("ioiSubmitted", "ioiReceived")
                      .replace("loiSubmitted", "loiReceived")
                      .replace(/([A-Z])/g, " $1")
                      .trim()}
                  </div>
                  <div className="onGoingDeals-types-div-5">
                    {/* {groupedDeals[stage].length} */}
                    {
                      groupedDeals[stage].filter((investor) =>
                        filteredDeals().includes(investor)
                      ).length
                    }
                  </div>
                </div>
              </div>
              {/* // .filter(deal => filteredDeals().includes(deal)) */}
              {/* {groupedDeals[stage].map((investor, index) =>
                renderDealCard(investor, stage, index)
              )} */}

              {groupedDeals[stage]
                .filter((investor) => filteredDeals().includes(investor))
                .map((investor, index) =>
                  renderDealCard(investor, stage, index)
                )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OngoingInvestors;
