import React, { useState } from "react";
import ReactGA from "react-ga4";
import Header from "../../LandingPage/header";
import Toast from "../../Common/Toast";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
import "../../../CSS/Signup/investorRegister.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Checkbox, FormControl, FormLabel, Input } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import Select from "react-select";
import PasswordValidator from "../../Common/passwordValidator.jsx";
import { investorSignup } from "../../../endpoints/investor";
import { useLoader } from "../../Common/LoaderProvider.jsx";

const InvestorRegisterBlock = (props) => {
  const navigate = useNavigate();
  const loader = useLoader();
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [investorType, setInvestorType] = useState("");
  const InvestorTypeOptions = [
    { value: "Private Equity", label: "Private Equity" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Family Office", label: "Family Office" },
    { value: "Corporate", label: "Corporate" },
    { value: "HNI/Angel Investor", label: "HNI/Angel Investor" },
    { value: "Others", label: "Others" },
  ];

  const [investorFormData, setInvestorFormData] = useState({
    companyName: "",
    name: "",
    email: "",
    investorTypeSpecify: "",
    password: "",
    confirmPassword: "",
    investorPhoneNumber: "",
  });
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };

  const handleInvestorTypeChange = (selectedOption) => {
    //
    setInvestorType(selectedOption.value);
    if (selectedOption.value === "Others") {
      document.querySelector(".investor-type-input").style.height =
        "fit-content";
    } else {
      if (window.innerWidth < 479) {
        document.querySelector(".investor-type-input").style.height = "75px";
      } else {
        document.querySelector(".investor-type-input").style.height = "85px";
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvestorFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const updatePassword = (newPassword) => {
    setInvestorFormData((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  };
  const handlePhoneChange = (value) => {
    setInvestorFormData((prevState) => ({
      ...prevState,
      investorPhoneNumber: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const finalFormData = {
      ...investorFormData,
      phoneNumber: investorFormData.investorPhoneNumber,
      investorType:
        investorType === "Others"
          ? investorFormData.investorTypeSpecify
          : investorType,
    };

    delete finalFormData.investorTypeSpecify;
    if (!Object.values(finalFormData).every((value) => value.trim())) {
      Toast("Please fill in all fields", "warning", "investorRegister");
      return;
    }

    if (!matchIsValidTel(finalFormData.investorPhoneNumber)) {
      Toast("Please enter a valid phone number", "warning", "investorRegister");
      return;
    }
    if (
      allStates.lowerValidated === false ||
      allStates.upperValidated === false ||
      allStates.numberValidated === false ||
      allStates.specialValidated === false ||
      allStates.lengthValidated === false
    ) {
      Toast(
        "Password does not meet the requirements",
        "warning",
        "investorRegister"
      );
      return;
    }
    if (finalFormData.password !== finalFormData.confirmPassword) {
      Toast("Passwords do not match", "error", "investorRegister");
      return;
    } else {
      delete finalFormData.confirmPassword;
    }

    let responseData;
    try {
      loader.start("Signing up...");
      responseData = await investorSignup(finalFormData);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (props.startPoint === "noNameDeals") {
      ReactGA.event({
        category: "Sign Up",
        action: "button_click",
        label: "Investor Sign Up Button From No Name Deals",
      });
    }
    const response = responseData?.data;
    if (
      response &&
      response?.data?.message === "Please verify your email with the code sent."
    ) {
      navigate("/emailVerification", {
        state: {
          startPoint: props.startPoint,
          investorEmail: finalFormData.email,
          investorName: finalFormData.name,
          companyName: finalFormData.companyName,
          investorToken: response.data.verificationToken,
          investorPhoneNumber: finalFormData.investorPhoneNumber,
          type: finalFormData.investorType,
          role: "investor",
          investorPassword: finalFormData.password, //sending password to email verification page as the user can change the email again
        },
      });
    } else if (responseData.error.response.status === 409) {
      Toast(
        "Alerady Registered with this email. Please sign in.",
        "info",
        "investorRegister"
      );
      setTimeout(() => {
        navigate("/signIn", {
          state: { sentemail: finalFormData.email, type: "Investor" },
        });
      }, 1000);
    } else {
      Toast(
        "Something went wrong. Please try again",
        "error",
        "investorRegister"
      );
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "64px",
      minWidth: "100%",
      fontSize: "14px",
      background: "none",
      border: "1px solid rgba(134, 146, 166, 1)",
      borderRadius: "6px",
      padding: "15px",
      fontFamily: '"Karla", sans-serif',
      minHeight: "64px",
      alignItems: "base-line",
    }),
  };
  return (
    <div className="investorRegisterWithSignUpText">
      <div className="signInWithHeader">
        <Header color="black" type="investor" />
        <div className="investorRegister">
          <div className="section">
            <div className="goBack" onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
              <Typography className="back-text">Back</Typography>
            </div>
            <div className="title">
              <Typography variant="h6" className="heading">
                Register Investor Account!
              </Typography>
              <Typography variant="subtitle1" color="grey" className="content">
                Make data-driven investments efficiently on Fundrev's platform.
              </Typography>
            </div>
            <div className="form">
              <div className="form-section Register-form-section">
                <FormControl
                  className="form-control"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Investment Firm Name</FormLabel>
                  <Input
                    className="input"
                    placeholder="Enter investment firm name"
                    color="neutral"
                    name="companyName"
                    value={investorFormData.companyName}
                    // value="hjhf"
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  className="form-control"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Full Name</FormLabel>
                  <Input
                    className="input"
                    placeholder="Enter Name"
                    color="neutral"
                    name="name"
                    value={investorFormData.name}
                    // value="hjhf"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
              <div className="form-section Register-form-section">
                <FormControl
                  className="form-control investor-type-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Investor Type</FormLabel>

                  <Select
                    // className="input"
                    options={InvestorTypeOptions}
                    name="investorType"
                    value={InvestorTypeOptions.find(
                      (option) => option.value === investorType
                    )}
                    onChange={handleInvestorTypeChange}
                    placeholder="Please Select"
                    required
                    styles={customStyles}
                  />
                  <br />
                  {investorType === "Others" && (
                    <Input
                      className="input"
                      name="investorTypeSpecify"
                      placeholder="Please Specify"
                      color="regular"
                      onChange={handleChange}
                    />
                  )}
                </FormControl>
                <FormControl
                  className="form-control"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Phone Number</FormLabel>

                  <MuiTelInput
                    defaultCountry="IN"
                    className="input"
                    value={investorFormData.investorPhoneNumber}
                    onChange={handlePhoneChange}
                  />
                </FormControl>
              </div>
              <div className="form-section Register-form-section">
                <FormControl
                  className="form-control"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">
                    Official Email Address
                  </FormLabel>
                  <Input
                    className="input"
                    placeholder="Enter work email address"
                    color="neutral"
                    type="email"
                    name="email"
                    value={investorFormData.email}
                    onChange={handleChange}
                  />
                </FormControl>
                <PasswordValidator
                  updatePassword={updatePassword}
                  sendStatesToParent={receiveStatesFromChild}
                />
              </div>
              <div className="form-section Register-form-section">
                <FormControl
                  className="form-control"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Confirm Password</FormLabel>
                  <Input
                    endDecorator={
                      !showConfirmPassword ? (
                        <VisibilityOffIcon
                          onClick={() => setshowConfirmPassword(true)}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setshowConfirmPassword(false)}
                        />
                      )
                    }
                    className="input"
                    placeholder="Re-enter password"
                    color="neutral"
                    type={showConfirmPassword ? "text" : "password"}
                    value={investorFormData.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    // onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="form-section Register-form-section">
                <Button className="button" fullWidth onClick={handleSubmit}>
                  Save & Continue
                </Button>
              </div>
            </div>
            <Typography className="text">
              <div className="textContent">
                Already have an account?{" "}
                <Link
                  to={
                    props.startPoint === "noNameDeals"
                      ? "/investor/deal/signIn"
                      : "/signIn"
                  }
                  state={{ startPoint: props.startPoint }}
                  style={{ color: "rgba(0, 166, 251, 1)" }}
                >
                  Sign In
                </Link>
              </div>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorRegisterBlock;
