import tick from "../../../Assets/Images/signup/tick.svg";


const StepProgress = ({ currentStep, stepsCompleted }) => {
  return (
      <div className="div">
        <div className="div-2">Complete Your Profile</div>
        
        {/* Step 1 */}
        <div className="div-10">
          <div className={`div-11 ${(stepsCompleted.step1 || currentStep === 1) && "active-step"}`}>
            {
              stepsCompleted.step1 ? <img src={tick} alt="Tick" /> : "1"
            }
          </div>
          <div className="div-30">
            <div className="div-12">Step 1</div>
            <div className={currentStep === 1 ? "div-7":"div-13"}>Your Details</div>
            <div className={currentStep === 1 ? (stepsCompleted.step1 ? "div-9 complete" : "div-9") : (stepsCompleted.step1 ? "div-9 complete" : "div-14")}>
              {currentStep === 1 ? (stepsCompleted.step1 ? "Complete" : "In Progress") : (stepsCompleted.step1 ? "Complete" : "Pending")}
            </div>
          </div>
        </div>

        {/* Step 2 */}
        <div className="div-10">
          <div className={`div-31 ${(stepsCompleted.step2 || currentStep === 2)&& "active-step"}`}>
            {
              stepsCompleted.step2 ? <img src={tick} alt="Tick" /> : "2"
            }
          </div>
          <div className="div-30">
            <div className="div-12">Step 2</div>
            <div className={currentStep === 2 ? "div-7":"div-13"}>Accept T&C</div>
            <div className={currentStep === 2 ? (stepsCompleted.step2 ? "div-9 complete" : "div-9 in-progress") : (stepsCompleted.step2 ? "div-9 complete" : "div-14")}>
              {currentStep === 2 ? (stepsCompleted.step2 ? "Complete" : "In Progress") : (stepsCompleted.step2 ? "Complete" : "Pending")}
            </div>
          </div>
        </div>

      </div>
  );
};

export default StepProgress;
