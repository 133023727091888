import React, { useState } from "react";
import NoDeal from "./NoDeal";
import Card from "../../../Common/components/Card";
import { useZoomEffect } from "../../../Common/functions";

import { Helmet } from "react-helmet";

import "../../../../CSS/InvestorPage/Dashboard/otherDeals.css";
import emptyCard from "../../../../Assets/Images/InvestorPage/Dashboard/emptyCard.svg";
import {
  ArrowBack,
  ArrowForward,
  ArrowForwardIos,
  KeyboardArrowRight,
} from "@mui/icons-material";

const OtherDeals = (props) => {
  const [currentIndexModerate, setCurrentIndexModerate] = useState(0);
  const [currentIndexNonFit, setCurrentIndexNonFit] = useState(0);
  const cardsToShow = 3;

  const nextSlideModerate = () => {
    if (currentIndexModerate < props.moderateFit.length - cardsToShow) {
      setCurrentIndexModerate(currentIndexModerate + 1);
    }
  };

  const prevSlideModerate = () => {
    if (currentIndexModerate > 0) {
      setCurrentIndexModerate(currentIndexModerate - 1);
    }
  };

  const nextSlideNonFit = () => {
    if (currentIndexNonFit < props.nonFit.length - cardsToShow) {
      setCurrentIndexNonFit(currentIndexNonFit + 1);
    }
  };

  const prevSlideNonFit = () => {
    if (currentIndexNonFit > 0) {
      setCurrentIndexNonFit(currentIndexNonFit - 1);
    }
  };

  const translateXValueModerate = -(currentIndexModerate * (100 / cardsToShow));
  const translateXValueNonFit = -(currentIndexNonFit * (100 / cardsToShow));
  useZoomEffect();
  return (
    <div className="other-deal">
      <Helmet>
        <title>Investor | Other Deals</title>
      </Helmet>
      <div className="deal-header">
        <div className="fit-section">
          <div className="fit-title">Moderate Fit</div>
          <KeyboardArrowRight className="fit-image" />
        </div>
        <div className="image-gallery">
          <div className="arrow-container" onClick={prevSlideModerate}>
            <ArrowBack
              className="arrow-icon"
              style={{
                color: currentIndexModerate === 0 ? "#8692A6" : "#000000",
              }}
            />
          </div>
          <div className="arrow-container">
            <ArrowForward
              className="arrow-icon"
              style={{
                color:
                  currentIndexModerate >= props.moderateFit.length - cardsToShow
                    ? "#8692A6"
                    : "#000000",
              }}
              onClick={nextSlideModerate}
            />
          </div>
        </div>
      </div>
      <div className="deals-container">
        {props.moderateFit.length > 0 ? (
          <div className="deal-carousel">
            <div
              className="carousel-inner"
              style={{ transform: `translateX(${translateXValueModerate}%)` }}
            >
              {props.moderateFit.map((deal) => (
                <div className="carousel-slide" key={deal.OrganizationID}>
                  <Card
                    isNew={false}
                    cardTitle={deal.Title}
                    cardDescription={deal.SubHeading}
                    cardTags={[
                      ...(() => {
                        try {
                          const roundType = JSON.parse(deal.RoundType);
                          return Array.isArray(roundType) ? roundType : [];
                        } catch (error) {
                          console.error("Error parsing Round Type:", error);
                          return []; // Fallback value
                        }
                      })(),
                      ...(() => {
                        try {
                          const industryVerticals = JSON.parse(
                            deal.IndustryVerticals
                          );
                          return Array.isArray(industryVerticals)
                            ? industryVerticals
                            : [];
                        } catch (error) {
                          console.error(
                            "Error parsing IndustryVerticals:",
                            error
                          );
                          return []; // Fallback value
                        }
                      })(),
                      ...(() => {
                        try {
                          const endMarketFocus = JSON.parse(
                            deal.EndMarketFocus
                          );
                          return Array.isArray(endMarketFocus)
                            ? endMarketFocus
                            : ["_"];
                        } catch (error) {
                          console.error("Error parsing EndMarketFocus:", error);
                          return ["_"]; // Fallback value
                        }
                      })(),
                    ]}
                    revenue={deal.Revenue || 0}
                    grossProfit={deal.GrossProfit}
                    EBITDA={deal.EBITDA}
                    EBITDA_Percentage={deal.EBITDA_Percentage}
                    IOI_Bids={deal.IOIBids}
                    numInvestors={deal.InvestorsNum}
                    fundingRequired={deal.FundingRequired || 0}
                    totalFundingRaised={deal.TotalFundingRaised}
                    dealId={deal.OrganizationID}
                    investorId={props.details?.investorID}
                    isStar={
                      props.details?.wishlistdeal?.includes(
                        deal.OrganizationID
                      ) || false
                    }
                    deal={deal}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", gap: "45px", flex: "auto" }}>
            <img className="emptyCard" src={emptyCard} />
            <img className="emptyCard" src={emptyCard} />
            <img className="emptyCard" src={emptyCard} />
          </div>
        )}
      </div>
      <div className="deal-header">
        <div className="fit-section">
          <div className="fit-title">Non Fit</div>
          <KeyboardArrowRight className="fit-image" />
        </div>
        <div className="image-gallery">
          <div className="arrow-container" onClick={prevSlideNonFit}>
            <ArrowBack
              className="arrow-icon"
              style={{
                color: currentIndexNonFit === 0 ? "#8692A6" : "#000000",
              }}
            />
          </div>
          <div className="arrow-container">
            <ArrowForward
              className="arrow-icon"
              style={{
                color:
                  currentIndexNonFit >= props.nonFit.length - cardsToShow
                    ? "#8692A6"
                    : "#000000",
              }}
              onClick={nextSlideNonFit}
            />
          </div>
        </div>
      </div>
      <div className="deals-container">
        {props.nonFit.length > 0 ? (
          <div className="deal-carousel">
            <div
              className="carousel-inner"
              style={{ transform: `translateX(${translateXValueNonFit}%)` }}
            >
              {props.nonFit.map((deal) => (
                <div className="carousel-slide" key={deal.OrganizationID}>
                  <Card
                    isNew={false}
                    cardTitle={deal.Title}
                    cardDescription={deal.SubHeading}
                    cardTags={[
                      ...(() => {
                        try {
                          const roundType = JSON.parse(deal.RoundType);
                          return Array.isArray(roundType) ? roundType : [];
                        } catch (error) {
                          console.error("Error parsing Round Type:", error);
                          return []; // Fallback value
                        }
                      })(),
                      ...(() => {
                        try {
                          const industryVerticals = JSON.parse(
                            deal.IndustryVerticals
                          );
                          return Array.isArray(industryVerticals)
                            ? industryVerticals
                            : [];
                        } catch (error) {
                          console.error(
                            "Error parsing IndustryVerticals:",
                            error
                          );
                          return []; // Fallback value
                        }
                      })(),
                      ...(() => {
                        try {
                          const endMarketFocus = JSON.parse(
                            deal.EndMarketFocus
                          );
                          return Array.isArray(endMarketFocus)
                            ? endMarketFocus
                            : ["_"];
                        } catch (error) {
                          console.error("Error parsing EndMarketFocus:", error);
                          return ["_"]; // Fallback value
                        }
                      })(),
                    ]}
                    revenue={deal.Revenue || 0}
                    grossProfit={deal.GrossProfit}
                    EBITDA={deal.EBITDA}
                    EBITDA_Percentage={deal.EBITDA_Percentage}
                    IOI_Bids={deal.IOIBids}
                    numInvestors={deal.InvestorsNum}
                    fundingRequired={deal.FundingRequired || 0}
                    totalFundingRaised={deal.TotalFundingRaised}
                    dealId={deal.OrganizationID}
                    investorId={props.details?.investorID}
                    isStar={
                      props.details?.wishlistdeal?.includes(
                        deal.OrganizationID
                      ) || false
                    }
                    deal={deal}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", gap: "45px", flex: "auto" }}>
            <img className="emptyCard" src={emptyCard} />
            <img className="emptyCard" src={emptyCard} />
            <img className="emptyCard" src={emptyCard} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherDeals;
