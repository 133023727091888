import React, { useEffect, useState, useRef } from "react";
import { searchUnifiedInvestors } from "../../../endpoints/admin";
import { TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const UnifiedInvestor = () => {
  const [investors, setInvestors] = useState(null);
  const [query, setQuery] = useState("");
  const debounceTimeout = useRef(null); // UseRef to store the timeout
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch investors on mount
    searchUnifiedInvestor();
  }, []);

  // Search function for fetching investors
  const searchUnifiedInvestor = async (query) => {
    try {
      const response = await searchUnifiedInvestors(query);
      setInvestors(response.data);
    } catch (error) {
      console.error("Error searching unified investors:", error);
    }
  };

  // Custom debounce function
  const debounceSearch = (query) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    // Set a new timeout
    debounceTimeout.current = setTimeout(() => {
      searchUnifiedInvestor(query); // Call the API after the delay
    }, 300); // Debounce delay of 300ms
  };

  const handleSearch = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    debounceSearch(newQuery); // Call the debounce function
  };

  return (
    <>
      <div className="admin-panel-investors">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Search />
          <TextField
            size="small"
            label="Search Investor"
            value={query}
            onChange={handleSearch} // Call the debounce search
          />
        </div>
        <div className="InvestorSide-box table-container">
          <table>
            <thead>
              <tr>
                <th>UID</th>
                <th>Investor Firm Name</th>
                <th>Onboarding ID</th>
                <th>Preview</th>
              </tr>
            </thead>
            <tbody>
              {investors?.map((investor, index) => (
                <tr key={index}>
                  <td>{investor.uid}</td>
                  <td>{investor.investor_name}</td>
                  <td>{investor.onboarded_investor_id || `Not Onboarded`}</td>
                  <td>
                    <button
                      style={{
                        color: "blue",
                      }}
                      onClick={() =>
                        navigate(
                          `/admin/unifiedInvestorPreview?${investor.investor_name}`,
                          {
                            state: {
                              investor,
                            },
                          }
                        )
                      }
                    >
                      Preview
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UnifiedInvestor;
