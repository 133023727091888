import React, { useEffect, useState } from "react";
import Sidebar from "./Components/sidebar";
import Topbar from "./Components/topbar";
import OnGoingDealCard from "./Components/onGoingDealCard";
import { getDealsByInvestorID, getDealByStage } from "../../../endpoints/deal";
import { fetchInvestorMemberDetails } from "../../../endpoints/investor";
import { useZoomEffect, stages } from "../../Common/functions";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";

import onGoingDealsBg2 from "../../../Assets/Images/InvestorPage/Dashboard/onGoingDealsBg2.svg";
import onGoingDealsBg3 from "../../../Assets/Images/InvestorPage/Dashboard/onGoingDealsBg3.svg";
import staytuned from "../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";

import "../../../CSS/InvestorPage/Dashboard/onGoingDeals.css";

import {
  formatDistanceToNow,
  parseISO,
  isWithinInterval,
  subDays,
} from "date-fns";
import { useLoader } from "../../Common/LoaderProvider";

const OngoingDeals = () => {
  const loader = useLoader();
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [investorId, setInvestorId] = useState("");
  const [deals, setDeals] = useState([]);
  const [dealDetails, setDealDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState("currentDeals");
  // const [filteredDeals, setFilteredDeals] = useState([]);

  const fetchDetails = async () => {
    const token = localStorage.getItem("token");
    let response;
    if (token) {
      response = await fetchInvestorMemberDetails(token);
    }
    if (token) {
      if (response && response.data !== null) {
        setName(response.data.data.name);
        setInvestorId(response.data.data.investorID);
      } else {
        loader.stop();
      }
    }
  };
  useEffect(() => {
    loader.start("Fetching deals...");
    fetchDetails();
  }, [token]);

  useEffect(() => {
    const fetchDeals = async () => {
      if (investorId) {
        try {
          loader.start("Fetching deals...");
          const response = await getDealsByInvestorID(investorId, token);
          setDeals(response.data);
        } catch (error) {
          loader.stop();
        }
      }
    };

    fetchDeals();
  }, [investorId]);

  useEffect(() => {
    const fetchDealDetails = async () => {
      const details = {};
      if (token) {
        for (const deal of deals) {
          try {
            const response = await getDealByStage(deal.DealID, token);
            details[deal.DealID] = response.data;
          } catch (error) {}
        }
        setDealDetails(details);
        filteredDeals();
      }
      setDealDetails(details);
      filteredDeals();
      loader.stop();
    };
    if (deals) {
      fetchDealDetails();
    } else if (deals.length === 0 && investorId) {
      loader.stop();
    }
  }, [deals, token]);

  useZoomEffect();

  const groupedDeals = stages.reduce((acc, stage) => {
    acc[stage] = deals ? deals.filter((deal) => deal.Stage === stage) : [];
    return acc;
  }, {});

  const getFormattedDate = (date) => {
    const createdAt = new Date(date);
    const tenDaysAgo = subDays(new Date(), 10);
    const isWithinLastTenDays = isWithinInterval(createdAt, {
      start: tenDaysAgo,
      end: new Date(),
    });

    if (isWithinLastTenDays) {
      return createdAt.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    } else {
      return formatDistanceToNow(parseISO(date), { addSuffix: true });
    }
  };

  const renderDealCard = (deal, stage, index) => {
    const details = dealDetails[deal.DealID];
    if (!details) return null;
    // const latestActivity = getFormattedDate(deal.createdAt);
    const latestActivity = moment(deal.createdAt)
      .tz("Asia/Kolkata")
      .format("D MMM YYYY");
    let EndMarketFocus;

    try {
      EndMarketFocus = JSON.parse(details.EndMarketFocus);
    } catch (error) {
      EndMarketFocus = details.EndMarketFocus;
    }
    return (
      <OnGoingDealCard
        index={index}
        FoundedYear={deal.FoundedYear}
        key={deal.DealID}
        stage={stage}
        companyInfo={details.Title}
        companySubHeading={details.SubHeading}
        companyName={details.CompanyName}
        companyWebsite={details.CompanyWebsite}
        companyLogoLink={details.CompanyLogo}
        amountBeingRaised={details.TotalFundingRaised}
        fundingRequired={details.FundingRequired}
        owner={details.OwnerName}
        location={details.Headquarters}
        requestedDate={stage === "requestSent" ? latestActivity : undefined}
        submittedDate={
          stage === "ioiSubmitted" || stage === "loiSubmitted"
            ? latestActivity
            : undefined
        }
        acceptedDate={
          stage === "requestAccepted" ||
          stage === "ioiAccepted" ||
          stage === "loiAccepted"
            ? latestActivity
            : undefined
        }
        signingDate={stage === "ndaSigned" ? latestActivity : undefined}
        IOIBidSubmittedAmount={
          stage === "ioiSubmitted"
            ? deal.IOI_Bid_Details.investmentAmount
            : undefined
        }
        IOIBidAcceptedAmount={
          stage === "ioiAccepted"
            ? deal.IOI_Bid_Details.investmentAmount
            : undefined
        }
        LOIBidSubmittedAmount={
          stage === "loiSubmitted"
            ? deal.LOI_Agreement_Details.investmentAmount
            : undefined
        }
        LOIBidAcceptedAmount={
          stage === "loiAccepted"
            ? deal.LOI_Agreement_Details.investmentAmount
            : undefined
        }
        closingDate={stage === "dealClosed" ? latestActivity : undefined}
        badges={[
          ...(() => {
            try {
              const roundType = JSON.parse(details.RoundType);
              return Array.isArray(roundType) ? roundType : [];
            } catch (error) {
              console.error("Error parsing Round Type:", error);
              return []; // Fallback value
            }
          })(),
          ...(() => {
            try {
              const endMarketFocus = JSON.parse(details.EndMarketFocus);
              return Array.isArray(endMarketFocus) ? endMarketFocus : ["_"];
            } catch (error) {
              console.error("Error parsing EndMarketFocus:", error);
              return ["_"]; // Fallback value
            }
          })(),
          ...(() => {
            try {
              const endMarketFocus = JSON.parse(details.IndustryVerticals);
              return Array.isArray(endMarketFocus) ? endMarketFocus : ["_"];
            } catch (error) {
              console.error("Error parsing EndMarketFocus:", error);
              return ["_"]; // Fallback value
            }
          })(),
        ]}
        deal={details}
        isInterested={deal.IsInterested}
        isRejected={deal.IsRejected}
      />
    );
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const filteredDeals = () => {
    switch (selectedTab) {
      case "allDeals":
        return deals;
      case "currentDeals":
        return deals ? deals.filter((deal) => deal.IsInterested === true) : "";
      case "deadDeals":
        return deals
          ? deals.filter(
              (deal) => deal.IsInterested === false || deal.IsRejected === true
            )
          : "";
      default:
        return deals;
    }
  };

  return (
    <div className="InvestorSide onGoingDeals-section">
      <Helmet>
        <title>Investor | Ongoing Deals</title>
      </Helmet>
      <Sidebar active="ongoingDeals" />
      <Topbar title="Ongoing Deals" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="InvestorSide-content-box">
            <div className="InvestorSide-content-text">
              <p className="InvestorSide-content-text-1">Hi {name}</p>
              <p className="InvestorSide-content-text-2">
                Here's an overview of your advancements.
              </p>
              <div className="onGoingDeals-tabs">
                <button
                  className={
                    selectedTab === "allDeals" ? "tabBtn active" : "tabBtn"
                  }
                  onClick={() => handleTabClick("allDeals")}
                >
                  All
                </button>
                <button
                  className={
                    selectedTab === "currentDeals" ? "tabBtn active" : "tabBtn"
                  }
                  onClick={() => handleTabClick("currentDeals")}
                >
                  Current Deals
                </button>
                <button
                  className={
                    selectedTab === "deadDeals" ? "tabBtn active" : "tabBtn"
                  }
                  onClick={() => handleTabClick("deadDeals")}
                >
                  Dead Deals
                </button>
              </div>
              <div className="onGoingDeals-section">
                {stages.map((stage, index) => (
                  <div key={index} className="onGoingDeals-col" id={stage}>
                    <div className="onGoingDeals-types-div">
                      <div className="onGoingDeals-types-div-2">
                        <img
                          loading="lazy"
                          src={
                            stage === "dealClosed"
                              ? onGoingDealsBg3
                              : onGoingDealsBg2
                          }
                          className="onGoingDeals-types-img"
                        />
                        <div className="onGoingDeals-types-div-3">
                          <div className="onGoingDeals-types-div-4">
                            {stage.replace(/([A-Z])/g, " $1").trim()}
                          </div>
                          <div className="onGoingDeals-types-div-5">
                            {
                              groupedDeals[stage].filter((deal) =>
                                filteredDeals().includes(deal)
                              ).length
                            }
                          </div>
                        </div>
                      </div>
                      {groupedDeals[stage]
                        .filter((deal) => filteredDeals().includes(deal))
                        .map((deal, index) =>
                          renderDealCard(deal, stage, index)
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="InvestorSide">
    //   <Helmet>
    //     <title>Investor | Ongoing Deals</title>
    //   </Helmet>
    //   <Sidebar active="ongoingDeals" />
    //   <Topbar title="Ongoing Deals" />
    //   <div className="InvestorSide-box">
    //     <div className="InvestorSide-content">
    //       <p className="stay-tuned-text">
    //         Stay Tuned <span className="blue-text">:)</span>
    //       </p>
    //       <p className="under-construnction-text">
    //         We are under construction...
    //       </p>
    //       <div className="img-box">
    //         <img src={staytuned}></img>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default OngoingDeals;
