// withMobileRedirect.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useDeviceWidth = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

const withMobileRedirect = (Component, redirectTo) => {
  return (props) => {
    const Navigate = useNavigate();
    const isMobile = useDeviceWidth();

    useEffect(() => {
      if (isMobile) {
        Navigate(redirectTo);
      }
    }, [isMobile, Navigate, redirectTo]);

    if (isMobile) {
      return null;
    }

    return <Component {...props} />;
  };
};

export default withMobileRedirect;
