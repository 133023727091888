import React, { useState } from "react";
import ReactGA from "react-ga4";
import NoNamePreview from "../../Common/components/NoNamePreview";
import Header from "../../Common/components/header";
import { showInterestInDeal } from "../../../endpoints/deal";

import CompleteOnBoardingModal from "../../Common/modals/completeOnBoardingModal";
import { useLocation, useNavigate } from "react-router-dom";
import Next from "../../../Assets/Images/signup/next.svg";
const NoNameDeal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openCompleteOnBoardingModal, setOpenCompleteOnBoardingModal] =
    useState();
  const token = localStorage.getItem("token");
  const { deal, cardTags } = location.state || {};
  const [data, setData] = useState();
  const navigateToInvestorOnboarding = () => {
    navigate("/investorDetails", {
      state: {
        investorEmail: data.email,
        investorName: data.name,
        tkn: token,
      },
    });
  };
  const showInterest = async () => {
    ReactGA.event({
      category: "navigation",
      action: "button_click",
      label: "Show Interest Button in No Name Deal",
    });
    try {
      const response = await showInterestInDeal(deal.DealID, token);
      if (response.response.status === 200) {
        if (
          response.response.data.data.onBoardingComplete &&
          response.response.data.data.isApplicationReviewed
        ) {
          navigate("/investor/opportunities", {
            state: {
              token: token,
            },
          });
        } else {
          setData(response.response.data.data);
          setOpenCompleteOnBoardingModal(true);
        }
      }
    } catch (error) {}
  };
  return (
    <section className="noNameDeal company-profile-preview investorDetailsPage">
      <CompleteOnBoardingModal
        openCompleteOnBoardingModal={openCompleteOnBoardingModal}
        setOpenCompleteOnBoardingModal={setOpenCompleteOnBoardingModal}
        navigateToInvestorOnboarding={navigateToInvestorOnboarding}
      />
      <div className="InvestorSide">
        <Header color="black" token="Startuptoken" type="Startup" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div" style={{ marginBottom: "100px" }}>
              <NoNamePreview
                deal={deal}
                cardTags={cardTags}
                stage={"companyOnBoarding"}
              />
              <div className="showInterestButtons-div">
                <div
                  className="showInterestButtons-div-3"
                  onClick={() => showInterest()}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Show Interest&nbsp; <img src={Next} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoNameDeal;
