import React, { useEffect, useState } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Card from "../../../Common/components/Card";
import {
  fetchInvestorDetails,
  getWishlist,
} from "../../../../endpoints/investor";
import { useZoomEffect } from "../../../Common/functions";

import { Helmet } from "react-helmet";

import NoDeal from "./NoDeal";
import { ToastContainer } from "react-toastify";
import { getNoNameDeals } from "../../../../endpoints/deal";
import { useLoader } from "../../../Common/LoaderProvider";

const Wishlist = () => {
  const [wishlistDeals, setWishlistDeals] = useState([]);
  const [dealsId, setDealsId] = useState([]);
  const [details, setDetails] = useState(null);
  const loader = useLoader();
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await fetchInvestorDetails(token);
        if (response && response.data !== null) {
          setDetails(response.data.data);
        } else {
          loader.stop();
        }
      } catch (error) {
        loader.stop();
      }
    };
    fetchDetails();
  }, []);

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await getWishlist(details.investorID, token);
        if (response) {
          if (response.data) setDealsId(response.data);
        }
      } catch (error) {
        loader.stop();
      }
    };
    loader.start("Getting your wishlist...");
    fetchWishlist();
  }, [details]);

  useEffect(() => {
    const fetchAllStartups = async () => {
      if (dealsId && dealsId.length > 0) {
        try {
          const responses = await getNoNameDeals(details.investorID, token);
          const responseData = responses.data;

          // Filter deals based on dealsId array
          loader.stop();
          const validResponses = responseData.filter((response) =>
            dealsId.includes(response.OrganizationID)
          );

          setWishlistDeals(validResponses);
        } catch (error) {
          loader.stop();
        }
      } else if (dealsId && dealsId.length === 0) {
        loader.stop();
      }
    };

    fetchAllStartups();
  }, [dealsId]);

  useZoomEffect();
  return (
    <>
      <div className="InvestorSide">
        <Helmet>
          <title>Investor | Wishlist</title>
        </Helmet>
        <Sidebar active="wishlist" />
        <Topbar title="Wishlist" />
        <ToastContainer position="top-center" containerId="wishlist" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="InvestorSide-content-box">
              <div className="opportunites-display">
                {wishlistDeals && wishlistDeals.length > 0 ? (
                  wishlistDeals.map((startup) => (
                    <Card
                      key={startup.OrganizationID}
                      isNew={false}
                      cardTitle={startup.Title}
                      cardDescription={startup.SubHeading}
                      cardTags={[
                        startup.RoundType || "-",
                        startup.Industry || "-",
                        ...(Array.isArray(JSON.parse(startup.EndMarketFocus))
                          ? JSON.parse(startup.EndMarketFocus)
                          : ["_"]),
                        ...(Array.isArray(JSON.parse(startup.IndustryVerticals))
                          ? JSON.parse(startup.IndustryVerticals)
                          : []),
                      ]}
                      revenue={startup.Revenue ? startup.Revenue : 0}
                      grossProfit={startup.GrossProfit}
                      EBITDA={startup.EBITDA}
                      EBITDA_Percentage={startup.EBITDA_Percentage}
                      IOI_Bids={startup.IOIBids}
                      numInvestors={startup.InvestorsNum}
                      fundingRequired={
                        startup.FundingRequired ? startup.FundingRequired : 0
                      }
                      totalFundingRaised={startup.TotalFundingRaised}
                      startupId={startup.OrganizationID}
                      investorId={details?.investorID}
                      isStar={
                        details?.wishlistStartup?.includes(
                          startup.OrganizationID
                        )
                          ? true
                          : false
                      }
                      deal={startup}
                    />
                  ))
                ) : (
                  <>
                    <p className="oppo-heading">
                      No wish-listed opportunities available currently...{" "}
                    </p>
                    <NoDeal />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wishlist;
