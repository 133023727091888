import $ from "jquery";
$(function () {
  const urlPath = window.location.href;
  const regex = /\/([^/]*)$/;
  const match = urlPath.match(regex);
  var type;
  const arr = [
    "deals",
    "companyDetails",
    "investorDetails",
    "companyDetails",
    "privacy-policy",
  ];
  if (match) {
    type = match[1];
  } else {
    type = "home";
  }
  $("li.has-child > a").after('<div class="child-menu-btn fa fa-plus"></div>');

  $(document).on("click", ".cm-menu-btn", function () {
    // Toggle a class on .cm-menu-btn to indicate the state
    $(".cm-menu-btn").toggleClass("menu-open");

    // Check if the menu is open
    if ($(".cm-menu-btn").hasClass("menu-open")) {
      if (arr.includes(type)) {
        $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
          "background-color",
          "#193946"
        );
        $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
      } else {
        $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
          "background-color",
          "white"
        );
        $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "black");
      }
    } else {
      if ($(window).scrollTop() > 100) {
        if (type === "InvestorDetails") {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "#1a3847"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
          $(".sign-in-span").css("color", "!#00A6FB");
        } else if (arr.includes(type)) {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "#193946"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
          $(".sign-in-span").css("color", "!#00A6FB");
        } else {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "white"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "black");
          $(".animatedBtn").css("background-color", "#00A6FB");
          $(".sign-in-span").css("color", "!#00A6FB");
        }
      } else {
        if (type === "InvestorDetails") {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "#1a3847"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
          $(".animatedBtn").css("background-color", "#00A6FB");
        } else if (arr.includes(type)) {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "#193946"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
        } else if (type !== "") {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "white"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "black");
        } else {
          $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
            "background-color",
            "transparent"
          );
          $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
          $(".animatedBtn").css("background-color", "#00A6FB");
        }
      }
    }

    $(".cm-menu-inner").slideToggle();
    $(".cm-menu-inner > ul ul.menu-ul").slideUp();
    $(".child-menu-btn").removeClass("fa-minus");

    // Check the scroll position
    if ($(window).scrollTop() < 100) {
      if (arr.includes(type)) {
        $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
          "background-color",
          "#193946"
        );
        $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
      } else {
        if ($(".cm-menu-btn").hasClass("menu-open")) {
          $(".company-logo").attr("src", "FUNDREV_LOGO.svg");
          $(".animatedBtn").css("background-color", "#00A6FB");
        } else {
          if (type === "InvestorDetails") {
            $(".company-logo").attr("src", "FUNDREV_LOGO_WHITE.svg");
            $(".animatedBtn").css("background-color", "#00A6FB");
          } else if (type !== "") {
            $(".company-logo").attr("src", "FUNDREV_LOGO.svg");
            $(".animatedBtn").css("background-color", "#00A6FB");
          } else {
            $(".company-logo").attr("src", "FUNDREV_LOGO_WHITE.svg");
            $(".animatedBtn").css("background-color", "#00A6FB");
          }
        }
      }
    } else {
      if (arr.includes(type)) {
        $(".landing-header, .cm-menu-inner, .cm-header-wrap").css(
          "background-color",
          "#193946"
        );
        $(".cm-menu-inner > ul > li > a, .cm-menu-btn").css("color", "white");
      } else {
        $(".company-logo").attr("src", "FUNDREV_LOGO.svg");
      }
    }
  });

  $(".child-menu-btn").on("click", function () {
    //do this to its sub menu
    $(this).next("ul.menu-ul").slideToggle();
    $(this).toggleClass("fa-minus");

    //do this to all other sub menu excluding the one that was clicked
    $(this).parent("li").siblings().children("ul.menu-ul").slideUp();
    $(this)
      .parent("li")
      .siblings()
      .children(".child-menu-btn")
      .removeClass("fa-minus");
    $(this)
      .parent("li")
      .siblings()
      .children()
      .find(".child-menu-btn")
      .removeClass("fa-minus");

    //this closes all the child sub menus when its parent child button at level 1 is clicked

    $(this)
      .next("ul.menu-ul")
      .children("li.has-child")
      .find("ul.menu-ul")
      .slideUp();
    $(this)
      .next("ul.menu-ul")
      .children("li.has-child")
      .find(".child-menu-btn")
      .removeClass("fa-minus");
  });
});
