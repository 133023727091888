import React from "react";

const CardTags = ({ deal, page, cardTags }) => {
  const parseAndRender = (jsonString) => {
    try {
      const parsedArray = JSON.parse(jsonString);
      return parsedArray.map((field, index) => (
        <div key={index} className="cdetails-div-8">
          {field}
        </div>
      ));
    } catch (error) {
      console.error("Parsing error:", error);
      return null;
    }
  };
  return (
    <div
      className="cdetails-div-7"
      style={{ marginTop: page === "noNameDetailsPage" && "24px" }}
    >
      {deal && deal.RoundType.length > 0 && parseAndRender(deal.RoundType)}
      {deal &&
        deal.IndustryVerticals.length > 0 &&
        parseAndRender(deal.IndustryVerticals)}
      {deal &&
        deal.EndMarketFocus.length > 0 &&
        parseAndRender(deal.EndMarketFocus)}
    </div>
  );
};

export default CardTags;
