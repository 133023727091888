import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CoInvestorTable = ({ data }) => {
  const preStages = [
    "ndaSigned",
    "ioiSubmitted",
    "ioiAccepted",
    "loiSubmitted",
  ];
  const investorStage = data?.investorStage;
  const tableData = data?.tableData;
  function Row(props) {
    const { stage } = props;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            sx={{
              width: 20,
              height: 48,
              fontFamily: "Karla, sans-serif",
            }}
            align="left"
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              width: 123,
              height: 48,
              fontFamily: "Karla, sans-serif",
              background: "#FFF",
            }}
            align="left"
          >
            {stage.toUpperCase() + ` (${tableData[stage]?.numInvestors})`}
          </TableCell>
          <TableCell
            sx={{
              width: 123,
              height: 48,
              fontFamily: "Karla, sans-serif",
            }}
            align="left"
          >
            {tableData[stage]?.investmentAmount}
          </TableCell>
        </TableRow>
        {investorStage && preStages.includes(investorStage) ? (
          <TableCell
            colSpan={3}
            sx={{
              display: open ? "table-cell" : "none",
              padding: 0,
              animation: open
                ? "fadeIn 0.5s ease-in-out"
                : "fadeOut 0.5s ease-in-out",
              "@keyframes fadeIn": {
                from: {
                  opacity: 0,
                  transform: "translateY(-10px)",
                },
                to: {
                  opacity: 1,
                  transform: "translateY(0)",
                },
              },
              "@keyframes fadeOut": {
                from: {
                  opacity: 1,
                  transform: "translateY(0)",
                },
                to: {
                  opacity: 0,
                  transform: "translateY(-10px)",
                },
              },
            }}
          >
            <Box
              sx={{
                fontFamily: "Karla, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                color: "#5D5D5D",
                padding: "10px 20px",
              }}
            >
              More details are visible after acceptance of LOI.
            </Box>
          </TableCell>
        ) : (
          tableData &&
          tableData[stage]?.investors.map((investor, index) => (
            <TableRow
              key={index}
              sx={{
                display: open ? "table-row" : "none",
                animation: open
                  ? "fadeIn 0.3s ease-in-out"
                  : "fadeOut 0.3s ease-in-out",
                "@keyframes fadeIn": {
                  from: {
                    opacity: 0,
                    transform: "translateY(-10px)",
                  },
                  to: {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
                "@keyframes fadeOut": {
                  from: {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                  to: {
                    opacity: 0,
                    transform: "translateY(-10px)",
                  },
                },
              }}
            >
              <TableCell
                sx={{
                  width: 42,
                  height: 48,
                  fontFamily: "Karla, sans-serif",
                  paddingRight: "38px",
                }}
                align="center"
              >
                {index + 1}.
              </TableCell>
              <TableCell
                sx={{
                  width: 123,
                  height: 48,
                  fontFamily: "Karla, sans-serif",
                }}
                align="left"
              >
                {investor?.name}
              </TableCell>
              <TableCell
                sx={{
                  width: 123,
                  height: 48,
                  fontFamily: "Karla, sans-serif",
                }}
                align="left"
              >
                {investor?.amount}
              </TableCell>
            </TableRow>
          ))
        )}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
          backgroundColor: "#FFFFFF",
          width: "549px",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
          fontFamily: "Karla, sans-serif",
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="coInvestors table">
            <caption
              style={{
                fontFamily: "Karla, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                color: "#5D5D5D",
                padding: "10px 20px",
              }}
            >
              *This data is based on current status of investors.
            </caption>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: 20,
                    height: 48,
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                  align="left"
                />

                <TableCell
                  sx={{
                    width: 123,
                    height: 48,
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                  align="left"
                >
                  {preStages?.includes(data?.investorStage)
                    ? `Stage`
                    : `Stage/Co-Investor's Name`}
                </TableCell>
                <TableCell
                  sx={{
                    width: 123,
                    height: 48,
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    background: "white",
                  }}
                  align="left"
                >
                  Amount (Cr.)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                Object.keys(tableData)
                  .reverse()
                  .map((stage, index) => <Row stage={stage} key={index} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CoInvestorTable;
