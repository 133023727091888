import { Box } from "@mui/material";
import React from "react";
import { MoonLoader } from "react-spinners";

const Loader = ({ fullScreen = true, message = "Loading..." }) => {
  const override = {
    display: "block",
    margin: "0",
  };

  const loaderStyles = fullScreen
    ? {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Increase zIndex to ensure it's above other elements
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }
    : {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 9999,
      };

  return (
    <div style={loaderStyles}>
      <Box
        sx={{
          backgroundColor: "white",
          flexDirection: "column",
          height: fullScreen ? "320px" : "120px",
          width: fullScreen ? "452px" : "200px",
          borderRadius: "34px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px",
          gap: 2,
          transition: "transform 0.3s ease-in-out",
          boxShadow: 1,
        }}
      >
        <MoonLoader
          color="#00A6FB"
          aria-label="Loading Spinner"
          cssOverride={override}
          size={fullScreen ? 60 : 30}
        />
        <p
          style={{
            fontFamily: "Karla, sans-serif",
            fontSize: fullScreen ? 20 : 16,
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {message}
        </p>
      </Box>
    </div>
  );
};

export default Loader;
