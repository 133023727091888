import React,{useState,useEffect} from 'react'
import step2 from "../../../Assets/Images/FounderPage/step2.svg"
import step2small from "../../../Assets/Images/FounderPage/step2small.svg"
const FoundersPageMid3 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const step2Url = windowWidth > 991 ? step2 : step2small
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className='InvestorPageMid3'>
    <div className="div-27">
    <div className="div-28">
        <div className="column-10">
        <div className="div-103">
            <div className="div-104" id="investorreporting">STEP 02</div>
            <div className="div-105">Connect with Compatible Investors</div>
            <p className='div-103-content'>Leverage Fundrev's advanced matching algorithm to connect with investors aligned with your startup's industry, stage, and funding requirements. Explore a curated list of investors actively seeking opportunities in your sector. Engage in meaningful conversations and pitch your startup to interested investors, maximizing your chances of securing funding.</p>
            </div>
        </div>
        <div className='sourcing-div'>
            <img className='sourcing-img' src={step2Url}></img>
        </div>
    </div>
    </div>
    </section>
  )
}

export default FoundersPageMid3
