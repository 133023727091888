import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import whiteLogo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import logout from "../../../Assets/Images/signup/logout.svg";
import whitelogout from "../../../Assets/Images/signup/whitelogout.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../../CSS/Header/header.css";
const Header = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const logoutUrl = windowWidth > 991 ? logout : whitelogout;
  const [headerBackground, setHeaderBackground] = useState("transparent");
  const color = props.color;
  const anticolor = color === "white" ? "black" : "white";
  const [linkbg, setLinkbg] = useState(color);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [logo, setLogo] = useState(
    color === "white" && props.type !== "investor" && props.type !== "founder"
      ? whiteLogo
      : Logo
  );
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setHeaderBackground("#fff");
        color === "white" ? setLinkbg(anticolor) : setLinkbg(color);
        color === "white"
          ? setLogo(Logo)
          : setLogo(
              props.type !== "investor" && props.type !== "founder"
                ? whiteLogo
                : Logo
            );
      } else {
        color === "white"
          ? setHeaderBackground("transparent")
          : setHeaderBackground("#fff");
        color === "white"
          ? setLogo(
              props.type !== "investor" && props.type !== "founder"
                ? whiteLogo
                : Logo
            )
          : setLogo(Logo);
        setLinkbg(color);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    //
    localStorage.removeItem(props.token);
    navigate("/signin", {
      state: {
        type: props.type,
      },
    });
  };

  return (
    <section className="Header">
      <div className="landing-header" style={{ backgroundColor: "#fff" }}>
        <div>
          <Link to="/">
            {" "}
            <img className="company-logo" src={Logo} />
          </Link>
        </div>
        <div className="cm-header-wrap" style={{ backgroundColor: "#fff" }}>
          <div className="cm-menu-wrap">
            <div
              className="cm-menu-btn fa fa-align-right"
              style={{ color: "#000" }}
            ></div>
            <div className="cm-menu-inner" style={{ backgroundColor: "#fff" }}>
              <ul className="menu-ul clear-all">
                <li className="has-child">
                  <Link
                    to="/"
                    className="text-wrapper-2"
                    style={{ color: "#FFF" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="has-child">
                  <Link
                    to="/"
                    className="text-wrapper-2"
                    style={{ color: "#FFF" }}
                  >
                    About us
                  </Link>
                </li>
                <li className="has-child">
                  <Link
                    to="/investor"
                    className="text-wrapper-2"
                    style={{ color: "#FFF" }}
                  >
                    Services
                  </Link>
                </li>
                <li className="has-child">
                  <Link
                    to="/investorDetails"
                    className="text-wrapper-2"
                    style={{ color: "#4EF79A" }}
                  >
                    Your Profile
                  </Link>
                </li>
                <div
                  className="group"
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                >
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="text-wrapper-4">Logout</div>
                      <img
                        className="material-symbols"
                        alt="Material symbols"
                        src={logoutUrl}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
