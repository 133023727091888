import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useInvestorLogout } from "../investorState";

import deals from "../../../../Assets/Images/InvestorPage/Dashboard/deals.svg";
import monitoring from "../../../../Assets/Images/InvestorPage/Dashboard/monitoring.svg";
import opportunities from "../../../../Assets/Images/InvestorPage/Dashboard/opportunities.svg";
import fundrevLogo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import whitelogout from "../../../../Assets/Images/InvestorPage/Dashboard/whitelogout.svg";
import { Typography } from "@mui/material";

const Sidebar2 = ({ active, setAnalytics, analytics }) => {
  const navigate = useNavigate();
  const logout = useInvestorLogout();
  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  const handleLogout = () => {
    logout();
    localStorage.removeItem("token");
    localStorage.removeItem("onBoardingComplete");
    localStorage.removeItem("role");
    navigate("/signin", {
      state: {
        type: "Investor",
      },
    });
  };
  return (
    <div className="opdiv2">
      <img
        loading="lazy"
        src={fundrevLogo}
        className="opimg2"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/investor/opportunities")}
      />
      <div className="complete-sidebar">
        <div className="sidebar-content2">
          <div className="opdiv2-2">
            <Link className="normal-link" to="/investor/Opportunities">
              <div className="opdiv2-3" id="opportunities">
                <img loading="lazy" src={opportunities} className="opimg2-2" />
              </div>
            </Link>
            <Link className="normal-link" to="/investor/ongoingdeals">
              <div className="opdiv2-3" id="ongoingDeals">
                <img loading="lazy" src={deals} className="opimg2-2" />
              </div>
            </Link>

            <Link className="normal-link" to="/investor/monitoring">
              <div className="opdiv2-3" id="monitoring">
                <img loading="lazy" src={monitoring} className="opimg2-2" />
              </div>
            </Link>
          </div>
          <div className="opdiv2-15" onClick={handleLogout}>
            <img loading="lazy" src={whitelogout} className="opimg2-8" />
          </div>
        </div>
        <div
          className="opdiv2-16"
          style={{
            backgroundColor: "#002132",
            alignItems: "start",
            gap: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor: analytics === "summary" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("summary")}
          >
            Summary
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "businessAnalytics" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("businessAnalytics")}
          >
            Business Analytics
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "salesAnalytics" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("salesAnalytics")}
          >
            Sales Analytics
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "historicalFinancials" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("historicalFinancials")}
          >
            Historical Financials
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "financialForecast" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("financialForecast")}
          >
            Financial Forecast
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "fundingDetails" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("fundingDetails")}
          >
            Funding Details
          </Typography>
          {/* <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "customerTape" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("customerTape")}
          >
            Customer Tape
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontFamily: "Karla, sans-serif",
              width: "100%",
              height: "36px",
              alignContent: "center",
              backgroundColor:
                analytics === "financialStatement" ? "#00A6FB33" : "unset",
              cursor: "pointer",
              paddingLeft: "10px",
            }}
            onClick={() => setAnalytics("financialStatement")}
          >
            Financial Statement
          </Typography> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar2;
