import React from 'react'

import { useRecoilState } from 'recoil';
import { investorState, saveState } from '../investorState';
import { Modal, Slide} from '@mui/material';

import rightSide from '../../../../Assets/Images/InvestorPage/Dashboard/rightSide.svg'
import '../../../../CSS/InvestorPage/Dashboard/notifications.css'
const Notifications = ({notifications}) => {
    const [state, setState] = useRecoilState(investorState);

    const handleOpen = () => {
        const newState = { ...state, notificationBarOpen: true };
        setState(newState);
        saveState(newState); // Save to local storage
    };

    const handleClose = () => {
        const newState = { ...state, notificationBarOpen: false };
        setState(newState);
        saveState(newState); // Save to local storage
    };
  return (
    <Modal
        open={state.notificationBarOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
    >
    <Slide direction="left" in={state.notificationBarOpen} mountOnEnter unmountOnExit>
        <div className="Notify-div">
            <div className="Notify-div-2">
            <div className="Notify-div-3">
                <div className="Notify-div-4">Notifications</div>
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6b07b7cd00d36c34b4f217f00e32fcf00c888d8cb668be2d1842f641b5588a92?apiKey=32c42a50fb8f44509096999a9c1a8276&"
                className="Notify-img"
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
                />
            </div>
            </div>
            {
                notifications.length === 0 &&
                <div className="No-new-notifications-text">No new notifications</div>
            }
            {
                notifications.map((notification, index) => (
                    <div className="Notify-div-5" key={index}>
                        <img
                            loading="lazy"
                            srcSet={notification.img}
                            className="Notify-img-2"
                        />
                        <div className="Notify-div-6">
                            <div className='Notify-div-13'>
                                <div className="Notify-div-7">
                                    <div className="Notify-div-8">{notification.company}</div>
                                </div>
                                <div className="Notify-div-9">
                                <div className="Notify-div-10">{notification.title}</div>
                                </div>
                                <div className="Notify-div-12">{notification.time}</div>
                            </div>
                            <img
                                loading="lazy"
                                src={rightSide}
                                style={{ cursor: 'pointer' }}
                                className="Notify-img-3"
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    </Slide>
    </Modal>
  )
}

export default Notifications
