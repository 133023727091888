import React from "react";
import InvestorRegisterBlock from "./investorRegisterBlock";
import { ToastContainer } from "react-toastify";
const InvestorDealRegister = () => {
  return (
    <div className="investorDealRegister ">
      <ToastContainer position="top-center" containerId="investorRegister" />
      <InvestorRegisterBlock startPoint="noNameDeals" />
    </div>
  );
};

export default InvestorDealRegister;
