import React, { useState } from "react";
import Person from "../../../../Assets/Images/signup/Person.svg";

const TeamProfile = ({ member }) => {
  const [showFullBio, setShowFullBio] = useState(false);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleToggleBio = () => {
    setShowFullBio(!showFullBio);
  };

  const displayedBio = showFullBio
    ? member.biography
    : truncateText(member.biography, 30);

  return (
    <div className="team-profiles-box">
      <div className="team-profile-div">
        <img
          loading="lazy"
          src={member.foundingTeamProfile ? member.foundingTeamProfile : Person}
          className="team-profile-img"
        />
        <div className="team-profile-div-2">
          <div className="team-profile-div-3">{member.name}</div>
          <div className="team-profile-div-4">{member.title}</div>
          <div className="team-profile-div-5">
            {displayedBio}
            {!showFullBio && member.biography.split(" ").length > 30 && (
              <span
                onClick={handleToggleBio}
                style={{ cursor: "pointer", color: "blue" }}
              >
                ... more
              </span>
            )}
            {showFullBio && (
              <span
                onClick={handleToggleBio}
                style={{ cursor: "pointer", color: "blue" }}
              >
                show less
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamProfile;
