import React, { useState } from "react";
import Topbar from "./topbar";
import Sidebar from "./Sidebar";
import CompanyUploadedFiles from "./companyUploadedFiles";
import { useLocation } from "react-router-dom";

import DealDetailsForm from "./dealDetailsForm";
import FoundingTeamDetailsForm from "./foundingTeamDetailsForm";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Helmet } from "react-helmet";
const DealDetailsEdit = () => {
  const location = useLocation();
  const [cardType, setCardType] = useState("DealDetails");
  const { dealID, startupID } = location.state || {};
  const handleCardTypeChange = (event, newCardType) => {
    setCardType(newCardType);
  };
  return (
    <section className="dealDetailsEdit">
      <Helmet>
        <title>Admin Panel - deal details Edit</title>
      </Helmet>
      <Topbar />
      <Sidebar dealID={dealID} active={"dealDetails"} startupID={startupID} />
      <div className="dealDetailsEdit-box">
        <ToggleButtonGroup
          color="primary"
          value={cardType}
          exclusive
          onChange={handleCardTypeChange}
          aria-label="card-type"
          className="toggle-card-type-btn"
        >
          <ToggleButton
            value="DealDetails"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Deal Details
          </ToggleButton>
          <ToggleButton
            value="Files"
            sx={{
              textTransform: "capitalize",
            }}
          >
            Files
          </ToggleButton>
          <ToggleButton value="FoundingTeamDetails">
            FoundingTeam Details
          </ToggleButton>
        </ToggleButtonGroup>
        {cardType === "DealDetails" ? (
          <DealDetailsForm />
        ) : cardType === "Files" ? (
          <CompanyUploadedFiles />
        ) : (
          <FoundingTeamDetailsForm />
        )}
      </div>
    </section>
  );
};

export default DealDetailsEdit;
