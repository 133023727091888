import React, { useEffect, useState } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import NoDeal from "./NoDeal";
import { fetchInvestorDetails } from "../../../../endpoints/investor";
import { getPassedDeals } from "../../../../endpoints/deal";
import { getValues } from "../../../../endpoints/investor";
import { useZoomEffect } from "../../../Common/functions";
import DealsFilterBox from "../../../Common/components/DealsFilterBox";

import { ToastContainer } from "react-toastify";
import { Slider, Input, Checkbox } from "@mui/joy";
import { Helmet } from "react-helmet";

import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { interactedDealsState, investorState } from "../investorState";
import filter from "../../../../Assets/Images/InvestorPage/Dashboard/filter.svg";
import sort from "../../../../Assets/Images/InvestorPage/Dashboard/sort.svg";

import "../../../../CSS/InvestorPage/Dashboard/style.css";
import RecommendedDeals from "./RecommendedDeals";
import OtherDeals from "./OtherDeals";

import staytuned from "../../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";
import emptyCard from "../../../../Assets/Images/InvestorPage/Dashboard/emptyCard.svg";
import {
  getDealsByInvestorID,
  getNoNameDeals,
} from "../../../../endpoints/deal";
import PassedDeals from "./PassedDeals";
import { useLoader } from "../../../Common/LoaderProvider";

const Opportunities = () => {
  const location = useLocation();
  const loader = useLoader();
  const Navigate = useNavigate();
  const [investorData, setInvestorData] = useRecoilState(investorState);
  const token = localStorage.getItem("token");
  const [startups, setStartups] = useState([]);
  const [details, setDetails] = useState(null);
  const [idealFit, setIdealFit] = useState([]);
  const [moderateFit, setModerateFit] = useState([]);
  const [nonFit, setNonFit] = useState([]);
  const [passedDeals, setPassedDeals] = useState([]);
  const [activeBtn, setActiveBtn] = useState(1);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [RaisedThisRound, setRaisedThisRound] = useState([0, 10]);
  const [EBIDTA, setEBIDTA] = useState([0, 10]);
  const [grossProfit, setGrossProft] = useState([0, 10]);
  const [EBIDTAPercent, setEBIDTAPercent] = useState([0, 10]);
  const [Revenue, setRevenue] = useState([0, 50]);
  const [industryFocus, setIndustryFocus] = useState([]);
  const [allFocusOptions, setAllFocusOptions] = useState([]);
  const [tags, setTags] = useState({});
  const [interactedDeals, setInteractedDeals] =
    useRecoilState(interactedDealsState);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [width, setWindowWidth] = useState(window.innerWidth);
  const handleRaisedThisRoundChange = (event, newValue) => {
    setRaisedThisRound(newValue);
  };
  const handleEBIDTAChange = (event, newValue) => {
    setEBIDTA(newValue);
  };
  const handleGrossProfitChange = (event, newValue) => {
    setGrossProft(newValue);
  };
  const handleEBIDTAPercentChange = (event, newValue) => {
    setEBIDTAPercent(newValue);
  };
  const handleRevenueChange = (event, newValue) => {
    setRevenue(newValue);
  };
  useEffect(() => {
    if (token) {
      const newData = {
        ...investorData,
      };
      setInvestorData(newData);
      localStorage.setItem("investorState", JSON.stringify(newData));
    }
  }, [token]);

  useEffect(() => {
    const fetchValues = async () => {
      try {
        loader.start();
        const response = await getValues();
        loader.stop();
        if (response && response.data !== null) {
          setIndustryFocus(response.data[0].IndustryFocusOptions);
        } else {
          loader.stop();
        }
      } catch (error) {
        loader.stop();
      }
    };
    fetchValues();
  }, []);
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetchInvestorDetails(token);
        if (response && response.data !== null) {
          setDetails(response.data.data);
        } else {
          //
          loader.stop();
        }
      } catch (error) {
        loader.stop();
      }
    };
    fetchDetails();
  }, [token]);

  useEffect(() => {
    //Get deals with investor has interacted with and we will not show them in opportunities page.
    const getInteractedDeals = async () => {
      try {
        const response = await getDealsByInvestorID(details.investorID, token);
        //
        setInteractedDeals(response.data);
      } catch (error) {
        //
        loader.stop();
      }
    };
    if (details) {
      getInteractedDeals();
    }
  }, [details, setInteractedDeals]);
  useEffect(() => {
    const fetchPassedDeals = async () => {
      try {
        const response = await getPassedDeals(details.investorID, token);
        if (response.data !== null) {
          setPassedDeals(response.data);
        }
      } catch (error) {}
    };
    fetchPassedDeals();
  }, [details]);
  useEffect(() => {
    const fetchAllStartupsAndCategorize = async () => {
      let filterDeals = [];
      try {
        const response = await getNoNameDeals(details.investorID, token);
        loader.stop();
        if (response.data !== null) {
          const allDeals = response.data;
          for (let index = 0; index < allDeals.length; index++) {
            const element = allDeals[index];
            //
            if (
              interactedDeals.some((deal) => deal.DealID === element.DealID)
            ) {
              //
            } else {
              filterDeals.push(element);
            }
          }
          setStartups(filterDeals);
        }
      } catch (error) {
        //
        loader.stop();
      }
    };
    if (details && interactedDeals) {
      fetchAllStartupsAndCategorize();
    }
  }, [details]);

  const categorizeStartups = () => {
    const ideal = [];
    const moderate = [];
    const non = [];

    // Check if details has the necessary fields
    if (
      details &&
      details.idealDealIds &&
      details.moderateDealIds &&
      details.nonFitDealIds
    ) {
      // Extract deal IDs from details
      const idealDealIds = details.idealDealIds;
      const moderateDealIds = details.moderateDealIds;
      const nonFitDealIds = details.nonFitDealIds;

      // Categorize startups
      startups &&
        startups.length > 0 &&
        startups.forEach((startup) => {
          //
          if (idealDealIds.includes(startup.DealID)) {
            ideal.push(startup);
          } else if (moderateDealIds.includes(startup.DealID)) {
            moderate.push(startup);
          } else if (nonFitDealIds.includes(startup.DealID)) {
            non.push(startup);
          }
        });
    } else {
      console.error("Details object does not contain the required fields.");
    }

    // Set state variables
    setIdealFit(ideal);
    setModerateFit(moderate);
    setNonFit(non);
    setAllFocusOptions([...ideal, ...moderate, ...non]);
  };
  useEffect(() => {
    categorizeStartups();
  }, [startups]);
  const toggleFilterBoxOpen = () => {
    setFilterBoxOpen(!filterBoxOpen);
  };
  useZoomEffect();
  function dealsLength(fitType) {
    try {
      return fitType.length ? fitType.length : 0;
    } catch (error) {
      return 0;
    }
  }
  return (
    <div className="InvestorSide">
      <Helmet>
        <title>Investor | Recommended deals</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="opportunties" />
      <Sidebar active="opportunities" />
      <Topbar title="Opportunities" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="InvestorSide-content-box">
            <div className="InvestorSide-content-text">
              <p className="InvestorSide-content-text-1">
                Invest in the visionaries{" "}
                <span className="shapingTheFuture">shaping the future</span>{" "}
              </p>
              <p className="InvestorSide-content-text-2">
                Discover great founders and support their fundraising journey
              </p>
            </div>
            <div className="InvestorSide-content-action-buttons">
              <button
                className="InvestorSide-content-button-1"
                onClick={toggleFilterBoxOpen}
              >
                <img loading="lazy" src={filter} className="img" />
                Filter
              </button>
            </div>
          </div>
          <DealsFilterBox
            deals={
              activeBtn === 1
                ? idealFit
                : activeBtn === 2
                ? [...nonFit, ...moderateFit]
                : activeBtn === 3
                ? passedDeals
                : []
            }
            activeBtn
            filterBoxOpen={filterBoxOpen}
            setFilteredDeals={setFilteredDeals}
          />
          <div className="filter-box" style={{ display: "none" }}>
            <div className="upper-filter-box">
              <div className="upper-filter-box-col">
                <div className="filter-box-content">
                  <p>Raised This Round</p>
                  <Slider
                    value={RaisedThisRound}
                    onChange={handleRaisedThisRoundChange}
                    valueLabelDisplay="auto"
                    max={10}
                  />
                  <div className="filter-box-inputs">
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={RaisedThisRound[0]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Min(Cr)</span>
                      }
                      onChange={(e) => {
                        setRaisedThisRound([
                          e.target.value,
                          RaisedThisRound[1],
                        ]);
                      }}
                    />
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={RaisedThisRound[1]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Max(Cr)</span>
                      }
                      onChange={(e) => {
                        setRaisedThisRound([
                          RaisedThisRound[0],
                          e.target.value,
                        ]);
                      }}
                    />
                  </div>
                  <p>EBIDTA</p>
                  <Slider
                    value={EBIDTA}
                    onChange={handleEBIDTAChange}
                    valueLabelDisplay="auto"
                    max={10}
                  />
                  <div className="filter-box-inputs">
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={EBIDTA[0]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Min(Cr)</span>
                      }
                      onChange={(e) => {
                        setEBIDTA([e.target.value, EBIDTA[1]]);
                      }}
                    />
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={EBIDTA[1]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Max(Cr)</span>
                      }
                      onChange={(e) => {
                        setEBIDTA([EBIDTA[0], e.target.value]);
                      }}
                    />
                  </div>
                  <p>End Market Focus</p>
                  <div className="checkbox-section-1">
                    <div className="checkbox-cols">
                      <Checkbox
                        label="B2B"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, B2B: e.target.checked });
                        }}
                      />
                      <Checkbox
                        label="B2B2C"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, B2BB2C: e.target.checked });
                        }}
                      />
                    </div>
                    <div className="checkbox-cols">
                      <Checkbox
                        label="B2C"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, B2C: e.target.checked });
                        }}
                      />
                      <Checkbox
                        label="D2C"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, D2C: e.target.checked });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="upper-filter-box-col">
                <div className="filter-box-content">
                  <p>Gross Profit</p>
                  <Slider
                    value={grossProfit}
                    onChange={handleGrossProfitChange}
                    valueLabelDisplay="auto"
                    max={10}
                  />
                  <div className="filter-box-inputs">
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={grossProfit[0]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Min(Cr)</span>
                      }
                      onChange={(e) => {
                        setGrossProft([e.target.value, grossProfit[1]]);
                      }}
                    />
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={grossProfit[1]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Max(Cr)</span>
                      }
                      onChange={(e) => {
                        setGrossProft([grossProfit[0], e.target.value]);
                      }}
                    />
                  </div>
                  <p>EBIDTA %</p>
                  <Slider
                    value={EBIDTAPercent}
                    onChange={handleEBIDTAPercentChange}
                    valueLabelDisplay="auto"
                    max={10}
                  />
                  <div className="filter-box-inputs">
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={EBIDTAPercent[0]}
                      startDecorator={
                        <span className="filterBox-Decorator">%</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Min</span>
                      }
                      onChange={(e) => {
                        setEBIDTAPercent([e.target.value, EBIDTAPercent[1]]);
                      }}
                    />
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={EBIDTAPercent[1]}
                      startDecorator={
                        <span className="filterBox-Decorator">%</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Max</span>
                      }
                      onChange={(e) => {
                        setEBIDTAPercent([EBIDTAPercent[0], e.target.value]);
                      }}
                    />
                  </div>
                  <p>Stage of Investment</p>
                  <div className="checkbox-section-1">
                    <div className="checkbox-cols">
                      <Checkbox
                        label="Seed"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, Seed: e.target.checked });
                        }}
                      />
                      <Checkbox
                        label="Early Stage"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, EarlyStage: e.target.checked });
                        }}
                      />
                    </div>
                    <div className="checkbox-cols">
                      <Checkbox
                        label="Growth"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, Growth: e.target.checked });
                        }}
                      />
                      <Checkbox
                        label="Late Stage"
                        className="reason-checkbox"
                        onChange={(e) => {
                          setTags({ ...tags, LateStage: e.target.checked });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="upper-filter-box-col" style={{ border: "0px" }}>
                <div className="filter-box-content">
                  <p>Revenue</p>
                  <Slider
                    value={Revenue}
                    onChange={handleRevenueChange}
                    valueLabelDisplay="auto"
                    max={50}
                  />
                  <div className="filter-box-inputs">
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={Revenue[0]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Min(Cr)</span>
                      }
                      onChange={(e) => {
                        handleRevenueChange([e.target.value, Revenue[1]]);
                      }}
                    />
                    <Input
                      className="filter-box-inputs-input"
                      size="md"
                      placeholder="Medium"
                      value={Revenue[1]}
                      startDecorator={
                        <span className="filterBox-Decorator">₹</span>
                      }
                      endDecorator={
                        <span className="filterBox-Decorator">Max(Cr)</span>
                      }
                      onChange={(e) => {
                        handleRevenueChange([Revenue[0], e.target.value]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lower-filter-box">
              <p>Industry</p>
              <div className="checkbox-section-2">
                {industryFocus &&
                  industryFocus.map((industry) => (
                    <Checkbox
                      key={industry}
                      label={
                        industry.length > 25
                          ? industry.slice(0, 25) + "..."
                          : industry
                      }
                      className="filter-checkbox"
                      onChange={(e) => {
                        setTags({ ...tags, [industry]: e.target.checked });
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="cdetails-first-div header-with-border-bottom">
            <div className="typeOfDeals">
              <button
                className={`typeOfDeals-btn ${
                  activeBtn === 1 ? "typeOfDeals-active-btn" : ""
                }`}
                onClick={() => {
                  setActiveBtn(1);
                  // setStartups(idealFit);
                }}
                style={{
                  width: "270px",
                }}
              >
                Recommended Deals -{" "}
                {activeBtn === 1
                  ? dealsLength(filteredDeals)
                  : dealsLength(idealFit)}
              </button>
              <button
                className={`typeOfDeals-btn ${
                  activeBtn === 2 ? "typeOfDeals-active-btn" : ""
                }`}
                onClick={() => {
                  setActiveBtn(2);
                  // setStartups(moderateFit);
                }}
                style={{
                  width: "180px",
                }}
              >
                Other Deals -{" "}
                {activeBtn === 2
                  ? dealsLength(filteredDeals)
                  : dealsLength(moderateFit) + dealsLength(nonFit)}
              </button>
              <button
                className={`typeOfDeals-btn ${
                  activeBtn === 3 ? "typeOfDeals-active-btn" : ""
                }`}
                onClick={() => {
                  setActiveBtn(3);
                }}
                style={{
                  width: "200px",
                }}
              >
                Passed Deals -{" "}
                {activeBtn === 3
                  ? dealsLength(filteredDeals)
                  : dealsLength(passedDeals)}
              </button>
            </div>
            <div className="typeOfDeals-border" style={{ top: "49.5px" }}></div>
          </div>
          {activeBtn == 2 && (
            <OtherDeals
              nonFit={nonFit}
              moderateFit={moderateFit}
              details={details}
              startups={startups}
            />
          )}
          <div className="opportunites-display">
            {/* {activeBtn} */}
            {activeBtn === 1 && (
              <RecommendedDeals
                allFocusOptions={allFocusOptions}
                idealFit={filteredDeals}
                details={details}
                RaisedThisRound={RaisedThisRound}
                Revenue={Revenue}
                grossProfit={grossProfit}
                EBIDTA={EBIDTA}
                EBIDTA_Percentage={EBIDTAPercent}
                tags={tags}
              />
            )}
            {activeBtn === 3 && (
              <PassedDeals details={details} passedDeals={filteredDeals} />
            )}
          </div>
        </div>
      </div>
    </div>
    // <div className="InvestorSide">
    //   <Helmet>
    //     <title>Investor | Recommended deals</title>
    //   </Helmet>
    //   <Sidebar active="opportunities" />
    //   <Topbar title="Opportunities" />
    //   <div className="InvestorSide-box">
    //     <div className="InvestorSide-content">
    //       <p className="oppo-heading">
    //         No live opportunities available currently, kindly revisit after some
    //         time...{" "}
    //       </p>
    //       <div className="opportunites-display" style={{ marginTop: "30px" }}>
    //         <img src={emptyCard} />
    //         <img src={emptyCard} />
    //         <img src={emptyCard} />
    //         <img src={emptyCard} />
    //         <img src={emptyCard} />
    //         <img src={emptyCard} />
    //         <img src={emptyCard} />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Opportunities;
