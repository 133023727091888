import React from "react";

const TermsOfUse = ({ termsRefs }) => {
  const scrollToSection = (ref, agreementRef) => {
    if (ref && ref.current && agreementRef && agreementRef.current) {
      agreementRef.current.scrollTo({
        top: ref.current.offsetTop - agreementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      className="mx-auto p-5 border border-gray-300 rounded-lg shadow-md max-h-[51vh] overflow-scroll"
      ref={termsRefs.agreementRef}
    >
      <h1 className="text-2xl font-bold text-center mb-8">
        TERMS OF USE (FOR FUNDRAISERS)
      </h1>
      <h2 className="text-[18px] mt-4 font-bold mb-4">TABLE OF CONTENTS</h2>
      <ol className="list-decimal pl-6 mb-6">
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.definitionsRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            DEFINITIONS
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.scopeOfServicesRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            SCOPE OF SERVICES
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.transactionFeeRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            TRANSACTION FEE
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.fundraiserResponsibilitiesRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            FUNDRAISER RESPONSIBILITIES
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.intellectualPropertyRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            INTELLECTUAL PROPERTY RIGHTS
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.confidentialityRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            CONFIDENTIALITY
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.nonSolicitationRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            NON-SOLICITATION CLAUSE
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.warrantiesRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            WARRANTIES AND DISCLAIMERS
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.limitationOfLiabilityRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            LIMITATION OF LIABILITY
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.indemnificationRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            INDEMNIFICATION
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.termAndTerminationRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            TERM AND TERMINATION
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.dataProtectionRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            DATA PROTECTION & PRIVACY
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.disputeResolutionRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            DISPUTE RESOLUTION
          </button>
        </li>

        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.miscellaneousRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            MISCELLANEOUS
          </button>
        </li>
      </ol>

      <h2
        ref={termsRefs.definitionsRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        1. Definitions
      </h2>
      <p className="mb-4 text-[14px]">
        For the purposes of this Agreement, the following terms shall have the
        meanings set forth below:
      </p>
      <ul className=" list-disc pl-6 mb-6 text-[14px]">
        <li>
          <strong>Affiliate</strong>: means any entity that directly or
          indirectly controls, is controlled by, or is under common control of a
          Party.
        </li>
        <li>
          <strong>Agreement</strong>: means this Master Service Agreement,
          including all Terms of Use, exhibits, schedules, and amendments
          hereto.
        </li>
        <li>
          <strong>Confidential Information</strong>: Confidential Information
          shall include any and all technical, commercial, financial,
          operational, marketing, legal, regulatory, strategic, intellectual
          property-related, or other information, including but not limited to:
          <ul className="list-disc pl-6">
            <li>
              Business plans, proposals, financial data, projections, and
              forecasts;
            </li>
            <li>
              Trade secrets, intellectual property, inventions, patents,
              designs, processes, methodologies, technologies, know-how, and
              proprietary algorithms;
            </li>
            <li>
              Corporate strategies, product or service roadmaps, research and
              development activities, and intellectual property filings;
            </li>
            <li>
              Marketing strategies, client lists, pricing policies, and market
              analyses;
            </li>
            <li>
              Contracts, agreements, legal opinions, regulatory submissions, and
              filings;
            </li>
            <li>
              Employee details, organizational structures, internal
              communications, and operational data;
            </li>
            <li>
              Information related to existing or potential business
              relationships with third parties, including suppliers, vendors,
              customers, investors, or partners;
            </li>
            <li>
              Analyses, compilations, studies, summaries, or other documents or
              records prepared by the Receiving Party, its representatives, or
              its affiliates that contain or reflect the Confidential
              Information;
            </li>
            <li>
              Any information disclosed in any form, whether tangible or
              intangible, including oral, written, electronic, visual, or other
              forms, and any information learned through observation,
              discussion, or otherwise.
            </li>
          </ul>
        </li>
        <li>
          <strong>Data Breach</strong>: means any unauthorized access,
          acquisition, use, or disclosure of data that compromises the security,
          confidentiality, or integrity of such data.
        </li>
        <li>
          <strong>Disclosing Party</strong>: means the party and its affiliates
          providing Confidential Information.
        </li>
        <li>
          <strong>Effective Date</strong>: means the date on which this
          Agreement is executed by both Parties.
        </li>
        <li>
          <strong>Force Majeure</strong>: means any event beyond the reasonable
          control of a Party, including acts of God, natural disasters, war,
          terrorism, and any other unforeseen circumstances that prevent a Party
          from performing its obligations under this Agreement.
        </li>
        <li>
          <strong>Fundraiser</strong>: refers to any individual, company,
          entity, or organization that seeks to raise capital, either through
          equity, debt, or other financial instruments, or that seeks to engage
          in the sale of a company, business unit, or asset, or pursue an exit
          strategy through a transaction facilitated by Fundrev. This includes,
          but is not limited to, startups, established businesses,
          project-specific entities, special purpose vehicles (SPVs), or any
          other organization or entity requiring financial investment, funding,
          capital infusion, or aiming for a sale or exit.
        </li>
        <li>
          <strong>Fundraiser Data</strong>: means any data or information
          provided by the Fundraiser to Fundrev for the purpose of performing
          the Services.
        </li>
        <li>
          <strong>Indemnified Parties</strong>: means a Party and its officers,
          directors, employees, agents, and Affiliates who are entitled to
          indemnification under this Agreement.
        </li>
        <li>
          <strong>Intellectual Property Rights</strong>: means all intellectual
          property rights, including patents, trademarks, copyrights, trade
          secrets, and proprietary information.
        </li>
        <li>
          <strong>Platform</strong>: means the online platform operated by
          Fundrev that facilitates the connection between Fundraisers and
          Investors, providing various services including but not limited to
          data analysis, matching, reporting, and monitoring.
        </li>
        <li>
          <strong>Receiving Party</strong>: means the party and its affiliates
          receiving Confidential Information.
        </li>
        <li>
          <strong>Services</strong>: means the services provided by Fundrev to
          the Fundraiser as described in this Agreement and any associated
          Statement of Work.
        </li>
        <li>
          <strong>Statement of Work (SOW)</strong>: means any document signed by
          both Parties that describes specific services to be provided by
          Fundrev under this Agreement.
        </li>
        <li>
          <strong>Term</strong>: means the duration of this Agreement, including
          the initial term and any subsequent renewal terms, during which the
          Parties are bound by the provisions of this Agreement.
        </li>
        <li>
          <strong>Third-Party Service Providers</strong>: means any independent
          contractor, consultant, or vendor engaged by Fundrev to perform any
          portion of the Services under this Agreement.
        </li>
        <li>
          For the purposes of this Agreement, the terms <strong>Sale</strong>,{" "}
          <strong>Fundraise</strong>, and <strong>Investment</strong> shall
          include, but not be limited to, any transaction involving the direct
          or indirect transfer of ownership, equity, debt, or other securities
          of the Fundraiser. This definition encompasses a wide array of asset
          classes, including but not limited to venture debt, equity, preference
          shares, instruments such as iSAFE (Indian Simple Agreement for Future
          Equity), Compulsorily Convertible Debentures (CCDs), and Compulsorily
          Convertible Preference Shares (CCPs). Any transaction that results in
          a change of control, ownership, or capital structure of the Fundraiser
          shall also be deemed a “Sale,” “Fundraise,” or “Investment” under this
          Agreement.
        </li>
      </ul>
      <h2
        ref={termsRefs.scopeOfServicesRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        2. Scope of Services
      </h2>
      <h3 className="text-lg font-semibold mb-4">2.1 Services Provided</h3>
      <p className="mb-4 text-[14px]">
        Fundrev agrees to provide the Services to the Fundraiser as detailed in
        the applicable Statement of Work ("SOW"). The Services may include, but
        are not limited to:
      </p>
      <ul className=" list-disc pl-6 mb-6 text-[14px]">
        <li>
          <strong>Matching Services</strong>: Connecting the Fundraiser with
          potential Investors through the Platform.
        </li>
        <li>
          <strong>Due Diligence Support</strong>: Assisting in the preparation
          and presentation of due diligence materials to potential Investors.
        </li>
        <li>
          <strong>Data Analytics</strong>: Providing data analysis and insights
          to optimize the Fundraiser's fundraising efforts.
        </li>
        <li>
          <strong>Reporting</strong>: Generating reports related to fundraising
          activities, including investor interest and engagement metrics.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        2.2 Modifications to Services
      </h3>
      <p className="mb-4 text-[14px]">
        Any modifications or additions to the Services shall be documented in a
        written amendment to the SOW and must be signed by both Parties.
      </p>

      <h3 ref={termsRefs.performanceRef} className="text-lg font-semibold mb-4">
        2.3 Performance Standards
      </h3>
      <p className="mb-4 text-[14px]">
        Fundrev shall perform the Services with reasonable skill, care, and
        diligence in accordance with industry standards. The Fundraiser
        acknowledges that Fundrev does not guarantee specific results or
        outcomes from the Services.
      </p>
      <h2
        ref={termsRefs.transactionFeeRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        3. Transaction Fee
      </h2>
      <h3 className="text-lg font-semibold mb-4">
        3.1 Obligation to Pay Transaction Fee
      </h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser/Seller shall pay Fundrev a transaction fee equal to 2% of
        the total transaction value upon the receipt of funds from any Investor
        or buyer, facilitated by Fundrev (the “Transaction Fee”). This fee
        applies to all financial transactions, including but not limited to
        equity investments, debt financing, venture debt, preference shares,
        convertible instruments (such as CCDs, CCPs, iSAFE), as well as any
        sale, merger, acquisition, or exit transaction of the
        Fundraiser/Seller's business, assets, or units. This fee is applicable
        each time the Fundraiser receives funds from the Investor through the
        platform/services offered by Fundrev, regardless of the nature,
        structure, or terms of the funding.
      </p>

      <h3 className="text-lg font-semibold mb-4">3.2 Scope of Application</h3>
      <p className="mb-4 text-[14px]">
        The Transaction Fee is applicable to all forms of funding, including but
        not limited to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>
          <strong>Lump-Sum Payments</strong>: The Fundraiser shall pay 2% of any
          one-time or lump-sum payment received from the Investor, whether the
          same is in form of equity, SAFE, convertible equity, venture debt, or
          any other form of funding.
        </li>
        <li>
          <strong>Milestone-Based Payments</strong>: In cases where the
          Fundraiser/Seller receives funds based on milestones or periodic
          payments from an Investor, the 2% transaction fee shall apply to each
          payment received. The Fundraiser/Seller is obligated to pay the
          transaction fee upon each receipt of funds, regardless of the
          structure of the investment or payment schedule agreed upon with the
          Investor.
        </li>
        <li>
          <strong>Periodic Payments</strong>: For transactions involving
          periodic funding (e.g., quarterly, bi-annual, or annual payments), the
          2% transaction fee shall be payable on the entire committed amount at
          the time the first payment is received from the Investor.
        </li>
        <li>
          <strong>Multiple Rounds of Funding</strong>: If the Fundraiser/Seller
          secures multiple rounds of funding from the same Investor or a
          consortium of investors, or if there are multiple phases of a sale
          transaction, the 2% transaction fee shall be applied to each round of
          funding or each phase of the sale. This applies whether the funding is
          structured as a follow-on round, bridge financing, or any other
          subsequent financial infusion, or whether the sale is completed in
          multiple stages, facilitated by Fundrev.
        </li>
        <li ref={termsRefs.saleOfCompany}>
          <strong>
            Applicability to Sale, Merger, or Acquisition Transactions
          </strong>
          : In the event of a sale, merger, or acquisition transaction, where
          the Fundraiser/Seller sells a part or whole of the business, assets,
          or undertakes a strategic exit facilitated by Fundrev, the
          Fundraiser/Seller shall be liable to pay Fundrev 2% of the total
          transaction value of such a sale, merger, or acquisition. This fee
          shall be due at the time of receipt of funds by the Fundraiser/Seller
          from the buyer or any other third party involved in the transaction.
        </li>
        <li>
          <strong>Termination Before Disbursement</strong>: In the event that
          the contract between the Fundraiser/Seller and Fundrev is terminated
          before the disbursement of funds or completion of a transaction,
          Fundrev shall still be entitled to the Transaction Fee if the
          introduction or facilitation by Fundrev led to a transaction or
          funding that occurs within a period of 12 months following the
          termination of the contract. The Transaction Fee shall be calculated
          based on the total transaction value received by the Fundraiser/Seller
          from the Investor or buyer, as facilitated by Fundrev prior to
          termination. The Fundraiser/Seller remains liable to pay the
          Transaction Fee to Fundrev in accordance with this provision.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">3.3 Subsequent Funding</h3>
      <p className="mb-4 text-[14px]">
        In the event of any future funding from the Investor, the Fundraiser
        shall be liable to pay 2% of each new fund disbursement, given the stage
        of funding is facilitated by Fundrev. This includes any additional
        investments, follow-on rounds, or any other financial contributions made
        by the Investor through the platform/services offered by Fundrev.
      </p>

      <h3 className="text-lg font-semibold mb-4">3.4 Multiple Investors</h3>
      <p className="mb-4 text-[14px]">
        If the Fundraiser receives funds from multiple investors within the same
        round or across different rounds, the 2% Transaction Fee applies to the
        amount received from each individual Investor. This ensures that each
        portion of the investment is subject to the Transaction Fee.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        3.5 Non-Exhaustive Examples
      </h3>
      <p className="mb-4 text-[14px]">
        The examples provided in this clause are illustrative and not
        exhaustive. The 2% Transaction Fee is applicable to all transactions
        facilitated by Fundrev, irrespective of the specific funding mechanism
        or agreement structure.
      </p>

      <h3 className="text-lg font-semibold mb-4">3.6 Payment Terms</h3>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>
          <strong>Due Date</strong>: The Transaction Fee is due and payable
          within 7 (Seven) days of the Fundraiser's receipt of each fund
          disbursement from the Investor. The Fundraiser must notify Fundrev in
          writing upon receipt of any funds and provide an accurate calculation
          of the Transaction Fee due.
        </li>
        <li>
          <strong>Payment Method</strong>: Payments shall be made via [Bank
          Transfer/Online Payment] to the account designated by Fundrev. The
          Fundraiser is responsible for any transaction fees or charges
          associated with the payment.
        </li>
        <li>
          <strong>Late Payment Penalty</strong>: Any payment not made by the due
          date shall incur a late payment penalty of [1]% per month, compounded
          monthly, until the full amount is paid.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">3.7 Audit Rights</h3>
      <p className="mb-4 text-[14px]">
        Fundrev reserves the right to audit the Fundraiser's financial records
        to verify the accuracy of the Transaction Fees paid. The Fundraiser
        shall provide access to all relevant documentation and cooperate with
        Fundrev's audit process.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        3.8 Breach of Payment Obligations
      </h3>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>
          <strong>Consequences of Non-Payment</strong>: Failure to comply with
          the payment obligations under this clause shall constitute a material
          breach of this Agreement. Fundrev may exercise any or all of the
          following remedies:
          <ul className="list-disc pl-6">
            <li>
              Suspension or termination of services provided to the Fundraiser;
            </li>
            <li>
              Legal action to recover unpaid Transaction Fees, including
              recovery of associated costs;
            </li>
            <li>
              Retention of any funds or assets held by Fundrev as security for
              the unpaid amounts.
            </li>
          </ul>
        </li>
        <li>
          <strong>No Waiver</strong>: Fundrev's decision to delay or refrain
          from exercising any right under this clause does not constitute a
          waiver of its right to enforce payment at a later date.
        </li>
      </ul>
      <h2
        ref={termsRefs.fundraiserResponsibilitiesRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        4. Fundraiser Responsibilities
      </h2>

      <h3 className="text-lg font-semibold mb-4">
        4.1 Provision of Information
      </h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser agrees to provide Fundrev with all necessary information,
        data, and documentation required to perform the Services. The Fundraiser
        represents and warrants that all information provided shall be accurate,
        complete, and up-to-date.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        4.2 Adherence to Platform Processes
      </h3>
      <h4 className="text-lg font-semibold mb-4">
        a. Compliance with Fundrev's Platform Procedures
      </h4>
      <p className="mb-4 text-[14px]">
        The Fundraiser agrees to strictly adhere to all the processes,
        procedures, guidelines, and protocols set forth by Fundrev's platform,
        accessible at www.fundrev.ai (the “Platform”). These processes may
        include, but are not limited to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>Steps for creating and submitting fundraising campaigns,</li>
        <li>Communicating with potential investors,</li>
        <li>Managing disclosures, and</li>
        <li>Complying with the requirements as stipulated by the Platform.</li>
      </ul>

      <h4
        ref={termsRefs.mandatoryDisclosure}
        className="text-lg font-semibold mb-4"
      >
        b. Mandatory Disclosure of Steps
      </h4>
      <p className="mb-4 text-[14px]">
        The Fundraiser shall accurately and fully disclose to Fundrev all
        actions, steps, communications, and decisions taken by the Fundraiser
        related to any fundraising activities facilitated through the Platform,
        from inception to culmination. This includes, but is not limited to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>
          Initial submission and setup of fundraising campaigns on the Platform,
        </li>
        <li>
          Ongoing updates, modifications, and communications regarding the
          status of the fundraising efforts,
        </li>
        <li>
          All interactions with potential investors, including meetings,
          pitches, and negotiations, and
        </li>
        <li>
          The final outcome of the fundraising efforts, whether successful or
          unsuccessful, including the details of funds raised, the terms agreed
          upon with investors, and any post-investment obligations.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">4.3 Record Keeping</h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser agrees to maintain comprehensive records of all
        activities and communications related to the fundraising process and to
        make such records available to Fundrev upon request. This obligation
        includes the submission of detailed reports as required by Fundrev to
        monitor compliance and ensure transparency in the use of the Platform.
      </p>

      <h4 className="text-lg font-semibold mb-4">a. Breach of Obligations</h4>
      <p className="mb-4 text-[14px]">
        Any failure by the Fundraiser to adhere to the obligations set forth in
        this clause, including failure to disclose required information or
        comply with Platform processes, shall constitute a material breach of
        this Agreement.
      </p>

      <h4 className="text-lg font-semibold mb-4">b. Remedies for Breach</h4>
      <p className="mb-4 text-[14px]">
        In the event of such a breach, Fundrev may, at its sole discretion:
      </p>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>Suspend or terminate the Fundraiser's access to the Platform,</li>
        <li>
          Withhold any funds or payments due to the Fundraiser under this
          Agreement,
        </li>
        <li>
          Seek injunctive relief, specific performance, or other equitable
          remedies as necessary to protect Fundrev's interests, and
        </li>
        <li>
          Pursue monetary damages or indemnification for any losses or damages
          incurred as a result of the breach.
        </li>
      </ul>

      <h4 className="text-lg font-semibold mb-4">c. Termination for Cause</h4>
      <p className="mb-4 text-[14px]">
        Repeated or severe violations of the Platform's processes or the
        obligations under this clause may result in immediate termination of
        this Agreement for cause, without the need for further notice or
        opportunity to cure.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        4.4 Regulatory and Legal Compliance
      </h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser shall comply with all applicable laws, regulations, and
        industry standards in relation to its fundraising activities and the use
        of the Platform.
      </p>

      <h3 className="text-lg font-semibold mb-4">4.5 Cooperation</h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser agrees to cooperate with Fundrev in the performance of
        the Services, including making available appropriate personnel and
        resources as reasonably requested by Fundrev.
      </p>

      <h3 className="text-lg font-semibold mb-4">4.6 Data Security</h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser is responsible for ensuring the security of any data it
        transmits and receives on the Platform. The Fundraiser agrees to comply
        with all applicable data protection laws and regulations.
      </p>
      <h2
        ref={termsRefs.intellectualPropertyRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        5. Intellectual Property Rights
      </h2>

      <h3 className="text-lg font-semibold mb-4">5.1 Ownership</h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Fundrev's IP</strong>: Fundrev shall retain all rights,
        title, and interest in and to its Platform, Services, and any related
        Intellectual Property Rights. Nothing in this Agreement shall be
        construed as transferring any ownership rights to the Fundraiser.
      </p>
      <p className="mb-4 text-[14px]">
        b. <strong>Fundraiser's IP</strong>: The Fundraiser shall retain all
        rights, title, and interest in and to any Intellectual Property Rights
        associated with its own business, products, and services, including any
        proprietary data or materials provided to Fundrev.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        5.2 License to Use Platform
      </h3>
      <p className="mb-4 text-[14px]">
        Fundrev grants the Fundraiser a non-exclusive, non-transferable,
        revocable license to access and use the Platform solely for the purposes
        of this Agreement. The Fundraiser agrees not to copy, modify, or
        reverse-engineer the Platform or any part thereof.
      </p>

      <h3 className="text-lg font-semibold mb-4">5.3 Use of Fundraiser Data</h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser grants Fundrev a non-exclusive, royalty-free license to
        use, reproduce, and modify the Fundraiser Data solely for the purpose of
        performing the Services under this Agreement.
      </p>
      <h2
        ref={termsRefs.confidentialityRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        6. Confidentiality
      </h2>

      <h3 className="text-lg font-semibold mb-4">
        6.1 Use of Confidential Information
      </h3>
      <p className="mb-4 text-[14px]">
        Both parties agree to use the Confidential Information solely for the
        purpose of fulfilling their obligations under this Agreement and for no
        other purpose. Both parties shall not, directly or indirectly, use
        Confidential Information for their own benefit or for the benefit of any
        third party, except for the purposes of aggregating data for insights
        and the formulation of metrics.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        6.2 Protection of Confidential Information
      </h3>
      <p className="mb-4 text-[14px]">
        Both parties shall take all reasonable measures to protect the
        confidentiality of the Confidential Information and shall use the same
        degree of care as they use to protect their own confidential
        information, but in no event less than a reasonable standard of care.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        6.3 Non-Disclosure to Third Parties
      </h3>
      <p className="mb-4 text-[14px]">
        Neither party shall disclose any Confidential Information to any third
        party without the other party's prior written consent, except as
        necessary to fulfill their obligations under the agreement, and only if
        the third party is bound by confidentiality obligations at least as
        protective as those contained herein.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        6.4 Return or Destruction of Confidential Information
      </h3>
      <p className="mb-4 text-[14px]">
        Upon termination or expiration of the agreement, or at any time upon
        either party's request, the other party shall promptly return or destroy
        all Confidential Information in its possession, including any copies,
        extracts, or summaries thereof, and certify in writing to the other
        party that it has done so.
      </p>

      <h3 className="text-lg font-semibold mb-4">6.5 Permitted Disclosures</h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Legal Obligations</strong>: Either party may disclose
        Confidential Information if required to do so by law, regulation, or
        court order, provided that it gives the other party prompt written
        notice of such a requirement and cooperates with the other party in
        seeking a protective order or other appropriate remedy to prevent or
        limit the disclosure.
      </p>
      <p className="mb-4 text-[14px]">
        b. <strong>Disclosure to Employees and Agents</strong>: Either party may
        disclose Confidential Information to its employees, agents, or
        subcontractors who need to know the information to perform their duties
        under the agreement, provided that such individuals are bound by
        confidentiality obligations at least as protective as those contained
        herein.
      </p>

      <h3 className="text-lg font-semibold mb-4">6.6 Remedies for Breach</h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Injunctive Relief</strong>: The Fundraiser acknowledges that
        a breach of this Confidentiality Clause may cause irreparable harm to
        Fundrev, for which monetary damages may be inadequate. Therefore, in the
        event of a breach or threatened breach, Fundrev shall be entitled to
        seek injunctive relief, specific performance, or other equitable
        remedies without the necessity of proving actual damages.
      </p>
      <p className="mb-4 text-[14px]">
        b. <strong>Monetary Damages</strong>: In addition to equitable remedies,
        Fundrev may seek monetary damages from the Fundraiser for any actual
        losses or damages resulting from a breach of this Confidentiality
        Clause, amounting to 3% of the current investment round amount sought to
        be raised from the platform as endorsed by the Fundraiser on the
        Platform.
      </p>

      <h3
        ref={termsRefs.indemnificationRef}
        className="text-lg font-semibold mb-4"
      >
        6.7 Indemnification
      </h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser agrees to indemnify and hold harmless Fundrev, its
        affiliates, and their respective directors, officers, employees, and
        agents from and against any and all losses, liabilities, damages, costs,
        and expenses (including reasonable attorneys' fees) arising out of or in
        connection with any breach of this Confidentiality Clause by the
        Fundraiser or its representatives.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        6.8 Survival of Obligations
      </h3>
      <p className="mb-4 text-[14px]">
        The confidentiality obligations and all related provisions under this
        clause shall survive the termination or expiration of the agreement and
        any other agreements between the Fundraiser and Fundrev for a period of
        five (5) years thereafter.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        6.9 No Limitation on Fundrev's Rights
      </h3>
      <p className="mb-4 text-[14px]">
        Nothing in this Confidentiality Clause shall be construed as limiting or
        negating any rights or remedies that Fundrev may have under applicable
        law or in equity with respect to the protection of its Confidential
        Information.
      </p>
      <h2
        ref={termsRefs.nonSolicitationRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        7. Non-Solicitation Clause
      </h2>

      <h3 className="text-lg font-semibold mb-4">
        7.1 Non-Solicitation of Employees
      </h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Obligations of the Fundraiser</strong>: During the term of
        this Agreement and for a period of two (2) years thereafter, the
        Fundraiser agrees that it shall not, directly or indirectly, solicit,
        recruit, induce, or encourage any employee, consultant, or independent
        contractor of Fundrev, or any of its affiliates, to:
      </p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px] "
        style={{ listStyleType: "lower-roman" }}
      >
        <li>Leave their employment or engagement with Fundrev;</li>
        <li>
          Accept employment or engagement with the Fundraiser or any other
          person or entity, whether as an employee, consultant, independent
          contractor, or in any other capacity;
        </li>
        <li>
          Assist in identifying or recruiting any employee, consultant, or
          independent contractor of Fundrev for the purposes described in
          sub-clauses (a) or (b).
        </li>
      </ul>

      <p className="mb-4 text-[14px]">
        b. <strong>Employee Contact</strong>: The Fundraiser acknowledges and
        agrees that any contact with Fundrev's employees, consultants, or
        independent contractors that is not authorized in writing by Fundrev,
        and that could reasonably lead to the solicitation or recruitment of
        such persons, will be considered a violation of this Non-Solicitation
        Clause.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        7.2 Non-Solicitation of Clients and Business Partners
      </h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Non-Solicitation of Clients</strong>: During the term of this
        Agreement and for a period of two (2) years thereafter, the Fundraiser
        agrees that it shall not, directly or indirectly:
      </p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-roman" }}
      >
        <li>
          Solicit or attempt to solicit any client, including but not limited to
          Investors onboarded on Fundrev's Platform, customer, or business
          partner of Fundrev, or any of its affiliates, for the purpose of
          providing products or services that compete with those offered by
          Fundrev, and/or raising funds or seeking assistance similar to the
          assistance/service provided by Fundrev.
        </li>
        <li>
          Induce or attempt to induce any client, including but not limited to
          Investors onboarded on Fundrev's Platform, customer, or business
          partner of Fundrev to terminate or reduce their business relationship
          with Fundrev.
        </li>
      </ul>

      <p className="mb-4 text-[14px]">
        b. <strong>Protection of Business Relationships</strong>: The Fundraiser
        agrees that it will not take any action that could interfere with the
        business relationships between Fundrev and its clients, including but
        not limited to Investors onboarded on Fundrev's Platform, customers,
        business partners, or affiliates.
      </p>

      <h3 className="text-lg font-semibold mb-4">7.3 Permitted Activities</h3>
      <p className="mb-4 text-[14px]">
        a. <strong>General Advertisements</strong>: Notwithstanding the
        foregoing, the Fundraiser may place general advertisements for
        employment that are not specifically targeted at Fundrev's employees,
        consultants, or independent contractors. Such general advertising shall
        not be considered a breach of this Non-Solicitation Clause.
      </p>
      <p className="mb-4 text-[14px]">
        b. <strong>Prior Relationships</strong>: The Fundraiser may engage in
        business with any client, customer, or business partner of Fundrev if
        the Fundraiser had a bona fide business relationship with such party
        prior to the effective date of this Agreement, provided that such
        relationship is disclosed to Fundrev in writing and does not involve the
        disclosure or use of Confidential Information belonging to Fundrev.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        7.4 Breach of Non-Solicitation
      </h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Notification of Breach</strong>: In the event that the
        Fundraiser breaches any provision of this Non-Solicitation Clause, or
        becomes aware of any such breach by its employees, agents, or
        representatives, the Fundraiser shall immediately notify Fundrev in
        writing, providing full details of the breach.
      </p>
      <p className="mb-4 text-[14px]">
        b. <strong>Consequences of Breach</strong>: Fundrev may, at its sole
        discretion, take the following actions upon any breach of this
        Non-Solicitation Clause by the Fundraiser:
      </p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-roman" }}
      >
        <li>
          Termination of Agreement: Fundrev may terminate this Agreement or any
          related agreements.
        </li>
        <li>
          Liquidated Damages: Fundrev may seek liquidated damages amounting to
          3% of the current investment round amount sought to be raised from the
          platform as endorsed by the Fundraiser on the Platform. This amount
          represents a reasonable estimate of damages caused by a breach.
        </li>
        <li>
          Loss of Privileges: The Fundraiser may lose certain privileges or
          access to Fundrev's services or information as a result of the breach.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        7.5 Remedies for Breach of Non-Solicitation
      </h3>
      <p className="mb-4 text-[14px]">
        a. <strong>Injunctive Relief</strong>: The Fundraiser acknowledges that
        a breach of this Non-Solicitation Clause may cause irreparable harm to
        Fundrev, for which monetary damages may be inadequate. Therefore,
        Fundrev shall be entitled to seek injunctive relief, specific
        performance, or other equitable remedies without the necessity of
        posting a bond or proving actual damages.
      </p>
      <p className="mb-4 text-[14px]">
        b. <strong>Monetary Damages</strong>: In addition to equitable remedies,
        Fundrev may seek monetary damages from the Fundraiser for any actual
        losses or damages resulting from a breach, including costs associated
        with hiring and training replacement personnel, and any lost business
        opportunities amounting to 3% of the current investment round amount
        sought to be raised from the platform as endorsed by the Fundraiser on
        the Platform.
      </p>
      <p className="mb-4 text-[14px]">
        c. <strong>Indemnification</strong>: The Fundraiser agrees to indemnify
        and hold harmless Fundrev, its affiliates, and their respective
        directors, officers, employees, and agents from and against any losses,
        liabilities, damages, costs, and expenses arising out of or in
        connection with any breach of this Non-Solicitation Clause by the
        Fundraiser or its representatives.
      </p>
      <p className="mb-4 text-[14px]">
        d. <strong>Cumulative Remedies</strong>: The rights and remedies
        provided in this clause are cumulative and in addition to any other
        rights and remedies available at law, in equity, or under this
        Agreement. Fundrev's pursuit of any remedy shall not preclude its right
        to pursue any other remedies available under this Agreement or
        applicable law.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        7.6 Survival of Obligations
      </h3>
      <p className="mb-4 text-[14px]">
        The non-solicitation obligations under this clause shall survive the
        termination or expiration of the agreement for the duration specified in
        this Non-Solicitation Clause.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        7.7 No Limitation on Fundrev's Rights
      </h3>
      <p className="mb-4 text-[14px]">
        Nothing in this Non-Solicitation Clause shall be construed as limiting
        or negating any rights or remedies that Fundrev may have under
        applicable law or in equity with respect to the protection of its
        employees, clients, customers, business partners, or Confidential
        Information.
      </p>
      <h2
        ref={termsRefs.warrantiesRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        8. Warranties and Disclaimers
      </h2>

      <h3 className="text-lg font-semibold mb-4">8.1 Mutual Warranties</h3>
      <p className="mb-4 text-[14px]">
        Each Party represents and warrants that:
      </p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          It has the full power and authority to enter into this Agreement and
          to perform its obligations hereunder;
        </li>
        <li>
          This Agreement does not conflict with any other agreement or
          obligation to which it is a party;
        </li>
        <li>
          It shall comply with all applicable laws, rules, and regulations in
          the performance of its obligations under this Agreement.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">8.2 Fundrev Warranties</h3>
      <p className="mb-4 text-[14px]">Fundrev represents and warrants that:</p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          he Services will be performed in a professional and workmanlike manner
          in accordance with industry standards;
        </li>
        <li>
          The Platform will substantially conform to the specifications set
          forth in the Statement of Work (SOW), provided that the Platform is
          used in accordance with this Agreement.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        8.3 Disclaimer of Warranties
      </h3>
      <p className="mb-4 text-[14px]">
        Except as expressly provided in this Agreement, Fundrev makes no other
        warranties, express or implied, including but not limited to warranties
        of merchantability, fitness for a particular purpose, or
        non-infringement. The Platform and Services are provided "as is" and "as
        available" without any warranty of any kind.
      </p>
      <h2
        ref={termsRefs.limitationOfLiabilityRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        9. Limitation of Liability
      </h2>

      <h3 className="text-lg font-semibold mb-4">
        9.1 Exclusion of Consequential Damages
      </h3>
      <p className="mb-4 text-[14px]">
        In no event shall Fundrev be liable to the Fundraiser or any other party
        for any indirect, incidental, special, consequential, or punitive
        damages, including but not limited to lost profits, loss of business
        opportunity, or loss of data, arising out of or in connection with this
        Agreement, whether based on contract, tort (including negligence), or
        any other legal theory, even if the party has been advised of the
        possibility of such damages.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        9.2 Limitation of Direct Damages
      </h3>
      <p className="mb-4 text-[14px]">
        The total aggregate liability of Fundrev arising out of or in connection
        with this Agreement shall not exceed the total amount of fees paid by
        the Fundraiser to Fundrev under this Agreement in the twelve (12) months
        preceding the event giving rise to the claim. This limitation shall
        apply regardless of the number of claims or the form of action, whether
        in contract, tort, or otherwise.
      </p>

      <h3 className="text-lg font-semibold mb-4">9.3 Exceptions</h3>
      <p className="mb-4 text-[14px]">
        The limitations set forth in Sections 9.1 and 9.2 shall not apply to
        damages arising from:
      </p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>Gross negligence or willful misconduct of either Party;</li>
        <li>Breach of confidentiality obligations under Section 6;</li>
        <li>Indemnification obligations under Section 10.</li>
      </ul>
      <h2 id="indemnification" className="text-[18px] mt-4 font-semibold mb-4">
        10. Indemnification
      </h2>

      <h3 className="text-lg font-semibold mb-4">
        10.1 Indemnification by Fundrev
      </h3>
      <p className="mb-4 text-[14px]">
        Fundrev shall indemnify, defend, and hold harmless the Fundraiser, its
        affiliates, and their respective directors, officers, employees, and
        agents from and against any and all claims, losses, liabilities,
        damages, costs, and expenses (including reasonable attorney's fees)
        arising out of or relating to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>
          a. Fundrev's breach of any representation, warranty, or obligation
          under this Agreement;
        </li>
        <li>
          b. Any claim that the Platform or Services infringe upon or violate
          the Intellectual Property Rights of any third party.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        10.2 Indemnification by the Fundraiser
      </h3>
      <p className="mb-4 text-[14px]">
        The Fundraiser shall indemnify, defend, and hold harmless Fundrev, its
        affiliates, and their respective directors, officers, employees, and
        agents from and against any and all claims, losses, liabilities,
        damages, costs, and expenses (including reasonable attorney's fees)
        arising out of or relating to:
      </p>
      <ul className="list-disc pl-6 mb-6 text-[14px]">
        <li>
          a. The Fundraiser's breach of any representation, warranty, or
          obligation under this Agreement;
        </li>
        <li>
          b. Any claim related to the Fundraiser's fundraising activities,
          including claims of misrepresentation or fraud.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        10.3 Indemnification Procedures
      </h3>
      <p className="mb-4 text-[14px]">
        The indemnified Party shall promptly notify the indemnifying Party of
        any claim subject to indemnification. The indemnifying Party shall have
        the right to control the defense and settlement of the claim, provided
        that the indemnified Party may participate in the defense at its own
        expense. The indemnifying Party shall not settle any claim without the
        indemnified Party's prior written consent, which shall not be
        unreasonably withheld.
      </p>
      <h3 className="text-lg font-semibold mb-4">
        10.1 Indemnification by Fundrev
      </h3>
      <p className="mb-4 text-[14px]">
        Both parties shall indemnify, defend, and hold harmless the other party,
        its affiliates, and their respective directors, officers, employees, and
        agents from and against any and all claims, losses, liabilities,
        damages, costs, and expenses (including reasonable attorney's fees)
        arising out of or relating to:
      </p>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-roman" }}
      >
        <li>
          Other party's breach of any representation, warranty, or obligation
          under this Agreement; Any claim that the Platform or Services infringe
          upon or violate the Intellectual Property Rights of any third party
          (in case of Fundrev) or any claim related to the Fundraiser's
          fundraising activities, including claims of misrepresentation or fraud
          (in case of Fundraiser).
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        10.2 Indemnification Procedures
      </h3>
      <p className="mb-4 text-[14px]">
        The indemnified Party shall promptly notify the indemnifying Party of
        any claim subject to indemnification. The indemnifying Party shall have
        the right to control the defence and settlement of the claim, provided
        that the indemnified Party may participate in the defence at its own
        expense. The indemnifying Party shall not settle any claim without the
        indemnified Party's prior written consent, which shall not be
        unreasonably withheld.
      </p>
      <h2
        ref={termsRefs.termAndTerminationRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        11. Term and Termination
      </h2>
      <h3 className="text-lg font-semibold mb-4">11.1 Term</h3>
      <p className="mb-4 text-[14px]">
        This Agreement shall commence on the Effective Date and continue in
        effect until terminated in accordance with the terms of this Agreement.
      </p>

      <h3 className="text-lg font-semibold mb-4">
        11.2 Termination for Convenience
      </h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-roman" }}
      >
        <li>
          <strong>Termination by Fundraiser:</strong> The Fundraiser may
          terminate this Agreement for convenience at any time upon (30 days')
          written notice to Fundrev. In such case, the Fundraiser shall not be
          entitled to any refund of prepaid fees.
        </li>
        <li>
          <strong>Termination by Fundrev:</strong> Fundrev may terminate this
          Agreement for convenience at any time upon 30 days written notice to
          the Fundraiser.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">11.3 Termination for Cause</h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-roman" }}
      >
        <li>
          Materially breaches any term or condition of this Agreement and fails
          to cure such breach within 30 days of receiving written notice of the
          breach;
        </li>
        <li>
          Becomes insolvent/bankrupt, makes a general assignment for the benefit
          of creditors, or becomes subject to any bankruptcy or insolvency
          proceeding.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">11.4 Effect of Termination</h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-roman" }}
      >
        <li>
          <strong>Cessation of Services:</strong> All rights and licenses
          granted to the Fundraiser under this Agreement shall immediately
          cease;
        </li>
        <li>
          <strong>Return of Data:</strong> Both parties shall promptly return or
          destroy all Confidential Information in their possession;
        </li>
        <li>
          <strong>Settlement of Accounts:</strong> The Fundraiser shall pay
          Fundrev for all Services rendered and expenses incurred up to the date
          of termination.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">11.5 Survival</h3>
      <p className="mb-4 text-[14px]">
        The provisions of Sections 3 (Fees and Payment Terms), 5 (Intellectual
        Property Rights), 6 (Confidentiality), 7 (Non-Solicitation), 9
        (Limitation of Liability), 10 (Indemnification), 11 (Term and
        Termination), and any other provisions that by their nature should
        survive termination, shall survive the termination or expiration of this
        Agreement.
      </p>
      <h2
        ref={termsRefs.dataProtectionRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        12. Data Protection and Privacy 
      </h2>
      <h3 className="text-lg font-semibold mb-4">
        12.1 Compliance with Data Protection Laws
      </h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          <strong>Data Protection Obligations:</strong> Fundrev agrees to comply
          with all applicable data protection and privacy laws, including the
          General Data Protection Regulation (GDPR), the Digital Personal Data
          Protection Act, 2023, and any other relevant legislation, in its
          collection, use, and processing of Fundraiser Data.
        </li>
        <li>
          <strong>Data Rights:</strong> The Fundraiser shall have the right to
          access, correct, delete, or restrict the processing of their personal
          data in accordance with applicable data protection laws. Fundrev shall
          provide the Fundraiser with the necessary tools and procedures to
          exercise these rights.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        12.2 Data Processing Agreement
      </h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          <strong>Data Processing Agreement:</strong> If required by applicable
          law, the Parties shall enter into a separate Data Processing Agreement
          (DPA) that governs the processing of personal data in connection with
          the Services. The DPA shall outline the roles and responsibilities of
          each Party with respect to data protection.
        </li>
        <li>
          <strong>Data Controller and Data Processor Roles:</strong> The Parties
          acknowledge that the Fundraiser may be the data controller and Fundrev
          the data processor with respect to certain personal data processed in
          connection with the Services. Fundrev shall process such data only in
          accordance with the Fundraiser's instructions and the terms of the
          DPA.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">12.3 Security Measures</h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          <strong>Data Security:</strong> Fundrev shall implement and maintain
          appropriate technical and organizational measures to protect
          Fundraiser Data against unauthorized access, loss, alteration, or
          destruction. Such measures shall include, but are not limited to,
          encryption, access controls, regular security testing, and incident
          response protocols.
        </li>
        <li>
          <strong>Data Breach Notification:</strong> In the event of a Data
          Breach, Fundrev shall promptly notify the Fundraiser and take all
          necessary steps to mitigate the impact of the breach. Fundrev shall
          cooperate with the Fundraiser in any investigations or reporting
          obligations related to the breach.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mb-4">
        12.4 Data Retention and Deletion
      </h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          <strong>Data Retention:</strong> Fundrev shall retain Fundraiser Data
          only for as long as necessary to fulfill the purposes for which it was
          collected or as required by applicable law. Upon termination of this
          Agreement, Fundrev shall delete or anonymize Fundraiser Data in
          accordance with its data retention policies, unless otherwise required
          by law.
        </li>
        <li>
          <strong>Data Deletion Requests:</strong> The Fundraiser may request
          the deletion of their personal data at any time, and Fundrev shall
          comply with such requests in accordance with applicable data
          protection laws.
        </li>
      </ul>
      <h2
        ref={termsRefs.disputeResolutionRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        13. Dispute Resolution
      </h2>
      <h3 className="text-lg font-semibold mb-4">13.1 Mediation</h3>
      <p className="mb-4 text-[14px]">
        In the event that a dispute arises between the Parties, they shall first
        attempt to resolve it amicably through mediation.
      </p>

      <h3 className="text-lg font-semibold mb-4">13.2 Arbitration</h3>
      <p className="mb-4 text-[14px]">
        If the dispute is not resolved within 30 days after the commencement of
        mediation, the dispute shall be referred to a sole Arbitrator, appointed
        mutually by both the Parties. The Arbitration shall be conducted in
        accordance with the provisions of the Arbitration and Conciliation Act,
        1996, and the decision of the Sole Arbitrator shall be final and binding
        on the Parties. The Arbitration proceedings shall be conducted in the
        English language at Bangalore, India, and the judgment on the award
        rendered by the Sole Arbitrator may be entered in any court having
        jurisdiction thereof.
      </p>
      <h2
        ref={termsRefs.miscellaneousRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        14. Miscellaneous
      </h2>
      <h3 className="text-lg font-semibold mb-4">14.1 Force Majeure</h3>
      <p className="mb-4 text-[14px]">
        Neither Party shall be liable for any failure or delay in performance
        due to causes beyond its reasonable control, including but not limited
        to acts of God, war, terrorism, fire, flood, earthquakes, etc. The
        affected Party shall promptly notify the other Party of the occurrence
        of a force majeure event and shall use reasonable efforts to mitigate
        its effects.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.2 Entire Agreement</h3>
      <p className="mb-4 text-[14px]">
        This Agreement, including any SOWs and amendments hereto, constitutes
        the entire agreement between the Parties with respect to the subject
        matter hereof and supersedes all prior and contemporaneous agreements,
        understandings, and communications, whether written or oral, relating to
        such subject matter.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.3 Amendments</h3>
      <p className="mb-4 text-[14px]">
        This Agreement may only be amended or modified by a written instrument
        signed by both Parties.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.4 Waiver</h3>
      <p className="mb-4 text-[14px]">
        No waiver of any term or condition of this Agreement shall be effective
        unless made in writing and signed by the Party to be charged with the
        waiver. A waiver of any breach of this Agreement shall not constitute a
        waiver of any subsequent breach.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.5 Assignment</h3>
      <p className="mb-4 text-[14px]">
        Neither Party may assign or transfer any of its rights or obligations
        under this Agreement without the prior written consent of the other
        Party, except that Fundrev may assign this Agreement in connection with
        a merger, acquisition, or sale of all or substantially all of its
        assets.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.6 Notices</h3>
      <p className="mb-4 text-[14px]">
        All notices and other communications required or permitted under this
        Agreement shall be in writing and shall be deemed to have been duly
        given if delivered personally, sent by recognized courier, or sent by
        registered post, to the addresses of the Parties set forth in the
        preamble to this Agreement or to such other address as a Party may
        designate by notice in accordance with this Section 14.6.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.7 Severability</h3>
      <p className="mb-4 text-[14px]">
        If any provision of this Agreement is found to be invalid or
        unenforceable, the remaining provisions shall remain in full force and
        effect, and the invalid or unenforceable provision shall be replaced
        with a valid and enforceable provision that most closely approximates
        the intent of the original provision.
      </p>

      <h3 className="text-lg font-semibold mb-4">14.8 Counterparts</h3>
      <p className="mb-4 text-[14px]">
        This Agreement may be executed in counterparts, each of which shall be
        deemed an original, but all of which together shall constitute one and
        the same instrument. Signatures transmitted by facsimile or electronic
        means shall be deemed to be original signatures for all purposes.
      </p>

      <h3 ref={termsRefs.bindingNature} className="text-lg font-semibold mb-4">
        14.9 Binding Nature and Extension of Terms
      </h3>
      <ul
        className="list-disc pl-6 mb-6 text-[14px]"
        style={{ listStyleType: "lower-alpha" }}
      >
        <li>
          <strong>Binding Nature:</strong> The terms and conditions of this
          Agreement are binding upon and shall inure to the benefit of the
          Parties hereto, as well as their respective successors, assigns, and
          any authorized representatives, including, but not limited to, agents,
          employees, officers, directors, and affiliates, whether or not such
          representatives are signatories to this Agreement.
        </li>
        <li>
          <strong>Extension to Authorized Non-Signatories:</strong> The Parties
          expressly agree that the obligations, responsibilities, and rights
          under this Agreement shall extend to and be enforceable against any
          authorized non-signatories, including but not limited to, agents,
          intermediaries, brokers, and representatives acting on behalf of
          either Party.
        </li>
        <li>
          <strong>Responsibility for Authorized Non-Signatories:</strong> Each
          Party shall be fully responsible and liable for any actions,
          omissions, or breaches of this Agreement by its authorized
          non-signatories, agents, representatives, or affiliates, to the same
          extent as if such actions, omissions, or breaches were committed by
          the Party itself.
        </li>
      </ul>
    </div>
  );
};

export default TermsOfUse;
