import React, { useState, useEffect } from "react";
import { formatSize, timeDiff } from "../functions";
import Toast from "../Toast";
import { fetchAllFiles, downloadZip } from "../../../endpoints/dataroom";
import moment from "moment";
import { FileIcon, defaultStyles } from "react-file-icon";

import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "rctx-contextmenu";
import Button from "@mui/joy/Button";

import { MdOutlineFileDownload } from "react-icons/md";
import home from "../../../Assets/Images/InvestorPage/Dashboard/home.svg";
import { useLoader } from "../LoaderProvider";
const Dataroom = ({ deal }) => {
  const loader = useLoader();
  const [activeDataRoomBtn, setActiveDataRoomBtn] = useState(1);
  const [recentlyUploadedFiles, setRecentlyUploadedFiles] = useState([]);
  const [recentlyUpdatedFiles, setRecentlyUpdatedFiles] = useState([]);
  const [activeFiles, setActiveFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [activePath, setActivePath] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [questions, setQuestions] = useState([]);
  const token = localStorage.getItem("token");
  const [fileInfo, setFileInfo] = useState([]);
  const [activeIndices, setActiveIndices] = useState([]);
  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      label: "abc",
      path: "",
    },
  ]);
  const folderClick = (path, index) => {
    if (activePath === path) {
      // This is implemented because if a user click on the same path again then folders and files might disappear from ViewAgenda.
      return;
    }
    const indices = activeIndices;
    indices.push(index + 1);
    setActiveIndices(indices);
    setActiveFiles([]);
    setRecentlyUpdatedFiles([]);
    setRecentlyUploadedFiles([]);
    setAllFiles([]);
    setActivePath(path);
  };
  const BreadCrumbClick = (path, index) => {
    if (activePath === path) {
      // This is implemented because if a user click on the same path again then folders and files might disappear from ViewAgenda.
      return;
    }
    const indices = activeIndices.slice(0, index);
    setActiveIndices(indices);
    setActiveFiles([]);
    setRecentlyUpdatedFiles([]);
    setRecentlyUploadedFiles([]);
    setAllFiles([]);
    setActivePath(path);
  };
  const getFilesAndFolders = (path) => {
    fileInfo.map((file) => {
      if (file.path.startsWith(path)) {
        const remainingPath = file.path.substring(path.length);
        const len = remainingPath.split("/").length;
        if (len === 2) {
          setActiveFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (!fileExists) {
              return [...prev, file];
            }
            return prev;
          });
          setRecentlyUploadedFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (timeDiff(file.createdAt, 168) && !fileExists) {
              return [...prev, file];
            }

            return prev;
          });
          setRecentlyUpdatedFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (timeDiff(file.updatedAt, 168) && !fileExists) {
              return [...prev, file];
            }

            return prev;
          });
        } else {
        }
      }
    });
  };

  const fetchAllFilesAndFolders = async () => {
    try {
      loader.start("Fetching all files and folders...");
      const response = await fetchAllFiles(deal.DealID, token);
      loader.stop();
      if (response.data) {
        setFileInfo(response.data);
      } else {
        Toast("Incorrect details provided", "error", "admindataroom");
      }
    } catch (err) {
      loader.stop();

      Toast("Failed to fetch all files and folders", "error", "admindataroom");
    }
  };
  const handlePathChange = () => {
    const pathnames = activePath.split("/").filter((x) => x);
    pathnames.unshift("home");
    setBreadCrumbs(pathnames);

    const breadcrumbList = pathnames.map((label, index) => {
      const path =
        index === 0 ? "" : `/${pathnames.slice(1, index + 1).join("/")}`;
      return { label, path };
    });

    setBreadCrumbs(breadcrumbList);
  };

  useEffect(() => {
    if (deal.DealID && token) {
      fetchAllFilesAndFolders();
    }
  }, [token, deal.DealID]);
  useEffect(() => {
    if (fileInfo.length > 0) {
      getFilesAndFolders(activePath);
    }
  }, [fileInfo, activePath]);
  useEffect(() => {
    handlePathChange();
  }, [activePath]);
  const downloadAZip = async (path) => {
    try {
      const response = await downloadZip(deal.DealID, path, token);
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element, set its href to the blob URL, and simulate a click
      const a = document.createElement("a");
      a.href = url;
      a.download = `${deal.CompanyName}.zip`; // The filename to save as
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading zip:", error);
      Toast("Failed to download zip", "error", "admindataroom");
    }
  };
  useEffect(() => {
    if (activeDataRoomBtn === 1) setAllFiles(activeFiles);
    else if (activeDataRoomBtn === 2) setAllFiles(recentlyUploadedFiles);
    else if (activeDataRoomBtn === 3) setAllFiles(recentlyUpdatedFiles);
    else setAllFiles(setActiveFiles);
  }, [
    activeDataRoomBtn,
    activeFiles,
    recentlyUpdatedFiles,
    recentlyUploadedFiles,
  ]);
  return (
    <>
      <div className="dataroom-first-div">
        <img src={home} alt="Home" />
        {breadCrumbs.map((breadCrumb, index) => (
          <span key={index}>
            {index !== 0 && <span>&nbsp;&gt;&nbsp;</span>}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                BreadCrumbClick(breadCrumb.path, index);
              }}
            >
              {breadCrumb.label}
            </span>
          </span>
        ))}
      </div>
      <div className="dataroom-btns">
        <div className="dataroom-btns-left">
          <button
            className={`dataroom-btn ${
              activeDataRoomBtn === 1 && "active-dataroom-btn"
            }`}
            onClick={() => setActiveDataRoomBtn(1)}
          >
            All
          </button>
          <button
            className={`dataroom-btn ${
              activeDataRoomBtn === 2 && "active-dataroom-btn"
            }`}
            onClick={() => setActiveDataRoomBtn(2)}
          >
            Recently Uploaded
          </button>
          <button
            className={`dataroom-btn ${
              activeDataRoomBtn === 3 && "active-dataroom-btn"
            }`}
            onClick={() => setActiveDataRoomBtn(3)}
          >
            Recently Updated
          </button>
        </div>

        <div dataroom-btns-right>
          <Button
            sx={{
              backgroundColor: "#0075AF",
              "&:hover": {
                backgroundColor: "#025C89",
              },
            }}
            onClick={() => downloadAZip(activePath)}
            className="download-zip-btn"
          >
            <MdOutlineFileDownload style={{ width: "17px", height: "16px" }} />{" "}
            &nbsp; Download zip
          </Button>
        </div>
      </div>
      <div className="dataroom-btn-div"></div>
      <table className="dataroom-table" style={{ width: "100%" }}>
        <thead className="dataroom-header">
          <tr>
            {/* <th style={{ width: "2%" }} className="top-left-th"></th> */}
            <th style={{ width: "6%" }}>#</th>
            <th style={{ width: "20%" }}>File name</th>
            <th style={{ width: "20%" }}>Size</th>
            <th style={{ width: "20%" }}>Date Uploaded</th>
            <th style={{ width: "20%" }}>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {allFiles &&
            allFiles.map((item, index) => (
              <tr key={index} className="dataroom-row">
                <td>
                  {activeIndices.length === 0
                    ? `${index + 1}`
                    : `${activeIndices.join(".")}.${index + 1}`}
                </td>
                <td>
                  <div className="folder-cell" style={{ cursor: "pointer" }}>
                    {item.isFolder ? (
                      <div>
                        <ContextMenuTrigger id={item.path}>
                          <div
                            className="admin-folder-name"
                            onClick={() => folderClick(item.path, index)}
                          >
                            <div style={{ width: "35px" }}>
                              <FileIcon
                                extension={"Folder"}
                                color="#FF9933"
                                {...defaultStyles[item.path.split(".").pop()]}
                              />
                            </div>
                            <p>{item.path.split("/").slice(-1)[0]}</p>
                          </div>
                        </ContextMenuTrigger>
                        <ContextMenu id={item.path}>
                          <ContextMenuItem
                            onClick={() => folderClick(item.path, index)}
                          >
                            Open
                          </ContextMenuItem>
                          <ContextMenuItem
                            onClick={() => downloadAZip(item.path)}
                          >
                            Download
                          </ContextMenuItem>
                        </ContextMenu>
                      </div>
                    ) : (
                      <div className="admin-folder-name">
                        <div style={{ width: "35px" }}>
                          <FileIcon
                            extension={item.path.split(".").pop()}
                            {...defaultStyles[item.path.split(".").pop()]}
                          />
                        </div>
                        <a href={item.url}>{item.name}</a>
                      </div>
                    )}
                  </div>
                </td>
                <td>{item.isFolder ? "N/A" : formatSize(item.size)}</td>
                <td>
                  {moment(item.createdAt)
                    .tz("Asia/Kolkata")
                    .format("MMM D, YYYY")}
                </td>
                <td>
                  {moment(item.updatedAt)
                    .tz("Asia/Kolkata")
                    .format("MMM D, YYYY")}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Dataroom;
