import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";

import star from "../../../Assets/Images/InvestorPage/Dashboard/star.svg";
import filledStar from "../../../Assets/Images/InvestorPage/Dashboard/filledStar.svg";

import { useNavigate } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";
import "../../../CSS/InvestorPage/Dashboard/card.css";
import { removeStartupFromWishlist } from "../../../endpoints/investor";
import { getDealsByInvestorID } from "../../../endpoints/deal";
import { addDealToWishlist, removeDealFromWishlist } from "../functions";
import ProgressBar from "./molecules/progressBar";

const Card = (props) => {
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const [isStarFilled, setIsStarFilled] = useState(props.isStar);

  useEffect(() => {
    const getInteractedDeals = async () => {
      const deal = props.deal;
      //
      try {
        const response = await getDealsByInvestorID(props.investorId, token);
        //
        const interactedDeals = response.data;
        for (let index = 0; index < interactedDeals.length; index++) {
          const element = interactedDeals[index];
          //
          if (deal.DealID === element.DealID) {
            const startupId = deal.OrganizationID;
            //
            const response = await removeStartupFromWishlist(
              startupId,
              props.investorId,
              token
            );
            //
          }
        }
      } catch (error) {}
    };
    getInteractedDeals();
  }, [isStarFilled]);

  const handleStarClick = async () => {
    setIsStarFilled((prevIsStarFilled) => {
      const newIsStarFilled = !prevIsStarFilled;
      //
      if (newIsStarFilled) {
        addDealToWishlist(props.startupId, props.investorId);
      } else {
        removeDealFromWishlist(props.startupId, props.investorId);
      }
      return newIsStarFilled;
    });
  };
  function navigateToCompanyDetails() {
    if (props.startPoint === "noNameDeals") {
      const label = `No Name Deals Card - ${props.deal.DealID}`;
      ReactGA.event({
        category: "navigation",
        action: "button_click",
        label: label,
      });
      if (token && role === "investor") {
        Navigate("/investor/deal", {
          state: {
            deal: props.deal,
          },
        });
      } else {
        Navigate("/investor/deal/register");
      }
    } else {
      Navigate(`/investor/companyDetails`, {
        state: {
          deal: props.deal,
          investorId: props.investorId,
          passedStage: "initialStage",
          isPassed: props.isPassed,
        },
      });
    }
  }

  //Calculates the no. of tags that can fit in the width
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      props.cardTags.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = props.cardTags.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [props.cardTags]);
  return (
    <>
      <div className="Card-div">
        <div
          className="Card-div-2"
          style={{ display: props.isNew ? "flex" : "none" }}
        >
          New
        </div>
        {props.startPoint !== "noNameDeals" && (
          <img
            src={isStarFilled ? filledStar : star}
            className="card-star"
            alt="star"
            style={{
              cursor: "pointer",
              display: `${props.isPassed && "none"}`,
            }}
            onClick={handleStarClick}
          />
        )}

        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigateToCompanyDetails()}
        >
          <div
            className="Card-div-3"
            style={{
              margin: props.isNew ? "10px 0px 0px 0px" : "0px 25px 0px 0px",
            }}
          >
            {props.cardTitle}
          </div>
          <div className="Card-div-4">{props.cardDescription}</div>
          <div className="Card-div-5" ref={containerRef}>
            {visibleTags.map((tag, index) => (
              <div key={index} className="Card-div-6">
                {tag}
              </div>
            ))}
            {remainingTags > 0 && (
              <div className="Card-div-7 blue-text">+{remainingTags}</div>
            )}
          </div>
          <div className="Card-div-9">
            <div className="Card-div-10">
              <div className="Card-div-11"> ₹{props.revenue}Cr</div>
              <div className="Card-div-12">Revenue</div>
            </div>
            <div style={{ borderRight: "1px solid #E5E5E5" }}></div>
            <div className="Card-div-10">
              <div className="Card-div-11">₹{props.grossProfit}</div>
              <div className="Card-div-12">Gross Profit</div>
            </div>
            <div style={{ borderRight: "1px solid #E5E5E5" }}></div>
            <div className="Card-div-10">
              <div className="Card-div-11">₹{props.EBITDA}</div>
              <div className="Card-div-12">EBITDA</div>
            </div>
            <div style={{ borderRight: "1px solid #E5E5E5" }}></div>
            <div className="Card-div-10">
              <div className="Card-div-11">{props.EBITDA_Percentage}%</div>
              <div className="Card-div-12">EBITDA%</div>
            </div>
          </div>
          <div className="Card-div-22">
            <ProgressBar
              totalFundingRaised={props.totalFundingRaised}
              fundingRequired={props.fundingRequired}
            />
            {/* <div className="Card-div-23">
              <div className="Card-div-24">Current Round</div>
              <div className="Card-div-25">
                <div className="Card-div-26">
                  <div className="Card-div-27">₹{props.IOI_Bids}Cr</div>
                  <div className="Card-div-28">IOI bids</div>
                </div>
                <img loading="lazy" src={greenLine} className="Card-img" />
                <div className="Card-div-29">
                  <div className="Card-div-30">{props.numInvestors}</div>
                  <div className="Card-div-31">
                    Investors <br />
                    interested
                  </div>
                </div>
              </div>
            </div>
            <CircularProgressbarWithChildren
              value={(
                (props.totalFundingRaised / props.fundingRequired) *
                100
              ).toFixed(2)}
              circleRatio={0.75}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 8,
                trailColor: "#eee",
                pathColor: "#00a6fb",
              })}
              className="circular-progress-bar"
            >
              <div
                className="progress-bar-div"
                style={{
                  position: "relative",
                  top: -2,
                }}
              >
                <div className="progress-bar-div-2">
                  ₹{props.totalFundingRaised}Cr
                </div>
                <div className="progress-bar-div-3">
                  {(
                    (props.totalFundingRaised / props.fundingRequired) *
                    100
                  ).toFixed(2)}
                  % Raised
                </div>
              </div>
              <p className="progress-bar-div-4">₹{props.fundingRequired}Cr</p>
              <p className="progress-bar-div-5">Round size</p>
            </CircularProgressbarWithChildren> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
