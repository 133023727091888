import { useState, createContext, useContext, useEffect } from "react";
import Loader from "./Loader";

const LoaderContext = createContext();

export const useLoader = () => {
  return useContext(LoaderContext);
};

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Loading...");

  const start = (msg = "Loading...") => {
    setMessage(msg);
    setLoading(true);
  };

  const stop = () => {
    setTimeout(() => setLoading(false), 500);
  };
  useEffect(() => {}, [loading]);
  return (
    <LoaderContext.Provider value={{ start, stop }}>
      {children}
      {loading && <Loader message={message} />}
    </LoaderContext.Provider>
  );
};
