import React, { useState, useEffect, useRef } from "react";
import {
  useZoomEffect,
  validateFolderName,
  formatSize,
} from "../../../Common/functions";
import home from "../../../../Assets/Images/InvestorPage/Dashboard/home.svg";
import Sidebar from "./Sidebar";
import Topbar from "./topbar";
import {
  fetchAllFiles,
  createFolder,
  UploadFile,
  renameFolder,
  deleteFolder,
  deleteFile,
  downloadZip,
} from "../../../../endpoints/dataroom";
import Toast from "../../../Common/Toast";
import DataroomAddBtn from "../../../Founders/Dashboard/MyProfile/dataroomAddBtn";

import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { Input, Box } from "@mui/joy";
import { ToastContainer } from "react-toastify";
import moment from "moment-timezone";
import { FileIcon, defaultStyles } from "react-file-icon";
import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "rctx-contextmenu";
import Button from "@mui/joy/Button";
import SvgIcon from "@mui/joy/SvgIcon";
import { styled } from "@mui/joy";

import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import Bin from "../../../../Assets/Images/signup/bin.svg";
import loading from "../../../../Assets/Images/FounderPage/Dashboard/loading.gif";

const DataroomUpload = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { dealID, fundrevAnalystID, startupID } = location.state || {};
  const token = localStorage.getItem("token");
  const [fileInfo, setFileInfo] = useState([]);
  const [activePath, setActivePath] = useState("");
  const [activeFiles, setActiveFiles] = useState([]);
  const [newFolderName, setNewFolderName] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [activeIndices, setActiveIndices] = useState([]);
  const [renameModal, setRenameModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [renamePath, setRenamePath] = useState("");
  const [addFolderModal, setAddFolderModal] = useState(false);
  const [isaddDocument, setIsaddDocument] = useState(false);
  const [file, setFile] = useState("");
  const handleClose = () => setAddFolderModal(false);
  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      label: "Home",
      path: "",
    },
  ]);
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    } else {
      setFile("");
    }
  };
  const getFilesAndFolders = (path) => {
    fileInfo.map((file) => {
      if (file.path.startsWith(path)) {
        const remainingPath = file.path.substring(path.length);
        const len = remainingPath.split("/").length;
        if (len === 2) {
          setActiveFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (!fileExists) {
              return [...prev, file];
            }
            return prev;
          });
        } else {
        }
      }
    });
  };
  const handleFileUpload = async () => {
    setUploading(true);
    if (!file) {
      Toast("No files selected", "err", "admindataroom");
      return;
    }
    const size = file.size;
    const formDataInput = new FormData();
    formDataInput.append("file", file);
    formDataInput.append("dealID", dealID);
    formDataInput.append("size", size);
    formDataInput.append("path", activePath);
    formDataInput.append("uploadedBy", fundrevAnalystID);
    try {
      const response = await UploadFile(formDataInput, token);
      if (response.status === 200) {
        setUploading(false);
        Toast("File uploaded successfully", "success", "admindataroom");
        fetchAllFilesAndFolders();
        setFile("");
        setAddFolderModal(false);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      Toast("Error while uploading file", "error", "admindataroom");
    }
  };
  const renameAFolder = async (path, newName) => {
    try {
      const response = await renameFolder(dealID, path, newName, token);
      if (response.data.message === "Folder Renamed") {
        setActiveFiles([]);
        fetchAllFilesAndFolders();
        setInputValue();
        setRenamePath("");
        setRenameModal(false);
        Toast("File renamed successfully", "success", "admindataroom");
      } else {
        Toast("Failed to rename folder", "error", "admindataroom");
      }
    } catch (error) {
      Toast("Error while renaming folder", "error", "admindataroom");
    }
  };
  const deleteAFolder = async (path) => {
    try {
      const response = await deleteFolder(dealID, path, token);
      if (response.data.message === "Folder deleted") {
        setActiveFiles([]);
        fetchAllFilesAndFolders();
        Toast("Folder deleted successfully", "success", "admindataroom");
      } else {
        Toast("Failed to delete folder", "error", "admindataroom");
      }
    } catch (error) {
      Toast("Error while deleting folder", "error", "admindataroom");
    }
  };
  const deleteAFile = async (path) => {
    try {
      const response = await deleteFile(dealID, path, token);
      if (response.data.message === "File deleted") {
        setActiveFiles([]);
        fetchAllFilesAndFolders();
        Toast("File deleted successfully", "success", "admindataroom");
      } else {
        Toast("Failed to delete folder", "error", "admindataroom");
      }
    } catch (error) {
      console.error(error);
      Toast("Error while deleting file", "error", "admindataroom");
    }
  };
  const folderClick = (path, index) => {
    if (activePath === path) {
      // This is implemented because if a user click on the same path again then folders and files might disappear from ViewAgenda.
      return;
    }
    const indices = activeIndices;
    indices.push(index + 1);
    setActiveIndices(indices);
    setActiveFiles([]);
    setActivePath(path);
  };
  const BreadCrumbClick = (path, index) => {
    if (activePath === path) {
      // This is implemented because if a user click on the same path again then folders and files might disappear from ViewAgenda.
      return;
    }
    const indices = activeIndices.slice(0, index);
    setActiveIndices(indices);
    setActiveFiles([]);
    setActivePath(path);
  };
  const handlePathChange = () => {
    const pathnames = activePath.split("/").filter((x) => x);
    pathnames.unshift("home");
    setBreadCrumbs(pathnames);

    const breadcrumbList = pathnames.map((label, index) => {
      const path =
        index === 0 ? "" : `/${pathnames.slice(1, index + 1).join("/")}`;
      return { label, path };
    });

    setBreadCrumbs(breadcrumbList);
  };
  const createAFolder = async () => {
    const error = validateFolderName(newFolderName);
    if (error) {
      Toast(error, "error", "admindataroom");
      return;
    }
    const str = activePath + "/" + newFolderName;
    try {
      const response = await createFolder(dealID, str, token);
      if (response.data === "Folder Created") {
        Toast("Folder created successfully", "success", "admindataroom");
        setNewFolderName("");
        fetchAllFilesAndFolders();
        getFilesAndFolders(activePath);
        setAddFolderModal(false);
      } else {
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      Toast("Failed to create folder", "error", "admindataroom");
    }
  };
  const fetchAllFilesAndFolders = async () => {
    try {
      const response = await fetchAllFiles(dealID, token);
      if (response.data) {
        setFileInfo(response.data);
      } else {
        Toast("Incorrect details provided", "error", "admindataroom");
      }
    } catch (err) {
      console.error("Error fetching all files and folders:", err);
      Toast("Failed to fetch all files and folders", "error", "admindataroom");
    }
  };
  const downloadAZip = async (path) => {
    try {
      const response = await downloadZip(dealID, path, token);
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element, set its href to the blob URL, and simulate a click
      const a = document.createElement("a");
      a.href = url;
      a.download = "dataroom.zip"; // The filename to save as
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading zip:", error);
      Toast("Failed to download zip", "error", "admindataroom");
    }
  };

  const handleRename = async (path) => {
    setRenamePath(path);
    setRenameModal(true);
  };
  useEffect(() => {
    if (dealID && token) {
      fetchAllFilesAndFolders();
    }
  }, [token, dealID]);
  useEffect(() => {
    if (fileInfo.length > 0) {
      getFilesAndFolders(activePath);
    }
  }, [fileInfo, activePath]);
  useEffect(() => {
    handlePathChange();
  }, [activePath]);
  useZoomEffect();
  return (
    <>
      <div className="InvestorSide">
        <ToastContainer position="top-center" containerId="admindataroom" />
        <Sidebar dealID={dealID} active={"dataRoom"} startupID={startupID} />
        <Topbar title="Dataroom" />
        <Modal
          className="ask-question-modal"
          style={{ padding: "20px" }}
          open={renameModal}
          onClose={() => setRenameModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-box question-box" style={{ width: "500px" }}>
            <div
              className="reason-box-first-div"
              style={{ alignItems: "center" }}
            >
              <p style={{ fontSize: "20px" }}>Rename folder</p>
              <img
                className="reason-box-closeicon"
                src={closeIcon}
                style={{ cursor: "pointer" }}
                onClick={() => setRenameModal(false)}
                alt="close"
              />
            </div>
            <div className="admin-qna-section">
              <Input
                className="admin-rename-input"
                placeholder="Enter new name of folder"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
              />
              <button
                className="admin-dataroom-rename"
                onClick={() => renameAFolder(renamePath, inputValue)}
              >
                <p>Rename</p>
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          open={addFolderModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-box reason-box" style={{ width: "700px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                className="div-4"
                onClick={() => {
                  setIsaddDocument(false);
                  setAddFolderModal(false);
                }}
              >
                <img
                  className="reason-box-closeicon"
                  src={closeIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => setAddFolderModal(false)}
                  alt="close"
                />
              </div>
              <p className="add-folder-text">
                {isaddDocument ? "Add Document" : "Add Folder"}
              </p>
            </div>
            <div className="qna-section" style={{ flexDirection: "column" }}>
              <div className="div-5">
                {file ? (
                  <>
                    <div
                      className="fileUploads"
                      style={{ width: "fit-content", marginTop: "20px" }}
                    >
                      <div className="fileUploads-div-2">
                        <div className="fileUploads-div-3">{file.name}</div>
                      </div>
                      <img
                        cursor="pointer"
                        loading="lazy"
                        src={Bin}
                        className="fileUploads-img"
                        alt="Upload file"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => setFile("")}
                      />
                    </div>
                    {uploading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={loading}
                          alt="loading"
                          style={{ width: "20px" }}
                        />
                        <p>Uploading</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : isaddDocument ? (
                  <Button
                    sx={{ width: "200px", marginTop: "20px" }}
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    color="neutral"
                    startDecorator={
                      <SvgIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>
                      </SvgIcon>
                    }
                  >
                    Upload a file
                    <VisuallyHiddenInput
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </Button>
                ) : (
                  ""
                )}
                {isaddDocument ? (
                  ""
                ) : (
                  <Input
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    style={{ marginTop: "20px", width: "300px" }}
                    placeholder="Enter a folder name"
                  ></Input>
                )}
                <div className="div-6">Directory</div>
                <div className="div-7">
                  <div className="div-8">
                    {activePath === "" ? "/" : activePath}
                  </div>
                </div>
                <div
                  className="div-16"
                  style={{ width: "100%" }}
                  onClick={isaddDocument ? handleFileUpload : createAFolder}
                >
                  {isaddDocument ? "Upload" : "Add Folder"}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        <div className="InvestorSide-box">
          <div className="dataroom-first-div">
            <img src={home} alt="Home" />
            {breadCrumbs.map((breadCrumb, index) => (
              <span key={index}>
                {index !== 0 && <span>&nbsp;&gt;&nbsp;</span>}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    BreadCrumbClick(breadCrumb.path, index);
                  }}
                >
                  {breadCrumb.label}
                </span>
              </span>
            ))}
          </div>
          <div className="dataroom-btn-div">
            <Button
              onClick={() => downloadAZip(activePath)}
              className="download-zip-btn"
            >
              Download zip
            </Button>
            <DataroomAddBtn
              setAddFolderModal={setAddFolderModal}
              setIsaddDocument={setIsaddDocument}
            />
          </div>
          <table className="dataroom-table" style={{ width: "100%" }}>
            <thead className="dataroom-header">
              <tr>
                {/* <th style={{ width: "2%" }} className="top-left-th"></th> */}
                <th style={{ width: "6%" }}>#</th>
                <th style={{ width: "20%" }}>File name</th>
                <th style={{ width: "20%" }}>Size</th>
                <th style={{ width: "20%" }}>Date Uploaded</th>
                <th style={{ width: "20%" }}>Last Updated</th>
                {/* {props.role === "investor" && <th style={{ width: "12%" }} className="top-right-th"></th>} */}
              </tr>
            </thead>
            <tbody>
              {activeFiles &&
                activeFiles.map((item, index) => (
                  <tr key={index} className="dataroom-row">
                    <td>
                      {activeIndices.length === 0
                        ? `${index + 1}`
                        : `${activeIndices.join(".")}.${index + 1}`}
                    </td>
                    <td>
                      <div
                        className="folder-cell"
                        style={{ cursor: "pointer" }}
                      >
                        {item.isFolder ? (
                          <div>
                            <ContextMenuTrigger id={item.path}>
                              <div
                                className="admin-folder-name"
                                onClick={() => folderClick(item.path, index)}
                              >
                                <div style={{ width: "35px" }}>
                                  <FileIcon
                                    extension={"Folder"}
                                    color="#FF9933"
                                    {...defaultStyles[
                                      item.path.split(".").pop()
                                    ]}
                                  />
                                </div>
                                <p>{item.path.split("/").slice(-1)[0]}</p>
                              </div>
                            </ContextMenuTrigger>
                            <ContextMenu id={item.path}>
                              <ContextMenuItem
                                onClick={() => folderClick(item.path, index)}
                              >
                                Open
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => downloadAZip(item.path)}
                              >
                                Download
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => handleRename(item.path)}
                              >
                                Rename
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => deleteAFolder(item.path)}
                              >
                                Delete
                              </ContextMenuItem>
                            </ContextMenu>
                          </div>
                        ) : (
                          <div className="admin-folder-name">
                            <div style={{ width: "35px" }}>
                              <ContextMenuTrigger id={item.path}>
                                <FileIcon
                                  extension={item.path.split(".").pop()}
                                  {...defaultStyles[item.path.split(".").pop()]}
                                />
                              </ContextMenuTrigger>
                              <ContextMenu id={item.path}>
                                <ContextMenuItem>
                                  <a
                                    href={item.url}
                                    style={{
                                      color: "#000",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Download
                                  </a>
                                </ContextMenuItem>
                                <ContextMenuItem
                                  onClick={() => deleteAFile(item.path)}
                                >
                                  Delete
                                </ContextMenuItem>
                              </ContextMenu>
                            </div>
                            <a href={item.url}>{item.name}</a>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{item.isFolder ? "N/A" : formatSize(item.size)}</td>
                    <td>
                      {moment(item.createdAt)
                        .tz("Asia/Kolkata")
                        .format("MMM D, YYYY")}
                    </td>
                    <td>
                      {moment(item.updatedAt)
                        .tz("Asia/Kolkata")
                        .format("MMM D, YYYY")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DataroomUpload;
