import React, { useState } from "react";
import { Helmet } from "react-helmet";
import LeftSection from "../../SignUp/LeftSection";
import Header from "../../LandingPage/header";
import InvestorRegisterBlock from "./investorRegisterBlock";
import { ToastContainer } from "react-toastify";

const InvestorRegister = () => {
  return (
    <>
      <Helmet>
        <title>Fundrev | Investor Register</title>
      </Helmet>
      <div className="signUp-div">
        <ToastContainer position="top-center" containerId="investorRegister" />
        <LeftSection
          heading="Increased Success Rates"
          content="Fundrev's tailored matches increase partnership success compared to traditional methods."
        />
        <InvestorRegisterBlock startPoint="register" />
      </div>
    </>
  );
};

export default InvestorRegister;
