import React, { useEffect, useState } from "react";
import { useZoomEffect } from "../../../Common/functions";
import CompanyOverview from "../../../Common/components/companyOverview";
import NoNamePreview from "../../../Common/components/NoNamePreview";
import { companyState, saveState } from "../companyState";
import Topbar from "../Components/topbar";
import Sidebar from "../Components/sidebar";
import "../../../../CSS/Signup/investorDetails.css";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import { useLoader } from "../../../Common/LoaderProvider";
import CommentBox from "./commentBox";

import { useRecoilValue, useRecoilState } from "recoil";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import commentIcon from "../../../../Assets/Images/FounderPage/commentIcon.svg";
import reqEdit from "../../../../Assets/Images/FounderPage/reqedit.svg";
const MyProfile = () => {
  const [cardType, setCardType] = useState("detailedCard");
  const token = localStorage.getItem("token");
  const [deal, setDeal] = useState();
  const { dealId, numMessages } = useRecoilValue(companyState);
  const [loading, setLoading] = useState(true);
  const loader = useLoader();
  const [state, setState] = useRecoilState(companyState);
  const analystID = "FN123456";

  const getDealDetails = async () => {
    try {
      const response = await getDealDetailsByDealId(dealId, token);
      setDeal(response.data);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (dealId) getDealDetails();
  }, [loading]);
  const handleCardTypeChange = (event, newCardType) => {
    setCardType(newCardType);
  };
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };
  useZoomEffect();
  return (
    <>
      <div className="InvestorSide">
        <Topbar title="My Profile" />
        <Sidebar active="overview" />
        <CommentBox dealId={dealId} token={token} analystID={analystID} />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div
              className="cdetails-first-div"
              style={{ alignItems: "center" }}
            >
              <ToggleButtonGroup
                color="primary"
                value={cardType}
                exclusive
                onChange={handleCardTypeChange}
                aria-label="card-type"
                className="toggle-card-type-btn"
                style={{
                  margin:
                    cardType === "noNameCard" ? "0px 0px 20px 0px" : "0px",
                }}
              >
                <ToggleButton
                  value="detailedCard"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Detailed Card
                </ToggleButton>
                <ToggleButton
                  value="noNameCard"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  No Name Card
                </ToggleButton>
              </ToggleButtonGroup>
              {numMessages > 0 ? (
                <div onClick={handleOpen} className="comments-div">
                  <p>
                    <img src={commentIcon} />
                    &nbsp; {numMessages} comments
                  </p>
                </div>
              ) : (
                <div onClick={handleOpen} className="request-to-edit-btn">
                  <img src={reqEdit} />
                  &nbsp;&nbsp; request to edit
                </div>
              )}
            </div>
            {cardType === "noNameCard" ? (
              <NoNamePreview deal={deal} stage={"companyOnBoarding"} />
            ) : (
              <CompanyOverview deal={deal} reqToEdit={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
