import React, { useState, useEffect } from "react";
import { getFundrevAnalystDetailsById } from "../../../../endpoints/fundrevAnalyst";

import userProfile from "../../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";

const Topbar = ({ title }) => {
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const fundrevAnalystID = "FN123456";

  const fetchAnalystDetails = async () => {
    try {
      // If the user is fundrev analyst then fetch the details of the fundrev analyst
      if (fundrevAnalystID) {
        const response = await getFundrevAnalystDetailsById({
          fundrevAnalystID: fundrevAnalystID,
        });
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
          setName(data.name);
          setProfile(data.profile);
        }
        return response;
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, [fundrevAnalystID]);

  return (
    <div className="topbardiv">
      <div className="topbardiv-2">{title}</div>
      <div className="topbardiv-3">
        <div className="topbar-utilities">
          <div className="investor-profile-div">
            <img
              loading="lazy"
              srcSet={
                profile === undefined || profile === "" ? userProfile : profile
              }
              className="topbarimg-6"
            />
            <div className="topbardiv-9">
              <div className="topbardiv-10">
                <div className="topbardiv-11">{name}</div>
              </div>
              <div className="topbardiv-12">Fundrev Analyst</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
