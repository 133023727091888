import React, { useState } from "react";
import { getAllDeals, createOpenListing } from "../../../endpoints/deal";
import { setToggleApproval } from "../../../endpoints/admin";
import Toast from "../Toast";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const CreateOrAssignDealModal = ({ open, setOpen, id, fetchData }) => {
  const token = localStorage.getItem("token");
  const [deals, setDeals] = useState([]);
  const [showDealIDs, setShowDealIDs] = useState(false);
  const handleYesClick = async () => {
    setShowDealIDs(true);
    getAllListings();
  };
  const handleNoClick = async () => {
    try {
      const dealID = await CreateNewDeal();
      if (!dealID) {
        Toast("Error creating a new deal", "error", "adminPanel");
        return;
      }
      await toggleApproval(dealID);
      setOpen(false);
    } catch (error) {
      Toast("Error creating a new deal", "error", "adminPanel");
    }
  };
  const handleCrossClick = () => {
    setOpen(false);
    setShowDealIDs(false);
  };
  async function getAllListings() {
    try {
      const data = await getAllDeals(token);
      const openListing = data.data.filter((deal) => !deal.OrganizationID);
      setDeals(openListing);
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "adminPanel");
    }
  }
  async function CreateNewDeal() {
    try {
      const response = await createOpenListing(token);
      if (response.response.status === 200) {
        Toast("New Deal created successfully", "success", "adminPanel");
        getAllListings();
        return response.response.data.DealID;
      } else {
        Toast("Error creating new listing", "error", "adminPanel");
        return null;
      }
    } catch (error) {
      console.error("Error creating new Deal:", error);
      Toast("Error creating new Deal", "error", "adminPanel");
      return null;
    }
  }
  const toggleApproval = async (DealID) => {
    try {
      const response = await setToggleApproval(id, DealID, token);
      if (response.status === 200) {
        fetchData();
        setOpen(false);
      } else {
        Toast("Error toggling approval", "error", "adminPanel");
      }
    } catch (error) {
      console.error("Error toggling approval:", error);
      Toast("Error assigning deal", "error", "adminPanel");
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        component: "form",
        sx: {
          width: "700px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle className="dialog-title">Deal Assign</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCrossClick}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="dialogcontent dialogcontent-forget-password">
          {!showDealIDs ? (
            <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
              <p className="dialog-box-enter-email-text">
                How you created a deal for the selected company?
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  className="button proceed-btn cancel-btn"
                  onClick={handleNoClick}
                >
                  No
                </Button>
                <Button className="button proceed-btn" onClick={handleYesClick}>
                  Yes
                </Button>
              </div>
            </DialogContentText>
          ) : (
            <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
              <p className="dialog-box-enter-email-text">
                Choose from the following Deal IDs
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "20px",
                  flexWrap: "wrap",
                }}
              >
                {deals?.map((deal) => (
                  <Button
                    key={deal?.DealID}
                    onClick={() => {
                      toggleApproval(deal?.DealID);
                    }}
                  >
                    {deal?.DealID} - {deal?.CompanyName}
                  </Button>
                ))}
              </div>
            </DialogContentText>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateOrAssignDealModal;
