import React, { useState, useEffect } from "react";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import whiteLogo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import logout from "../../../Assets/Images/signup/logout.svg";
import whitelogout from "../../../Assets/Images/signup/whitelogout.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../../CSS/Header/header.css";
import Toast from "../Toast";
const Header = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const logoutUrl = windowWidth > 991 ? logout : whitelogout;
  const [headerBackground, setHeaderBackground] = useState("transparent");
  const color = props.color;
  const anticolor = color === "white" ? "black" : "white";
  const [linkbg, setLinkbg] = useState(color);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [logo, setLogo] = useState(
    color === "white" && props.type !== "investor" && props.type !== "founder"
      ? whiteLogo
      : Logo
  );
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setHeaderBackground("#fff");
        color === "white" ? setLinkbg(anticolor) : setLinkbg(color);
        color === "white"
          ? setLogo(Logo)
          : setLogo(
              props.type !== "investor" && props.type !== "founder"
                ? whiteLogo
                : Logo
            );
      } else {
        color === "white"
          ? setHeaderBackground("transparent")
          : setHeaderBackground("#fff");
        color === "white"
          ? setLogo(
              props.type !== "investor" && props.type !== "founder"
                ? whiteLogo
                : Logo
            )
          : setLogo(Logo);
        setLinkbg(color);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    if (props.data) handleSave(props.data);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("isOpen");

    navigate("/signin", {
      state: {
        type: props.type,
      },
    });
  };

  const handleSave = async (currentStepData, currentStep) => {
    try {
      await props.postData(currentStepData); // Assuming postFormData is properly handling API requests
      // setOpenSaveModal(true);
    } catch (error) {
      console.error("Failed to save data:", error);
      Toast("Failed to save data, please try again.", "error"); // User-friendly error message
    }
  };
  return (
    <section className="Header">
      <div className="landing-header" style={{ backgroundColor: "#1a3847" }}>
        <div>
          <Link to="/">
            {" "}
            <img className="company-logo" src={whiteLogo} />
          </Link>
        </div>
        <div className="cm-header-wrap" style={{ backgroundColor: "#1a3847" }}>
          <div className="cm-menu-wrap">
            <div
              className="cm-menu-btn fa fa-align-right"
              style={{ color: "#FFF" }}
            ></div>
            <div
              className="cm-menu-inner"
              style={{ backgroundColor: "#1a3847" }}
            >
              <ul className="menu-ul clear-all">
                <div
                  className="group"
                  onClick={handleLogout}
                  style={{
                    cursor: "pointer",
                    top: "-12px",
                    backgroundColor: "#193946",
                  }}
                >
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="text-wrapper-4">Logout</div>
                      <img
                        className="material-symbols"
                        alt="Material symbols"
                        src={logoutUrl}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
