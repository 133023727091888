import React, { useEffect } from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { Table } from "react-bootstrap";

const Container = styled(Table)`
  width: fit-content;
`;
const Header = styled.thead.attrs({
  className: "editable-header",
})`
  background: #e1e1e1 !important;
`;

const Body = styled.tbody`
  td {
    border: none !important;
  }
  tr {
    background: #ffffff; /* Other rows background */
  }
`;

export const Row = styled.tr.attrs({
  className: "editable-row",
})`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  padding: 0 10px;
  align-items: baseline;
  border-bottom: 1px solid #7a7a7a;
`;

export const Cell = styled.td.attrs({
  className: "editable-cell",
})`
overflow-wrap: break-word;
width: ${(props) => (props.width ? `${props.width}px` : "100%")};
font-size:16px,
font-family:'Karla',
color:'black'
`;

export function Editable({
  columns,
  data,
  updateData,
  onValidate,
  onDeleteRecipient,
  emptyContent = null,
  className,
  rowClassNameSupplier,
  SubRow,
  isCompany = true,
}) {
  const { headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    updateData,
    onValidate,
    onDeleteRecipient,
  });
  useEffect(() => {
    const placeholders = ["Fund I", "2020", "3", "100", "200"];
    const placeholders2 = ["12 Jan 2024", "Seed", "1", "10", "10"];

    const inputs = document.querySelectorAll(".table-details input");
    // Iterate over the NodeList of inputs and perform actions
    inputs.forEach((input) => {
      // Example: Log the value of each input
    });

    const data = !isCompany ? placeholders : placeholders2;
    inputs.forEach((input, index) => {
      if (data[index]) {
        input.placeholder = data[index];
      }
    });
  }, []);
  return (
    <Container className={className}>
      <Header>
        <Row>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) => (
              <Cell
                className="font-medium"
                key={column.id}
                width={column.width}
              >
                {column.render("Header")}
              </Cell>
            ))
          )}
        </Row>
      </Header>
      <Body>
        {rows.length === 0 ? (
          <Row>
            <Cell>{emptyContent}</Cell>
          </Row>
        ) : (
          rows.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={`fragment-${row.getRowProps().key}`}>
                <Row
                  {...row.getRowProps()}
                  className={
                    rowClassNameSupplier ? rowClassNameSupplier(row, rows) : ""
                  }
                >
                  {row.cells.map((cell) => (
                    <Cell {...cell.getCellProps()} width={cell.column.width}>
                      {cell.render("Cell")}
                    </Cell>
                  ))}
                </Row>
                {SubRow && <SubRow row={row} rows={rows} />}
              </React.Fragment>
            );
          })
        )}
      </Body>
    </Container>
  );
}

export default Editable;
