import React from 'react'
import Sidebar from './Components/sidebar'
import Topbar from './Components/topbar'
import { useZoomEffect } from '../../Common/functions'

import { Helmet } from 'react-helmet'

import staytuned from '../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg'
const OngoingDeals = () => {
  useZoomEffect()
  return (
    <div className='InvestorSide'>
      <Helmet>
        <title>Investor | Monitoring</title>
      </Helmet>
      <Sidebar active="monitoring"/>
      <Topbar  title= "Monitoring" />
      <div className='InvestorSide-box stay-tuned'>
        <div className='InvestorSide-content'>
          <p className='stay-tuned-text'>Stay Tuned <span className='blue-text'>:)</span></p>
          <p className='under-construnction-text'>We are under construction...</p>
          <div className='img-box'>
              <img src={staytuned}></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OngoingDeals
