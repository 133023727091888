import React, { useState, useRef } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MSAAgreement from "./MSAAgreement";
import TermsOfUse from "./TermsOfUse";
const TermsAndConditions = () => {
  const [cardType, setCardType] = useState("MSA");
  const handleCardTypeChange = (event, newCardType) => {
    setCardType(newCardType);
  };
  const termsRefs = {
    agreementRef: useRef(null),
    definitionsRef: useRef(null),
    scopeOfServicesRef: useRef(null),
    transactionFeeRef: useRef(null),
    fundraiserResponsibilitiesRef: useRef(null),
    intellectualPropertyRef: useRef(null),
    confidentialityRef: useRef(null),
    nonSolicitationRef: useRef(null),
    warrantiesRef: useRef(null),
    limitationOfLiabilityRef: useRef(null),
    indemnificationRef: useRef(null),
    termAndTerminationRef: useRef(null),
    disputeResolutionRef: useRef(null),
    dataProtectionRef: useRef(null),
    miscellaneousRef: useRef(null),
    saleOfCompany: useRef(null),
    mandatoryDisclosure: useRef(null),
    bindingNature: useRef(null),
  };
  return (
    <section className="companies-TermsAndUse">
      <ToggleButtonGroup
        color="primary"
        value={cardType}
        exclusive
        onChange={handleCardTypeChange}
        aria-label="card-type"
        className="toggle-card-type-btn"
        style={{ margin: "10px" }}
      >
        <ToggleButton
          value="MSA"
          sx={{
            textTransform: "capitalize",
          }}
        >
          Master Service Agreement
        </ToggleButton>
        <ToggleButton
          value="terms"
          sx={{
            textTransform: "capitalize",
          }}
        >
          Terms Of Use
        </ToggleButton>
      </ToggleButtonGroup>
      {cardType === "MSA" ? (
        <MSAAgreement setCardType={setCardType} termsRefs={termsRefs} />
      ) : (
        <TermsOfUse termsRefs={termsRefs} />
      )}
    </section>
  );
};

export default TermsAndConditions;
