import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const RedirectToOnboarding = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const startupId = queryParams.get("startupId");
    if (token && startupId) {
      Navigate("/company/profilePreview", {
        state: {
          token: token,
          startupID: startupId
        },
      });
    }
    else{
      Navigate("/company/notFound");
    }
  }, [location]);
  return <div>Redirecting...</div>;
};

export default RedirectToOnboarding;
