import { useState, useEffect } from "react";
import Icon from "react-icons-kit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import { FormControl, FormLabel, Input } from "@mui/joy";

import "../../CSS/SignIn/passwordValidator.css";
const PasswordValidator = ({ updatePassword, sendStatesToParent, page }) => {
  const [type, setType] = useState("password");

  // validated states
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
    updatePassword(value);
  };
  const sendStates = () => {
    sendStatesToParent({
      lowerValidated,
      upperValidated,
      numberValidated,
      specialValidated,
      lengthValidated,
    });
  };
  useEffect(() => {
    sendStates();
  }, [
    lowerValidated,
    upperValidated,
    numberValidated,
    specialValidated,
    lengthValidated,
  ]);
  return (
    <div className={page === "signin" ? "" : "wrapper"}>
      {/* box containing input field and validation tracker  */}
      <div className="box">
        {/* input */}
        <FormControl
          className="passwordvalidator-input"
          id="Id"
          required
          size="lg"
          color="primary"
        >
          <FormLabel className={page === "signin" ? "password-label" : "label"}>
            {page === "signin" ? "New Password" : "Create Password"}
          </FormLabel>
          <div className={`input-with-icon-div ${isFocused ? "focused" : ""}`}>
            <Input
              className={
                page === "signin" ? "email-address-input" : "input custom-input"
              }
              placeholder="Enter password"
              type={showConfirmPassword ? "text" : "password"}
              color="neutral"
              name="email"
              onFocus={() => setIsFocused(true)} // Set focus state to true when input is focused
              onBlur={() => setIsFocused(false)} // Set focus state to false when input is blurred
              onChange={(e) => handleChange(e.target.value)}
              endDecorator={
                !showConfirmPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setshowConfirmPassword(true)}
                  />
                ) : (
                  <Visibility onClick={() => setshowConfirmPassword(false)} />
                )
              }
            />
            {/* {type==="password"?(
            <span className='icon-span' onClick={()=>setType("text")}>
              <Icon icon={VisibilityOffIcon} size={18}/>
            </span>
          ):(
            <span className='icon-span' onClick={()=>setType("password")}>
              <Icon icon={Visibility} size={18}/>
            </span>
          )} */}
          </div>
        </FormControl>
        {isFocused && (
          <main className="tracker-box">
            <p
              className="heading"
              style={{ fontSize: "16px", fontFamily: "Karla" }}
            >
              Your password must contain:
            </p>
            <div className={lowerValidated ? "validated" : "not-validated"}>
              {lowerValidated ? (
                <span className="list-icon green">
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className="list-icon">
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one lowercase letter
            </div>
            <div className={upperValidated ? "validated" : "not-validated"}>
              {upperValidated ? (
                <span className="list-icon green">
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className="list-icon">
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one uppercase letter
            </div>
            <div className={numberValidated ? "validated" : "not-validated"}>
              {numberValidated ? (
                <span className="list-icon green">
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className="list-icon">
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one number
            </div>
            <div className={specialValidated ? "validated" : "not-validated"}>
              {specialValidated ? (
                <span className="list-icon green">
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className="list-icon">
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one special character
            </div>
            <div className={lengthValidated ? "validated" : "not-validated"}>
              {lengthValidated ? (
                <span className="list-icon green">
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className="list-icon">
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least 8 characters
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default PasswordValidator;
