import React, { useState } from "react";
import DataRoom from "../../../Common/components/dataroom";

import { Helmet } from "react-helmet";

import accessDeniedDataRoom from "../../../../Assets/Images/InvestorPage/Dashboard/accessDeniedDataroom.svg";
const Dataroom = (props) => {
  const companyName = props.deal.CompanyName;
  const [stage, setStage] = useState(props.stage);

  return (
    <>
      <Helmet>
        <title>{`Investor | ${companyName} - dataroom`}</title>
      </Helmet>

      {stage === "ndaSigned" ? (
        <>
          <p className="stay-tuned-text">Access Denied</p>
          <p className="under-construction-text">
            Access to the data room will be granted upon submission of the IOI
            bid.
          </p>
          <div className="img-box">
            <img src={accessDeniedDataRoom} alt="Access Denied" />
          </div>
        </>
      ) : (
        <DataRoom deal={props.deal} />
      )}
    </>
  );
};

export default Dataroom;
