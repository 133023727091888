import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { companyState, saveState, useCompanyLogout } from "../companyState";
import AskQuestionModal from "../../../Common/modals/askQuestionModal";
import DealAnalystDetails from "../../OnboardingPage/dealAnalystDetails";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";
import monitoring from "../../../../Assets/Images/InvestorPage/Dashboard/monitoring.svg";
import opportunities from "../../../../Assets/Images/InvestorPage/Dashboard/opportunities.svg";
import fundrevLogo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import whitelogout from "../../../../Assets/Images/InvestorPage/Dashboard/whitelogout.svg";
import dropdownwhite from "../../../../Assets/Images/InvestorPage/dropdownwhite.svg";
import { VscFeedback } from "react-icons/vsc";
const Sidebar = (active) => {
  const [askQuestionModal, setAskQuestionModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const logout = useCompanyLogout();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { wishListOpen } = useRecoilValue(companyState);
  const [startupId, setStartupId] = useState("");
  const [state, setState] = useRecoilState(companyState);
  useEffect(() => {
    const fetchDetails = async () => {
      let response;
      if (token) {
        response = await fetchStartupMemberDetails(token);
      }

      if (response && response.data !== null) {
        setStartupId(response.data.data.startupId);
      } else {
      }
    };

    fetchDetails();
  }, [token]);
  const ToggleWishList = () => {
    const newState = { ...state, wishListOpen: !wishListOpen };
    setState(newState);
    saveState(newState); // Save to local storage
  };
  useEffect(() => {
    const activeTab = document.getElementById(active.active);

    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  const handleLogout = async () => {
    logout();
    localStorage.removeItem("token");
    localStorage.removeItem("isOpen");
    localStorage.removeItem("role");
    navigate("/signin", {
      state: {
        type: "Investor",
      },
    });
  };
  return (
    <div className="opdiv">
      <AskQuestionModal
        askQuestionModal={askQuestionModal}
        setAskQuestionModal={setAskQuestionModal}
      />
      <img
        loading="lazy"
        src={fundrevLogo}
        className="opimg"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/company/fundraising")}
      />
      <div className="sidebar-content">
        <div className="opdiv-2">
          {/* <Link className="normal-link" to="/company/fundraising">
            <div className="opdiv-3" id="dashboard">
              <img loading="lazy" src={deals} className="opimg-2" />
              <div className="opdiv-4">Dashboard</div>
            </div>
          </Link> */}

          <Link className="normal-link" to="/company/fundraising">
            <div className="opdiv-3" id="fundraising">
              <img loading="lazy" src={monitoring} className="opimg-2" />
              <div className="opdiv-4">Fundraising</div>
            </div>
          </Link>

          <div className="opdiv-3" onClick={ToggleWishList}>
            <img loading="lazy" src={opportunities} className="opimg-2" />
            <div
              className="opdiv-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              My Profile
              <img
                loading="lazy"
                src={dropdownwhite}
                className="opimg-3"
                onClick={ToggleWishList}
                style={{
                  marginLeft: "20px",
                  transform: wishListOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </div>
          </div>

          <Link className="normal-link" to="/company/myprofile">
            <div
              className="opdiv-3"
              id="overview"
              style={{
                display: wishListOpen ? "flex" : "none",
              }}
            >
              <img
                loading="lazy"
                src={opportunities}
                className="opimg-2"
                style={{ visibility: "hidden" }}
              />
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <div className="opdiv-4">Overview</div>
                </li>
              </ul>
            </div>
          </Link>
          <Link className="normal-link" to="/company/dataroom">
            <div
              className="opdiv-3"
              id="dataroom"
              style={{
                display: wishListOpen ? "flex" : "none",
              }}
            >
              <img
                loading="lazy"
                src={opportunities}
                className="opimg-2"
                style={{ visibility: "hidden" }}
              />
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <div className="opdiv-4">Dataroom</div>
                </li>
              </ul>
            </div>
          </Link>
          <Link className="normal-link" to="/company/analytics">
            <div
              className="opdiv-3"
              id="analytics"
              style={{
                display: wishListOpen ? "flex" : "none",
              }}
            >
              <img
                loading="lazy"
                src={opportunities}
                className="opimg-2"
                style={{ visibility: "hidden" }}
              />
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <div className="opdiv-4">Analytics</div>
                </li>
              </ul>
            </div>
          </Link>
          <a
            className="normal-link"
            href="https://fundrev.frill.co/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="opdiv-3" id="ongoingDeals">
              <VscFeedback />
              <div className="opdiv-4">Feature request</div>
            </div>
          </a>

          {/* <div className="opdiv-3">
            <button
              className='opdiv-ask-question-button'
              onClick={() => setAskQuestionModal(true)}
            >
              Ask A Question
            </button>
          </div> */}
        </div>
        <div className="opdiv-16">
          <DealAnalystDetails
            user="company"
            startupID={startupId}
            fundrevAnalystID="FN123456"
          />
          <div className="opdiv-15" onClick={handleLogout}>
            <img loading="lazy" src={whitelogout} className="opimg-8" />
            <div className="opdiv-4">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
