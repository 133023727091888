import { useZoomEffect } from "../../../Common/functions";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getAllInvestors } from "../../../../endpoints/investor";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/topbar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearDealIdIndustryVerticals,
  getInvestorIdIndustryVerticals,
  setDealToInvestor,
} from "../../../../endpoints/admin";
import Toast from "../../../Common/Toast";

const InvestorPannel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [investors, setInvestors] = useState([]);
  const [fitSelections, setFitSelections] = useState({});
  const token = localStorage.getItem("token");
  const { dealID, startupID } = location.state;

  useEffect(() => {
    const fetchAllInvestors = async () => {
      try {
        const response = await getAllInvestors(token);
        setInvestors(response.data);
      } catch (error) {}
    };
    fetchAllInvestors();
  }, []);

  useZoomEffect();
  useEffect(() => {
    const fetchInvestorDeals = async () => {
      try {
        const response = await getInvestorIdIndustryVerticals(dealID, token);
        const fitMap = {};
        response.data.forEach((investor) => {
          fitMap[investor.investorId] = {
            ideal: investor.ideal,
            moderate: investor.moderate,
            nonFit: investor.nonFit,
          };
        });
        setFitSelections(fitMap);
      } catch (error) {}
    };
    fetchInvestorDeals();
  }, []);

  const handleFitChange = async (investorId, fit, onBoardingComplete) => {
    try {
      if (!onBoardingComplete) {
        Toast(
          "OnBoarding for given Investor is not completed",
          "error",
          "investorPanel"
        );
        return;
      }
      const response = await setDealToInvestor(investorId, dealID, fit, token);
      setFitSelections((prevState) => ({
        ...prevState,
        [investorId]: {
          ideal: fit === "ideal",
          moderate: fit === "moderate",
          nonFit: fit === "nonFit",
        },
      }));
      Toast("Fit type set successfully!", "success", "investorPanel");
    } catch (error) {
      Toast("Failed to set fit type.", "error", "investorPanel");
    }
  };

  const handleClearSelection = async (investorId) => {
    try {
      const response = await clearDealIdIndustryVerticals(
        investorId,
        dealID,
        token
      );
      setFitSelections((prevState) => {
        const newState = { ...prevState };
        delete newState[investorId];
        return newState;
      });
      Toast("Fit type cleared successfully!", "success", "investorPanel");
    } catch (error) {
      Toast("Failed to clear fit type.", "error", "investorPanel");
    }
  };

  return (
    <>
      <div className="InvestorSide" style={{ overflowY: "hidden" }}>
        <ToastContainer position="top-center" containerId="investorPanel" />
        <Sidebar active="investorPanel" dealID={dealID} startupID={startupID} />
        <Topbar title="Investor Panel" />
        <div className="InvestorSide-box table-container">
          <table>
            <thead>
              <tr>
                <th>Investor Firm Name</th>
                <th>Website</th>
                <th>Investor ID</th>
                <th>Onboarding Completed</th>
                <th>Preview</th>
                <th>Ideal Fit</th>
                <th>Moderate Fit</th>
                <th>Non Fit</th>
                <th>Clear</th>
              </tr>
            </thead>
            <tbody>
              {investors.map((investor) => (
                <tr key={investor.investorId}>
                  <td>{investor.companyName}</td>
                  <td>
                    <a
                      href={`https:\\${investor.investorWebsite}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {investor.investorWebsite}
                    </a>
                  </td>
                  <td>{investor.investorID}</td>
                  <td>
                    {investor.onBoardingComplete
                      ? "Completed"
                      : "Not Completed"}
                  </td>
                  <td>
                    <button
                      className="toggleStatusbtn"
                      onClick={() =>
                        navigate("/admin/investor/preview", {
                          state: {
                            investor,
                            startupID,
                            dealID,
                            state: "companies",
                          },
                        })
                      }
                    >
                      Preview
                    </button>
                  </td>
                  <td>
                    {/* {fitSelections[investor.investorID]?.ideal ? <span>&#8226;</span> : null} */}
                    <input
                      type="radio"
                      name={`fit-${investor.investorID}`}
                      value="ideal"
                      checked={
                        fitSelections[investor.investorID]?.ideal || false
                      }
                      onChange={() =>
                        handleFitChange(
                          investor.investorID,
                          "ideal",
                          investor.onBoardingComplete
                        )
                      }
                    />
                  </td>
                  <td>
                    {/* {fitSelections[investor.investorID]?.moderate ? <span>&#8226;</span> : null} */}
                    <input
                      type="radio"
                      name={`fit-${investor.investorID}`}
                      value="moderate"
                      checked={
                        fitSelections[investor.investorID]?.moderate || false
                      }
                      onChange={() =>
                        handleFitChange(
                          investor.investorID,
                          "moderate",
                          investor.onBoardingComplete
                        )
                      }
                    />
                  </td>
                  <td>
                    {/* {fitSelections[investor.investorID]?.nonFit ? <span>&#8226;</span> : null} */}
                    <input
                      type="radio"
                      name={`fit-${investor.investorID}`}
                      value="non"
                      checked={
                        fitSelections[investor.investorID]?.nonFit || false
                      }
                      onChange={() =>
                        handleFitChange(
                          investor.investorID,
                          "nonFit",
                          investor.onBoardingComplete
                        )
                      }
                    />
                  </td>

                  <td>
                    <button
                      onClick={() => handleClearSelection(investor.investorID)}
                      className="download_btn"
                    >
                      Clear
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default InvestorPannel;
