import React, { useEffect, useState } from "react";
import CheckboxGroup from "./molecules/checkBoxGroup";
import { Button } from "react-bootstrap";
import { FaFilterCircleXmark } from "react-icons/fa6";

const DealsFilterBox = ({
  deals,
  setFilteredDeals,
  activeBtn,
  filterBoxOpen,
}) => {
  const [display, setDisplay] = useState("flex");
  const [endMarketFocus, setEndMarketFocus] = useState([]);
  const [industryVerticals, setIndustryVerticals] = useState([]);
  const [roundTypes, setRoundTypes] = useState([]);
  const [selectedEndMarketFocus, setSelectedEndMarketFocus] = useState([]);
  const [selectedIndustryVerticals, setSelectedIndustryVerticals] = useState(
    []
  );
  const [selectedRoundTypes, setSelectedRoundTypes] = useState([]);
  function clearAllFilters() {
    setSelectedEndMarketFocus([]);
    setSelectedIndustryVerticals([]);
    setSelectedRoundTypes([]);
  }
  function getAllOptions(deals) {
    updateOptions(
      deals,
      "EndMarketFocus",
      setEndMarketFocus,
      setSelectedEndMarketFocus
    );
    updateOptions(
      deals,
      "IndustryVerticals",
      setIndustryVerticals,
      setSelectedIndustryVerticals
    );
    updateOptions(deals, "RoundType", setRoundTypes, setSelectedRoundTypes);
  }
  useEffect(() => {
    setRoundTypes([]);
    setEndMarketFocus([]);
    setIndustryVerticals([]);
    setSelectedEndMarketFocus([]);
    setSelectedIndustryVerticals([]);
    setSelectedRoundTypes([]);
    getAllOptions(deals);
  }, [activeBtn, deals]);
  function updateOptions(deals, fieldName, setState, setSelectedState) {
    deals &&
      deals.length > 0 &&
      deals.forEach((deal) => {
        try {
          const parsedField = JSON.parse(deal[fieldName]);
          const fieldArray = Array.isArray(parsedField) ? parsedField : [];

          setState((prev) => {
            const updatedArray = [...prev, ...fieldArray];
            return [...new Set(updatedArray)];
          });
          setSelectedState((prev) => {
            const updatedArray = [...prev, ...fieldArray];
            return [...new Set(updatedArray)];
          });
        } catch (error) {
          setState((prev) => {
            const updatedArray = [...prev];
            return [...new Set(updatedArray)];
          });
          setSelectedState((prev) => {
            const updatedArray = [...prev];
            return [...new Set(updatedArray)];
          });
        }
      });
  }
  useEffect(() => {
    const filterDeals = () => {
      const filtered =
        deals &&
        deals.length > 0 &&
        deals.filter((deal) => {
          try {
            const roundType = JSON.parse(deal.RoundType);
            const industryVerticals = JSON.parse(deal.IndustryVerticals);
            const endMarketFocus = JSON.parse(deal.EndMarketFocus);

            const roundTypeMatches = roundType.some(
              (type) =>
                selectedRoundTypes.length === 0 ||
                selectedRoundTypes.includes(type)
            );
            const industryVerticalsMatches = industryVerticals.some(
              (vertical) =>
                selectedIndustryVerticals.length === 0 ||
                selectedIndustryVerticals.includes(vertical)
            );
            const endMarketFocusMatches = endMarketFocus.some(
              (focus) =>
                selectedEndMarketFocus.length === 0 ||
                selectedEndMarketFocus.includes(focus)
            );

            if (
              selectedEndMarketFocus.length === 0 &&
              selectedIndustryVerticals.length === 0 &&
              selectedRoundTypes.length === 0
            ) {
              return false;
            } else if (
              roundTypeMatches &&
              industryVerticalsMatches &&
              endMarketFocusMatches
            ) {
              return true;
            }
            return false;
          } catch (error) {
            console.error("Error parsing deal data:", error);
            return false;
          }
        });
      setFilteredDeals(filtered);
    };

    filterDeals();
  }, [
    deals,
    selectedRoundTypes,
    selectedIndustryVerticals,
    selectedEndMarketFocus,
  ]);
  useEffect(() => {
    setDisplay(filterBoxOpen ? "flex" : "none");
  }, [filterBoxOpen]);
  return (
    <div className="deals-filter-box" style={{ display: display }}>
      <Button className="clear-filters" onClick={clearAllFilters}>
        <FaFilterCircleXmark />
        &nbsp; Clear filters
      </Button>
      <CheckboxGroup
        title="Industry Vertical"
        options={industryVerticals}
        selectedOptions={selectedIndustryVerticals}
        setSelectedOptions={setSelectedIndustryVerticals}
      />
      <div className="deals-filter-box-first-row">
        <CheckboxGroup
          title="End Market Focus"
          options={endMarketFocus}
          selectedOptions={selectedEndMarketFocus}
          setSelectedOptions={setSelectedEndMarketFocus}
          className="noNameDeal-EndMarketFocus"
        />
        <CheckboxGroup
          title="Round Type"
          options={roundTypes}
          selectedOptions={selectedRoundTypes}
          setSelectedOptions={setSelectedRoundTypes}
        />
      </div>
    </div>
  );
};

export default DealsFilterBox;
