import React, { useEffect, useState } from "react";
import { useZoomEffect } from "../../../Common/functions";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import { companyState, saveState } from "../companyState";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import DataRoom from "../../../Common/components/dataroom";
import CommentBox from "../../../Founders/Dashboard/MyProfile/commentBox";

import { Helmet } from "react-helmet";
import { useRecoilState, useRecoilValue } from "recoil";
import { ToastContainer } from "react-toastify";
import commentIcon from "../../../../Assets/Images/FounderPage/commentIcon.svg";
import reqEdit from "../../../../Assets/Images/FounderPage/reqedit.svg";
import { useLoader } from "../../../Common/LoaderProvider";

const Dataroom = (props) => {
  const [state, setState] = useRecoilState(companyState);
  const loader = useLoader();
  const { numMessages, startupMemberId } = useRecoilValue(companyState);
  const [deal, setDeal] = useState({});
  const token = localStorage.getItem("token");
  const { dealId } = useRecoilValue(companyState);
  const companyName = deal?.CompanyName;

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        loader.start("Loading deal details...");
        const response = await getDealDetailsByDealId(dealId, token);
        setDeal(response.data);
        setTimeout(() => {
          loader.stop();
        }, 1000);
      } catch (error) {
        loader.stop();
      }
    };
    fetchDeal();
  }, [dealId, token]);
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };
  useZoomEffect();
  return (
    <>
      <div className="InvestorSide">
        <Sidebar active="dataroom" />
        <ToastContainer position="top-center" containerId="dataroom" />
        <Topbar title="Dataroom" />
        <Helmet>
          <title>{`${companyName} - dataroom`}</title>
        </Helmet>
        <div className="InvestorSide-box" style={{ position: "relative" }}>
          <div
            className="cdetails-first-div"
            style={{ marginRight: "20px", position: "absolute", right: "20px" }}
          >
            <div></div>
            {numMessages > 0 ? (
              <div onClick={handleOpen} className="comments-div">
                <p>
                  <img src={commentIcon} />
                  &nbsp; {numMessages} comments
                </p>
              </div>
            ) : (
              <div onClick={handleOpen} className="request-to-edit-btn">
                <img src={reqEdit} />
                &nbsp;&nbsp; request to edit
              </div>
            )}
          </div>
          <CommentBox
            dealId={deal.DealID}
            token={token}
            memberId={startupMemberId}
          />
          <DataRoom deal={deal} />
        </div>
      </div>
    </>
  );
};

export default Dataroom;
