import { Box, Grid } from "@mui/material";
import React from "react";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import TopCustomerTable from "./AnalyticsPlots/TopCustomerTable";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";

const BusinessAnalytics = ({ data }) => {
  // const smallCardData = [
  //   {
  //     name: "Revenue",
  //     value: data?.financialData?.plotData["ltm"]["Revenue"].slice(-1),
  //     compareValue: data?.financialData?.plotData["ltm"]["Revenue"].slice(
  //       -13,
  //       -12
  //     ),
  //     plotData: data?.financialData?.plotData["m"]["Revenue"].slice(-12),
  //     compareData: data?.financialData?.plotData["m"]["Revenue"].slice(
  //       -24,
  //       -12
  //     ),
  //   },
  //   {
  //     name: "Customer Growth",
  //     value:
  //       data?.customerData["customerGrowth"]["ltm"]["growth"]["ltm"].slice(-1),
  //     compareValue: data?.customerData["customerGrowth"]["ltm"]["growth"][
  //       "ltm"
  //     ].slice(-13, -12),
  //     plotData:
  //       data?.customerData["customerGrowth"]["m"]["growth"]["m"].slice(-12),
  //     compareData: data?.customerData["customerGrowth"]["m"]["growth"][
  //       "m"
  //     ].slice(-24, -12),
  //   },
  //   {
  //     name: "Order Count",
  //     value:
  //       data?.customerData?.plotData["orders"]["ltm"]["orders"]["ltm"].slice(
  //         -1
  //       ),
  //     compareValue: data?.customerData?.plotData["orders"]["ltm"]["orders"][
  //       "ltm"
  //     ].slice(-13, -12),
  //     plotData:
  //       data?.customerData?.plotData["orders"]["m"]["orders"]["m"].slice(-12),
  //     compareData: data?.customerData?.plotData["orders"]["m"]["orders"][
  //       "m"
  //     ].slice(-24, -12),
  //   },
  //   {
  //     name: "Avg. Order Value",
  //     value:
  //       data?.customerData?.plotData["averageRevenue"]["ltm"]["revenue"][
  //         "ltm"
  //       ].slice(-1)[0],
  //     compareValue: data?.customerData?.plotData["averageRevenue"]["ltm"][
  //       "revenue"
  //     ]["ltm"].slice(-13, -12)[0],
  //     plotData:
  //       data?.customerData?.plotData["averageRevenue"]["m"]["revenue"][
  //         "m"
  //       ].slice(-12),
  //     compareData: data?.customerData?.plotData["averageRevenue"]["m"][
  //       "revenue"
  //     ]["m"].slice(-24, -12),
  //   },
  //   {
  //     name: "% Gross Margin",
  //     value: data?.customerData["percentageGM"]["ltm"]["ltm"]
  //       .slice(-1)[0]
  //       .toFixed(2),
  //     plotData: data?.customerData["percentageGM"]["m"]["m"].slice(-12),
  //     compareData: data?.customerData["percentageGM"]["m"]["m"].slice(-24, -12),
  //   },
  // ];
  return (
    <>
      <AnalyticsPageTitle title={`Business Analytics`} />
      <Box
        sx={{
          marginBottom: "100px",
        }}
      >
        <SmallCardGrid data={data.fiveCards} />
        {data.plots.financialPlot.isVisible && (
          <Box
            sx={{
              marginBottom: 3,
              background: "white",
              width: "1151px",
              height: "450px",
              borderRadius: "8px",
              paddingX: "10px",
              paddingTop: "10px",
            }}
          >
            <FinancialPlot data={data?.plots?.financialPlot} />
          </Box>
        )}
        <Box>
          <Grid container spacing={1.5} marginBottom={10}>
            {data.tables.topCustomerTable.isVisible && (
              <Grid item xs="auto" md="auto" lg="auto" key={0}>
                <TopCustomerTable data={data?.tables?.topCustomerTable} />
              </Grid>
            )}
            {data?.plots?.customerPlots &&
              data?.plots?.customerPlots.map(
                (plotData, index) =>
                  plotData &&
                  plotData.isVisible && (
                    <Grid item xs="auto" md="auto" lg="auto" key={index}>
                      <Box
                        sx={{
                          backgroundColor: "#FFFFFF",
                          width: "569px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
                          fontFamily: "Karla, sans-serif",
                          padding: 2,
                          paddingRight: 0,
                          paddingLeft: 2.5,
                        }}
                      >
                        <PlotTemplate data={plotData} />
                      </Box>
                    </Grid>
                  )
              )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default BusinessAnalytics;
