import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import expandIcon from "../../../../Assets/Images/InvestorPage/Dashboard/expandIcon.svg";
import contractIcon from "../../../../Assets/Images/InvestorPage/Dashboard/contractIcon.svg";
import { Close, Done } from "@mui/icons-material";
import "../../../../CSS/FounderPage/Dashboard/ongoingInvestor.css";
import { postStageData } from "../../../../endpoints/deal";
import { truncateText } from "../../../Common/functions";
import PassDealModal from "../../../Common/modals/passDealModal";
import { useLoader } from "../../../Common/LoaderProvider";
import { Box, Modal } from "@mui/material";
import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
// import { ownerWindow } from "@mui/material";

const OngoingInvestorCard = (props) => {
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const loader = useLoader();
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [expand, setExpand] = useState(false);
  const [acceptanceModal, setAcceptanceModal] = useState(false);
  const [formData, setFormData] = useState({
    DealID: props.dealId,
    investorID: props.investor.investorID,
    sentBy: props.owner,
  });
  const [stage, setStage] = useState(props.stage);

  const toggleExpand = (stage, index) => {
    const col = document.getElementById(stage);
    const ele1 = col.getElementsByClassName("group-5")[index];
    const ele2 = col.getElementsByClassName("group-8")[index];
    if (ele1)
      if (ele1.style.display === "none") {
        setExpand(false);
        ele1.style.display = "block";
      } else {
        setExpand(true);
        ele1.style.display = "none";
      }
    if (ele2) {
      if (ele2.style.display === "none") {
        ele2.style.display = "block";
      } else {
        ele2.style.display = "none";
      }
    }
  };

  const handleAccept = async () => {
    let stageAccept;
    if (stage === "requestSent") {
      stageAccept = "requestAccepted";
    } else if (stage === "ioiSubmitted") {
      stageAccept = "ioiAccepted";
    } else if (stage === "loiSubmitted") {
      stageAccept = "loiAccepted";
    }
    const updatedFormData = {
      ...formData,
      IsInterested: true,
      Stage: stageAccept,
    };
    loader.start("Accepting...");
    const response = await postStageData(updatedFormData, token);
    loader.stop();
    if (response.data !== null) {
      setStage(stageAccept);
      props.fetchInvestorsByDealId();
    }
  };

  const handleDecline = async () => {
    const updatedFormData = {
      ...formData,
      IsRejected: true,
      Stage: stage,
    };
    loader.start("Declining...");
    const response = await postStageData(updatedFormData, token);
    loader.stop();
    props.fetchInvestorsByDealId();
    // Navigate("/company/fundraising");
  };
  useEffect(() => {}, [acceptanceModal]);
  useEffect(() => {
    props.fetchInvestorsByDealId();
  }, [openReasonModal]);

  return (
    <>
      <div className="onGoingDealCard">
        <Modal
          open={acceptanceModal}
          onClose={() => setAcceptanceModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="modal-box reason-box"
            style={{ width: "600px", height: "fit-content" }}
          >
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{
                cursor: "pointer",
                position: "fixed",
                right: "20px",
                top: "20px",
              }}
              onClick={() => setAcceptanceModal(false)}
            ></img>
            <div className="text-box">
              <div className="heading">
                <p className="heading">Are you sure?</p>
              </div>
              <div className="subheading">
                <p>
                  You want to proceed with accepting the{" "}
                  {stage === "requestSent" && `request`}
                  {stage === "ioiSubmitted" && `IOI bid`}{" "}
                  {stage === "loiSubmitted" && `LOI agreement`} ?
                </p>
              </div>
            </div>

            <div className="btn-box">
              <button
                className="reason-submit cancel"
                style={{ height: "40px" }}
                onClick={() => setAcceptanceModal(false)}
              >
                Cancel
              </button>
              <button className="reason-submit" onClick={handleAccept}>
                Confirm
              </button>
            </div>
          </Box>
        </Modal>
        <PassDealModal
          openReasonModal={openReasonModal}
          setOpenReasonModal={setOpenReasonModal}
          stage={stage}
          isCompany={true}
          formData={formData}
        />

        <div
          className="div"
          style={{
            cursor: props.isInterested ? "pointer" : "not-allowed",
          }}
        >
          <div
            className={`dead_deal_tag ${
              props.isInterested === false && "passed"
            } ${props.isRejected === true && "rejected"}`}
            style={{ display: props.isInterested ? "none" : "flex" }}
          >
            {props.isInterested === false && "Passed Deal by Investor"}
            {props.isRejected === true && "Declined Offer by Company"}
          </div>
          <div
            // className="div"
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            onClick={() => {
              // props.investor.isOpen &&
              props.isInterested &&
                Navigate(
                  stage === "requestSent" || stage === "requestAccepted"
                    ? "/company/investorDetails"
                    : "/company/investorDetails",
                  {
                    state: {
                      investor: props.investor,
                      dealId: props.dealId,
                      passedStage: stage,
                      formData: formData,
                      sentBy: props.owner,
                      // dealInterest : props.dealInterest
                    },
                  }
                );
            }}
          >
            <div className="group">
              <div className="make-money-online-th">{props.companyName}</div>
              <a
                className="text-wrapper"
                href={"https://" + props.companyWebsite}
                rel="noopener noreferrer"
                target="_blank"
              >
                {props.companyWebsite}
              </a>
              <img
                className="rectangle"
                alt="Rectangle"
                src={props.companyLogoLink}
              />
            </div>

            <div className="group-2">
              <div className="group-3">
                <div className="make-money-online-th-2">Ticket Size</div>
                <div className="make-money-online-th-3">
                  ₹{props.ticketSize[0]}Cr -- ₹{props.ticketSize[1]}Cr
                </div>
              </div>
              {stage === "requestSent" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Receiving Date</div>
                  <div className="make-money-online-th-3">
                    {props.requestedDate}
                  </div>
                </div>
              )}
              {stage === "requestAccepted" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Accepted Date</div>
                  <div className="make-money-online-th-3">
                    {props.acceptedDate}
                  </div>
                </div>
              )}
              {stage === "ndaSigned" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Signing Date</div>
                  <div className="make-money-online-th-3">
                    {props.signingDate}
                  </div>
                </div>
              )}
              {stage === "ioiSubmitted" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Receiving Date</div>
                  <div className="make-money-online-th-3">
                    {props.acceptedDate}
                  </div>
                </div>
              )}
              {stage === "ioiAccepted" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Accepted Date</div>
                  <div className="make-money-online-th-3">
                    {props.acceptedDate}
                  </div>
                </div>
              )}
              {stage === "loiSubmitted" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Receiving Date</div>
                  <div className="make-money-online-th-3">
                    {props.submittedDate}
                  </div>
                </div>
              )}
              {stage === "loiAccepted" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Accepted Date</div>
                  <div className="make-money-online-th-3">
                    {props.acceptedDate}
                  </div>
                </div>
              )}
              {stage === "dealClosed" && (
                <div className="group-4">
                  <div className="make-money-online-th-2">Closing Date</div>
                  <div className="make-money-online-th-3">
                    {props.closingDate}
                  </div>
                </div>
              )}
            </div>

            <div className="group-5">
              <div className="group-6">
                <div className="make-money-online-th-2">Request Sent By</div>
                <div className="make-money-online-th-3">{props.owner}</div>
              </div>
              <div className="group-7">
                <div className="make-money-online-th-2">Location</div>
                <div className="make-money-online-th-3">{props.location}</div>
              </div>
            </div>

            <div className="group-8">
              <div className="group-6">
                <div className="make-money-online-th-2">AUM</div>
                <div className="make-money-online-th-3">₹{props.aum}Cr</div>
              </div>
              <div className="group-7">
                <div className="make-money-online-th-2">Investor Type</div>
                <div className="make-money-online-th-3">
                  {props.investorType}
                </div>
              </div>
            </div>

            <div className="badges">
              <div className="badge-soft-secondary">
                {props.badges.slice(0, 2).map((badge, index) => (
                  <div className="badge-base-wrapper" key={index}>
                    <div className="badge-base">
                      <div className="elements">
                        <div
                          className="text-wrapper-2"
                          style={{ textWrap: "wrap" }}
                        >
                          {badge.length > 40 ? truncateText(badge, 30) : badge}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {props.badges.length > 2 && (
                  <div className="blue-text" style={{ fontSize: "12px" }}>
                    +{props.badges.length - 2}
                  </div>
                )}
              </div>
            </div>
          </div>
          {props.isInterested &&
            (stage === "requestSent" ||
              stage === "ioiSubmitted" ||
              stage === "loiSubmitted") && (
              <div className="button_grp">
                <button
                  className="btn"
                  style={{ backgroundColor: "#D32F2F" }}
                  onClick={() => setOpenReasonModal(true)}
                >
                  <Close
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      marginTop: "3px",
                    }}
                  />
                </button>
                <button
                  className="btn"
                  style={{ backgroundColor: "#00A6FB" }}
                  onClick={() => setAcceptanceModal(true)}
                >
                  <Done
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "3px",
                    }}
                  />
                </button>
              </div>
            )}
        </div>
        <img
          id={props.index}
          className="streamline-interface"
          alt="Streamline interface"
          src={expand ? contractIcon : expandIcon}
          onClick={() => toggleExpand(stage, props.index)}
        />
      </div>
    </>
  );
};

export default OngoingInvestorCard;
