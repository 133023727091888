import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
const SendForReviewModal = ({ open, setOpen, sendEmail }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        component: "form",
        sx: {
          width: "700px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle className="dialog-title">Are you sure</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="dialogcontent dialogcontent-forget-password">
          <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
            <p className="dialog-box-enter-email-text">
              Are you sure you want to send the profile for review? This action
              can't be reversed.
            </p>
            <div className="delete-account-btns">
              <Button
                className="button proceed-btn cancel-btn"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="button proceed-btn"
                onClick={() => sendEmail()}
              >
                Send for Review
              </Button>
            </div>
          </DialogContentText>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SendForReviewModal;
