import React from "react";
import "../../CSS/Signup/frame.css";
import ellipse from '../../Assets/Images/signup/elipse.svg'
const Frame16 = ({props}) => {
  return (
    <>
      <div className="container-1">
        <div className="container-2">
          <div className="frame-wrapper-1">
            <div className="frame-1">
              <div className="typography-1">{props.heading}</div>
              <div className="typography-2">
                {props.content}
              </div>
            </div>

            <div className="frame-2">
              <div className="group-1">
                  <img
                  className="ellipse"
                    alt="Ellipse"
                    src={ellipse}
                  />
              </div>
              <div className="group-2">
                <div className="rectangle-1" />
                <div className="rectangle-2" />
                <div className="rectangle-3" />
              </div>
            </div>
            <div className="frame-3">
              <div className="group-3">
                <div className="overlap-group-1">
                  <div className="rectangle-4" />
                  <div className="cell-3">55%</div>
                </div>
              </div>
              <div className="group-3">
                <div className="overlap-group-2">
                  <div className="rectangle-5" />
                  <div className="cell-2">80%</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-wrapper-2">
            <div className="frame-4">
              <div className="rectangle-6" />
              <div className="rectangle-7" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Frame16;
