import React, { useEffect, useState } from "react";

import Topbar from "./topbar";

import { useZoomEffect } from "../../../Common/functions";

import { ToastContainer } from "react-toastify";

import { useLocation } from "react-router-dom";
import filter from "../../../../Assets/Images/InvestorPage/Dashboard/filter.svg";
import sort from "../../../../Assets/Images/InvestorPage/Dashboard/sort.svg";

import "../../../../CSS/InvestorPage/Dashboard/style.css";
import NoNameCard from "./NoNameCard";
import Sidebar from "./Sidebar";

const OpportunitiesPreview = () => {
  const location = useLocation();
  const { deal, verificationToken, file, dealId } = location.state || {};
  const [activeBtn, setActiveBtn] = useState(1);
  useZoomEffect();
  return (
    <div className="InvestorSide">
      <ToastContainer position="top-center" />
      {/* <Sidebar
        active="opportunities"
        token={verificationToken}
        fileToPreview={file}
        deal={deal}
        dealId={dealId}
      /> */}
      <Topbar title="Company Preview" />
      <ToastContainer position="top-center" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="opportunites-display">
            <NoNameCard
              key={deal.DealID}
              isNew={false}
              cardTitle={deal.Title}
              cardDescription={deal.SubHeading}
              cardTags={[
                deal.RoundType ? deal.RoundType : "-",
                deal.Industry ? deal.Industry : "-",
                deal.EndMarketFocus,
                ...JSON.parse(deal.IndustryVerticals),
              ]}
              revenue={deal.Revenue ? deal.Revenue : 0}
              grossProfit={deal.GrossProfit}
              EBITDA={deal.EBITDA}
              EBITDA_Percentage={deal.EBITDA_Percentage}
              IOI_Bids={deal.IOIBids}
              numInvestors={deal.InvestorsNum}
              fundingRequired={deal.FundingRequired ? deal.FundingRequired : 0}
              totalFundingRaised={deal.TotalFundingRaised}
              dealId={deal.dealID}
              deal={deal}
              token={verificationToken}
              fileToPreview={file}
              //  investorId={details?.investorID}
              //  isStar={
              //    details?.wishlistdeal?.includes(deal.dealID)
              //      ? true
              //      : false
              //  }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesPreview;
