import React, { useEffect, useState, useMemo } from "react";
import Editable from "./editable";
import { cols } from "../functions";
import CardTags from "./molecules/cardTags";
import Finanicals from "../../Investor/Dashboard/Components/finanicals";
import { companyState, saveState } from "../../Founders/Dashboard/companyState";
import { useRecoilState, useRecoilValue } from "recoil";
import CommentBox from "../../Founders/Dashboard/MyProfile/commentBox";
import { getDealTeamDetails } from "../../../endpoints/startup";
import TeamProfile from "../../Investor/Dashboard/companyDetails/teamProfile";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import { fetchMembers } from "../../../endpoints/startup";

import commentIcon from "../../../Assets/Images/FounderPage/commentIcon.svg";
import reqEdit from "../../../Assets/Images/FounderPage/reqedit.svg";
import Person from "../../../Assets/Images/signup/Person.svg";
import { useLoader } from "../LoaderProvider";

const CompanyOverview = ({ startup, deal, reqToEdit, stage }) => {
  const columns = useMemo(() => cols, []);
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(companyState);
  const [analyst, setAnalyst] = useState({});
  const { numMessages, startupMemberId } = useRecoilValue(companyState);
  const [dealId, setDealId] = useState(null);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [teamDetails, setTeamDetails] = useState({});
  const analystID = "FN123456";
  const loader = useLoader();
  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState); // Save to local storage
  };

  const fetchAnalystDetails = async () => {
    try {
      const response = await getFundrevAnalystDetailsById({
        fundrevAnalystID: analystID,
      });
      setAnalyst(response.data.data);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, []);

  const getTeamDetails = async () => {
    try {
      if (!startup?.startupID) return;
      const response = await getDealTeamDetails(startup.startupID, token);
      if (response.data !== null) {
        setTeamDetails(response.data.data);
      }
    } catch (error) {
      loader.stop();
    }
  };
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(deal.OrganizationID, token);
      loader.stop();
      if (response && response.data) {
        const membersData = response.data.data;
        setInvitedMembers(membersData);
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to fetch members:", error);
    }
  };
  useEffect(() => {
    fetchMembersData();
  }, [deal]);
  useEffect(() => {
    getTeamDetails();
  }, [startup]);
  return (
    <>
      <CommentBox
        dealId={deal?.DealID}
        token={token}
        memberId={startupMemberId}
      />
      <div
        className="cdetails-div"
        style={{ margin: stage === "companyReview" && "0px" }}
      >
        <div className="companyReview">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="investorDetails-subheading">Company Overview</p>
            <div
              className="cdetails-first-div"
              style={{
                marginRight: "20px",
                display: reqToEdit ? "flex" : "none",
              }}
            >
              {numMessages > 0 ? (
                <div onClick={handleOpen} className="comments-div">
                  <p>
                    <img src={commentIcon} />
                    &nbsp; {numMessages} comments
                  </p>
                </div>
              ) : (
                <div onClick={handleOpen} className="request-to-edit-btn">
                  <img src={reqEdit} />
                  &nbsp;&nbsp; request to edit
                </div>
              )}
            </div>
          </div>
          <div className="companyReview-box">
            <div className="companyReview-img ">
              <img
                src={deal ? deal.CompanyLogo : ""}
                className="InvestorLogo"
                alt="Upload Image"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <div className="company-info-stats">
                <div className="companyReview-info">
                  <div>
                    <p className="companyReview-companyName">
                      {deal ? deal.CompanyName : ""}
                    </p>
                    <p className="companyReview-comapnyDesc">
                      {deal ? deal.CompanyDescription : ""}
                    </p>
                  </div>
                </div>
                <CardTags deal={deal} page={"detailsPage"} />
                <div className="company-stats">
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {deal ? deal.LegalEntityName : ""}
                    </div>
                    <div className="group-subheading">Legal Entity Name</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {deal ? deal.Headquarters : ""}
                    </div>
                    <div className="group-subheading">Headquarters</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {deal ? deal.FoundedYear : ""}
                    </div>
                    <div className="group-subheading">Founded Year</div>
                  </div>
                  <div className="companyReview-group">
                    <div className="group-heading">
                      {deal ? deal.NumberOfEmployees : ""}
                    </div>
                    <div className="group-subheading">No. of Employees</div>
                  </div>
                  <div
                    className="companyReview-group"
                    style={{ width: "auto" }}
                  >
                    <div className="group-heading">
                      {deal ? deal.CompanyWebsite : ""}
                    </div>
                    <div className="group-subheading">Website</div>
                  </div>
                  <div
                    className="companyReview-group"
                    style={{ width: "auto" }}
                  >
                    <div className="group-heading">
                      {deal && deal.IndustryVerticals
                        ? (() => {
                            try {
                              const industryVerticals = JSON.parse(
                                deal.IndustryVerticals
                              );
                              return Array.isArray(industryVerticals)
                                ? industryVerticals.join(", ")
                                : ""; // Fallback if parsed data is not an array
                            } catch (error) {
                              console.error(
                                "Error parsing IndustryVerticals:",
                                error
                              );
                              return ""; // or some fallback text
                            }
                          })()
                        : ""}
                    </div>

                    <div className="group-subheading">Industry Verticals</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="FoundingTeam">
          <p className="investorDetails-subheading">Founding Team</p>
          <div className="profile-boxes">
            {deal && deal.FoundingTeamDetails
              ? (() => {
                  try {
                    const teamDetails = JSON.parse(deal.FoundingTeamDetails);
                    return Array.isArray(teamDetails)
                      ? teamDetails.map((member, index) => (
                          <TeamProfile
                            member={member}
                            key={member?.name || index}
                          />
                        ))
                      : ""; // Fallback if parsed data is not an array
                  } catch (error) {
                    console.error("Error parsing FoundingTeamDetails:", error);
                    return ""; // or some fallback UI
                  }
                })()
              : ""}
          </div>
        </div>
        <Finanicals deal={deal} />
        <div className="FundingDetails">
          <p className="investorDetails-subheading">Funding Details</p>
          <div className="companyReview-stats laptop-stats">
            <div className="companyReview-group companyReview-group-2">
              <div className="group-heading">
                {deal ? deal.StakeOffered : ""}%
              </div>
              <div className="group-subheading">Stake Offered</div>
            </div>
            <div className="companyReview-group companyReview-group-2">
              <div className="group-heading">
                {deal && deal.RoundType.length > 0
                  ? JSON.parse(deal?.RoundType).join(", ")
                  : null}
              </div>

              <div className="group-subheading">Round Type</div>
            </div>
            <div className="companyReview-group companyReview-group-2">
              <div className="group-heading">
                {deal ? deal.LastRoundType : ""}
              </div>
              <div className="group-subheading">Last Round Type</div>
            </div>
            <div
              className="companyReview-group companyReview-group-2"
              style={{ width: "auto" }}
            >
              <div className="group-heading">
                {deal ? deal.ExistingKeyInvestors : ""}
              </div>
              <div className="group-subheading">Exisiting Key Investors</div>
            </div>
          </div>
          <div className="table-details">
            <Editable
              columns={columns}
              data={
                deal && deal.fundingRounds
                  ? (() => {
                      try {
                        const fundingRounds = JSON.parse(deal.fundingRounds);
                        return Array.isArray(fundingRounds)
                          ? fundingRounds
                          : [];
                      } catch (error) {
                        console.error("Error parsing fundingRounds:", error);
                        return [];
                      }
                    })()
                  : []
              }
              updateData={() => {}}
            />
          </div>
          <div className="cdetails-div-12" style={{ fontSize: "16px" }}>
            Purpose Of Fundraising
          </div>
          <div className="cdetails-div-13">
            <div className="cdetails-div-14">
              <div className="column">
                <div
                  className="cdetails-div-15 key-highlights-col"
                  style={{ marginLeft: "30px" }}
                >
                  {deal && deal.PurposeOfFundraising
                    ? (() => {
                        try {
                          const purposes = JSON.parse(
                            deal.PurposeOfFundraising
                          );
                          return purposes.map((purpose, index) => (
                            <ul key={index} style={{ listStyleType: "disc" }}>
                              <li style={{ fontSize: "16px" }}>{purpose}</li>
                            </ul>
                          ));
                        } catch (error) {
                          console.error("Error parsing purposes:", error);
                          return null; // or some fallback UI
                        }
                      })()
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dealTeam"
          style={{ marginBottom: stage === "companyReview" && "0px" }}
        >
          <p className="investorDetails-subheading">Deal Team</p>
          <div className="team-profiles-box">
            <div className="team-profile-div">
              <img
                loading="lazy"
                src={analyst.profile}
                className="team-profile-img"
                alt="Fundrev Analyst Profile"
              />
              <div className="team-deal-div-2">
                <div className="team-deal-div-3">{analyst.name}</div>
                <div className="team-deal-div-4 blue-text">Fundrev Analyst</div>
                <div className="team-deal-div-5">
                  <span className="deal-team-span">Email Id: &nbsp;</span>
                  <a
                    href={`mailto:${analyst.email}`}
                    style={{ color: "#696F79", textDecoration: "none" }}
                  >
                    {analyst.email}
                  </a>
                </div>
                <div className="team-deal-div-5">
                  <span className="deal-team-span">Contact Info: &nbsp;</span>
                  <a
                    href={`tel:${analyst.phoneNumber}`}
                    style={{ color: "#696F79", textDecoration: "none" }}
                  >
                    {analyst.phoneNumber}
                  </a>
                </div>
              </div>
            </div>
            {invitedMembers.map((member) => (
              <div className="team-profile-div">
                <img
                  loading="lazy"
                  src={member.memberImg ? member.memberImg : Person}
                  className="team-profile-img"
                  alt="team profile"
                />
                <div className="team-deal-div-2">
                  <div className="team-deal-div-3">
                    {member.name ? member.name : "-"}
                  </div>
                  <div className="team-deal-div-4">
                    {member.title ? member.title : "-"}
                  </div>
                  <div className="team-deal-div-5">
                    <span className="deal-team-span">Email Id: &nbsp;</span>
                    <a
                      href={`mailto:${member.email ? member.email : "-"}`}
                      style={{ color: "#696F79", textDecoration: "none" }}
                    >
                      {member.email ? member.email : "-"}
                    </a>
                  </div>

                  <div className="team-deal-div-5">
                    <span className="deal-team-span">Contact Info: &nbsp;</span>
                    <a
                      href={`tel:${
                        member.phoneNumber ? member.phoneNumber : "-"
                      }`}
                      style={{ color: "#696F79", textDecoration: "none" }}
                    >
                      {member.phoneNumber ? member.phoneNumber : "-"}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyOverview;
