import React, { useState ,useEffect} from "react";
import dropdown from "../../../Assets/Images/FounderPage/dropdown.svg";
import dropup from "../../../Assets/Images/FounderPage/dropup.svg";
import cross from "../../../Assets/Images/FounderPage/cross.svg";
import crosssmall from "../../../Assets/Images/FounderPage/crosssmall.svg";
import fundrev from "../../../Assets/Images/FounderPage/fundrev.svg";
import traditionalFunding from "../../../Assets/Images/FounderPage/traditional-funding.svg";
import traditionalFundingSmall from "../../../Assets/Images/FounderPage/traditionalFundingSmall.svg";
import fundrevFunding from "../../../Assets/Images/FounderPage/fundrev-funding.svg";
import fundrevFundingSmall from "../../../Assets/Images/FounderPage/fundrevFundingSmall.svg";

const FoundersPageMid1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const crossUrl = windowWidth > 991 ? cross : crosssmall
  const traditionalFundingUrl = windowWidth > 991 ? traditionalFunding : traditionalFundingSmall
  const fundrevFundingUrl = windowWidth > 991 ? fundrevFunding : fundrevFundingSmall
  const [showDetails, setShowDetails] = useState(false);
  const [showDetailsFirst, setShowDetailsFirst] = useState(false)
  const [showDetailsSecond, setShowDetailsSecond] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="FoundersPageMid1">
      <div className="div">
        <div className="div-2"  id="fundraise">Elevate fundraising with our complete stack</div>
        <div className="div-3">
          Through Fundrev's tailored platform, founders tap into an exclusive
          network of investors. Conversely, navigating the funding terrain
          without Fundrev can prove daunting.
        </div>
        <div className="div-4">
          <div className="div-5">
            <div className="column">
              <div className="div-6">
                <div className="horizontal-row">
                <img loading="lazy" src={crossUrl} className="img" />
                <div className="div-12 see-details-mobile">
                  {showDetails || showDetailsFirst? (
                    <>
                    <div
                        className="div-13"
                        onClick={() => setShowDetailsFirst(!showDetailsFirst)}
                      >
                        See Less
                      </div>
                      <img loading="lazy" src={dropup} className="img-3" onClick={() => setShowDetailsFirst(!showDetailsFirst)} />
                    </>
                  ) : (
                    <>
                      <div
                        className="div-13"
                        onClick={() => setShowDetailsFirst(!showDetailsFirst)}
                      >
                        See Details
                      </div>
                      <img loading="lazy" src={dropdown} className="img-3" onClick={() => setShowDetailsFirst(!showDetailsFirst)} />
                    </>
                  )}
                </div>
                </div>
                
                <div className="div-7">Traditional Funding Methods</div>
                <div className="div-8">
                  Getting through the funding maze without Fundrev can be tough,
                  requiring extensive research, uncertain investor links, and
                  the risk of overlooking opportunities.
                </div>
                {(showDetails || showDetailsFirst) && (
                  <div className="img-div">
                    <img
                      className="founders-img-1"
                      src={traditionalFundingUrl}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="column-2">
              <div className="div-9">
              <div className="horizontal-row">
                <img loading="lazy" src={fundrev} className="img" />
                <div className="div-12 see-details-mobile">
                  {showDetails || showDetailsSecond? (
                    <>
                    <div
                        className="div-13"
                        onClick={() => setShowDetailsSecond(!showDetailsSecond)}
                      >
                        See Less
                      </div>
                      <img loading="lazy" src={dropup} className="img-3" onClick={() => setShowDetailsSecond(!showDetailsSecond)} />
                    </>
                  ) : (
                    <>
                      <div
                        className="div-13"
                        onClick={() => setShowDetailsSecond(!showDetailsSecond)}
                      >
                        See Details
                      </div>
                      <img loading="lazy" src={dropdown} className="img-3" onClick={() => setShowDetailsSecond(!showDetailsSecond)}/>
                    </>
                  )}
                </div>
                </div>
                <div className="div-10">Using FUNDREV</div>
                <div className="div-8">
                  Fundrev's specialized platform offers founders access to a
                  carefully selected network of investors, personalized
                  guidance, and invaluable resources, significantly enhancing
                  their chances of success.{" "}
                </div>
                {(showDetails||showDetailsSecond) && (
                  <div className="img-div">
                    <img 
                    className="founders-img-2"
                    src={fundrevFundingUrl} 
                    alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="div-12">
          {showDetails ? (
            <>
             <div
                className="div-13"
                onClick={() => setShowDetails(!showDetails)}
              >
                See Less
              </div>
              <img loading="lazy" src={dropup} className="img-3" onClick={() => setShowDetails(!showDetails)} />
            </>
          ) : (
            <>
              <div
                className="div-13"
                onClick={() => setShowDetails(!showDetails)}
              >
                See Details
              </div>
              <img loading="lazy" src={dropdown} className="img-3" onClick={() => setShowDetails(!showDetails)} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default FoundersPageMid1;
