import React, { useEffect, useState } from "react";
import { cn } from "../../../lib/utils";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AnimatedShinyText = ({
  children,
  className,
  shimmerWidth = 100,
  type,
}) => {
  return (
    <>
      {type === "elevateExperience" ? (
        <p
          style={{
            "--shimmer-width": `${shimmerWidth}px`,
          }}
          className={cn(
            "mx-auto max-w-md text-neutral-100/50 dark:text-neutral-100/50",

            // Shimmer effect
            `animate-shimmer bg-clip-text bg-no-repeat text-[#FFF] [background-position:0_0] [background-size:var(--shimmer-width)_100%] [transition:background-position_1s_cubic-bezier(.6,.6,0,1)_infinite]`,

            // Shimmer gradient
            "bg-gradient-to-r from-transparent via-black/80 via-50% to-transparent  dark:via-white/80",

            className
          )}
        >
          {children}
        </p>
      ) : (
        <p
          style={{
            "--shimmer-width": `${shimmerWidth}px`,
          }}
          className={cn(
            "mx-auto max-w-md text-[#FFF]",

            // Shimmer effect
            `animate-shimmer bg-clip-text bg-no-repeat text-[#FFF] [background-position:0_0] [background-size:var(--shimmer-width)_100%] [transition:background-position_1s_cubic-bezier(.6,.6,0,1)_infinite]`,

            // Shimmer gradient
            "bg-gradient-to-r from-transparent via-black/80 via-50% to-transparent  dark:via-white/80",

            className
          )}
        >
          {children}
        </p>
      )}
    </>
  );
};

export function AnimatedShinyTextDemo({ bgcolor, type, groupName }) {
  const [background, setBackground] = useState(bgcolor);

  useEffect(() => {
    if (type === "elevateExperience") {
      setBackground("neutral-900");
    }
  }, [type]);

  return (
    <>
      {/* button at elevate experience section */}
      {type === "elevateExperience" ? (
        <div
          className="z-10 flex items-center justify-center laptop-stats"
          style={{
            position: "relative",
            top: "270px",
          }}
        >
          <div
            className={cn(
              "group rounded-full border border-black/5 bg-neutral-100 text-base text-white transition-all ease-in hover:cursor-pointer hover:bg-neutral-200 dark:border-white/5 dark:hover:bg-neutral-800"
            )}
            style={{
              backgroundColor: "#3c5168",
            }}
          >
            <Link to="/signup">
              <AnimatedShinyText
                type={type}
                className="inline-flex items-center justify-center px-4 py-1 transition ease-out  hover:duration-300 hover:dark:text-neutral-400"
              >
                <span>Get Started</span>
                <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
              </AnimatedShinyText>
            </Link>
          </div>
        </div>
      ) : type === "sign-in" ? (
        // signIn button in landing page
        <>
          <div
            className="z-10 flex items-center lg:justify-center justify-start laptop-stats ml-[30px] my-[15px]"
            style={{
              position: undefined,
              top: undefined,
            }}
          >
            <div
              className={cn(
                "animatedBtn group-2 rounded-full border border-black/5",
                "backdrop-blur-sm",
                `text-[#FFF]`,
                "text-[16px] transition-all ease-in hover:cursor-pointer",
                "dark:border-white/5",
                "!h-fit !w-fit"
              )}
            >
              <Link to="/signin">
                <AnimatedShinyText className="inline-flex items-center justify-center px-4 py-1 transition ease-out hover:black hover:duration-300 hover:dark:black">
                  <span
                    className="sign-in-span"
                    style={{
                      color: "#00A6FB",
                    }}
                  >
                    Sign in
                  </span>
                  <FaAngleRight className="sign-in-span ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5 text-[#00A6FB]" />
                </AnimatedShinyText>
              </Link>
            </div>
          </div>
        </>
      ) : (
        // get started button
        <div
          className="z-10 flex items-center lg:justify-center justify-start laptop-stats ml-[30px] my-[15px]"
          style={{
            position: undefined,
            top: undefined,
          }}
        >
          <div
            className={cn(
              "animatedBtn group rounded-full border border-black/5",
              `bg-[#00A6FB]`,
              `text-[#FFF]`,
              "text-[16px] transition-all ease-in hover:cursor-pointer",
              `hover:bg-[#00A6FB]`,
              "dark:border-white/5",
              `dark:hover:bg-[#00A6FB]`,
              "!h-fit !w-fit"
            )}
          >
            <Link to="/signup">
              <AnimatedShinyText className="inline-flex items-center justify-center px-4 py-1 transition ease-out hover:black hover:duration-300 hover:dark:black">
                <span>Get Started</span>
                <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
              </AnimatedShinyText>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default AnimatedShinyTextDemo;
