import React from 'react'
import trendingup from  "../../../Assets/Images/InvestorPage/trendingup.svg"
import star from  "../../../Assets/Images/InvestorPage/star.svg"
import stars from "../../../Assets/Images/InvestorPage/stars.svg"
const InvestorPageMid1 = () => {
  return (
    <section className='InvestorPageMid1'>
      <div className="div-14" id="phase1">Phase 1</div>
        <div className="div-15">
          <div className="div-16">
            <div className="column-3">
              <div className="div-17">
                <img
                  loading="lazy"
                  src={trendingup}
                  className="img-4"
                />
                <div className="div-18">Sourcing</div>
                <div className="div-19">
                Concentrate on investment opportunities that align with your investment criteria.
                </div>
              </div>
            </div>
            <div className="column-4">
              <div className="div-20">
                <img
                  loading="lazy"
                  src={star}
                  className="star"
                />
                <div className="div-21">Due Diligence</div>
                <div className="div-22">
                Enable investments through AI-driven decision-making and standardized performance metrics
                </div>
              </div>
            </div>
            <div className="column-5">
              <div className="div-23">
                <img
                  loading="lazy"
                  src={stars}
                  className="stars"
                />
                <div className="div-24">Deal Close</div>
                <div className="div-25">
                  Close deals faster with hassle free negotiation and deal
                  documentation
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default InvestorPageMid1
