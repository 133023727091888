import axios from "axios";
const token = localStorage.getItem("token");
export const investorSignup = async (finalFormData) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/signup",
      finalFormData
    );

    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getValues = async () => {
  try {
    const response = await fetch("https://api.fundrev.ai/investor/getValues", {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null };
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message };
  }
};

export const fetchInvestorDetails = async (token) => {
  try {
    const response = await fetch("https://api.fundrev.ai/investor/details", {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const PostDetails = async (formDataInput, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/onboarding",
      // "http://localhost:3000/investor/onboarding",
      formDataInput,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 200) {
    }
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};
export const investorAddMember = async (emails, token) => {
  const formData = {
    members: emails.map((email) => ({ email })),
  };
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/addmember",
      // "http://localhost:3000/investor/addMember",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
    }
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Failed to send request", error);
    return { data: null, error: error.message };
  }
};
export const fetchMembers = async (investorID, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/getMembers",
      // "http://localhost:3000/startup/getMembers",
      { investorID },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};
export const memberSignUp = async (memberData) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/memberSignup",
      // "https://api.fundrev.ai/startup/memberSignup",
      memberData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const memberUpdate = async (memberData, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/memberUpdate",
      // "https://api.fundrev.ai/startup/memberSignup",
      memberData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const deleteMember = async (id, investorID, token) => {
  try {
    const response = await axios.post(
      `https://api.fundrev.ai/investor/memberDelete/${id}`,
      // `http://localhost:3000/startup/memberDelete/${id}`,
      { investorID },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const investorMemberLogin = async (signInData) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/memberLogin",
      signInData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const fetchInvestorMemberDetails = async (token) => {
  try {
    const response = await fetch(
      "https://api.fundrev.ai/investor/memberDetails",
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      return { data: null };
    }

    const responseData = await response.json();
    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to fetch user data:", error);
    return { data: null, error: error.message };
  }
};

export const fetchInvestorMemberDetailsbyId = async (data) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/memberDetailsId",
      data,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const addStartuptoWishlist = async (startupId, investorId, token) => {
  const formData = {
    startupId: startupId,
    investorId: investorId,
  };
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/addWishlist",
      // "http://localhost:3000/investor/addWishlist",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    }

    return { data: response, error: null };
  } catch (error) {
    console.error("Failed to save data:", error);
    return { data: null, error: error };
  }
};

export const removeStartupFromWishlist = async (
  startupId,
  investorId,
  token
) => {
  const formData = {
    startupId: startupId,
    investorId: investorId,
  };
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/removeWishlist",
      // "http://localhost:3000/investor/removeWishlist",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    }

    return { data: response, error: null };
  } catch (error) {
    console.error("Failed to save data:", error);
    return { data: null, error: error };
  }
};

export const getWishlist = async (investorId, token) => {
  const formData = {
    investorId: investorId,
  };
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/getWishlist",
      // "http://localhost:3000/investor/getWishlist",
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    }
    const responseData = response.data.data;

    return { data: responseData, error: null };
  } catch (error) {
    console.error("Failed to save data:", error);
    return { data: null, error: error };
  }
};
export const memberResendInvite = async (data, token) => {
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/investor/memberResendInvite",
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getAllInvestors = async (token) => {
  try {
    const response = await fetch(
      "https://api.fundrev.ai/investor/getAllInvestors",
      // "http://localhost:3000/investor/getAllInvestors/",
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null }; // Returning data and null for error
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message }; // Returning null for data and the error message
  }
};

export const getDetailsById = async (investorId, token) => {
  try {
    const response = await fetch(
      `https://api.fundrev.ai/investor/detailsById/${investorId}`,
      // `http://localhost:3000/investor/detailsById/${investorId}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const json = await response.json();
    return { data: json, error: null }; // Returning data and null for error
  } catch (error) {
    console.error("There was a problem with fetching data:", error);
    return { data: null, error: error.message }; // Returning null for data and the error message
  }
};
export const getInvestmentTeamDetails = async (investorID, token) => {
  try {
    const formData = {
      investorID: investorID,
    };
    const response = await axios.post(
      `https://api.fundrev.ai/investor/getInvestmentTeamDetails`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
