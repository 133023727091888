import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Toast = (message, type, containerId) => {
  switch (type) {
    case "success":
      return toast.success(
        <div>
          <p>{message}</p>
        </div>
        ,{
          containerId:containerId
        }
      );
    case "error":
      return toast.error(
        <div>
          <p>{message}</p>
        </div>
        ,{
          containerId:containerId
        }
      );
    case "warning":
      return toast.warning(
        <div>
          <p>{message}</p>
        </div>
        ,{
          containerId:containerId
        }
      );
    case "info":
      return toast.info(
        <div>
          <p>{message}</p>
        </div>
        ,{
          containerId:containerId
        }
      );
    default:
      return toast.warning(
        <div>
          <p>Toast not defined...</p>
        </div>
        ,{
          containerId:containerId
        }
      );
  }
};
export default Toast;