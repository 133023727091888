import React from "react";
import NoDeal from "./NoDeal";
import Card from "../../../Common/components/Card";
import { useZoomEffect } from "../../../Common/functions";

import { Helmet } from "react-helmet";
const PassedDeals = ({ details, passedDeals }) => {
  useZoomEffect();
  return (
    <>
      <Helmet>
        <title>Investor | Passed Deals</title>
      </Helmet>
      {passedDeals.length > 0 ? (
        passedDeals.map((deal) => (
          <Card
            key={deal.OrganizationID}
            isNew={false}
            cardTitle={deal.Title}
            cardDescription={deal.SubHeading}
            cardTags={[
              ...(() => {
                try {
                  const roundType = JSON.parse(deal.RoundType);
                  return Array.isArray(roundType) ? roundType : [];
                } catch (error) {
                  console.error("Error parsing Round Type:", error);
                  return []; // Fallback value
                }
              })(),
              ...(() => {
                try {
                  const industryVerticals = JSON.parse(deal.IndustryVerticals);
                  return Array.isArray(industryVerticals)
                    ? industryVerticals
                    : [];
                } catch (error) {
                  console.error("Error parsing IndustryVerticals:", error);
                  return []; // Fallback value
                }
              })(),
              ...(() => {
                try {
                  const endMarketFocus = JSON.parse(deal.EndMarketFocus);
                  return Array.isArray(endMarketFocus) ? endMarketFocus : ["_"];
                } catch (error) {
                  console.error("Error parsing EndMarketFocus:", error);
                  return ["_"]; // Fallback value
                }
              })(),
            ]}
            revenue={deal.Revenue ? deal.Revenue : 0}
            grossProfit={deal.GrossProfit}
            EBITDA={deal.EBITDA}
            EBITDA_Percentage={deal.EBITDA_Percentage}
            IOI_Bids={deal.IOIBids}
            numInvestors={deal.InvestorsNum}
            fundingRequired={deal.FundingRequired ? deal.FundingRequired : 0}
            totalFundingRaised={deal.TotalFundingRaised}
            dealId={deal.OrganizationID}
            investorId={details?.investorID}
            isStar={
              details?.wishlistdeal?.includes(deal.OrganizationID)
                ? true
                : false
            }
            deal={deal}
            isPassed={true}
          />
        ))
      ) : (
        <>
          <NoDeal />
        </>
      )}
    </>
  );
};

export default PassedDeals;
