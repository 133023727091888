import React from "react";

import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const ProgressBar = ({ totalFundingRaised, fundingRequired }) => {
  console.log(totalFundingRaised, fundingRequired);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
      ...theme.applyStyles("dark", {
        backgroundColor: "#308fe8",
      }),
    },
  }));
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <div className="Card-div-24">Current Round</div>
      <BorderLinearProgress
        variant="determinate"
        value={((totalFundingRaised / fundingRequired) * 100).toFixed(2)}
        sx={{ height: 5, marginTop: "5px" }}
      />

      <div className="Card-div-32">
        <div className="Card-div-33">
          <p className="Card-div-34">₹{totalFundingRaised}Cr</p>
          <p className="Card-div-34">
            {((totalFundingRaised / fundingRequired) * 100).toFixed(2)} % Raised
          </p>
        </div>
        <div>
          <p className="Card-div-35">₹{fundingRequired}Cr</p>
        </div>
      </div>
    </Stack>
  );
};

export default ProgressBar;
