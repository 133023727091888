import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CustomerTapeAnalytics from "./customerTapeAnalytics";
import FinancialStatementAnalytics from "./financialStatementAnalytics";
import AnalyticsOverview from "./analyticsOverview";
import { Box, Container, Typography } from "@mui/material";
import {
  getCustomerTapeData,
  getFinancialStatementData,
} from "../../../../../endpoints/fundrevAnalyst";
import { useLoader } from "../../../../Common/LoaderProvider";
import BusinessAnalytics from "./businessAnalytics";
import { getAnalyticsData } from "../../../../../endpoints/common";
import HistoricalFinancials from "./historicalFinancials";

//TODO: For Quaterly data need check last month end on quater or not
const Analytics = ({ deal, analytics, setAnalytics }) => {
  const token = localStorage.getItem("token");
  const companyName = deal ? deal.CompanyName : "";
  const [customerTapeData, setCustomerTapeData] = useState(null);
  const [financialData, setFinancialData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const loader = useLoader();
  const [analyticsData, setAnalyticsData] = useState(null);
  useEffect(() => {
    getCustomerData();
    getFinancialData();
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      const response = await getAnalyticsData(deal.DealID);
      setAnalyticsData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };

  const getFinancialData = async () => {
    try {
      const response = await getFinancialStatementData({
        dealId: deal.DealID || null,
        token,
      });
      setFinancialData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };
  const getCustomerData = async () => {
    try {
      const response = await getCustomerTapeData({
        dealId: deal.DealID || null,
        token,
      });
      setCustomerTapeData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };

  if (loading) {
    loader.start("Fetching data...");
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography variant="h6" color="error">
            Something went wrong! Please try again later.
          </Typography>
        </Box>
      </Container>
    );
  }
  return (
    <>
      {/* <Helmet>
        <title>{`Investor | ${companyName} - Analytics`}</title>
      </Helmet> */}
      {analytics === "summary" && analyticsData?.summary && (
        <AnalyticsOverview
          data={analyticsData.summary}
          setAnalytics={setAnalytics}
        />
      )}
      {analytics === "businessAnalytics" &&
        analyticsData?.businessAnalytics && (
          <BusinessAnalytics data={analyticsData.businessAnalytics} />
        )}
      {analytics === "financialStatement" && (
        <FinancialStatementAnalytics data={financialData} />
      )}
      {analytics === "customerTape" && (
        <CustomerTapeAnalytics data={customerTapeData} />
      )}
      {analytics === "historicalFinancials" &&
        analyticsData?.historicalFinancials && (
          <HistoricalFinancials data={analyticsData.historicalFinancials} />
        )}
    </>
  );
};

export default Analytics;
