import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../../LandingPage/header";
import FoundersPageFront from "./FoundersPageFront";
import FoundersPageMid1 from "./FoundersPageMid1";
import FoundersPageMid2 from "./FoundersPageMid2";
import FoundersPageMid3 from "./FoundersPageMid3";
import FoundersPageMid4 from "./FoundersPageMid4";
import OrbitingCirclesDemo from "../../Common/animations/orbitingCircles";
import Footer from "../../LandingPage/Footer";
import "../../../CSS/FounderPage/style.css";
const FoundersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="FoundersPage">
      <Helmet>
        <title>Fundrev | Founder</title>
      </Helmet>
      <Header color="black" type="founder" />
      <FoundersPageFront />
      <FoundersPageMid1 />
      <div className="div-26">
        <FoundersPageMid2 />
        <FoundersPageMid3 />
        <FoundersPageMid4 />
      </div>

      <OrbitingCirclesDemo
        headingtext="Begin your fundraising journey with Fundrev"
        text="Raise funds lightning-fast with Fundrev's platform, connecting you to investors seamlessly, so you can focus on growing your business."
      />
      <div className="landing-page">
        <Footer />
      </div>
    </section>
  );
};

export default FoundersPage;
