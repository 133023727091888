import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FinancialPlot from "./AnalyticsPlots/FinancialPlot";
import FinancialStatementSmallComparisonTable from "./AnalyticsPlots/FinancialStatementSmallComparisonTable";
import PlotTemplate from "./AnalyticsPlots/PlotTemplate";
import { Link2Icon } from "@radix-ui/react-icons";
import TopCustomerTable from "./AnalyticsPlots/TopCustomerTable";
import SmallCardGrid from "./AnalyticsPlots/SmallCardGrid";
import AnalyticsPageTitle from "./AnalyticsPlots/AnalyticsPageTitle";

const AnalyticsOverview = ({ data, setAnalytics }) => {
  return (
    <>
      <AnalyticsPageTitle title={`Analytics Summary`} />
      <div
        style={{
          marginBottom: "100px",
        }}
      >
        <SmallCardGrid data={data?.fiveCards} />
        {data.plots.financialPlot.isVisible && (
          <Box>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                cursor: "pointer",
                width: "30%",
                marginBottom: 1,
              }}
              onClick={() => setAnalytics(`businessAnalytics`)}
            >
              Business Performance <Link2Icon />
            </Typography>
            <Box
              sx={{
                marginBottom: 3,
                background: "white",
                width: "1151px",
                height: "450px",
                borderRadius: "8px",
                paddingX: "10px",
                paddingTop: "10px",
              }}
            >
              <FinancialPlot data={data.plots.financialPlot} />
            </Box>
          </Box>
        )}
        {(data.plots.revenueByCategoryPlot.isVisible ||
          data.tables.topCustomerTable.isVisible) && (
          <Box>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                cursor: "pointer",
                width: "30%",
                marginBottom: 1,
              }}
              onClick={() => setAnalytics(`salesSummary`)}
            >
              Sales Summary
              <Link2Icon />
            </Typography>
            <Grid container spacing={1.5} marginBottom={3}>
              <Grid item xs="auto" md="auto" lg="auto">
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "569px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
                    fontFamily: "Karla, sans-serif",
                    padding: 2,
                    paddingRight: 0,
                    paddingLeft: 2.5,
                  }}
                >
                  <PlotTemplate data={data.plots.revenueByCategoryPlot} />
                </Box>
              </Grid>
              <Grid item xs="auto" md="auto" lg="auto">
                <TopCustomerTable data={data.tables.topCustomerTable} />
              </Grid>
            </Grid>
          </Box>
        )}
        {(data.tables.financialSmallTable.isVisible ||
          data.tables.balanceSheetSummary.isVisible) && (
          <Box>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                cursor: "pointer",
                width: "30%",
                marginBottom: 1,
              }}
              onClick={() => setAnalytics(`historicalFinancialSummary`)}
            >
              Historical Financial Summary
              <Link2Icon />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs="auto" md="auto" lg="auto">
                {data.tables.financialSmallTable.isVisible && (
                  <Box>
                    <FinancialStatementSmallComparisonTable
                      data={data.tables.financialSmallTable}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs="auto" md="auto" lg="auto">
                {data.tables.balanceSheetSummary.isVisible && <Box></Box>}
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </>
  );
};

export default AnalyticsOverview;
