import React from 'react'
import library from "../../Assets/Images/LandingPage/library.svg"
import shield from "../../Assets/Images/LandingPage/shield.svg"
import headset from "../../Assets/Images/LandingPage/Headset.svg"
const LandingPageMid4 = () => {
  return (
    <section className='LandingPageMid landingPageMid4'>
      <div className="frame-120">
            <div className="frame-121">
                <h2 className="typography-36 laptop-deals">Your Secure Path to Deal Making</h2>
                <h2 className="typography-36 mobile-deals">Your Secure Path to <span className="gradient-color">Deal making</span></h2>
            </div>
            <div className='frames'>
            <div className="frame-122">
                <div className="frame-123">
                    <div className='frame124block library'>
                        <img className="frame-124" alt="Frame" src={library} />
                    </div>
                    <div className="frame-125">
                        <h3 className="typography-37">End to End Encryption</h3>
                        <h4 className="text-wrapper-21">
                            All communications and transactions are encrypted end-to-end, ensuring utmost security of
                            sensitive information
                        </h4>
                    </div>
                </div>
            </div>
            <div className="frame-126">
                <div className="frame-127">
                    <div className='frame124block shield'>
                        <img className="frame-124" alt="Frame" src={shield} />
                    </div>
                    <div className="frame-125">
                        <h3 className="typography-37">Data Protection</h3>
                        <h4 className="text-wrapper-21">
                            Because security is paramount, even intr-aorganization, our RBAC feature gives every customer control
                            over user permissions and access to sensitive financial data.
                        </h4>
                    </div>
                </div>
            </div>
            <div className="frame-128">
                <div className="frame-123">
                    <div className='frame124block headset'>
                        <img className="frame-124" alt="Frame" src={headset} />
                    </div>
                    <div className="frame-125">
                        <h3 className="typography-37">We're here to help</h3>
                        <h4 className="text-wrapper-21">
                            Our customer experience team is ready to answer your questions 7 days a week.
                        </h4>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default LandingPageMid4
