import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const FinancialStatementBigComparisonTable = ({ data }) => {
  const keys = Object.keys(data["m"]["Revenue"]);
  const [option, setOption] = useState(keys.slice(-1)[0]);
  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(keys.slice(-13)[0]);

  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];
  const table_header = [
    "Metrics",
    key,
    ckey,
    `Variance(%)`,
    key,
    ckey,
    `Variance(%)`,
    key,
    ckey,
    `Variance(%)`,
  ];

  useEffect(() => {}, [data, option]);
  function getVariance(type, row, sga) {
    const validNumber = (num) =>
      typeof num === "number" && !isNaN(num) && isFinite(num);

    let a, b;
    if (
      row !== "Gross Profit %" &&
      row !== "EBITDA %" &&
      row !== "SG&A" &&
      row !== "EBIT %" &&
      row !== "Net Profit Margin"
    ) {
      a = data[type][row][key];
      b = data[type][row][ckey];
    } else if (row === "SG&A") {
      a = data[type][row][sga][key];
      b = data[type][row][sga][ckey];
    } else {
      return null; // or any other default value
    }

    if (validNumber(a) && validNumber(b) && b !== 0) {
      const variance = ((a / b - 1) * 100).toFixed(1);
      if (variance === "-100.0" && a === 0 && b !== 0) {
        return "0.0"; // or any other default value
      }
      return variance;
    }

    return null; // or any other default value to indicate an invalid calculation
  }

  function getWidth(type) {
    switch (type) {
      case "m":
        return 320;
      case "q":
        return 323;
      case "y":
        return 235;
      case "ltm":
        return 430;
      default:
        return 263;
    }
  }

  function CollapsibleRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell
            sx={{
              background: "white",
              width: "123px",
              paddingY: "10px",
              // whiteSpace: "nowrap",
              fontFamily: "Karla, sans-serif",
              borderRight: "1px solid #B8B8B8",
            }}
          >
            {row}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{
                padding: 0,
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
            }}
          >
            {formatToLakhs(data["m"][row]["Total SG&A Expenses"][key])}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
            }}
          >
            {formatToLakhs(data["m"][row]["Total SG&A Expenses"][ckey])}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
              background:
                getVariance("m", row, "Total SG&A Expenses") > 0
                  ? "#4EF79A1A"
                  : "#D32F2F1A",
              color:
                getVariance("m", row, "Total SG&A Expenses") > 0
                  ? "#00CD9E"
                  : "#D32F2F",
              borderRight: "1px solid #B8B8B8",
            }}
            align="right"
          >
            {getVariance("m", row, "Total SG&A Expenses") + `%`}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
            }}
          >
            {formatToLakhs(data["ytd"][row]["Total SG&A Expenses"][key])}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
            }}
          >
            {formatToLakhs(data["ytd"][row]["Total SG&A Expenses"][ckey])}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
              background:
                getVariance("ytd", row, "Total SG&A Expenses") > 0
                  ? "#4EF79A1A"
                  : "#D32F2F1A",
              color:
                getVariance("ytd", row, "Total SG&A Expenses") > 0
                  ? "#00CD9E"
                  : "#D32F2F",
              borderRight: "1px solid #B8B8B8",
            }}
            align="right"
          >
            {getVariance("ytd", row, "Total SG&A Expenses") + `%`}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
            }}
          >
            {formatToLakhs(data["ltm"][row]["Total SG&A Expenses"][key])}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
            }}
          >
            {formatToLakhs(data["ltm"][row]["Total SG&A Expenses"][ckey])}
          </TableCell>
          <TableCell
            sx={{
              width: "123px",
              paddingY: "10px",
              fontFamily: "Karla, sans-serif",
              background:
                getVariance("ltm", row, "Total SG&A Expenses") > 0
                  ? "#4EF79A1A"
                  : "#D32F2F1A",
              color:
                getVariance("ltm", row, "Total SG&A Expenses") > 0
                  ? "#00CD9E"
                  : "#D32F2F",
              borderRight: "1px solid #B8B8B8",
            }}
            align="right"
          >
            {getVariance("ltm", row, "Total SG&A Expenses") + `%`}
          </TableCell>
        </TableRow>
        {Object.keys(data["m"][row]).map((inside_row, index) => {
          return (
            inside_row !== "Total SG&A Expenses" &&
            Object.values(data["m"][row][inside_row])[0] && (
              <TableRow
                key={index}
                sx={{
                  display: open ? "table-row" : "none",
                  animation: open
                    ? "fadeIn 0.5s ease-in-out"
                    : "fadeOut 0.5s ease-in-out",
                  "@keyframes fadeIn": {
                    from: {
                      opacity: 0,
                      transform: "translateY(-10px)",
                    },
                    to: {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                  "@keyframes fadeOut": {
                    from: {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                    to: {
                      opacity: 0,
                      transform: "translateY(-10px)",
                    },
                  },
                }}
              >
                <TableCell
                  sx={{
                    background: "white",
                    width: 123,
                    paddingY: "10px",
                    whiteSpace: "nowrap",
                    fontFamily: "Karla, sans-serif",
                    borderRight: "1px solid #B8B8B8",
                    paddingLeft: "30px",
                  }}
                >
                  {inside_row}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                  }}
                >
                  {formatToLakhs(data["m"][row][inside_row][key])}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                  }}
                >
                  {formatToLakhs(data["m"][row][inside_row][ckey])}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                    background:
                      getVariance("m", row, inside_row) > 0
                        ? "#4EF79A1A"
                        : "#D32F2F1A",
                    color:
                      getVariance("m", row, inside_row) > 0
                        ? "#00CD9E"
                        : "#D32F2F",
                    borderRight: "1px solid #B8B8B8",
                  }}
                  align="right"
                >
                  {getVariance("m", row, inside_row) + `%`}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                  }}
                >
                  {formatToLakhs(data["ytd"][row][inside_row][key])}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                  }}
                >
                  {formatToLakhs(data["ytd"][row][inside_row][ckey])}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                    background:
                      getVariance("ytd", row, inside_row) > 0
                        ? "#4EF79A1A"
                        : "#D32F2F1A",
                    color:
                      getVariance("ytd", row, inside_row) > 0
                        ? "#00CD9E"
                        : "#D32F2F",
                    borderRight: "1px solid #B8B8B8",
                  }}
                  align="right"
                >
                  {getVariance("ytd", row, inside_row) + `%`}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                  }}
                >
                  {formatToLakhs(data["ltm"][row][inside_row][key])}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                  }}
                >
                  {formatToLakhs(data["ltm"][row][inside_row][ckey])}
                </TableCell>
                <TableCell
                  sx={{
                    width: "123px",
                    paddingY: "10px",
                    fontFamily: "Karla, sans-serif",
                    background:
                      getVariance("ltm", row, inside_row) > 0
                        ? "#4EF79A1A"
                        : "#D32F2F1A",
                    color:
                      getVariance("ltm", row, inside_row) > 0
                        ? "#00CD9E"
                        : "#D32F2F",
                    borderRight: "1px solid #B8B8B8",
                  }}
                  align="right"
                >
                  {getVariance("ltm", row, inside_row)}
                </TableCell>
              </TableRow>
            )
          );
        })}
      </>
    );
  }
  const handleSelect = (event) => {
    const month = event.target.value;
    setOption(month);
    setKey(month);
    const [m, y] = month.split(" ");
    setCKey(`${m} ${y - 1}`);
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "1150px",
          height: "840px",
          padding: "15px",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          display={`flex`}
          alignItems={`center`}
          justifyContent={`space-between`}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Karla,sans-serif",
              color: "#3C5168",
            }}
          ></p>
          <div>
            <Select
              value={option}
              onChange={handleSelect}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "173px",
                height: "32px",
                fontFamily: "Karla,sans-serif",
                fontSize: "14px",
                fontWeight: "400",
                color: "#636363",
                borderRadius: 1,
                marginBottom: "5px",
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "200px", // Set the max height of the dropdown box
                  },
                },
              }}
            >
              {keys
                .slice(
                  keys.findIndex(
                    (m) => m === Object.keys(data["ltm"]["Revenue"])[0]
                  ) + 12
                )
                .reverse()
                .map((val, index) => (
                  <MenuItem
                    sx={{
                      fontFamily: "Karla,sans-serif",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    value={val}
                    key={index}
                  >
                    {val}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderBottom: "1px solid #DADADA",
            boxShadow: 0,
          }}
        >
          <TableContainer>
            <Table
              aria-label="Financial statement table"
              sx={{
                border: "1px solid #DADADA",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      background: "white",
                      width: "233px",
                      paddingY: "10px",
                      // whiteSpace: "nowrap",
                      fontWeight: 700,
                      fontFamily: "Karla, sans-serif",
                      borderRight: "1px solid #B8B8B8",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      background: "white",
                      width: "123px",
                      paddingY: "10px",
                      // whiteSpace: "nowrap",
                      fontWeight: 700,
                      fontFamily: "Karla, sans-serif",
                      borderRight: "1px solid #B8B8B8",
                    }}
                    colSpan={3}
                    align="center"
                  >
                    Monthly Comparison
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "white",
                      width: "123px",
                      paddingY: "10px",
                      // whiteSpace: "nowrap",
                      fontWeight: 700,
                      fontFamily: "Karla, sans-serif",
                      borderRight: "1px solid #B8B8B8",
                    }}
                    colSpan={3}
                    align="center"
                  >
                    YTD Comparison
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "white",
                      width: "123px",
                      paddingY: "10px",
                      // whiteSpace: "nowrap",
                      fontWeight: 700,
                      fontFamily: "Karla, sans-serif",
                      borderRight: "1px solid #B8B8B8",
                    }}
                    colSpan={3}
                    align="center"
                  >
                    LTM Comparison
                  </TableCell>
                </TableRow>
                <TableRow>
                  {table_header.map((column, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      sx={{
                        position: index === 0 && "relative",
                        bottom: index === 0 ? "27px" : 0,
                        background: index === 0 ? "white" : "none",
                        width: "123px",
                        paddingY: "10px",
                        fontWeight: 700,
                        fontFamily: "Karla, sans-serif",
                        borderRight: index % 3 === 0 && "1px solid #B8B8B8",
                        borderBottom: "1px solid #B8B8B8",
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data["m"]).map((row, index) =>
                  row === "SG&A" ? (
                    <CollapsibleRow row={row} key={index} />
                  ) : (
                    <TableRow key={index}>
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            background: "white",
                            width: "123px",
                            paddingY: "10px",
                            whiteSpace: "nowrap",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            borderRight: "1px solid #B8B8B8",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {row}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {formatToLakhs(data["m"][row][key])}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {formatToLakhs(data["m"][row][ckey])}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            background:
                              getVariance("m", row) > 0
                                ? "#4EF79A1A"
                                : "#D32F2F1A",
                            color:
                              getVariance("m", row) > 0 ? "#00CD9E" : "#D32F2F",
                            borderRight: "1px solid #B8B8B8",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                          }}
                          align="right"
                        >
                          {getVariance("m", row)
                            ? getVariance("m", row) + `%`
                            : `-`}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {formatToLakhs(data["ytd"][row][key])}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {formatToLakhs(data["ytd"][row][ckey])}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            background:
                              getVariance("ytd", row) > 0
                                ? "#4EF79A1A"
                                : "#D32F2F1A",
                            color:
                              getVariance("ytd", row) > 0
                                ? "#00CD9E"
                                : "#D32F2F",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            borderRight: "1px solid #B8B8B8",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                          }}
                          align="right"
                        >
                          {getVariance("ytd", row)
                            ? getVariance("ytd", row) + `%`
                            : `-`}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {formatToLakhs(data["ltm"][row][key])}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                            color: IMPRows.includes(row) && "#00A6FB",
                          }}
                        >
                          {formatToLakhs(data["ltm"][row][ckey])}
                        </TableCell>
                      )}
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            width: "123px",
                            paddingY: "10px",
                            fontFamily: "Karla, sans-serif",
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                            background:
                              getVariance("ltm", row) > 0
                                ? "#4EF79A1A"
                                : "#D32F2F1A",
                            color:
                              getVariance("ltm", row) > 0
                                ? "#00CD9E"
                                : "#D32F2F",
                            borderRight: "1px solid #B8B8B8",
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid #00A6FB",
                          }}
                          align="right"
                        >
                          {getVariance("ltm", row)
                            ? getVariance("ltm", row) + `%`
                            : `-`}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Typography
          sx={{
            fontSize: "11px",
            fontFamily: "Karla, sans-serif",
            fontWeight: 600,
            color: "#6B7494",
          }}
        >
          *all values in lakhs except percentages
        </Typography>
      </Box>
    </>
  );
};

export default FinancialStatementBigComparisonTable;
