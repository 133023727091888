import React, { useEffect, useState } from "react";
import {
  getAllDealInteractions,
  dealInterestByStage,
  getCoInvestorData,
} from "../../../../endpoints/deal";
import { investorState } from "../investorState";
import { fetchBlobName } from "../../../Common/functions";
import { getqnAs } from "../../../../endpoints/qnA";

import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import blankActivity from "../../../../Assets/Images/InvestorPage/Dashboard/blankActivity.svg";
import filledActivity from "../../../../Assets/Images/InvestorPage/Dashboard/filledActivity.svg";
import FileImg from "../../../../Assets/Images/signup/file.svg";
import noQuestions from "../../../../Assets/Images/FounderPage/Dashboard/noQuestions.svg";
import CoInvestorTable from "../Components/CoInvestorTable";
import { useLoader } from "../../../Common/LoaderProvider";

const Activity = ({ deal, newQuestionAsked, setNewQuestionAsked }) => {
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const loader = useLoader();
  const { investorId } = useRecoilValue(investorState);
  const [interactions, setInteractions] = useState([]);
  const companyName = deal ? deal.CompanyName : "";
  const [NDADocument, setNDADocument] = useState([]);
  const [IoiBid, setIoiBid] = useState([]);
  const [LoiAgreement, setLoiAgreement] = useState([]);
  const [SPA, setSPA] = useState([]);
  const [qnAs, setqnAs] = useState([]);
  const [latestStage, setLatestStage] = useState("");
  const [coInvestorData, setCoInvestorData] = useState(null);

  useEffect(() => {
    const fetchCoInvestorData = async () => {
      try {
        const response = await getCoInvestorData({
          dealId: deal.DealID,
          investorId: investorId,
          token: token,
        });
        setCoInvestorData(response.data);
      } catch (error) {
        loader.stop();
      }
    };
    if (investorId) fetchCoInvestorData();
  }, [investorId]);

  useEffect(() => {}, [coInvestorData]);

  useEffect(() => {
    const fetchAllInteractions = async () => {
      try {
        const response = await getAllDealInteractions(
          deal.DealID,
          investorId,
          token
        );
        setLatestStage(response.data[response.data.length - 1].Stage);
        setInteractions(response.data); // Store the interactions in state
      } catch (error) {
        loader.stop();
      }
    };
    loader.start();
    if (deal.DealID && investorId && token) {
      fetchAllInteractions();
    }
  }, [deal, investorId]);

  // Define the stages and their order
  const stages = [
    { label: "Deal Published", dateKey: "initialStage", index: 0 },
    { label: "Request Sent", dateKey: "requestSent", index: 1 },
    { label: "Request Accepted", dateKey: "requestAccepted", index: 2 },
    { label: "NDA Signed", dateKey: "ndaSigned", index: 3 },
    { label: "IOI Submitted", dateKey: "ioiSubmitted", index: 4 },
    { label: "IOI Accepted", dateKey: "ioiAccepted", index: 5 },
    { label: "LOI Submitted", dateKey: "loiSubmitted", index: 6 },
    { label: "LOI Accepted", dateKey: "loiAccepted", index: 7 },
    { label: "SPA Negotiation", dateKey: "spaNegotiation", index: 8 },
    { label: "Deal Closed", dateKey: "dealClosed", index: 9 },
  ];

  const stageRoutes = {
    "NDA Signed": {
      path: "/investor/signNDA",
      state: {
        stage: "requestAccepted",
        companyName: deal ? deal.Title : "",
        dealId: deal ? deal.DealID : "",
      },
    },
    "IOI Accepted": {
      path: "/investor/IOIBid",
      state: {
        stage: "ioiSubmitted",
        fundingRequired: deal.FundingRequired,
        companyName: deal ? deal.CompanyName : "",
        dealId: deal ? deal.DealID : "",
      },
    },
    "LOI Accepted": {
      path: "/investor/LOIAgreement",
      state: {
        stage: "loiSubmitted",
        companyName: deal ? deal.CompanyName : "",
        dealId: deal ? deal.DealID : "",
      },
    },
    "SPA Negotiation": {
      path: "/investor/spaNegotiation",
      state: {
        stage: "spaNegotiation",
        companyName: deal ? deal.CompanyName : "",
        dealId: deal ? deal.DealID : "",
      },
    },
  };
  const handleClick = (stageLabel) => {
    const route = stageRoutes[stageLabel];
    if (route) {
      Navigate(route.path, {
        state: {
          ...route.state,
        },
      });
    }
  };
  // Mapping of interactions to stages
  const interactionsMap = interactions.reduce((acc, interaction) => {
    acc[interaction.Stage] = interaction;
    return acc;
  }, {});

  const getDealInterestByStage = async (dealId, investorID, stage) => {
    try {
      const response = await dealInterestByStage(
        dealId,
        investorID,
        stage,
        token
      );
      loader.stop();
      if (response) {
        if (stage === "requestAccepted") {
          const filteredData = response.data.filter((item) => item.NDADocument);
          const NDADocuments = filteredData.map((item) => item.NDADocument);
          setNDADocument(NDADocuments[0]);
        } else if (stage === "ioiSubmitted") {
          const IOIBids = response.data.map((item) => item.IOI_Bid_Details);
          setIoiBid(IOIBids[0].IOILetter);
        } else if (stage === "loiSubmitted") {
          const LOIAgreements = response.data.map(
            (item) => item.LOI_Agreement_Details
          );
          setLoiAgreement(LOIAgreements[0].LOILetter);
        } else if (stage === "spaNegotiation") {
          const filteredData = response.data.filter((item) => item.SPA);
          const SPAs = filteredData.map((item) => item.SPA);
          setSPA(SPAs[0]);
        }
      }
    } catch (error) {
      loader.stop();
    }
  };
  // 1. Added index for each stage to reduce the number of APIs
  // 2. We will make API calls to only those stages which were before the latest stage
  const getDealDocuments = async () => {
    if (deal.DealID && investorId) {
      const index = stages.findIndex((stage) => stage.dateKey === latestStage);
      if (index >= 2) {
        getDealInterestByStage(deal.DealID, investorId, "requestAccepted");
      }
      if (index >= 4) {
        getDealInterestByStage(deal.DealID, investorId, "ioiSubmitted");
      }
      if (index >= 6) {
        getDealInterestByStage(deal.DealID, investorId, "loiSubmitted");
      }
      if (index >= 8) {
        getDealInterestByStage(deal.DealID, investorId, "spaNegotiation");
      }
    }
  };
  useEffect(() => {
    if (deal) {
      getDealDocuments();
    }
  }, [deal, latestStage, investorId]);
  const FileUpload = ({ file, fileName }) => (
    <div className="fileUploads" style={{ width: "fit-content" }}>
      <img src={FileImg} alt="file" />
      <div className="fileUploads-div-2">
        <Link to={file} className="fileLink">
          <div className="fileUploads-div-3" style={{ width: "fit-content" }}>
            {fetchBlobName(file)}
          </div>
        </Link>
      </div>
    </div>
  );
  const files = [
    { file: NDADocument, name: "NDADocument" },
    { file: IoiBid, name: "IoiBid" },
    { file: LoiAgreement, name: "LoiAgreement" },
    { file: SPA, name: "SPA" },
  ];
  const addOrUpdateQnAs = (qnAData) => {
    const qnAMap = new Map(qnAs.map((qnA) => [qnA.qnAID, qnA]));

    qnAData.forEach((qnA) => {
      qnAMap.set(qnA.qnAID, qnA);
    });

    setqnAs(Array.from(qnAMap.values()));
  };
  const getqnAdata = async () => {
    if (deal.DealID && investorId) {
      const response = await getqnAs({
        dealID: deal.DealID,
        investorID: investorId,
      });
      try {
        if (response.response.data === "No qnA found") {
          return;
        }
      } catch {
        const qnAData = response.data;
        addOrUpdateQnAs(qnAData);
        setNewQuestionAsked(false);
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    getqnAdata();
  }, [deal]);
  useEffect(() => {
    if (newQuestionAsked) getqnAdata();
  }, [newQuestionAsked]);
  return (
    <>
      <Helmet>
        <title>{`Investor | ${companyName} - activity`}</title>
      </Helmet>
      <div className="TimeLine-div">
        <p className="TimeLine-text">TimeLine</p>
        <div className="TimeLine">
          <div className="tm-div">
            <div className="tm-div-2">
              {stages.map((stage, index) => {
                // Determine if the stage is 'initialStage' and handle it separately
                const isInitialStage = stage.dateKey === "initialStage";
                const interaction = interactionsMap[stage.dateKey];
                const isFilled = isInitialStage || !!interaction;
                const date = isInitialStage
                  ? deal.createdAt
                    ? deal.createdAt
                    : "N/A"
                  : interaction
                  ? new Date(interaction.createdAt).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )
                  : "Date";

                // Define the stages where 'View Details' should be shown
                const detailStages = [
                  "NDA Signed",
                  "IOI Accepted",
                  "LOI Accepted",
                  "SPA Negotiation",
                ];

                return (
                  <React.Fragment key={stage.label}>
                    <div
                      className={`tm-div-3 ${
                        stage.label === "Deal Closed" && "last-tm-div"
                      }`}
                    >
                      <div className="tm-div-4">{isFilled ? date : "Date"}</div>
                      <img
                        loading="lazy"
                        src={isFilled ? filledActivity : blankActivity}
                        className="tm-img"
                        alt={`${stage.label} icon`}
                      />
                      <div
                        className={!isFilled ? "tm-div-5" : "tm-div-5-filled"}
                      >
                        {stage.label}
                      </div>
                      {isFilled &&
                        detailStages.includes(stage.label) &&
                        stageRoutes[stage.label] && (
                          <div
                            className="tm-div-9"
                            onClick={() => handleClick(stage.label)}
                          >
                            View Details
                          </div>
                        )}
                    </div>
                    {index < stages.length - 1 && (
                      <div
                        className="tm-div-6"
                        style={{ color: isFilled ? "#00A6FB" : "#000" }}
                      >
                        . . . . .{" "}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="Deal-documents-div">
        <p className="TimeLine-text">Deal Documents</p>
        <div className="all-file-uploads">
          {files.map(
            ({ file, name }) =>
              file.length > 0 && (
                <FileUpload key={name} file={file} fileName={name} />
              )
          )}
        </div>
      </div>
      <div className="Deal-documents-div">
        <p className="TimeLine-text">Co-Investors Info</p>
        <CoInvestorTable data={coInvestorData} />
      </div>
      <div className="QnA-div">
        <p className="TimeLine-text">Q&A Section</p>

        {qnAs.length > 0 ? (
          qnAs.map((qnA) => {
            return (
              <div className="QnA" style={{ marginBottom: "30px" }}>
                <div className="QnA-question">
                  <span className="blue-text">Q.</span> {qnA.question}
                </div>
                {qnA.answer && (
                  <div className="QnA-answer">
                    <span className="blue-text">A.</span> {qnA.answer}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <>
            <p
              style={{
                fontSize: "20px",
                color: "#696F79",
                margin: "20px 0px 0px 30px",
              }}
            >
              Currently, there are no questions or answers posted
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <img src={noQuestions} alt="No questions" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Activity;
