import React from "react";
import MarqueeDemo from "./animatedCards";
import ShimmerButton from "../Common/animations/shimmerButton";
import "../../CSS/LandingPage/front.css";
export const Front = () => {
  return (
    <div className="header">
      <div className="overlap">
        <div className="ellipse" />
        <div className="frame-and-frame-4">
          <div className="frame">
            <div className="frame-2">
              <div className="frame-3">
                <h1 className="text-wrapper">LET'S FUNDREV</h1>
              </div>
              <h2 className="typography">
                Helping Companies Raise{" "}
                <span className="onetotwentymillion">$1-20 million</span>{" "}
                Institutional Capital
              </h2>
              <h2 className="p">
                Assisting entrepreneurs in securing institutional/strategic
                funding from verified investors, accelerating the process and
                obtaining vital capital swiftly than ever before!
              </h2>
            </div>
            {/* <button className="button">
              <div className="content">
                <div className="text-wrapper-2">
                  <Link to="/signup" className="get-started-link">
                    Get Started
                  </Link>
                </div>
              </div>
              <img
                className="heroicons-outline"
                alt="Heroicons outline"
                src={ArrowRight}
              />
            </button> */}
            <ShimmerButton text="Get Started" />
          </div>
          <div className="group-4">
            <MarqueeDemo />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Front;
