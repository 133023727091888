import React, { forwardRef } from "react";
import { cn } from "../../../lib/utils";
import ArrowRight from "../../../Assets/Images/LandingPage/arrow-small-right.svg";
import { Link } from "react-router-dom";
const ShimmerButton = forwardRef(function ShimmerButton(
  {
    shimmerColor = "#ffffff",
    shimmerSize = "0.15em",
    shimmerDuration = "3s",
    borderRadius = "100px",
    background = "#00A6FB",
    className,
    children,
    ...props
  },
  ref
) {
  return (
    <button
      style={{
        "--spread": "90deg",
        "--shimmer-color": shimmerColor,
        "--radius": borderRadius,
        "--speed": shimmerDuration,
        "--cut": shimmerSize,
        "--bg": background,
      }}
      className={cn(
        "relative z-0 flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-4 py-2 text-white [background:var(--bg)] [border-radius:var(--radius)] dark:text-black",
        "transform-gpu transition-transform duration-300 ease-in-out active:translate-y-[1px]",
        className
      )}
      ref={ref}
      {...props}
    >
      {/* spark container */}
      <div
        className={cn(
          "-z-30 blur-[2px]",
          "absolute inset-0 overflow-visible [container-type:size]"
        )}
      >
        {/* spark */}
        <div className="absolute inset-0 h-[100cqh] animate-slide [aspect-ratio:1] [border-radius:0] [mask:none]">
          {/* spark before */}
          <div className="animate-spin-around absolute inset-[-100%] w-auto rotate-0 [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,var(--shimmer-color)_var(--spread),transparent_var(--spread))] [translate:0_0]" />
        </div>
      </div>
      {children}

      {/* Highlight */}
      <div
        className={cn(
          "insert-0 absolute h-full w-full",

          "rounded-2xl px-2 py-1 text-sm font-medium shadow-[inset_0_-8px_10px_#ffffff1f]",

          // transition
          "transform-gpu transition-all duration-300 ease-in-out",

          // on hover
          "group-hover:shadow-[inset_0_-6px_10px_#ffffff3f]",

          // on click
          "group-active:shadow-[inset_0_-10px_10px_#ffffff3f]"
        )}
      />

      {/* backdrop */}
      <div
        className={cn(
          "absolute -z-20 [background:var(--bg)] [border-radius:var(--radius)] [inset:var(--cut)]"
        )}
      />
    </button>
  );
});

ShimmerButton.displayName = "ShimmerButton";

export function ShimmerButtonDemo({ text }) {
  return (
    <div className="z-1 flex items-center justify-center">
      <Link to="/signup">
        <ShimmerButton className="shadow-2xl">
          <span className="whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
            {text}&nbsp;
          </span>
          <img
            className="heroicons-outline transition-transform duration-300 ease-in-out group-hover:translate-x-0.5"
            alt="Heroicons outline"
            src={ArrowRight}
          />
        </ShimmerButton>
      </Link>
    </div>
  );
}

export default ShimmerButtonDemo;
