import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LeftSection from "../../SignUp/LeftSection";
import { Box, Button } from "@mui/joy";
import "../../../CSS/Signup/investorRegister.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RefreshIcon from "@mui/icons-material/Refresh";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { Edit, Margin } from "@mui/icons-material";
import { Modal, Typography } from "@mui/material";
import Toast from "../Toast";
import Verify from "../../../Assets/Images/signup/InvestorRegister/Verify.png";
import Header from "../../LandingPage/header";
import { resendCode, verifyEmail } from "../../../endpoints/common";
import { ToastContainer } from "react-toastify";
import { investorSignup } from "../../../endpoints/investor";
import { startupSignUp } from "../../../endpoints/startup";
import { PopupWidget } from "react-calendly";
import { useLoader } from "../LoaderProvider";
const VerificationPage = () => {
  var calendlyURL = "https://calendly.com/hello-fundrev";
  const location = useLocation();
  const {
    investorEmail,
    investorName,
    investorToken,
    investorPhoneNumber,
    type,
    investorPassword,
    startupToken,
    role,
    companyEmail,
    companyName,
    companyPhoneNumber,
    companyPassword,
    formData,
    startPoint,
  } = location.state || {};
  const [email, setEmail] = useState(investorEmail || companyEmail || "");
  const [updatedInvestorToken, setUpdatedInvestorToken] =
    useState(investorToken);
  const [updatedStartupToken, setUpdatedStartupToken] = useState(startupToken);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [time, setTime] = useState(5 * 60);
  const [timeUp, setTimeUp] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [token, setToken] = useState("");
  const [investorDetailsToken, setInvestorDetailsToken] = useState();
  const loader = useLoader();
  useEffect(() => {
    if (role === "investor") {
      setToken(updatedInvestorToken);
    } else {
      setToken(updatedStartupToken);
    }
  }, [updatedInvestorToken, updatedStartupToken]);
  const [investorFormData, setInvestorFormData] = useState({
    companyName: companyName,
    name: investorName,
    email: email,
    investorType: type,
    password: investorPassword,
    phoneNumber: investorPhoneNumber,
  });
  const [companyFormData, setCompanyFormData] = useState({
    name: formData ? formData.name : "",
    companyName: companyName,
    email: companyEmail,
    password: companyPassword,
    phoneNumber: companyPhoneNumber,
    revenue: formData ? formData.revenue : "",
    industry: formData ? formData.industry : "",
    cin: formData ? formData.cin : "",
    description: formData ? formData.description : "",
    timeline: formData ? formData.timeline : "",
    website: formData ? formData.website : "",
    fundingType: formData ? formData.fundingType : "",
    verified: true,
  });
  useEffect(() => {
    setInvestorFormData((prevData) => ({
      ...prevData,
      email: email,
    }));
    setCompanyFormData((prevData) => ({
      ...prevData,
      email: email,
    }));
  }, [email]);
  const confirmEmailAddress = async () => {
    let responseData = null;
    try {
      if (role === "investor") {
        loader.start();
        responseData = await investorSignup(investorFormData);
        loader.stop();
      } else {
        loader.start();
        responseData = await startupSignUp(companyFormData);
        loader.stop();
      }
    } catch (error) {
      loader.stop();
    }

    if (responseData.data !== null) {
      const response = responseData.data;

      if (
        response.data.message === "Please verify your email with the code sent."
      ) {
        Toast("OTP sent to new email address", "success", "verificationPage");
        if (role === "investor") {
          setUpdatedInvestorToken(response.data.verificationToken);
        } else {
          setUpdatedStartupToken(response.data.verificationToken);
        }
      }
      // Handle success
    } else {
      Toast(
        "Alerady Registered with this email. Please sign in.",
        "info",
        "verificationPage"
      );
      setTimeout(() => {
        navigate("/signIn", {
          state: {
            sentemail: role === "investor" ? email : email,
            type: role === "investor" ? "Investor" : "Startup",
          },
        });
      }, 1000);
    }
    setIsEditing(false);
  };
  useEffect(() => {
    if (role === "investor") {
      setEmail(investorEmail);
      setToken(investorToken);
    } else {
      setEmail(companyEmail);
      setToken(startupToken);
    }
  }, []);

  useEffect(() => {
    if (time === 0) {
      setTimeUp(true);
      return;
    }

    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [time]);

  const handleSendAgain = async () => {
    let data = null;
    if (token) {
      try {
        loader.start("Sending OTP again...");
        data = await resendCode(token);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
      //Added a check before sending again

      if (data?.response !== null) {
        const response = data.response;

        if (!response.ok) {
        }

        // Successfully resent the verification code

        Toast(
          "Verification code resent successfully.",
          "success",
          "verificationPage"
        );

        // Reset the timer
        setTime(5 * 60);
        setTimeUp(false);
      } else {
        // Handle any errors, such as showing a message to the user
      }
    }
  };

  const navigateToInvestorDetails = () => {
    localStorage.setItem("token", investorDetailsToken);
    localStorage.setItem("role", "investor");
    setTimeout(() => {
      if (startPoint === "noNameDeals") {
        navigate("/investor/deals");
      } else {
        navigate("/investorDetails", {
          state: {
            investorName: investorName,
            investorEmail: email,
            tkn: investorDetailsToken,
          },
        });
      }
    }, 300);
  };
  const scheduleCall = () => {};
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedTime = `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  const verifyCode = async () => {
    if (timeUp) {
      Toast(
        "Time up! Please click on the send again.",
        "warning",
        "verificationPage"
      );
      return;
    }
    try {
      loader.start("Verifying OTP...");
      const response = await verifyEmail(email, verificationCode, token);
      loader.stop();
      if (response.data.status === 200) {
        if (role === "startup") {
          localStorage.removeItem("companyDetails");
        } else {
          const tkn = response.data.data.data.token;
          setInvestorDetailsToken(tkn);
        }
        handleOpen();
      } else {
        Toast("Invalid or expired code!", "error", "verificationPage");
        setVerificationCode("");
      }
    } catch (error) {
      loader.stop();

      Toast("Invalid or expired code!", "error", "verificationPage");
      setVerificationCode("");
      return;
    }
  };
  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }
  window.addEventListener("message", function (e) {
    if (isCalendlyEvent(e)) {
      if (e.data.event === "calendly.event_scheduled") {
        navigate("/companyWaitlistPage");
      }
    }
  });
  return (
    <>
      <Helmet>
        <title>Fundrev | Email Verification</title>
      </Helmet>
      <div className="signUp-div">
        <ToastContainer position="top-center" containerId="verificationPage" />
        <LeftSection
          heading="Secure and Supportive Environment"
          content="Encrypted transactions, RBAC controls, and responsive customer support ensure a secure and streamlined investment experience."
        />
        <div className="investorRegisterWithHeader">
          <Header color="black" type="investor" />
          <div className="investorRegister">
            <div className="section verification-page-section">
              <div className="goBack" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                <Typography className="back-text">Back</Typography>
              </div>
              <div className="title">
                <Typography variant="h6" className="heading">
                  Email Address Verification
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="grey"
                  className="content"
                >
                  Please enter the confirmation code on your email address for
                  verification.
                </Typography>
              </div>
              <div className="form">
                <Box className="mail-section">
                  {isEditing ? (
                    <input
                      type="email"
                      className="email-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  ) : (
                    <Typography className="email-text">{email}</Typography>
                  )}

                  <div className="edit-grp">
                    {isEditing ? (
                      <button
                        className="confirm-email-btn"
                        onClick={confirmEmailAddress}
                      >
                        Confirm
                      </button>
                    ) : (
                      <Typography className="not-confirmed-text">
                        Email Address &nbsp;
                        <span className="confirm-text">not confirmed </span>yet
                      </Typography>
                    )}
                    {isEditing ? null : (
                      <Edit
                        className="edit-icon"
                        onClick={() => setIsEditing(true)}
                      />
                    )}
                  </div>
                </Box>

                <div className="section-1">
                  <Typography className="code-text">
                    Confirmation Code
                  </Typography>
                  <div className="code-section">
                    <VerificationInput
                      length={6}
                      placeholder=""
                      className="code-input"
                      value={verificationCode}
                      onChange={(code) => setVerificationCode(code)}
                    />
                    <div className="time-up-send-again">
                      <Typography className="code-time">
                        {" "}
                        {timeUp ? "Time up" : formattedTime}
                      </Typography>
                      <div
                        className="send-again mobile-send-again"
                        onClick={handleSendAgain}
                      >
                        <RefreshIcon />
                        <Typography className="send-again-text">
                          Send again
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className="send-again laptop-send-again"
                    onClick={handleSendAgain}
                  >
                    <RefreshIcon />
                    <Typography className="send-again-text">
                      Send again
                    </Typography>
                  </div>
                </div>

                <div className="form-section2">
                  <Button className="button" fullWidth onClick={verifyCode}>
                    Verify
                  </Button>
                  <div className="secured-txt-sectiom">
                    <LockOutlinedIcon />
                    <Typography className="secured-text">
                      Your Info is safely secured
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <img src={Verify} alt="verify" className="verify-image" />
          <Typography
            id="modal-modal-title"
            style={{ textAlign: "center" }}
            variant="h6"
            component="h2"
          >
            Welcome {role === "investor" ? investorName : companyName}, you have
            successfully registered!
          </Typography>
          <Typography id="modal-modal-description">
            {role === "investor"
              ? "Complete your profile to unlock new opportunities and connect more effectively. Your profile is your digital footprint - make it shine!"
              : "We appreciate you signing up for our waitlist. A member of our team will be in touch with you shortly."}
          </Typography>
          {role === "startup" ? (
            <PopupWidget
              url={calendlyURL}
              rootElement={document.getElementById("root")}
              text="Click here to schedule!"
              textColor="#ffffff"
              color="#00a2ff"
            />
          ) : (
            <Button
              className="modal-button"
              onClick={
                role === "investor" ? navigateToInvestorDetails : scheduleCall
              }
            >
              Proceed
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default VerificationPage;
