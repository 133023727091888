import axios from "axios";
const token = localStorage.getItem("token");
export const getAnalyticsData = async (dealId) => {
  try {
    const response = await axios.get(
      `https://api.fundrev.ai/analytics/getAnalyticsData/${dealId}`
      // `https://api.fundrev.ai/analytics/getAnalyticsData/${dealId}`
      // {
      //   headers: {
      //     Authorization: token,
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const verifyEmail = async (email, verificationCode, token) => {
  const finalFormData = {
    email: email,
    code: verificationCode,
  };
  try {
    const response = await axios.post(
      "https://api.fundrev.ai/auth/verify-email",
      finalFormData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const resendCode = async (token) => {
  try {
    const response = await fetch(
      // "http://localhost:3000/auth/resend-verification",
      "https://api.fundrev.ai/auth/resend-verification",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return { response: response, error: null };
  } catch (error) {
    return { response: null, error: error };
  }
};

export const sendOtpPasswordChange = async (role, email) => {
  try {
    const response = await fetch(
      // "http://localhost:3000/auth/send-otp-password-change",
      "https://api.fundrev.ai/auth/send-otp-password-change",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role: role, email: email }),
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const verifyEmailPasswordChange = async (
  token,
  email,
  verificationCode
) => {
  try {
    const response = await fetch(
      "https://api.fundrev.ai/auth/verify-email-password-change",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          email: email, // Assuming this is from your component's state
          code: verificationCode,
        }),
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const updatePasswordRequest = async (secondToken, newPassword) => {
  try {
    const response = await fetch(
      "https://api.fundrev.ai/auth/update-password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: secondToken,
        },
        body: JSON.stringify({
          password: newPassword,
        }),
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const changePassword = async (body, token) => {
  try {
    const response = await axios.post(
      // "http://localhost:3000/auth/resend-verification",
      "https://api.fundrev.ai/auth/changePassword",
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return { response: response, error: null };
  } catch (error) {
    return { response: null, error: error };
  }
};
