import React, { useState, useEffect } from "react";
import { customStyles, inputCustomStyles } from "../../../Common/functions";
import Editable from "../../../Common/components/editable";
import InputCell from "../../../Common/components/inputCell";
import {
  getDealDetailsByDealId,
  updateDeal,
  sendForReview,
} from "../../../../endpoints/deal";
import { getValues } from "../../../../endpoints/startup";
import Toast from "../../../Common/Toast";
import SendForReviewModal from "../../../Common/modals/sendForReviewModal";

import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import Select from "react-select";
import { TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import previous from "../../../../Assets/Images/signup/previous.svg";
import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";

const DealDetailsForm = () => {
  const location = useLocation();
  const { dealID } = location.state || {};
  const [fileUrl, setFileUrl] = useState();
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const token = localStorage.getItem("token");
  const [newhighlight, setNewHighlight] = useState();
  const [newPurpose, setNewPurpose] = useState();
  const [isApplicationReviewd, setIsApplicationReviewd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [field, setField] = useState("");
  const [sendForReviewModal, setSendForReviewModal] = useState(false);
  const [formData, setFormData] = useState({
    DealID: "",
    OrganizationID: "",
    RoundType: [],
    Industry: "",
    TotalFundingRaised: "",
    Equity: "",
    Debt: "",
    MinPostMoneyValuation: "",
    FundingRequired: "",
    CompanyLogo: "",
    CompanyWebsite: "",
    Location: "",
    CompanyDescription: "",
    LegalEntityName: "",
    Headquarters: "",
    FoundedYear: "",
    NumberOfEmployees: "",
    StakeOffered: "",
    PurposeOfFundraising: [],
    LastRoundType: "",
    ExistingKeyInvestors: "",
    CIN: "",
    TaxNumber: "",
    EndMarketFocus: [],
    IndustryVerticals: [],
    Title: "",
    SubHeading: "",
    Description: "",
    KeyHighlights: [],
    GrossProfit: "",
    EBITDA: "",
    EBITDA_Percentage: "",
    IOIBids: "",
    InvestorsNum: "",
    OwnerName: "",
    Revenue: "",
    Techstack: "",
    KeyMetrics: {},
    DataAvailabilityWithFundrev: {
      CustomerData: false,
      Financial: false,
      Dataroom: false,
      ForecastModel: false,
      ManagementPresentation: false,
    },
    fundingRounds: [
      {
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
      },
    ],
  });

  const [industryVerticalOptions, setIndustryVerticalOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [roundTypeOptions, setRoundTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [lastRoundTypeOptions, setLastRoundTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [endMarketFocusOptions, setEndMarketFocusOptions] = useState([
    {
      value: "B2B",
      label: "B2B",
    },
    {
      value: "B2C",
      label: "B2C",
    },
    {
      value: "B2B2C",
      label: "B2B2C",
    },
    {
      value: "D2C",
      label: "D2C",
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        placeholder: "date",
        Cell: InputCell,
      },
      {
        Header: "Round",
        accessor: "round",
        placeholder: "round",
        Cell: InputCell,
      },
      {
        Header: "Raised (₹Cr)",
        accessor: "raised",
        placeholder: "raised",
        Cell: InputCell,
      },
      {
        Header: "% Stake",
        accessor: "stake",
        placeholder: "stake",
        Cell: InputCell,
      },
      {
        Header: "Valuation (₹Cr)",
        accessor: "valuation",
        placeholder: "valuation",
        Cell: InputCell,
      },
      {
        id: "delete",
        Cell: ({ row }) =>
          row.index > 0 && (
            <RiDeleteBin6Line
              onClick={() => deleteRow(row.index)}
              className="red-bin"
            />
          ),
        width: 50,
      },
    ],
    []
  );
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addFieldToKeyMetrics();
    }
  };
  const [data, setData] = useState(formData && formData.fundingRounds);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const fileURL = URL.createObjectURL(file);
      setFileUrl(fileURL);
      setCompanyLogoFile(file);
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only).",
        "info",
        "dealDetailsEdit"
      );
    }
  }
  async function fetchDealDetails() {
    try {
      const response = await getDealDetailsByDealId(dealID, token);
      if (!response) {
        Toast("failed to fetch details", "error", "dealDetailsEdit");
      }
      setFormData(response.data);
      const parsedDetails = response.data.fundingRounds.map((detail) =>
        JSON.parse(detail)
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        DealID: response.data.DealID,
        OrganizationID: response.data.OrganizationID || "",
        RoundType:
          response.data?.RoundType.map((detail) => JSON.parse(detail))[0] || [],
        Industry: response.data.Industry || "",
        TotalFundingRaised: response.data.TotalFundingRaised || "",
        Equity: response.data.Equity || "",
        Debt: response.data.Debt || "",
        minPostMoneyValuation: response.data.minPostMoneyValuation || "",
        FundingRequired: response.data.FundingRequired || "",
        CompanyLogo: response.data.CompanyLogo || "",
        CompanyWebsite: response.data.CompanyWebsite || "",
        CompanyDescription: response.data.CompanyDescription || "",
        LegalEntityName: response.data.LegalEntityName || "",
        Headquarters: response.data.Headquarters || "",
        FoundedYear: response.data.FoundedYear || "",
        NumberOfEmployees: response.data.NumberOfEmployees || "",
        StakeOffered: response.data.StakeOffered || "",
        LastRoundType: response.data.LastRoundType || "",
        ExistingKeyInvestors: response.data.ExistingKeyInvestors || "",
        CIN: response.data.CIN || "",
        TaxNumber: response.data.TaxNumber || "",
        Revenue: response.data.Revenue,
        Techstack: response.data.Techstack || "",
        KeyMetrics: response.data.KeyMetrics || {},
        DataAvailabilityWithFundrev: {
          CustomerData:
            response.data.DataAvailabilityWithFundrev.CustomerData === "true"
              ? true
              : false,
          Financial:
            response.data.DataAvailabilityWithFundrev.Financial === "true"
              ? true
              : false,
          Dataroom:
            response.data.DataAvailabilityWithFundrev.Dataroom === "true"
              ? true
              : false,
          ForecastModel:
            response.data.DataAvailabilityWithFundrev.ForecastModel === "true"
              ? true
              : false,
          ManagementPresentation:
            response.data.DataAvailabilityWithFundrev.ManagementPresentation ===
            "true"
              ? true
              : false,
        },
        KeyHighlights:
          response.data.KeyHighlights.map((detail) => JSON.parse(detail))[0] ||
          [],
        PurposeOfFundraising:
          response.data.PurposeOfFundraising.map((detail) =>
            JSON.parse(detail)
          )[0] || [],
        IndustryVerticals:
          response.data?.IndustryVerticals.map((detail) =>
            JSON.parse(detail)
          )[0] || [],
        EndMarketFocus:
          response.data?.EndMarketFocus.map((detail) =>
            JSON.parse(detail)
          )[0] || [],
        FoundingTeamDetails:
          response.data?.FoundingTeamDetails.map((detail) =>
            JSON.parse(detail)
          )[0] || [],

        fundingRounds:
          parsedDetails.length > 0
            ? parsedDetails[0]
            : [
                {
                  fund: "",
                  vintage: "",
                  investments: "",
                  aum: "",
                },
              ],
      }));
      if (parsedDetails.length > 0) {
        setData(parsedDetails[0]);
      }
      setIsApplicationReviewd(response.data?.isApplicationReviewed);
      setIsOpen(response.data?.isOpen);
    } catch (err) {}
  }
  useEffect(() => {
    if (dealID && token) {
      fetchDealDetails();
    }
  }, [dealID, token]);
  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await getValues();
        if (result.error) {
          console.error("Error fetching data:", result.error);
        } else {
          // Process each options set
          if (result.data[0]) {
            if (result.data[0].IndustryVerticalOptions) {
              const formattedIndustryVerticalOptions =
                result.data[0].IndustryVerticalOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setIndustryVerticalOptions(formattedIndustryVerticalOptions);
            }

            if (result.data[0].RoundTypeOptions) {
              const formattedRoundTypeOptions =
                result.data[0].RoundTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setRoundTypeOptions(formattedRoundTypeOptions);
            }

            if (result.data[0].LastRoundTypeOptions) {
              const formattedLastRoundTypeOptions =
                result.data[0].LastRoundTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setLastRoundTypeOptions(formattedLastRoundTypeOptions);
            }
          }
        }
      } catch (error) {
        console.error("Unexpected error fetching data:", error);
      }
    };

    loadData();
  }, [dealID]);
  async function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const handleKeyMetricsChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      KeyMetrics: {
        ...prevData.KeyMetrics,
        [name]: value,
      },
    }));
  };
  const handleIndustryVerticalChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setFormData((prev) => ({
      ...prev,
      IndustryVerticals: selectedValues,
    }));
  };
  const handleRoundTypeChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setFormData((prev) => ({
      ...prev,
      RoundType: selectedValues,
    }));
  };

  const handleLastRoundTypeChange = (selected) => {
    setFormData((prev) => ({
      ...prev,
      LastRoundType: selected.value,
    }));
  };
  const handleEndMarketChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setFormData({
      ...formData,
      EndMarketFocus: selectedValues,
    });
  };
  const handleKeyHighlightsChange = () => {
    const highlights = formData.KeyHighlights;
    highlights.push(newhighlight);
    setFormData((prev) => ({
      ...prev,
      KeyHighlights: highlights,
    }));
    setNewHighlight("");
  };
  const handlePurposeChange = () => {
    const purposes = formData.PurposeOfFundraising;
    purposes.push(newPurpose);
    setFormData((prev) => ({
      ...prev,
      PurposeOfFundraising: purposes,
    }));
    setNewPurpose("");
  };
  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const deleteHighlight = (indexToDelete) => {
    setFormData((oldData) => {
      const updatedKeyHighlights = oldData.KeyHighlights.filter(
        (_, index) => index !== indexToDelete
      );
      return {
        ...oldData,
        KeyHighlights: updatedKeyHighlights,
      };
    });
  };
  const deletePurpose = (indexToDelete) => {
    setFormData((oldData) => {
      const updatedPurposes = oldData.PurposeOfFundraising.filter(
        (_, index) => index !== indexToDelete
      );
      return {
        ...oldData,
        PurposeOfFundraising: updatedPurposes,
      };
    });
  };
  const addNew = () => {
    setData((oldData) => {
      // Validate last row data before adding a new row
      const lastRow = oldData[oldData.length - 1];
      if (
        !lastRow.date ||
        !lastRow.round ||
        !lastRow.raised ||
        !lastRow.stake ||
        !lastRow.valuation
      ) {
        Toast(
          "Please fill in all fields of the last row before adding a new one.",
          "info",
          "dealDetailsEdit"
        );
        return oldData; // Return the existing data without adding a new row if validation fails
      }

      // If validation is successful, add a new row
      return [
        ...oldData,
        { date: "", round: "", raised: "", stake: "", valuation: "" },
      ];
    });
  };

  const deleteRow = (rowIndex) => {
    setData((currentData) => {
      if (currentData.length > 1) {
        setFormData((data) => {
          const updatedFundingRounds = data.fundingRounds.filter(
            (_, index) => index !== rowIndex
          );
          return {
            ...data,
            FundingRounds: updatedFundingRounds,
          };
        });

        return currentData.filter((_, index) => index !== rowIndex);
      }
      return currentData;
    });
  };
  const handleSave = async () => {
    const formDataInput = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== undefined) {
        if (
          typeof formData[key] === "object" &&
          !(formData[key] instanceof File)
        ) {
          formDataInput.append(key, JSON.stringify(formData[key]));
        } else {
          formDataInput.append(key, formData[key]);
        }
      }
    }
    if (companyLogoFile) {
      formDataInput.append("CompanyLogo", companyLogoFile);
    }
    try {
      const response = await updateDeal(formDataInput, token);
      if (response.data) {
        Toast("Data saved successfully", "success", "dealDetailsEdit");
      } else {
        Toast("Failed to save the data", "error", "dealDetailsEdit");
      }
    } catch (err) {
      Toast("Failed to save the data", "error", "dealDetailsEdit");
    }
  };
  useEffect(() => {
    const updatedFundingRounds = data.map((row) => {
      return row;
    });

    setFormData((prev) => ({
      ...prev,
      fundingRounds: updatedFundingRounds,
    }));
  }, [data]);
  const handleDataAvailability = (field, value) => {
    if (value === 1) {
      setFormData((prev) => ({
        ...prev,
        DataAvailabilityWithFundrev: {
          ...prev.DataAvailabilityWithFundrev,
          [field]: true,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        DataAvailabilityWithFundrev: {
          ...prev.DataAvailabilityWithFundrev,
          [field]: false,
        },
      }));
    }
  };
  const addFieldToKeyMetrics = () => {
    if (field.trim()) {
      setFormData((prevData) => ({
        ...prevData,
        KeyMetrics: {
          ...prevData.KeyMetrics,
          [field]: "",
        },
      }));
      setField("");
    }
  };
  const deleteKeyMetric = (key) => {
    setFormData((prevData) => {
      const updatedMetrics = { ...prevData.KeyMetrics };
      delete updatedMetrics[key];
      return {
        ...prevData,
        KeyMetrics: updatedMetrics,
      };
    });
  };
  const sendEmail = async () => {
    try {
      const startupID = formData.OrganizationID;
      const data = {
        startupId: startupID,
        dealId: dealID,
      };
      const response = await sendForReview(data, token);
      if (response.data.message === "Sent for review") {
        Toast(
          "Data sent for review successfully",
          "success",
          "dealDetailsEdit"
        );
        setIsApplicationReviewd(false);
        setSendForReviewModal(false);
        fetchDealDetails();
      } else {
        Toast("Failed to send data for review", "error", "dealDetailsEdit");
      }
    } catch (err) {
      Toast("Failed to send data for review", "error", "dealDetailsEdit");
    }
  };
  const renderKeyMetricsRows = (groupSize) => {
    const keyMetrics = Object.entries(formData?.KeyMetrics || {});

    const rows = [];
    for (let i = 0; i < keyMetrics.length; i += groupSize) {
      const group = keyMetrics.slice(i, i + groupSize);
      const emptyFieldsCount = groupSize - group.length;

      rows.push(
        <div key={i} className="input-div">
          {group.map(([key, value], index) => (
            <FormControl
              key={index}
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                {key}
                <RiDeleteBin6Line
                  className="red-bin"
                  onClick={() => {
                    deleteKeyMetric(key);
                  }}
                />
              </FormLabel>

              <Input
                className="input"
                placeholder={`Add value of ${key}`}
                color="neutral"
                name={key}
                type="text"
                value={value}
                onChange={handleKeyMetricsChange}
                required
              />
            </FormControl>
          ))}
          {Array.from({ length: emptyFieldsCount }).map((_, index) => (
            <FormControl
              key={`empty-${index}`}
              className="form-control investorDetails-row-input random-input"
            >
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
          ))}
        </div>
      );
    }

    return rows;
  };
  return (
    <div
      className="investorDetails dealDetailsEdit"
      style={{
        margin: "0px 0px 0px 0px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ToastContainer position="top-center" containerId="dealDetailsEdit" />
      <SendForReviewModal
        open={sendForReviewModal}
        setOpen={setSendForReviewModal}
        sendEmail={sendEmail}
      />
      <div
        className="investorDetails-first-section admin-dealdetailsedit-box"
        style={{ margin: "0px", paddingBottom: "100px" }}
      >
        <div className="add-member-div">
          <div>
            <p className="investorDetails-heading">Company Details</p>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button className="add-row OrgId">
              Org. ID: {formData.OrganizationID}{" "}
            </button>
            <button className="add-row OrgId">
              Deal. ID: {formData.DealID}{" "}
            </button>
          </div>
        </div>
        <div class="upload-btn-wrapper">
          <button class="upload-img-btn">
            <img
              // src={
              //   formData.CompanyLogo
              //     ? formData.CompanyLogo
              //     : companyLogoFile
              //     ? fileUrl
              //     : uploadImg
              // }
              src={
                !companyLogoFile
                  ? formData.CompanyLogo
                    ? formData.CompanyLogo
                    : uploadImg
                  : fileUrl
              }
              className="InvestorLogo"
              alt="Upload"
              style={{ cursor: "pointer" }}
            />
          </button>

          <label htmlFor="company-logo" className="edit-img-btn">
            <img src={editImg} className="editImg" alt="Edit" />
          </label>

          <input
            id="company-logo"
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            style={{ cursor: "pointer" }}
            onChange={(e) => handleImageUpload(e)}
          />
        </div>
        <form className="input-section">
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Company Name</FormLabel>
              <Input
                className="input"
                placeholder="Amazon"
                color="neutral"
                name="CompanyName"
                type="text"
                value={formData.CompanyName}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Legal Entity Name</FormLabel>
              <Input
                className="input"
                placeholder="Amazon Inc."
                color="neutral"
                name="LegalEntityName"
                type="text"
                value={formData.LegalEntityName}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Headquarters</FormLabel>
              <Input
                className="input"
                placeholder="Bengaluru, India"
                color="neutral"
                name="Headquarters"
                type="text"
                value={formData.Headquarters}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Founded Year</FormLabel>
              <Input
                className="input"
                placeholder="2018"
                color="neutral"
                name="FoundedYear"
                type="number"
                value={formData.FoundedYear}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Number of Employees</FormLabel>
              <Input
                className="input"
                placeholder="1000"
                color="neutral"
                name="NumberOfEmployees"
                type="number"
                value={formData.NumberOfEmployees}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Company Website</FormLabel>
              <Input
                className="input"
                placeholder="www.amazon.com"
                color="neutral"
                name="CompanyWebsite"
                type="url"
                value={formData.CompanyWebsite}
                required
                onChange={handleInputChange}
              />
            </FormControl>
          </div>
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Industry Verticals</FormLabel>

              <Select
                // className="input"
                isMulti
                className="react-select-input"
                options={industryVerticalOptions}
                name="industryVerticals"
                onChange={handleIndustryVerticalChange}
                value={
                  formData.IndustryVerticals
                    ? industryVerticalOptions.filter((option) =>
                        formData.IndustryVerticals.includes(option.value)
                      )
                    : []
                }
                placeholder="Please Select"
                required
                styles={customStyles}
                isClearable={true}
                isSearchable={true}
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">End Market Focus</FormLabel>
              <Select
                className="react-select-input"
                options={endMarketFocusOptions}
                placeholder="Please Select"
                required
                isMulti
                styles={inputCustomStyles}
                value={
                  formData.EndMarketFocus
                    ? endMarketFocusOptions.filter((option) =>
                        formData.EndMarketFocus.includes(option.value)
                      )
                    : []
                }
                onChange={handleEndMarketChange}
                isClearable={true}
                isSearchable={true}
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
              type="text"
            >
              <FormLabel className="label">Owner Name</FormLabel>
              <Input
                className="input"
                placeholder="Bill gates"
                color="neutral"
                name="OwnerName"
                type="text"
                value={formData.OwnerName}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">ERP Platform Used</FormLabel>

              <Input
                className="input"
                placeholder="Epicore"
                color="neutral"
                name="ErpPlatform"
                type="text"
                value={formData.ErpPlatform}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">CIN/ LLPIN</FormLabel>
              <Input
                className="input"
                placeholder="U45690TN0983PTC567904"
                color="neutral"
                name="CIN"
                type="text"
                value={formData.CIN}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Tax Identification Number</FormLabel>
              <Input
                className="input"
                placeholder="ABCTY1234D"
                color="neutral"
                name="TaxNumber"
                type="text"
                value={formData.TaxNumber}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="input-div one-input-div">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Tech Stack</FormLabel>
              <Input
                className="input"
                placeholder="ABCTY1234D"
                color="neutral"
                name="Techstack"
                type="text"
                value={formData.Techstack}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="input-div companyDetails-business-desc-div">
            <FormControl
              className="business-desc-form-control"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                Business Description (Visible after nda sign to investor)
              </FormLabel>
              <TextField
                className="large-input"
                placeholder="Describe your business"
                name="CompanyDescription"
                type="text"
                value={formData.CompanyDescription}
                onChange={handleInputChange}
                required
                multiline
                minRows={3}
              />
            </FormControl>
          </div>
        </form>
        <div className="admin-Funding-details-div">
          <p className="investorDetails-heading">Funding Details</p>
          <p className="investorDetails-subheading">Current round</p>
          <div className="third-step-first-row">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Funding Required (₹Cr)</FormLabel>
              <Input
                className="input"
                placeholder="11.1"
                color="neutral"
                name="FundingRequired"
                type="number"
                value={formData.FundingRequired}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Stake Offered %</FormLabel>
              <Input
                className="input"
                placeholder="10"
                color="neutral"
                name="StakeOffered"
                type="number"
                value={formData.StakeOffered}
                onChange={handleInputChange}
                required
              />
              <div>
                {formData.Equity && formData.StakeOffered && (
                  <p style={{ marginTop: "10px" }}>
                    Post money valuation (₹Cr) :
                    {((formData.Equity / formData.StakeOffered) * 100).toFixed(
                      2
                    )}
                  </p>
                )}
              </div>
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                Min. Post Money Valuation (₹Cr)
              </FormLabel>
              <Input
                className="input"
                placeholder="0.12"
                color="neutral"
                name="MinPostMoneyValuation"
                type="number"
                value={formData.MinPostMoneyValuation}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="third-step-first-row">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                Amount Already Raised (₹Cr)
              </FormLabel>
              <Input
                className="input"
                placeholder="0.12"
                color="neutral"
                name="TotalFundingRaised"
                type="number"
                value={formData.TotalFundingRaised}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Equity</FormLabel>
              <Input
                className="input"
                placeholder="35"
                color="neutral"
                name="Equity"
                type="number"
                value={formData.Equity}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Debt</FormLabel>
              <Input
                className="input"
                placeholder="10"
                color="neutral"
                name="Debt"
                type="number"
                value={formData.Debt}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="third-step-second-row">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Round Type</FormLabel>

              <Select
                // className="input"
                className="react-select-input"
                isMulti
                options={roundTypeOptions}
                name="roundType"
                value={
                  formData.RoundType
                    ? roundTypeOptions.filter((option) =>
                        formData.RoundType.includes(option.value)
                      )
                    : []
                }
                onChange={handleRoundTypeChange}
                placeholder={"Please select"}
                required
                styles={customStyles}
              />
            </FormControl>
            {/* Random input for proper spacing */}
            <FormControl className="form-control investorDetails-row-input random-input">
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
            {/* Random input for proper spacing */}
            <FormControl className="form-control investorDetails-row-input random-input">
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
          </div>
          <div className="third-step-second-row input-div companyDetails-business-desc-div">
            <FormControl
              className="business-desc-form-control"
              id="Id"
              size="lg"
              color="primary"
              required
            >
              <FormLabel className="label">Purpose of Fundraising</FormLabel>
              <TextField
                className="large-input"
                placeholder="Expansion of company"
                type="text"
                value={newPurpose}
                onChange={(e) => setNewPurpose(e.target.value)}
                multiline
                minRows={1}
              />
            </FormControl>
          </div>
          <div className="table-edit-buttons">
            <button
              onClick={(event) => {
                event.preventDefault();
                handlePurposeChange();
              }}
              className="add-row"
            >
              <AiOutlinePlus />
              &nbsp;&nbsp; Add Purpose
            </button>
          </div>
          <div className="hightlights-div">
            <b>Purposes</b>
            <ol style={{ listStyleType: "decimal" }}>
              {formData.PurposeOfFundraising.map((purpose, index) => (
                <li key={index} style={{ textWrap: "no-wrap" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>{purpose}</p>
                    <RiDeleteBin6Line
                      className="red-bin"
                      onClick={() => deletePurpose(index)}
                    />
                  </div>
                </li>
              ))}
            </ol>
          </div>
          <p className="investorDetails-subheading">Historical rounds</p>
          <div className="third-step-third-row">
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Last Round Type</FormLabel>

              <Select
                // className="input"
                className="react-select-input"
                options={lastRoundTypeOptions}
                name="lastRoundType"
                value={formData.LastRoundType}
                onChange={handleLastRoundTypeChange}
                placeholder={
                  formData.LastRoundType
                    ? formData.LastRoundType
                    : "Please Select"
                }
                required
                styles={customStyles}
              />
            </FormControl>
            <FormControl
              className="form-control investorDetails-row-input"
              id="Id"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">Key Investors</FormLabel>
              <Input
                className="input"
                placeholder="Aman Gputa, Peeyush Bansal"
                color="neutral"
                name="ExistingKeyInvestors"
                type="text"
                value={formData.ExistingKeyInvestors}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </div>
          <div className="third-step-fourth-row">
            <div className="investmentDetails-table">
              <FormControl required>
                <FormLabel className="label">Funding Rounds</FormLabel>
              </FormControl>
              <div className="table-details">
                <Editable
                  columns={columns}
                  data={data}
                  updateData={updateData}
                  deleteRow={deleteRow}
                />
                <div className="table-edit-buttons">
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      addNew();
                    }}
                    className="add-row"
                  >
                    <AiOutlinePlus />
                    &nbsp;&nbsp; Add Row
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="admin-Funding-details-div">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="investorDetails-heading">Key Metrics</p>
            <div style={{ display: "flex", gap: "10px" }}>
              <Input
                placeholder="field"
                type="text"
                name="field"
                value={field}
                onChange={(e) => setField(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button onClick={addFieldToKeyMetrics}>Add field</Button>
            </div>
          </div>
          {renderKeyMetricsRows(3)}
        </div>
        <div className="noName-details-div">
          <p className="investorDetails-heading">No_name details</p>
          <div className="third-step-second-row input-div companyDetails-business-desc-div">
            <FormControl
              className="business-desc-form-control"
              id="Id"
              size="lg"
              color="primary"
              required
            >
              <FormLabel className="label">
                Heading (title - Write in less than 60 characters)
              </FormLabel>
              <TextField
                className="large-input"
                placeholder="Enter your title here..."
                name="Title"
                type="text"
                value={formData.Title}
                onChange={handleInputChange}
                multiline
                minRows={1}
              />
            </FormControl>
          </div>
          <div className="third-step-second-row input-div companyDetails-business-desc-div">
            <FormControl
              className="business-desc-form-control"
              id="Id"
              size="lg"
              color="primary"
              required
            >
              <FormLabel className="label">
                Sub Heading (Traction - Write in less tahn 120 characters )
              </FormLabel>
              <TextField
                className="large-input"
                placeholder="Enter your title here..."
                name="SubHeading"
                type="text"
                value={formData.SubHeading}
                onChange={handleInputChange}
                multiline
                minRows={1}
              />
            </FormControl>
          </div>
          <div className="third-step-second-row input-div companyDetails-business-desc-div">
            <FormControl
              className="business-desc-form-control"
              id="Id"
              size="lg"
              color="primary"
              required
            >
              <FormLabel className="label">Company Description</FormLabel>
              <TextField
                className="large-input"
                placeholder="Enter your description here..."
                name="Description"
                type="text"
                value={formData.Description}
                onChange={handleInputChange}
                multiline
                minRows={3}
              />
            </FormControl>
          </div>
          <div className="third-step-second-row input-div companyDetails-business-desc-div">
            <FormControl
              className="business-desc-form-control"
              id="Id"
              size="lg"
              color="primary"
              required
            >
              <FormLabel className="label">Key highlights</FormLabel>
              <TextField
                className="large-input"
                placeholder="Enter your description here..."
                name="purpose"
                type="text"
                value={newhighlight}
                onChange={(e) => setNewHighlight(e.target.value)}
                multiline
                minRows={1}
              />
            </FormControl>
          </div>
          <div className="table-edit-buttons">
            <button
              onClick={(event) => {
                event.preventDefault();
                handleKeyHighlightsChange();
              }}
              className="add-row"
            >
              <AiOutlinePlus />
              &nbsp;&nbsp; Add Key Highlight
            </button>
          </div>
          <div className="hightlights-div">
            <b>Highlights</b>
            <ol style={{ listStyleType: "decimal" }}>
              {formData.KeyHighlights.map((highlight, index) => (
                <li key={index} style={{ textWrap: "no-wrap" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>{highlight}</p>
                    <RiDeleteBin6Line
                      className="red-bin"
                      onClick={() => deleteHighlight(index)}
                    />
                  </div>
                </li>
              ))}
            </ol>
          </div>
          <div className="calculated-fields-div">
            <p className="investorDetails-heading">Calculated Fields</p>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Revenue</FormLabel>
                <Input
                  className="input"
                  placeholder="0.12"
                  color="neutral"
                  name="Revenue"
                  type="number"
                  value={formData.Revenue}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
                type="text"
              >
                <FormLabel className="label">
                  Gross Profit (mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="43L"
                  color="neutral"
                  name="GrossProfit"
                  value={formData.GrossProfit}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  EBITDA (mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="14L"
                  color="neutral"
                  name="EBITDA"
                  type="text"
                  value={formData.EBITDA}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  EBITDA% (Don't mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="EBITDA_Percentage"
                  type="text"
                  value={formData.EBITDA_Percentage}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
                type="number"
              >
                <FormLabel className="label">IOI Bids (in Crores)</FormLabel>
                <Input
                  className="input"
                  placeholder="18"
                  color="neutral"
                  name="IOIBids"
                  type="number"
                  value={formData.IOIBids}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                id="Id"
                required
                size="lg"
                color="primary"
                type="number"
              >
                <FormLabel className="label">InvestorNumber</FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="InvestorsNum"
                  type="text"
                  value={formData.InvestorsNum}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
          </div>
          <div className="DataAvailabilityWithFundrev-div">
            <p className="investorDetails-heading">
              Data Availability With Fundrev
            </p>
            <div className="input-div">
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  Customer Data
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.CustomerData
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("CustomerData", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.CustomerData
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("CustomerData", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  Financial
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.Financial
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Financial", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.Financial
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Financial", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  Dataroom
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.Dataroom
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Dataroom", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.Dataroom
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Dataroom", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  ForecastModel
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.ForecastModel
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("ForecastModel", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.ForecastModel
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("ForecastModel", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  ManagementPresentation
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev
                        ?.ManagementPresentation
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() =>
                      handleDataAvailability("ManagementPresentation", 1)
                    }
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev
                        ?.ManagementPresentation
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() =>
                      handleDataAvailability("ManagementPresentation", 0)
                    }
                  >
                    NO
                  </button>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-buttons">
        <button className="previousStep" style={{ visibility: "hidden" }}>
          <img src={previous} alt="back-arrow" />
          &nbsp;&nbsp; Previous Step
        </button>
        <div className="next-buttons">
          <button
            className="save-button"
            onClick={handleSave}
            style={{ cursor: "pointer" }}
          >
            Save
          </button>
          {isOpen ? (
            <p>Deal live</p>
          ) : isApplicationReviewd ? (
            <p>Sent for review</p>
          ) : (
            <button
              className="nextStep"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSendForReviewModal(true);
              }}
            >
              Send for review
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealDetailsForm;
