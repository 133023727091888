import React from "react";
import Chart from "react-apexcharts";

const SmallAreaPlot = ({ data, compareData }) => {
  // Split data into positive and negative values
  const positiveData = data?.map((val) => (val >= 0 ? val : null));
  const negativeData = data?.map((val) => (val < 0 ? val : null));
  const options = {
    chart: {
      height: "95px",
      width: "210px",
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [0.85, 0.8, 1.5], // Increase the width for the dashed line
      dashArray: [0, 0, 2], // Keep the dash pattern for second series
      colors: ["#00A6FB", "#D32F2F", "#98A6D4"], // Stroke colors
    },
    grid: {
      show: false,
    },
    markers: {
      size: [0, 0, 0],
    },
    fill: {
      colors: ["#00A6FB11", "#D32F2F44", "#98A6D4"],
      type: ["gradient", "gradient", "solid"],
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#00A6FB44", "#D32F2F11", "#98A6D4"],
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: Math.min(data)[0],
    },
  };

  const series = [
    {
      name: "+ve Area Data",
      type: "area",
      data: positiveData,
    },
    {
      name: "-ve Area Data",
      type: "area",
      data: negativeData,
    },
    compareData?.length > 0 && {
      name: "Compare Data",
      type: "line",
      data: compareData,
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width={`200`}
      height={`95`}
    />
  );
};

export default SmallAreaPlot;
