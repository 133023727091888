import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import expandIcon from "../../../../Assets/Images/InvestorPage/Dashboard/expandIcon.svg";
import contractIcon from "../../../../Assets/Images/InvestorPage/Dashboard/contractIcon.svg";
import ProgressBar from "../../../Common/components/molecules/progressBar";
const OnGoingDealCard = (props) => {
  const Navigate = useNavigate();
  const [expand, setExpand] = React.useState(false);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const toggleExpand = (stage, index) => {
    const col = document.getElementById(stage);
    const ele1 = col.getElementsByClassName("group-5")[index];
    const ele2 = col.getElementsByClassName("group-8")[index];
    if (ele1)
      if (ele1.style.display === "none") {
        setExpand(false);
        ele1.style.display = "block";
      } else {
        setExpand(true);
        ele1.style.display = "none";
      }
    if (ele2) {
      if (ele2.style.display === "none") {
        ele2.style.display = "block";
      } else {
        ele2.style.display = "none";
      }
    }
  };
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      props.badges.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = props.badges.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [props.badges]);
  return (
    <div className="onGoingDealCard">
      <div
        className="div"
        style={{
          cursor: props.isInterested ? "pointer" : "not-allowed",
          alignItems:
            props.stage === "requestSent" || props.stage === "requestAccepted"
              ? "self-start"
              : "center",
        }}
        onClick={() => {
          props.isInterested &&
            Navigate(
              props.stage === "requestSent" || props.stage === "requestAccepted"
                ? "/investor/companyDetails"
                : "/investor/companyDetails/ndaSigned",
              {
                state: {
                  deal: props.deal,
                  passedStage: props.stage,
                },
              }
            );
        }}
      >
        <div
          className={`dead_deal_tag ${
            props.isInterested === false && "passed"
          } ${props.isRejected === true && "rejected"}`}
          style={{ display: props.isInterested ? "none" : "flex" }}
        >
          {props.isInterested === false && "Passed Deal by Investor"}
          {props.isRejected === true && "Declined Offer by Company"}
        </div>
        {(props.stage === "ndaSigned" ||
          props.stage === "ioiSubmitted" ||
          props.stage === "ioiAccepted" ||
          props.stage === "loiSubmitted" ||
          props.stage === "loiAccepted" ||
          props.stage === "spaNegotiation" ||
          props.stage === "dealClosed") && (
          <div className="group">
            <div className="make-money-online-th">{props.companyName}</div>
            <a
              className="text-wrapper"
              href={"https://" + props.companyWebsite}
              rel="noopener noreferrer"
              target="_blank"
            >
              {props.companyWebsite}
            </a>
            <img
              className="rectangle"
              alt="Rectangle"
              src={props.companyLogoLink}
            />
          </div>
        )}
        {(props.stage === "requestSent" ||
          props.stage === "requestAccepted") && (
          <>
            <p className="Deal-card-heading">{props.companyInfo}</p>
            <p className="Deal-card-subheading">{props.companySubHeading}</p>
          </>
        )}
        <div
          className="group-2"
          // style={{
          //   margin:
          //     props.stage === "requestSent" || props.stage === "requestAccepted"
          //       ? "auto"
          //       : "intial",
          // }}
        >
          <div className="group-3">
            <div className="make-money-online-th-2">Amount Being Raised</div>
            <div className="make-money-online-th-3">
              ₹{props.amountBeingRaised}Cr
            </div>
          </div>
          {props.stage === "requestSent" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Requested Date</div>
              <div className="make-money-online-th-3">
                {props.requestedDate}
              </div>
            </div>
          )}
          {props.stage === "requestAccepted" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Accepted Date</div>
              <div className="make-money-online-th-3">{props.acceptedDate}</div>
            </div>
          )}
          {props.stage === "ndaSigned" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Signing Date</div>
              <div className="make-money-online-th-3">{props.signingDate}</div>
            </div>
          )}
          {props.stage === "ioiSubmitted" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Submitted Date</div>
              <div className="make-money-online-th-3">
                {props.submittedDate}
              </div>
            </div>
          )}
          {props.stage === "ioiAccepted" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Accepted Date</div>
              <div className="make-money-online-th-3">{props.acceptedDate}</div>
            </div>
          )}
          {props.stage === "loiSubmitted" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Submitted Date</div>
              <div className="make-money-online-th-3">
                {props.submittedDate}
              </div>
            </div>
          )}
          {props.stage === "loiAccepted" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Accepted Date</div>
              <div className="make-money-online-th-3">{props.acceptedDate}</div>
            </div>
          )}
          {props.stage === "loiAccepted" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Accepted Date</div>
              <div className="make-money-online-th-3">{props.acceptedDate}</div>
            </div>
          )}
          {props.stage === "dealClosed" && (
            <div className="group-4">
              <div className="make-money-online-th-2">Closing Date</div>
              <div className="make-money-online-th-3">{props.closingDate}</div>
            </div>
          )}
        </div>
        {(props.stage === "ndaSigned" ||
          props.stage === "ioiSubmitted" ||
          props.stage === "ioiAccepted" ||
          props.stage === "loiSubmitted" ||
          props.stage === "loiAccepted" ||
          props.stage === "spaNegotiation" ||
          props.stage === "dealClosed") && (
          <div className="group-5">
            <div className="group-6">
              <div className="make-money-online-th-2">Owner</div>
              <div className="make-money-online-th-3">{props.owner}</div>
            </div>
            <div className="group-7">
              <div className="make-money-online-th-2">Location</div>
              <div className="make-money-online-th-3">{props.location}</div>
            </div>
          </div>
        )}
        {props.stage === "ioiSubmitted" && (
          <div className="group-8">
            <div className="group-6">
              <div className="make-money-online-th-2">
                IOI Bid Submitted Amount
              </div>
              <div className="make-money-online-th-3">
                ₹{props.IOIBidSubmittedAmount}Cr
              </div>
            </div>
          </div>
        )}
        {props.stage === "ioiAccepted" && (
          <div className="group-8">
            <div className="group-6">
              <div className="make-money-online-th-2">
                IOI Bid Accepted Amount
              </div>
              <div className="make-money-online-th-3">
                ₹{props.IOIBidAcceptedAmount}Cr
              </div>
            </div>
            {/* <div className="group-7">
              <div className="make-money-online-th-2">Latest Activity</div>
              <div className="make-money-online-th-3">
                {props.latestActivity}
              </div>
            </div> */}
          </div>
        )}
        {props.stage === "loiSubmitted" && (
          <div className="group-8">
            <div className="group-6">
              <div className="make-money-online-th-2">
                LOI Bid Submitted Amount
              </div>
              <div className="make-money-online-th-3">
                ₹{props.LOIBidSubmittedAmount}Cr
              </div>
            </div>
          </div>
        )}
        {props.stage === "loiAccepted" && (
          <div className="group-8">
            <div className="group-6">
              <div className="make-money-online-th-2">
                LOI Bid Accepted Amount
              </div>
              <div className="make-money-online-th-3">
                ₹{props.LOIBidAcceptedAmount}Cr
              </div>
            </div>
          </div>
        )}
        {props.stage === "dealClosed" && (
          <div className="group-8">
            <div className="group-6">
              <div className="make-money-online-th-2">
                IOI Bid Accepted Amount
              </div>
              <div className="make-money-online-th-3">
                ₹{props.IOIBidAcceptedAmount}Cr
              </div>
            </div>
          </div>
        )}
        <div
          className="badges"
          style={{
            margin:
              props.stage === "requestSent" || props.stage === "requestAccepted"
                ? "auto"
                : "intial",
          }}
        >
          <div className="badge-soft-secondary" ref={containerRef}>
            {visibleTags.map((tag, index) => (
              <div className="badge-base-wrapper" key={index}>
                <div className="badge-base">
                  <div className="elements">
                    <div className="text-wrapper-2">{tag}</div>
                  </div>
                </div>
              </div>
            ))}
            {remainingTags > 0 && (
              <div className="blue-text" style={{ fontSize: "12px" }}>
                +{remainingTags}
              </div>
            )}
          </div>
        </div>
        <div className="group-9">
          <ProgressBar
            totalFundingRaised={props.amountBeingRaised}
            fundingRequired={props.fundingRequired}
          />
        </div>
      </div>
      {(props.stage === "ndaSigned" ||
        props.stage === "ioiSubmitted" ||
        props.stage === "ioiAccepted" ||
        props.stage === "loiSubmitted" ||
        props.stage === "loiAccepted" ||
        props.stage === "spaNegotiation" ||
        props.stage === "dealClosed") && (
        <img
          id={props.index}
          className="streamline-interface"
          alt="Streamline interface"
          src={expand ? contractIcon : expandIcon}
          onClick={() => toggleExpand(props.stage, props.index)}
        />
      )}
    </div>
  );
};

export default OnGoingDealCard;
